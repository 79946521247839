<script setup lang="ts">
import { defineProps } from 'vue'
import {
  useBankAccountEditPageContentStates,
  IBankAccountEditPageContentProps,
  useBankAccountEditPageContentActions,
  BankAccountEditPageContentEmitParams,
} from '.'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import BankAccountForm from '@/vueComponents/photographer/organisms/pageContent/BankAccountEditPageContent/BankAccountForm/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'

interface IProps {
  bankAccountInfo: IBankAccountEditPageContentProps['bankAccountInfo']
  breadcrumbs: IBankAccountEditPageContentProps['breadcrumbs']
}

interface IEmits {
  (
    e: 'update:bank-account-info',
    bankAccountInfo: BankAccountEditPageContentEmitParams['update:bank-account-info']
  ): void
}

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const states = useBankAccountEditPageContentStates(props)
const actions = useBankAccountEditPageContentActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixin;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/colors' as colors;

.header {
  margin-top: space(10);
}
.bread-crumbs,
.account-content-container,
.separator,
.bank-account-form {
  margin-top: space(6);
}
.account-content-container {
  padding: space(8);
}
.save-button-wrapper {
  margin-top: space(12);
  @include position-mixin.flex-vertical();
}
</style>

<template>
  <div>
    <div :class="css.header">
      <Typography type="h1">アカウント</Typography>
      <PageBreadcrumbs
        :class="css['bread-crumbs']"
        :breadcrumbs="props.breadcrumbs"
      />
    </div>
    <ContentBox :class="css['account-content-container']">
      <Typography type="h2-simple"> 振込先 </Typography>
      <Separator :class="css.separator" />
      <BankAccountForm
        :bank-account-info="bankAccountInfo"
        :class="css['bank-account-form']"
        @update:bank-account-info="actions.onUpdateBankAccountInfo"
      />
      <div :class="css['save-button-wrapper']">
        <Button
          type="large"
          color="primary"
          :disabled="states.hasErrorBankAccountForm"
          width="266px"
          :on-click="actions.onClickSaveButton"
          >保存する</Button
        >
      </div>
    </ContentBox>
  </div>
</template>
