<script setup lang="ts">
import { defineProps, defineEmits, withDefaults } from 'vue'
import RadioButton from '@/vueComponents/photographer/atoms/RadioButton/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import TextInput from '@/vueComponents/photographer/molecules/TextInput/index.vue'
import {
  RadioWithLabelEmitParams,
  RadioWithLabelProps,
  useRadioWithLabelStates,
  useRadioWithLabelActions,
} from '.'

interface IProps {
  radioValue: RadioWithLabelProps['radioValue']
  selectedRadioValue: RadioWithLabelProps['selectedRadioValue']
  inputValue?: RadioWithLabelProps['inputValue']
  disabled?: RadioWithLabelProps['disabled']
  disabledInput?: RadioWithLabelProps['disabledInput']
  name: RadioWithLabelProps['name']
  hasInput?: RadioWithLabelProps['hasInput']
  inputWidth?: RadioWithLabelProps['inputWidth']
  placeholder?: RadioWithLabelProps['placeholder']
}

interface IEmits {
  (
    e: 'update:selectedRadioValue',
    value: RadioWithLabelEmitParams['update:selectedRadioValue']
  ): void
  (
    e: 'update:inputValue',
    value: RadioWithLabelEmitParams['update:inputValue']
  ): void
}

const props = withDefaults(defineProps<IProps>(), {
  inputValue: '',
  hasInput: false,
  inputWidth: 'auto',
  placeholder: '',
  disabled: false,
  disabledInput: true,
})
const emits = defineEmits<IEmits>()
const states = useRadioWithLabelStates(props, emits)
useRadioWithLabelActions(props, states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
.radio {
  display: inherit;
}
.radio input[type='radio'] {
  display: none;
}
.label {
  position: relative;
  padding-left: space(6);
}

/** ラベル横に擬似的にカスタマイズしたラジオボタンを配置 */
.label::before {
  position: absolute;
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: colors.$background-color-content-primary;
  left: 0;
  top: 0;
  border: 1px solid colors.$border-color-data-horizontal;
}
.label-inactive::before {
  border: 1px solid colors.$border-color-tab-inactive;
}
.label::after {
  position: absolute;
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  left: 5px;
  top: 5px;
  opacity: 0;
  background-color: colors.$border-color-data-horizontal;
}
.label-inactive::after {
  background-color: colors.$border-color-tab-inactive;
}
.radio input[type='radio']:checked + .label::after {
  opacity: 1;
}
.container {
  display: flex;
  align-items: center;
}
.textInput {
  width: v-bind('props.inputWidth');
  margin-left: space(3);
}
.disabled {
  &-text {
    color: colors.$font-color-disabled;
  }
}
</style>

<template>
  <div :class="css['radio']">
    <label :class="css.container">
      <RadioButton
        v-model:selected-value="states.selectedRadioValue"
        :name="props.name"
        :value="props.radioValue"
        :disabled="props.disabled"
      />
      <Typography
        :class="{
          [css['label']]: true,
          [css['label-inactive']]: props.disabled,
          [css['disabled-text']]: props.disabled,
        }"
        ><slot
      /></Typography>
      <TextInput
        v-if="props.hasInput"
        v-model:value="states.inputValue"
        :name="props.name"
        :placeholder="props.placeholder"
        :class="css['textInput']"
        :disabled="props.disabledInput"
        :gray-out="props.disabled"
      />
    </label>
  </div>
</template>
