<script setup lang="ts">
import { defineProps } from 'vue'
import {
  PasswordUpdateContentProps,
  usePasswordUpdateContentStates,
  usePasswordUpdateContentActions,
} from '.'
import NewPasswordInput from '@/vueComponents/photographer/organisms/input/NewPasswordInput/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import PasswordChangedModal from '@/vueComponents/photographer/organisms/modal/PasswordChangedModal/index.vue'
import Form from '@/vueComponents/photographer/atoms/Form/index.vue'

interface IProps {
  message: PasswordUpdateContentProps['message']
  buttonLabel: PasswordUpdateContentProps['buttonLabel']
  onClickButton: PasswordUpdateContentProps['onClickButton']
  nextLocation: PasswordUpdateContentProps['nextLocation']
}

const props = defineProps<IProps>()

const states = usePasswordUpdateContentStates()

const actions = usePasswordUpdateContentActions(props, states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/position' as position-mixins;
.message {
  margin-top: 7px;
  white-space: pre-line;
  font-size: sizes.$font-size-md;
  line-height: 26px;
}
.btn {
  @include position-mixins.flex-center(true, false);
  margin-top: 26px;
}
</style>

<template>
  <Form @enter="actions.submit">
    <p :class="css.message">{{ props.message }}</p>
    <div>
      <NewPasswordInput
        @update:password="actions.updatePassword"
        @update:validation-code="actions.updateValidationCode"
      />
    </div>
    <div>
      <InputError
        v-for="errorMessage in states.errorMessages"
        :key="errorMessage"
        >{{ errorMessage }}</InputError
      >
    </div>
    <div :class="css.btn">
      <Button type="password-reset" color="old" :on-click="actions.submit">{{
        props.buttonLabel
      }}</Button>
    </div>
  </Form>
  <PasswordChangedModal
    :shown="states.isModalShown"
    :location="props.nextLocation"
  />
</template>
