import { PHOTOGRAPHER } from '@/utils/constants/messages/photographer'

const tabTitle = PHOTOGRAPHER.TAB

export const handleTabItems = (invoiceGenerationFlg: boolean): string[] => {
  const tabItems: string[] = [
    tabTitle.CASE_REQUEST,
    tabTitle.INSPECTION_DELIVERY_REQUEST,
  ]
  if (invoiceGenerationFlg) {
    tabItems.push(tabTitle.INVOICE_REQUEST)
  }
  return tabItems
}
