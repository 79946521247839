<script setup lang="ts">
import ExpenseTable from './Table/index.vue'
import { ExpenseProps, useExpenseStates } from '.'

interface IProps {
  expenses: ExpenseProps['expenses']
  totalAmount: ExpenseProps['totalAmount']
}
const props = defineProps<IProps>()
const states = useExpenseStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/font' as font-mixins;

.container {
  color: colors.$font-color-primary;
}
.title {
  font-size: sizes.$font-size-contentbox-title2;
  @include font-mixins.font-weight-bold;
  margin-bottom: space(6);
}
.table {
  margin-bottom: space(8);
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.remarks {
  font-size: sizes.$font-size-md;
  color: colors.$font-color-gray;
}
.amount {
  width: 257px;
  background-color: colors.$background-color-gray;
  font-size: sizes.$font-size-md;
  line-height: 20px;
  padding: space(4);
  > div {
    display: flex;
    justify-content: space-between;
  }
}
.total-amount {
  @include font-mixins.font-weight-bold;
}
.unit {
  margin-left: space(2);
}
</style>

<template>
  <div :class="css.container">
    <div :class="css.title">●ガソリン以外の諸経費（任意）</div>
    <ExpenseTable :expenses="props.expenses" :class="css.table"></ExpenseTable>
    <div :class="css.bottom">
      <div :class="css.remarks">※手数料は貴社にてご負担ください</div>
      <div :class="css.amount">
        <div :class="css['total-amount']">
          <div>税額総額</div>
          <div>
            {{ states.displayTotalAmount }}<span :class="css.unit">円</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
