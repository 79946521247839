export enum UploadingModalStatus {
  /** 初期状態 */
  INITIAL,
  /** 処理中 */
  UPLOADING,
  /** ファイル数超過 */
  FILE_COUNT_EXCEEDED,
  /** 重複 */
  DUPLICATING,
  /** キャンセル */
  CANCELLED,
  /** 不正ファイルあり */
  ILLEGAL,
  /** 成功 */
  SUCCEEDED,
  /** 一部失敗 */
  PARTIALLY_FAILED,
  /** 通信エラー */
  NETWORK_ERROR,
}
