<script setup lang="ts">
import { useUploadRulesStates } from '.'

const states = useUploadRulesStates()
</script>

<style lang="scss" module="css" scoped>
.container {
  display: flex;
  gap: 8px;
  justify-content: center;
  -webkit-user-drag: none;
}
.img {
  height: 49px;
  width: 116px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
}
</style>

<template>
  <div :class="css.container">
    <img :class="css.img" :src="states.fileFormat" alt="ファイル形式JPEGのみ" />
    <img :class="css.img" :src="states.imageCapacity" alt="1枚の容量20MBまで" />
    <img
      :class="css.img"
      :src="states.folderCapacity"
      alt="1フォルダの枚数500枚まで"
    />
  </div>
</template>
