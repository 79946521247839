import { Computable } from '@/types/utils'
import { reactive } from 'vue'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { AccountInfoProps } from '../../organisms/pageContent/AccountPageContent/AccountInfo'
import { AccountApi } from '@/domains/api/account/AccountApi'
import { onCreate } from '@/utils/functions/lifecycle'
import { useRouter } from 'vue-router'

export interface IAccountStates {
  breadcrumbs: IBreadcrumb[]
  accountInfo: AccountInfoProps | { account: null; company: null }
}

export type IAccountInfoActions = {
  onUpdateAccountInfo: (accountInfo: AccountInfoProps) => Promise<void>
}

export const useAccountInfoEditStates = () => {
  return reactive<Computable<IAccountStates>>({
    breadcrumbs: [
      {
        label: 'ホーム',
        location: { name: photographerRoutes.NAME.TOP },
      },
      {
        label: 'アカウント',
        location: { name: photographerRoutes.NAME.ACCOUNT },
      },
      {
        label: '登録情報編集',
      },
    ],
    accountInfo: {
      company: null,
      account: null,
    },
  })
}

const handleIsCompanyPropertyEmpty = (company: AccountInfoProps['company']) => {
  // companyのプロパティが空もしくは0の場合はnullを返す
  if (Object.values(company).some((value) => value === '' || value === 0)) {
    return {
      name: null,
      name_kana: null,
      location: {
        zipcode: null,
        address: null,
        prefecture_id: null,
      },
    }
  }
  return {
    name: company.name,
    name_kana: company.nameKana,
    location: {
      zipcode: company.location.zipcode,
      address: company.location.address,
      prefecture_id: company.location.prefectureId,
    },
  }
}

const handleIsInvoiceNumberEmpty = (invoiceNumber: string) => {
  return invoiceNumber === '' ? null : invoiceNumber
}

const createAccountParams = (
  account: AccountInfoProps['account'],
  company: AccountInfoProps['company']
) => {
  return {
    name: account.name,
    name_kana: account.nameKana,
    email: account.email,
    tel: account.tel,
    delivery: {
      address: account.delivery.address,
      zipcode: account.delivery.zipcode,
      prefecture_id: account.delivery.prefectureId,
    },
    invoice_number: handleIsInvoiceNumberEmpty(account.invoiceNumber),
    company: handleIsCompanyPropertyEmpty(company),
  }
}

export const useAccountInfoEditActions = (
  states: IAccountStates
): IAccountInfoActions => {
  const router = useRouter()
  const accountApi = new AccountApi()
  const createInitializer = async () => {
    states.accountInfo.account = await accountApi.fetchAccount()
    states.accountInfo.company = await accountApi.fetchCompany()
  }
  onCreate(async () => {
    await createInitializer()
  })
  const updateAccount = async (accountInfo: AccountInfoProps) => {
    const params = createAccountParams(accountInfo.account, accountInfo.company)
    await accountApi.updateAccount(params)
  }
  return {
    async onUpdateAccountInfo(accountInfo: AccountInfoProps) {
      await updateAccount(accountInfo)
      await router.push({
        name: photographerRoutes.NAME.ACCOUNT,
      })
    },
  }
}
