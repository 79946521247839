import { Promisable } from '@/types/utils'
import { reactive } from 'vue'

export interface IDeleteButtonModalProps {
  disabled: boolean
  onDelete: () => Promisable<void>
}

interface IDeleteButtonModalStates {
  isModalOpened: boolean
}

export const useDeleteButtonModalStates = () =>
  reactive<IDeleteButtonModalStates>({
    isModalOpened: false,
  })

type DeleteButtonModalActions = {
  openModal: () => void
  delete: () => Promise<void>
}

export const useDeleteButtonModalActions = (
  props: IDeleteButtonModalProps,
  states: IDeleteButtonModalStates
): DeleteButtonModalActions => ({
  openModal() {
    states.isModalOpened = true
  },
  async delete() {
    await props.onDelete()
    states.isModalOpened = false
  },
})
