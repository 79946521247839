import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { reactive } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export type UploadSlowModeLinkProps = {
  slowModeFlg: boolean
}

type UploadSlowModeLinkStates = {
  slowModePageLocation: RouteLocationRaw
  normalModePageLocation: RouteLocationRaw
}

export const useUploadSlowModeLinkStates = () =>
  reactive<UploadSlowModeLinkStates>({
    slowModePageLocation: {
      name: photographerRoutes.NAME.FOLDER,
      query: { slow: 'true' },
    },
    normalModePageLocation: {
      name: photographerRoutes.NAME.FOLDER,
    },
  })
