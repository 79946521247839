<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import TableHead from '@/vueComponents/photographer/atoms/TableHead/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import CheckboxWithLabel from '@/vueComponents/photographer/molecules/CheckboxWithLabel/index.vue'
import FolderTableFoot from '../FolderTableFoot/index.vue'
import FolderTableRow from '../FolderTableRow/index.vue'
import {
  FolderTableEmitParams,
  FolderTableProps,
  useFolderTableActions,
  useFolderTableStates,
} from '.'

interface IProps {
  editable: FolderTableProps['editable']
  eventDate: FolderTableProps['eventDate']
  eventStatus: FolderTableProps['eventStatus']
  eventName: FolderTableProps['eventName']
  directories: FolderTableProps['directories']
  dispatchId: FolderTableProps['dispatchId']
  checkedIdSet: FolderTableProps['checkedIdSet']
  onUpdateFolderName: FolderTableProps['onUpdateFolderName']
  onDelete: FolderTableProps['onDelete']
}

const props = defineProps<IProps>()

const columnHeads = ['作成日', '写真数', '']

const emits = defineEmits<{
  (
    e: 'update:checkedIdSet',
    value: FolderTableEmitParams['update:checkedIdSet']
  ): void
}>()

const states = useFolderTableStates(props, emits)
const actions = useFolderTableActions(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.foot {
  margin-top: space(4);
}
</style>

<template>
  <Table
    :scrollable="{
      bodyHeight: 48 * 8,
      gridTemplateColumns: ['auto', '112px', '112px', '112px', '120px'],
    }"
  >
    <template #head>
      <TableRow>
        <TableHead type="head" :colspan="2">
          <CheckboxWithLabel
            v-model:checked="states.checkedAll"
            type="tablehead"
            :disabled="states.disabled"
            :hide-checkbox="states.delivered"
          >
            フォルダ名
          </CheckboxWithLabel>
        </TableHead>
        <TableHead v-for="columnHead in columnHeads" :key="columnHead">
          <Typography v-if="columnHead" :type="states.headTextType">{{
            columnHead
          }}</Typography>
        </TableHead>
      </TableRow>
    </template>
    <FolderTableRow
      v-for="directory in states.directories"
      :key="directory.directory.id"
      v-bind="directory"
      @update:checked="actions.checkFolder"
    />
    <template #foot>
      <FolderTableFoot
        :class="css.foot"
        :is-delete-button-shown="!states.delivered"
        :checked-folders-count="props.checkedIdSet.size"
        :on-delete="props.onDelete"
        :folders-count="props.directories.length"
        :photos-count="states.photosCount"
      />
    </template>
  </Table>
</template>
