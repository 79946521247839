import { Computable } from '@/types/utils'
import { IDeliveryModalProps } from '@/vueComponents/photographer/organisms/modal/DeliveryModal'
import { computed, reactive, ref } from 'vue'

export type DeliveryButtonProps = {
  editable: boolean
  deliverable: boolean
  sellerName: IDeliveryModalProps['sellerName']
  eventName: IDeliveryModalProps['eventName']
  sendingBack: boolean
  onSubmit: IDeliveryModalProps['onSubmit']
}

type DeliveryButtonStates = {
  isModalOpened: boolean
  readonly buttonLabel: string
  readonly disabled: boolean
  fixed: boolean
}

export const useDeliveryButtonStates = (
  props: DeliveryButtonProps
): DeliveryButtonStates => {
  const fixedRef = ref<DeliveryButtonStates['fixed']>(false)
  return reactive<Computable<DeliveryButtonStates>>({
    isModalOpened: false,
    buttonLabel: computed(() =>
      props.sendingBack
        ? '差し戻した写真の再検品依頼をする'
        : 'すべて検品依頼をする'
    ),
    disabled: computed(
      () =>
        !props.editable ||
        !props.deliverable ||
        (props.sendingBack && !fixedRef.value)
    ),
    fixed: fixedRef,
  })
}

type DeliveryButtonActions = {
  openModal: () => void
}

export const useDeliveryButtonActions = (
  states: DeliveryButtonStates
): DeliveryButtonActions => ({
  openModal() {
    states.isModalOpened = true
  },
})
