<script setup lang="ts">
import EventPageContent from '@/vueComponents/photographer/organisms/pageContent/EventPageContent/index.vue'
import { useEventActions, useEventStates } from '.'

const states = useEventStates()

const actions = useEventActions(states)
</script>

<template>
  <div>
    <EventPageContent
      v-if="states.eventInfo"
      :seller-name="states.eventTitle.sellerName"
      :event-name="states.eventTitle.eventName"
      :event-info="states.eventInfo"
      :dispatch-id="states.dispatchId"
      :directories="states.directories"
      :on-create-folder="actions.createFolder"
      :on-delete-folder="actions.onDeleteFolder"
      :on-update-folder-name="actions.updateFolderName"
      :on-delivery="actions.onDelivery"
    />
  </div>
</template>
