import { Computable, Emits, Promisable } from '@/types/utils'
import { computed, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { IDeliverySettingDialogProps } from './DeliverySettingDialog'
import { photographerRoutes } from '@/vue/routers/photographer/routes'

const { NAME: ROUTE_NAME } = photographerRoutes

export interface IDeliveryModalProps {
  shown: boolean
  sellerName: IDeliverySettingDialogProps['sellerName']
  eventName: IDeliverySettingDialogProps['eventName']
  onSubmit?: (deliveryMemo: string) => Promisable<string | void>
}

export type DeliveryModalEmitParams = {
  'update:shown': IDeliveryModalProps['shown']
}

type IDeliveryModalStates = {
  shown: boolean
  isDelivered: boolean
  value: string
  readonly title: string
  readonly submitLabel: string
  readonly cancelLabel: string
}

export const useDeliveryModalStates = (
  props: IDeliveryModalProps,
  emits: Emits<DeliveryModalEmitParams>
): IDeliveryModalStates => {
  const isDeliveredRef = ref<IDeliveryModalStates['isDelivered']>(false)
  return reactive<Computable<IDeliveryModalStates>>({
    shown: computed({
      get() {
        return props.shown
      },
      set(value) {
        emits('update:shown', value)
      },
    }),
    isDelivered: isDeliveredRef,
    value: '',
    title: computed(() =>
      !isDeliveredRef.value ? '検品依頼' : '検品依頼が完了しました'
    ),
    submitLabel: computed(() =>
      !isDeliveredRef.value ? '検品依頼をする' : 'イベント一覧へ戻る'
    ),
    cancelLabel: computed(() =>
      !isDeliveredRef.value ? 'キャンセル' : '閉じる'
    ),
  })
}

type DeliveryModalActions = {
  submit: () => Promise<void>
  linkToEvents: () => Promise<void>
  reload: () => void
}

export const useDeliveryModalActions = (
  states: IDeliveryModalStates,
  props: IDeliveryModalProps
): DeliveryModalActions => {
  const router = useRouter()

  return {
    async submit() {
      if (props.onSubmit) await props.onSubmit(states.value)
      states.isDelivered = true
    },
    async linkToEvents() {
      await router.push({ name: ROUTE_NAME.EVENTS })
    },
    reload() {
      window.location.reload()
    },
  }
}
