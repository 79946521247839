<script setup lang="ts">
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import HelpIcon from '@/vueComponents/photographer/molecules/HelpIcon/index.vue'

const MESSAGE = `
  下記例をご参考にフォルダ名を入力してください。

  ●プログラムなしの場合
  ・クラスごと：「すみれ組」、「うめ組」等
  ・撮影シーンごと：「日常風景」、「家族写真」等

  ●プログラムありの場合
  ・プログラムごと：「劇」、「合唱」等
  `
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.content {
  position: relative;
  width: 340px;
}
</style>

<template>
  <div>
    <HelpIcon>
      <div :class="css.content">
        <Typography type="head">フォルダ名の命名ルールについて</Typography>
        <Typography element="p" :line-breakable="true">{{
          MESSAGE
        }}</Typography>
      </div>
    </HelpIcon>
  </div>
</template>
