import { Computable } from '@/types/utils'
import { computed, reactive } from 'vue'

export type SpeechBubbleProps = {
  shown: boolean
  top?: string
  position: number
}

type SpeechBubbleStates = {
  position: string
  transform: string
}

export const useSpeechBubbleStates = (props: SpeechBubbleProps) =>
  reactive<Computable<SpeechBubbleStates>>({
    position: computed(() => `${props.position}%`),
    transform: computed(() => `-${props.position}%`),
  })
