<script setup lang="ts">
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import InvoiceDetailTableRow from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTableRow/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import AddRowButton from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/AddRowButton/index.vue'
import { photographerConstants } from '@/utils/constants/photographerConstants'
import {
  InvoiceDetailTableEmitParams,
  InvoiceDetailTableProps,
  useInvoiceDetailTableActions,
  useInvoiceDetailTableStates,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable'

interface IProps {
  details: InvoiceDetailTableProps['details']
  gasUnitPrice: InvoiceDetailTableProps['gasUnitPrice']
}
interface IEmits {
  (
    e: 'update:details',
    value: InvoiceDetailTableEmitParams['update:details']
  ): void
}

const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useInvoiceDetailTableStates(props)
const actions = useInvoiceDetailTableActions(props, states, emits)

defineExpose({
  validate: actions.validate,
})
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;

th {
  vertical-align: middle;
}
.th-gas-cost-title {
  box-sizing: border-box;
  border: 1px solid colors.$border-color-white;
  border-top: none;
}
.tr-gas-cost-item {
  th {
    border-right: 1px solid colors.$border-color-white;

    &:first-child {
      border-left: 1px solid colors.$border-color-white;
    }
  }
}
.th-title-align-left {
  text-align: left;
  padding-left: space(2.5);
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: space(6);
}
.amount-block {
  background: colors.$background-color-gray;
  padding: space(4);
  width: 289px;
  box-sizing: border-box;
  dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  dt {
    width: 60%;
  }

  dd {
    width: 40%;
    text-align: right;
  }
}
.total-amount-including-tax {
  font-weight: bold;
}
</style>

<template>
  <div>
    <Table>
      <template #head>
        <TableRow type="head" height="40px">
          <th scope="col">
            <Typography type="read-normal-bold" element="p">No.</Typography>
          </th>
          <th scope="col" :class="css['th-title-align-left']">
            <Typography type="read-normal-bold" element="p">撮影日</Typography>
          </th>
          <th scope="col" :class="css['th-title-align-left']">
            <Typography type="read-normal-bold" element="p">内容</Typography>
          </th>
          <th scope="col" :class="css['th-title-align-left']">
            <Typography type="read-normal-bold" element="p"
              >撮影費（税別）</Typography
            >
          </th>
          <TableRow type="head" height="20px">
            <th :colspan="3" scope="colgroup" :class="css['th-gas-cost-title']">
              <Typography type="read-small-bold" element="p"
                >出張手当（ガソリン代）</Typography
              >
            </th>
          </TableRow>
          <TableRow type="head" :class="css['tr-gas-cost-item']" height="20px">
            <th scope="col" width="95px">
              <Typography type="read-small-bold" element="p">距離</Typography>
            </th>
            <th scope="col" width="63px">
              <Typography type="read-small-bold" element="p">単価</Typography>
            </th>
            <th scope="col" width="95px">
              <Typography type="read-small-bold" element="p">金額</Typography>
            </th>
          </TableRow>
          <th scope="col">
            <Typography type="read-normal-bold" element="p">操作</Typography>
          </th>
        </TableRow>
      </template>
      <InvoiceDetailTableRow
        v-for="item in states.details"
        :id="item.id"
        :key="item.rowNo"
        :detail-input="{
          date: actions.handleFormatDate(item.detailInput.date, item.canEdit),
          content: item.detailInput.content,
          amount: item.detailInput.amount,
          mileage: item.detailInput.mileage,
        }"
        :gas-unit-price="props.gasUnitPrice"
        :row-no="item.rowNo"
        :can-edit="item.canEdit"
        :has-row-error="item.hasRowError"
        :dispatch-cameraman-id="item.dispatchCameramanId"
        @update:detail-input="actions.updateDetailInput($event, item.rowNo)"
        @delete-row="actions.deleteRow(item.rowNo)"
      />
    </Table>
    <div :class="css.footer">
      <AddRowButton @add-row="actions.addRow" />
      <div :class="css['amount-block']">
        <dl>
          <dt>小計</dt>
          <dd>{{ states.subTotal.toLocaleString() }} 円</dd>
          <dt>適用税率</dt>
          <dd>{{ photographerConstants.CONSUMPTION_TAX_RATE * 100 }} %</dd>
          <dt>消費税額</dt>
          <dd>{{ states.taxAmount.toLocaleString() }} 円</dd>
          <dt :class="css['total-amount-including-tax']">税込総額</dt>
          <dd :class="css['total-amount-including-tax']">
            {{ states.totalAmountIncludingTax.toLocaleString() }} 円
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
