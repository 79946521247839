<script setup lang="ts">
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import { AcceptAndRejectButtonProps, useAcceptAndRejectButtonActions } from '.'
import { defineProps } from 'vue'
import { ReplyStatus } from '@/utils/constants/enums/photographer/dispatchOrder'

interface IProps {
  disableAccept: AcceptAndRejectButtonProps['disableAccept']
  disableReject: AcceptAndRejectButtonProps['disableReject']
  onReply: AcceptAndRejectButtonProps['onReply']
  dispatchOrderId: AcceptAndRejectButtonProps['dispatchOrderId']
  transportation: AcceptAndRejectButtonProps['transportation']
  otherTransportation: AcceptAndRejectButtonProps['otherTransportation']
}

const props = defineProps<IProps>()
const actions = useAcceptAndRejectButtonActions(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/functions/space' as *;
.content {
  margin: auto;
  width: 288px;
  @include space-mixins.vertical-margin(8);
}
.button-space {
  @include space-mixins.vertical-margin(4, 4);
}
</style>

<template>
  <div :class="css.content">
    <Button
      :disabled="props.disableAccept"
      @click="actions.reply(ReplyStatus.ACCEPTANCE)"
      >引き受ける</Button
    >
    <Button
      :class="css['button-space']"
      :disabled="props.disableReject"
      color="secondary"
      @click="actions.reply(ReplyStatus.REFUSE)"
      >今回は断る</Button
    >
  </div>
</template>
