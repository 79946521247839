<script setup lang="ts">
import { defineEmits, defineProps, ref } from 'vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import AtomsInput from '@/vueComponents/photographer/atoms/Input/index.vue'
import { photographerConstants } from '@/utils/constants/photographerConstants'
import {
  ExpenseTableRowProps,
  ExpenseTableRowEmitPrams,
  useExpenseTableRowStates,
  useExpenseTableRowActions,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTableRow'

interface IProps {
  id: ExpenseTableRowProps['id']
  rowNo: ExpenseTableRowProps['rowNo']
  expenseInput: ExpenseTableRowProps['expenseInput']
  fetchFileUrl?: ExpenseTableRowProps['fetchFileUrl']
  hasRowError: ExpenseTableRowProps['hasRowError']
  hasFileError: ExpenseTableRowProps['hasFileError']
  fileName: ExpenseTableRowProps['fileName']
}
const props = withDefaults(defineProps<IProps>(), {
  fetchFileUrl: undefined,
})

interface IEmits {
  (
    e: 'update:expense-input',
    value: ExpenseTableRowEmitPrams['update:expense-input']
  ): void
  (e: 'delete-row'): void
  (e: 'set-file', value: ExpenseTableRowEmitPrams['set-file']): void
}
const emits = defineEmits<IEmits>()

const states = useExpenseTableRowStates(props)

const fileInputElement = ref<HTMLInputElement>()
const fileDivElement = ref<HTMLDivElement>()
const actions = useExpenseTableRowActions(
  props,
  states,
  emits,
  fileInputElement,
  fileDivElement
)
</script>

<style lang="scss" module="css">
@use '@/styles/functions/space' as *;
@use '@/styles/sizes' as sizes;
@use '@/styles/colors' as colors;
@use '@/styles/mixins/position' as position-mixin;
@use '@/styles/mixins/hover' as hover-mixins;

.row {
  font-size: sizes.$font-size-md;
  height: 54px;
}
.date-input {
  font-family: 'Noto Sans JP';
  min-width: 143px;
  box-sizing: border-box;
}
.file {
  display: flex;
  align-items: center;
  gap: space(2);
}
.file-btn-block {
  cursor: pointer;
}
.file-btn {
  border: 1px solid #b6b6b6;
  border-radius: 4px;
  background-color: #f3f3f3;
  height: 32px;
  width: 32px;
  position: relative;
  @include hover-mixins.hover-opacity;
}
.file-name {
  width: 117px;
  font-size: sizes.$font-size-sm;

  &-error {
    color: colors.$font-color-file-error;
  }
  &-none {
    color: colors.$font-color-file-none;
  }
  &-exists {
    color: colors.$font-color-link;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include hover-mixins.hover-opacity;
  }
}
.file-name-tooltip {
  position: absolute;
  background-color: colors.$background-color-tooltip;
  border: solid 1px colors.$border-color-tooltip;
  padding: space(1) space(2);
  color: colors.$font-color-tooltip;
  z-index: 10;
}
.file-name-link {
  cursor: pointer;
  color: colors.$font-color-link;
}
.clip-icon {
  @include position-mixin.translate-center;
}
.tax {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: space(1);
}
.amount {
  display: flex;
  align-items: center;
  gap: space(1);
}
.delete-icon-block {
  cursor: pointer;
}
.delete-icon {
  color: colors.$font-color-primary;
  @include hover-mixins.hover-opacity;
}

// セル幅
.row-no-cell {
  width: 40px;
  min-width: 40px;
}
.date-cell {
  width: 128px;
  min-width: 128px;
}
.content-cell {
  width: 400px;
}
.file-cell {
  width: 176px;
  min-width: 176px;
}
.tax-cell {
  width: 80px;
  min-width: 80px;
}
.amount-cell {
  width: 168px;
  min-width: 168px;
}
.amount-input {
  text-align: right;
}
.delete-cell {
  width: 64px;
  min-width: 64px;
  text-align: center;
}
</style>

<template>
  <TableRow :type="states.tableRowBgColor" :class="css.row">
    <TableCell :separatorable="false" :class="css['row-no-cell']">
      {{ props.rowNo }}.
    </TableCell>
    <TableCell :separatorable="false" :class="css['date-cell']">
      <AtomsInput
        v-model:value="states.expenseInput.date"
        type="date"
        name="date"
        :class="css['date-input']"
      />
    </TableCell>
    <TableCell :separatorable="false" :class="css['content-cell']">
      <AtomsInput
        v-model:value="states.expenseInput.content"
        type="text"
        name="content"
        maxlength="50"
        :placeholder="'電車代、高速料金代、駐車代\u3000等'"
      />
    </TableCell>
    <TableCell :class="css['file-cell']">
      <div :class="css.file">
        <div :class="css['file-btn-block']">
          <div :class="css['file-btn']" @click="actions.clickFileInputElement">
            <Icon type="paperclip" width="14px" :class="css['clip-icon']" />
          </div>
        </div>

        <input
          ref="fileInputElement"
          type="file"
          name="file"
          accept="image/jpeg, application/pdf"
          hidden
          @change="actions.setFile"
        />
        <div :class="css['file-name']">
          <div
            v-if="states.isShowFileErrorMessage"
            :class="css['file-name-error']"
          >
            失敗しました
          </div>
          <div
            v-else-if="!states.expenseInput.originalFileName"
            :class="css['file-name-none']"
          >
            選択されていません
          </div>
          <div v-else ref="fileDivElement" :class="css['file-name-exists']">
            <span
              :class="css['file-name-link']"
              @mouseover="states.isHover = true"
              @mouseleave="states.isHover = false"
              @click="actions.openFile"
            >
              {{ states.expenseInput.originalFileName }}
            </span>
          </div>
          <div
            v-if="states.isHover && states.isOverFlow"
            :class="css['file-name-tooltip']"
          >
            {{ states.expenseInput.originalFileName }}
          </div>
        </div>
      </div>
    </TableCell>
    <TableCell :class="css['tax-cell']">
      <div :class="css.tax">
        <span>{{ photographerConstants.CONSUMPTION_TAX_RATE * 100 }}</span>
        <span>%</span>
      </div>
    </TableCell>
    <TableCell :class="css['amount-cell']">
      <div :class="css.amount">
        <AtomsInput
          v-model:value="states.expenseInput.amount"
          type="text"
          name="amount"
          placeholder="0"
          :class="css['amount-input']"
          @input="actions.handleAmountInput"
        />
        <span>円</span>
      </div>
    </TableCell>
    <TableCell :separatorable="false" :class="css['delete-cell']">
      <div :class="css['delete-icon-block']" @click="actions.deleteRow">
        <Icon type="trash-alt" width="14px" :class="css['delete-icon']" />
      </div>
    </TableCell>
  </TableRow>
</template>
