<script setup lang="ts">
import { useAccountEditBankStates, useAccountEditBankActions } from '.'
import BankAccountEditPageContent from '@/vueComponents/photographer/organisms/pageContent/BankAccountEditPageContent/index.vue'

const states = useAccountEditBankStates()
const actions = useAccountEditBankActions(states)
</script>

<template>
  <BankAccountEditPageContent
    :key="states.bankAccountInfo.bankCode"
    :bank-account-info="states.bankAccountInfo"
    :breadcrumbs="states.breadcrumbs"
    @update:bank-account-info="actions.onUpdateBankAccountInfo"
  />
</template>
