import { ValueOf } from '@/types/utils'
import { UploadingModalStatus } from '@/utils/constants/enums/photographer/folder'
import { uploadHeaderType } from '@/vueComponents/photographer/organisms/pageContent/FolderPageContent/Upload/UploadingModal'

type UploadHeaderType = ValueOf<typeof uploadHeaderType>

export const convertUploadModalHeader = (
  uploadStatus: UploadingModalStatus
): UploadHeaderType => {
  switch (uploadStatus) {
    case UploadingModalStatus.SUCCEEDED:
    case UploadingModalStatus.PARTIALLY_FAILED:
      return uploadHeaderType.completed
    case UploadingModalStatus.DUPLICATING:
      return uploadHeaderType.duplication
    case UploadingModalStatus.FILE_COUNT_EXCEEDED:
    case UploadingModalStatus.ILLEGAL:
    case UploadingModalStatus.NETWORK_ERROR:
      return uploadHeaderType.failed
    case UploadingModalStatus.INITIAL:
    case UploadingModalStatus.UPLOADING:
      return uploadHeaderType.progress
    default:
      return uploadHeaderType.progress
  }
}
