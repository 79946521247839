<script setup lang="ts">
import { defineEmits, defineProps, withDefaults } from 'vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import {
  ScheduleRegistrationButtonParams,
  ScheduleRegistrationButtonProps,
  useScheduleRegistrationButtonActions,
} from '.'

interface IProps {
  id: ScheduleRegistrationButtonProps['id']
  activeId: ScheduleRegistrationButtonProps['activeId']
  disabled?: ScheduleRegistrationButtonProps['disabled']
  type: ScheduleRegistrationButtonProps['type']
}

interface IEmits {
  (
    e: 'update:activeId',
    id: ScheduleRegistrationButtonParams['update:activeId']
  ): void
}

const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
})

const emits = defineEmits<IEmits>()
const actions = useScheduleRegistrationButtonActions(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/button' as button-mixins;
$radius: 16px;
.container {
  position: relative;
  width: #{$radius * 2};
  height: #{$radius * 2};
  border-radius: $radius;
  box-sizing: border-box;
  &-disabled {
    background-color: colors.$background-color-content-primary;
    border: 1px solid colors.$button-color-secondary-disabled;
    box-shadow: 0 2px colors.$button-color-secondary-disabled;
    cursor: not-allowed;
  }
  &-active {
    background-color: colors.$background-color-primary;
    box-shadow: 0 2px colors.$button-shadow-color-primary;
    cursor: pointer;
    @include button-mixins.button-hover(colors.$button-shadow-color-primary);
  }
  &-inactive {
    background-color: colors.$background-color-secondary-false;
    border: 1px solid colors.$border-color-secondary-false;
    box-shadow: 0 2px colors.$button-shadow-color-secondary-false;
    cursor: pointer;
    @include button-mixins.button-hover(colors.$button-color-hover-gray);
  }
}
.icon {
  @include position-mixins.translate-center;
  padding-top: 3px;
  &-active {
    color: colors.$button-color-primary !important;
  }
  &-inactive {
    color: colors.$button-color-secondary-false !important;
  }
  &-disabled {
    color: colors.$button-color-secondary-disabled !important;
  }
}
.circle {
  @include position-mixins.translate-center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: solid 3px;
  &-active {
    border-color: colors.$button-color-primary;
  }
  &-inactive {
    border-color: colors.$button-color-secondary-false;
  }
  &-disabled {
    border-color: colors.$button-color-secondary-disabled;
  }
}
</style>

<template>
  <div
    :class="[
      css.container,
      props.disabled
        ? css['container-disabled']
        : props.activeId === props.id
        ? css['container-active']
        : css['container-inactive'],
    ]"
    @click="actions.onClick"
  >
    <div v-if="props.type === 'cross'" :class="css.icon">
      <Icon
        :class="[
          props.disabled
            ? css['icon-disabled']
            : props.activeId === props.id
            ? css['icon-active']
            : css['icon-inactive'],
        ]"
        type="close"
        width="14px"
      />
    </div>
    <div
      v-else
      :class="[
        css.circle,
        props.disabled
          ? css['circle-disabled']
          : props.activeId === props.id
          ? css['circle-active']
          : css['circle-inactive'],
      ]"
    />
  </div>
</template>
