import { Computable, Emits } from '@/types/utils'
import {
  parseDateYearToString,
  parseTimeToString,
} from '@/utils/functions/parser'
import { computed, reactive } from 'vue'

const ROWS_INCREMENT = 10
const INITIAL_VISIBLE_ROWS = 10

export type TransmissionHistoryTable = {
  submittedDate: string
  fileName: string
  submittedId: number
}

export type TransmissionHistoryTableProps = {
  tableList: TransmissionHistoryTable[]
}

type TransmissionHistoryTableStates = {
  tableList: {
    submittedId: number
    fileName: string
    dateYear: string
    dateTime: string
  }[]
  visibleTableList: {
    submittedId: number
    fileName: string
    dateYear: string
    dateTime: string
  }[]
  visibleRows: number
  isTableListGreaterThanInitialVisibleRows: boolean
  isAllTableListVisible: boolean
  isButtonShow: boolean
}

const createTableList = (
  tableList: TransmissionHistoryTableProps['tableList']
) => {
  return tableList.map((table) => {
    return {
      submittedId: table.submittedId,
      fileName: table.fileName,
      dateYear: parseDateYearToString(new Date(table.submittedDate)),
      dateTime: parseTimeToString(new Date(table.submittedDate)),
    }
  })
}

export const useTransmissionHistoryTableStates = (
  props: TransmissionHistoryTableProps
) => {
  const states: TransmissionHistoryTableStates = reactive<
    Computable<TransmissionHistoryTableStates>
  >({
    tableList: computed(() => createTableList(props.tableList)),
    visibleTableList: computed(() => {
      return states.tableList.slice(0, states.visibleRows)
    }),
    visibleRows: INITIAL_VISIBLE_ROWS,
    isTableListGreaterThanInitialVisibleRows: computed(
      () => states.tableList.length > INITIAL_VISIBLE_ROWS
    ),
    isAllTableListVisible: computed(
      () => states.visibleTableList.length === states.tableList.length
    ),
    isButtonShow: computed(
      () =>
        states.isTableListGreaterThanInitialVisibleRows &&
        !states.isAllTableListVisible
    ),
  })
  return states
}

export type TransmissionHistoryTableEmitsParams = {
  'download:pdf': number
}

type TransmissionHistoryTableActions = {
  onClickButton: () => void
  onClickIcon: (submittedId: number) => void
}

export const useTransmissionHistoryTableActions = (
  states: TransmissionHistoryTableStates,
  emits: Emits<TransmissionHistoryTableEmitsParams>
): TransmissionHistoryTableActions => {
  return {
    onClickButton: () => {
      states.visibleRows += ROWS_INCREMENT
    },
    onClickIcon: (submittedId) => {
      emits('download:pdf', submittedId)
    },
  }
}
