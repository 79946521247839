import { validateNotEntered } from '@/utils/functions/validation/validation'
import { reactive } from 'vue'

export interface NameInputProps {
  modelValue: string
}

export interface NameInputStates {
  NameErrorMessage: string
}

interface NameInputEmits {
  (e: 'update:modelValue', value: string): void
}

export const useNameInputStates = () => {
  return reactive<NameInputStates>({
    NameErrorMessage: '',
  })
}

type NameActions = {
  handleInputName(e: Event): void
  handleBlurName(e: Event): void
}

export const useNameInputActions = (
  states: NameInputStates,
  emits: NameInputEmits
): NameActions => {
  return {
    handleInputName(e: Event) {
      states.NameErrorMessage = ''
      const target = e.target as HTMLInputElement
      emits('update:modelValue', target.value)
    },
    handleBlurName(e: Event) {
      const target = e.target as HTMLInputElement
      if (validateNotEntered(target.value)) {
        states.NameErrorMessage = '未入力です'
      }
    },
  }
}
