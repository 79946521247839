import {
  CreatingStore,
  creatingStoreKey,
} from '@/vue/stores/global/CreatingStore'
import { SelfStore, selfStoreKey } from '@/vue/stores/photographer/SelfStore'
import { Stores } from '..'
import { EventsStore, eventsStoreKey } from './EventsStore'
import { EventStore, eventStoreKey } from './EventStore'
import { FolderStore, folderStoreKey } from './FolderStore'
import { InvoiceStore, invoiceStoreKey } from './InvoiceStore'

export const photographerStores = new Stores([
  [creatingStoreKey, CreatingStore],
  [selfStoreKey, SelfStore],
  [eventsStoreKey, EventsStore],
  [eventStoreKey, EventStore],
  [folderStoreKey, FolderStore],
  [invoiceStoreKey, InvoiceStore],
])
