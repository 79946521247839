import { Computable, Emits } from '@/types/utils'
import { PhotoUploadStatus } from '@/utils/constants/enums/photographer/photo'
import { PHOTOGRAPHER } from '@/utils/constants/messages/photographer'
import { convertErrorMsgPhoto } from '@/utils/functions/errorMsg'
import { parseDateYearToString } from '@/utils/functions/parser'
import { CheckboxProps } from '@/vueComponents/photographer/molecules/Checkbox'
import { IThumbnailProps } from '@/vueComponents/photographer/atoms/Thumbnail'
import { computed, reactive } from 'vue'
import { IEventInfo } from '@/types/photographer/event'

export interface IPhotoInfoProps {
  status: PhotoUploadStatus
  thumbnailUrlGetter?: IThumbnailProps['lazyLoadUrlGetter']
  photoId: number
  checked: CheckboxProps['checked']
  fileName: string
  photographed?: Date
  eventStatus: IEventInfo['status']
}

export type PhotoInfoEmitParams = {
  'update:checked': {
    [photoId: number]: IPhotoInfoProps['checked']
  }
}

interface IPhotoInfoStates {
  checked: CheckboxProps['checked']
  photographedStr: string
  thumbnailUrlGetter: IPhotoInfoProps['thumbnailUrlGetter']
  errorMessage: IThumbnailProps['errorMsg']
}

export const usePhotoInfoStates = (
  props: IPhotoInfoProps,
  emits: Emits<PhotoInfoEmitParams>
) =>
  reactive<Computable<IPhotoInfoStates>>({
    checked: computed({
      get() {
        return props.checked
      },
      set(value) {
        const photoId = props.photoId
        const checkedPhotoObj = {
          [photoId]: value,
        }
        emits('update:checked', checkedPhotoObj)
      },
    }),
    photographedStr: computed(() =>
      props.photographed
        ? parseDateYearToString(props.photographed)
        : PHOTOGRAPHER.UNDEFINED_DATE
    ),
    thumbnailUrlGetter: computed(() => {
      return (
        (props.status === PhotoUploadStatus.COMPLETE &&
          props.thumbnailUrlGetter) ||
        undefined
      )
    }),
    errorMessage: computed(() => convertErrorMsgPhoto(props.status)),
  })
