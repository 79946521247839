<script setup lang="ts">
import { defineProps } from 'vue'
import {
  useAccountInfoFormStates,
  useAccountInfoFormActions,
  AccountInfoFormEmitParams,
  AccountInfoFormProps,
} from '.'
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import MustLabel from '@/vueComponents/photographer/atoms/MustLabel/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import { PREFECTURES_MAP } from '@/utils/constants/global/prefectures'

import InvoiceNumberInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Account/InvoiceNumberInput/index.vue'
import EmailInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Account/EmailInput/index.vue'
import TelInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Account/TelInput/index.vue'
import NameInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Account/NameInput/index.vue'
import NameKanaInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Account/NameKanaInput/index.vue'
import ZipcodeInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Account/ZipcodeInput/index.vue'
import PrefectureInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Account/PrefectureInput/index.vue'
import AddressInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Account/AddressInput/index.vue'
import CompanyName from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Company/NameInput/index.vue'
import CompanyNameKana from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Company/NameKanaInput/index.vue'
import CompanyZipcodeInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Company/ZipcodeInput/index.vue'
import CompanyPrefectureInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Company/PrefectureInput/index.vue'
import CompanyAddressInput from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/AccountInfoTableRow/Company/AddressInput/index.vue'

interface IProps {
  account: AccountInfoFormProps['account']
  company: AccountInfoFormProps['company']
  validationFlg: AccountInfoFormProps['validationFlg']
}

interface IEmits {
  (
    e: 'update:account-info',
    { account, company }: AccountInfoFormEmitParams['update:account-info']
  ): void
  (e: 'validation:off'): void
}

const prefecturesOptions = [
  { value: 0, label: '都道府県' },
  ...Array.from(PREFECTURES_MAP).map(([value, label]) => ({ value, label })),
]

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const states = useAccountInfoFormStates(props)
const actions = useAccountInfoFormActions(states, props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as space-positions;
@use '@/styles/mixins/font' as font-mixins;
@use '@/styles/colors' as colors;

.title {
  @include font-mixins.font-weight-bold();
}
.table-cell div,
.col {
  @include space-positions.flex-horizontal(space(2));
}
.table-cell {
  padding: space(3) space(2);
  width: 256px;
}
.table-name {
  margin-top: space(12);
}
.radio-group {
  @include space-positions.flex-horizontal(space(10));
}
.icon {
  margin-left: space(1);
}
.text-input-error {
  border-radius: 2px;
  border: 1px solid colors.$border-color-attention;
  background: colors.$background-color-danger;
}
.input-error {
  margin-top: space(1);
}
.address {
  display: flex;
  margin-top: space(2);
}
.prefecture {
  margin-right: space(2);
}
</style>
<template>
  <div>
    <Table>
      <CompanyName
        v-model="states.company.name"
        :company-name-error-message="states.companyNameErrorMessage"
        @input:company-name="actions.handleInputField('companyName')"
      />
      <CompanyNameKana
        v-model="states.company.nameKana"
        :company-name-kana-error-message="states.companyNameKanaErrorMessage"
        @input:company-name-kana="actions.handleInputField('companyNameKana')"
      />
      <TableRow>
        <TableCell :separatorable="false" :class="css['table-cell']">
          <Typography :class="css.title">所在地</Typography>
        </TableCell>
        <TableCell :separatorable="false">
          <CompanyZipcodeInput
            v-model="states.company.location.zipcode"
            :location-zipcode-error-message="states.locationZipcodeErrorMessage"
            @input:zipcode="actions.handleInputField('locationZipcode')"
          />
          <div :class="css['address']">
            <div :class="css['prefecture']">
              <CompanyPrefectureInput
                v-model="states.company.location.prefectureId"
                :prefectures-options="prefecturesOptions"
                :location-prefecture-error-message="
                  states.locationPrefectureErrorMessage
                "
                @input:prefecture="
                  actions.handleInputField('locationPrefecture')
                "
              />
            </div>
            <div>
              <CompanyAddressInput
                v-model="states.company.location.address"
                :location-address-error-message="
                  states.locationAddressErrorMessage
                "
                @input:address="actions.handleInputField('locationAddress')"
              />
            </div>
          </div>
          <InputError
            v-if="
              states.locationPrefectureErrorMessage ||
              states.locationAddressErrorMessage
            "
            type="attention"
            :class="css['input-error']"
            >{{
              states.locationPrefectureErrorMessage ||
              states.locationAddressErrorMessage
            }}</InputError
          >
        </TableCell>
      </TableRow>
    </Table>
    <Table :class="css['table-name']">
      <NameInput v-model="states.account.name" />
      <NameKanaInput v-model="states.account.nameKana" />
      <EmailInput v-model="states.account.email" />
      <TelInput v-model:model-value="states.account.tel" />
      <TableRow>
        <TableCell :separatorable="false" :class="css['table-cell']">
          <Typography :class="css.title"
            >住所
            <MustLabel />
          </Typography>
        </TableCell>
        <TableCell :separatorable="false">
          <ZipcodeInput v-model="states.account.delivery.zipcode" />
          <div :class="css['address']">
            <div :class="css['prefecture']">
              <PrefectureInput
                v-model="states.account.delivery.prefectureId"
                :prefectures-options="prefecturesOptions"
              />
            </div>
            <AddressInput v-model="states.account.delivery.address" />
          </div>
        </TableCell>
      </TableRow>
      <InvoiceNumberInput v-model="states.account.invoiceNumber" />
    </Table>
  </div>
</template>
