import { Emits } from '@/types/utils'
import { Ref, reactive, watch } from 'vue'
import { InvoiceDetail } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable'
import InvoiceDetailTable from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable/index.vue'

export type InvoiceDetailProps = {
  note: string
  details: InvoiceDetail[]
  gasUnitPrice: number
}

export type InvoiceDetailStates = {
  note: string
  details: InvoiceDetail[]
  hasError: boolean
}

export type InvoiceDetailEmitParams = {
  'update:invoice-details': InvoiceDetailStates
}

export const useInvoiceDetailStates = (props: InvoiceDetailProps) => {
  const states: InvoiceDetailStates = reactive({
    details: [],
    note: props.note,
    hasError: false,
  })
  return states
}

const watchStatesNote = (
  states: InvoiceDetailStates,
  emits: Emits<InvoiceDetailEmitParams>
) => {
  watch(
    () => states.note,
    (newVal: string) => {
      emits('update:invoice-details', {
        note: newVal,
        details: states.details,
        hasError: states.hasError,
      })
    }
  )
}

const watchPropsNote = (
  states: InvoiceDetailStates,
  props: InvoiceDetailProps
) => {
  watch(
    () => props.note,
    (newVal: InvoiceDetailProps['note']) => {
      states.note = newVal
    }
  )
}

export const useInvoiceDetailActions = (
  states: InvoiceDetailStates,
  props: InvoiceDetailProps,
  emits: Emits<InvoiceDetailEmitParams>,
  invoiceDetailTableRef: Ref<InstanceType<typeof InvoiceDetailTable>>
) => {
  watchStatesNote(states, emits)
  watchPropsNote(states, props)

  const updateDetails = (details: InvoiceDetail[]) => {
    states.details = details
    states.hasError = details.some(
      (detail: InvoiceDetail) => detail.hasRowError === true
    )
    emits('update:invoice-details', {
      note: states.note,
      details,
      hasError: states.hasError,
    })
  }
  const validate = () => {
    return invoiceDetailTableRef.value.validate() as boolean
  }
  return {
    updateDetails,
    validate,
  }
}
