<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Modal from '@/vueComponents/photographer/molecules/Modal/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import {
  ScheduleSaveModalProps,
  ScheduleSaveModalEmitParams,
  useScheduleSaveModalActions,
  useScheduleSaveModalStates,
} from '.'

interface IProps {
  shown: ScheduleSaveModalProps['shown']
  isSaved: ScheduleSaveModalProps['isSaved']
  toPage: ScheduleSaveModalProps['toPage']
}

interface IEmits {
  (e: 'update:shown', shown: ScheduleSaveModalEmitParams['update:shown']): void
}

const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useScheduleSaveModalStates(props, emits)
const actions = useScheduleSaveModalActions(states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.text {
  @include space-mixins.vertical-margin(8, 22);
}
</style>

<template>
  <Modal
    v-model:shown="states.shown"
    :on-submit="actions.submit"
    :on-before-cancel="props.isSaved ? actions.toTop : props.toPage"
  >
    <template #header>{{ states.title }}</template>
    <template v-if="props.isSaved">
      <Typography :class="css.text" element="p"
        >スケジュールが保存されました。<br />
        登録・編集を続けますか？
      </Typography>
    </template>
    <template v-else>
      <Typography element="p" :class="css.text"
        >編集した内容が削除されますが<br />
        保存せずにページを移動しますか？
      </Typography>
    </template>
    <template #submitLabel>登録・編集を続ける</template>
    <template #cancelLabel>{{ states.cancelLabel }}</template>
  </Modal>
</template>
