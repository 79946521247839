<script setup lang="ts">
import { defineProps } from 'vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import HelpIcon from '@/vueComponents/photographer/molecules/HelpIcon/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { UploadSlowModeLinkProps, useUploadSlowModeLinkStates } from '.'

type Props = {
  slowModeFlg: UploadSlowModeLinkProps['slowModeFlg']
}

const props = defineProps<Props>()
const states = useUploadSlowModeLinkStates()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/functions/space' as *;

.content {
  @include position-mixins.flex-horizontal(space(1));
}

.help-icon {
  position: relative;
  width: 340px;
  display: grid;
  gap: space(4);
  padding: space(2);
}
</style>

<template>
  <div v-if="!props.slowModeFlg" :class="css.content">
    <Link :location="states.slowModePageLocation">低速モードへ切り替える</Link>
    <HelpIcon :speech-bubble-position="90">
      <div :class="css['help-icon']">
        <Typography type="head">低速モードについて</Typography>
        <Typography type="read-small"
          >通信状況が悪い場合、接続速度が遅い場合などにお使いいただけるアップローダーです。</Typography
        >
      </div>
    </HelpIcon>
  </div>
  <div v-else>
    <Link :location="states.normalModePageLocation"
      >通常モードへ切り替える</Link
    >
  </div>
</template>
