<script setup lang="ts">
import { defineProps } from 'vue'
import EventTable from '@/vueComponents/photographer/organisms//pageContent/EventsPageContent/Events/EventTable/index.vue'
import { IEventsPageContentProps, useEventsPageContentStates } from '.'
import DeliveryEventNotification from './Notification/DeliveryEventNotification/index.vue'

interface IProps {
  events: IEventsPageContentProps['events']
}

const props = defineProps<IProps>()

const states = useEventsPageContentStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.table {
  @include space-mixins.vertical-margin(7);
}
</style>

<template>
  <DeliveryEventNotification :notification-infos="states.notificationInfos" />
  <EventTable :class="css.table" :events="props.events" />
</template>
