<script setup lang="ts">
import InvoiceCreatePageContent from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/index.vue'
import { useInvoiceCreateStates, useInvoiceCreateActions } from '.'

const states = useInvoiceCreateStates()
const actions = useInvoiceCreateActions(states)
</script>

<template>
  <InvoiceCreatePageContent
    v-model:is-save-complete-modal-shown="states.isSaveCompleteModalShown"
    :breadcrumbs="states.breadcrumbs"
    :account="states.account"
    :invoice-details="states.details.details"
    :expenses="states.expenses"
    :gas-unit-price="states.details.gasUnitPrice"
    :note="states.details.note"
    :status="states.status"
    @update:invoice-details="actions.updateInvoiceDetails"
    @update:invoice-expenses="actions.updateInvoiceExpenses"
    @temporary-save="actions.temporarySave"
    @continue-edit="actions.continueEdit"
    @to-home="actions.toHome"
  />
</template>
