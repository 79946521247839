<script setup lang="ts">
import { defineProps } from 'vue'
import AwaitingDeliveryNotification from '../AwaitingDeliveryNotification/index.vue'
import {
  IDeliveryEventNotificationProps,
  useDeliveryEventNotificationActions,
} from '.'

interface IProps {
  notificationInfos: IDeliveryEventNotificationProps['notificationInfos']
}

const props = defineProps<IProps>()

const actions = useDeliveryEventNotificationActions()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
.wrapper {
  display: flex;
  flex-direction: column;
  gap: space(3);
}
.container {
  cursor: pointer;
  background-color: colors.$background-color-content-primary;
  border-radius: 8px;
  padding: space(2.5) space(4);
  border: 1px solid colors.$background-color-primary;
}
</style>

<template>
  <div v-if="props.notificationInfos.length" :class="css.wrapper">
    <div
      v-for="notificationInfo in props.notificationInfos"
      :key="notificationInfo.eventId"
      :class="css.container"
      @click.capture="actions.selectNotification(notificationInfo.eventId)"
    >
      <AwaitingDeliveryNotification
        :type="notificationInfo.type"
        :seller-name="notificationInfo.sellerName"
        :event-name="notificationInfo.eventName"
      />
    </div>
  </div>
</template>
