import {
  isOverFlow,
  openS3File,
  openLocalFile,
} from '@/utils/functions/invoice'
import { Ref, onMounted, reactive } from 'vue'

export type RowProps = {
  rowNo: number
  date: string
  content: string
  originalFileName: string
  file: File | null
  fetchFileUrl?: () => Promise<string>
  amount: number
}

type RowStates = {
  displayDate: string
  displayAmount: string
  isHover: boolean
  isOverFlow: boolean
}

export const useRowStates = (props: RowProps): RowStates => {
  return reactive<RowStates>({
    displayDate: props.date.replace(/-/g, '/'),
    displayAmount: props.amount.toLocaleString(),
    isHover: false,
    isOverFlow: true,
  })
}

export const useRowActions = (
  props: RowProps,
  states: RowStates,
  fileDivElement: Ref<HTMLDivElement | undefined>
) => {
  onMounted(async () => {
    states.isOverFlow = await isOverFlow(fileDivElement)
  })

  return {
    openFile: () => openFile(props),
  }
}

const openFile = async (props: RowProps): Promise<void> => {
  if (props.file) {
    openLocalFile(props.file)
  } else {
    await openS3File(props.fetchFileUrl)
  }
}
