import { AccountType } from '@/utils/constants/enums/photographer/account'
import { PREFECTURES_MAP } from '@/utils/constants/global/prefectures'
import { displayZipcode } from '@/utils/functions/invoice'

export type Account = {
  companyName: string
  name: string
  zipcode: string
  prefectureId: number
  address: string
  invoiceNumber: string
  bankName: string
  branchName: string
  accountType: number
  accountNumber: string
  accountHolder: string
}

export type HeadProps = {
  account: Account
  totalAmount: number
}

type HeadStates = {
  displayZipcode: string
  displayAddress: string
  displayAccountType: string
  displayTotalAmount: string
}

const accountTypeMap = {
  0: AccountType.NOT_SET_ACCOUNT,
  1: AccountType.SAVING_ACCOUNT,
  2: AccountType.CHECKING_ACCOUNT,
}

const accountTypeText = (accountType: Account['accountType']) => {
  return accountTypeMap[accountType as keyof typeof accountTypeMap]
}

const getPrefectureName = (prefectureId: number) =>
  PREFECTURES_MAP.get(prefectureId) || ''

const displayAddress = (prefectureId: number, address: string) => {
  return `${getPrefectureName(prefectureId)}${address}`
}

export const useHeadStates = (props: HeadProps): HeadStates => {
  return {
    displayZipcode: displayZipcode(props.account.zipcode),
    displayAddress: displayAddress(
      props.account.prefectureId,
      props.account.address
    ),
    displayAccountType: accountTypeText(props.account.accountType),
    displayTotalAmount: props.totalAmount.toLocaleString(),
  }
}
