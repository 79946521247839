<style lang="scss" module="css" scoped>
@use '@/styles/sizes' as sizes;
.list {
  display: grid;
  width: 100%;
  grid-template-columns: sizes.$width-data-term auto;
}
</style>

<template>
  <dl :class="css.list"><slot /></dl>
</template>
