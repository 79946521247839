import { Computable, Emits } from '@/types/utils'
import { reactive, computed } from 'vue'
import { assertString } from '@/utils/functions/typeCheck'

export interface INoteProps {
  value: string
}

export type NoteEmitParams = {
  'update:value': INoteProps['value']
}

interface INoteStates {
  value: string
}

export const useNoteStates = (
  props: INoteProps,
  emits: Emits<NoteEmitParams>
) =>
  reactive<Computable<INoteStates>>({
    value: computed({
      get() {
        return props.value
      },
      set(value) {
        assertString(value)
        emits('update:value', value)
      },
    }),
  })
