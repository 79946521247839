import { Computable } from '@/types/utils'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'
import { convertEventStatus } from '@/utils/functions/convertEvent'
import { ILabelProps } from '@/vueComponents/photographer/atoms/Label'
import { computed, reactive } from 'vue'

export interface IEventStatusLabelProps {
  status: EventStatusCode
}

interface IEventStatusLabelStates {
  readonly text: string
  readonly color: ILabelProps['color']
}

export const useEventStatusLabelStates = (props: IEventStatusLabelProps) =>
  reactive<Computable<IEventStatusLabelStates>>({
    text: computed(() => convertEventStatus(props.status)),
    color: computed(() => {
      switch (props.status) {
        case EventStatusCode.ORDERED:
        case EventStatusCode.WAITING_EVENT:
        case EventStatusCode.BEFORE_ANSWER:
          return 'primary'
        case EventStatusCode.CANCELLED:
          return 'gray'
        case EventStatusCode.BEFORE_DELIVERY:
        case EventStatusCode.SENDING_BACK:
          return 'red'
        case EventStatusCode.WAITING_INSPECTION:
        case EventStatusCode.INSPECTING:
        case EventStatusCode.DELIVERED:
          return 'black'
        default:
          return undefined
      }
    }),
  })
