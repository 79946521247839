import { reactive } from 'vue'
import { ExpiryStatus } from '@/utils/constants/enums/photographer/notifyDeparture/expiryStatus'
import { useRoute } from 'vue-router'
import { parseExtendedStringToString } from '@/utils/functions/parser'
import { NotifyDepartureApi } from '@/domains/api/notify_departure/NotifyDepartureApi'
import { INotifyDeparture } from '@/types/photographer/notifyDeparture'

type NotifyDepartureStates = {
  expiryStatus: number
  isNotified: boolean
}

export const useNotifyDepartureStates = () =>
  reactive<NotifyDepartureStates>({
    expiryStatus: ExpiryStatus.BEFORE,
    isNotified: false,
  })

export const useNotifyDepartureActions = (states: NotifyDepartureStates) => {
  const route = useRoute()
  const token = parseExtendedStringToString(route.params.token)
  const api = new NotifyDepartureApi()

  const fetchNotifyDeparture = async () => {
    const data = await api.fetchNotifyDeparture(token)
    setStates(states, data)
  }

  const postNotifyDeparture = async () => {
    const data = await api.fetchNotifyDeparture(token)
    setStates(states, data)
    const canNotify =
      data.expiryStatus === ExpiryStatus.DURING && !data.isNotified
    if (canNotify) {
      await api.postNotifyDeparture(token)
      states.isNotified = true
    }
  }
  return { fetchNotifyDeparture, postNotifyDeparture }
}

const setStates = (states: NotifyDepartureStates, data: INotifyDeparture) => {
  states.expiryStatus = data.expiryStatus
  states.isNotified = data.isNotified
}
