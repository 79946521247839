<script setup lang="ts">
import { defineEmits, defineProps, withDefaults } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import { ITextInputProps, TextInputEmitParams, useTextInputStates } from '.'

interface IProps {
  value: ITextInputProps['value']
  label?: ITextInputProps['label']
  name: ITextInputProps['name']
  placeholder?: ITextInputProps['placeholder']
  disabled?: ITextInputProps['disabled']
  grayOut?: ITextInputProps['grayOut']
  errorMessage?: ITextInputProps['errorMessage']
  width?: ITextInputProps['width']
}

interface IEmits {
  (e: 'update:value', value: TextInputEmitParams['update:value']): void
}

const props = withDefaults(defineProps<IProps>(), {
  label: '',
  placeholder: '',
  disabled: false,
  grayOut: false,
  errorMessage: '',
  isColorInactive: false,
  width: '100%',
})

const emits = defineEmits<IEmits>()

const states = useTextInputStates(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
.gray-out {
  background: colors.$background-color-subinfo;
  color: colors.$font-color-disabled;
}
</style>

<template>
  <div>
    <label>
      <Typography v-if="props.label">{{ props.label }}</Typography>
      <Input
        v-model:value="states.value"
        type="text"
        :name="props.name"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        :has-error="!!props.errorMessage"
        :class="{ [css['gray-out']]: props.grayOut }"
        :width="props.width"
      />
    </label>
    <InputError v-if="!!props.errorMessage">{{
      props.errorMessage
    }}</InputError>
  </div>
</template>
