<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import LoginIdInput from '@/vueComponents/photographer/organisms/input/LoginIdInput/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import {
  IResetMailFormContentProps,
  useResetMailFormContentActions,
  useResetMailFormContentStates,
} from '.'
import { ValidationCode } from '@/utils/constants/enums/validation'
import Form from '@/vueComponents/photographer/atoms/Form/index.vue'

interface IProps {
  onClick?: IResetMailFormContentProps['onClick']
}

const props = withDefaults(defineProps<IProps>(), {
  onClick: () => undefined,
})

const states = useResetMailFormContentStates()

const actions = useResetMailFormContentActions(props, states)

const disabled = computed(
  () =>
    states.isSendingEmail ||
    [ValidationCode.EMPTY, ValidationCode.TOO_LONG].some(
      (code) => states.loginIdValidationCode === code
    )
)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/space' as space-mixins;
.text {
  @include space-mixins.vertical-margin(2, 2);
}
.btn {
  @include position-mixins.flex-center(true, false);
  @include space-mixins.vertical-margin(5);
  justify-content: space-around;
}
</style>

<template>
  <Form @enter="actions.handleSendMailButtonClick">
    <Typography :class="css.text">※ログインIDを入力してください。</Typography>
    <LoginIdInput
      v-model:login-id="states.loginId"
      @update:validation-code="actions.updateLoginIdValidationCode"
    />
    <InputError
      v-for="errorMessage in states.errorMessages"
      :key="errorMessage"
      >{{ errorMessage }}</InputError
    >
    <div :class="css.btn">
      <Button
        type="password-reset"
        color="old"
        :on-click="actions.handleSendMailButtonClick"
        :disabled="disabled"
        >再設定メールを送信</Button
      >
    </div>
  </Form>
</template>
