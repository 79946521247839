import { reactive } from 'vue'
import { Expense } from './Preview/Expense/Table'
import { Account } from './Preview/Head'
import { Detail } from './Preview/Detail/Table'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { Emits } from '@/types/utils'
import { Router, useRouter } from 'vue-router'

export const breadcrumbs: IBreadcrumb[] = [
  {
    label: 'ホーム',
    location: { name: photographerRoutes.NAME.TOP },
  },
  {
    label: '請求書',
    location: { name: photographerRoutes.NAME.INVOICES },
  },
  {
    label: '請求書プレビュー',
  },
]

export type PreviewProps = {
  month: number
  account: Account
  notes: string
  details: Detail[]
  gasUnitPrice: number
  expenses: Expense[]
}

type PreviewStates = {
  isShowSubmitModal: boolean
}

export type PreviewEmitParams = {
  submit: void
}

export const usePreviewStates = () => {
  return reactive<PreviewStates>({
    isShowSubmitModal: false,
  })
}

type PreviewActions = {
  onClickSubmitButton: () => void
  moveBackPage: () => Promise<void>
}

const moveBackPage = async (router: Router) => {
  await router.push({ name: photographerRoutes.NAME.INVOICE_CREATE })
}

export const usePreviewActions = (
  states: PreviewStates,
  emits: Emits<PreviewEmitParams>
): PreviewActions => {
  const router = useRouter()
  const onClickSubmitButton = () => {
    states.isShowSubmitModal = true
    emits('submit') // todo: モーダル経由でsubmitする
  }
  return {
    onClickSubmitButton,
    moveBackPage: () => moveBackPage(router),
  }
}
