import { Computable, Emits } from '@/types/utils'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import {
  InvoiceDetail,
  getTaxAmount,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable'
import { Ref, computed, reactive } from 'vue'
import { InvoiceDetailStates } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail'
import { Expense } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable'
import { Account } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/Account'
import {
  RouteLocationNormalized,
  Router,
  onBeforeRouteLeave,
  useRouter,
} from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import InvoiceDetailComp from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/index.vue'
import InvoiceExpenseComp from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/index.vue'
import { InvoiceUpdateDetailParam } from '@/vueComponents/photographer/pages/InvoiceCreate'
import { FetchStatus } from '@/types/photographer/invoice'
import { currentScheduleMonth } from '@/utils/functions/createSchedulePrams'
import { ExpenseStates } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense'
import { preventReload } from '@/utils/functions/preventReload'

const { NAME: ROUTE_NAME } = photographerRoutes

export type InvoiceCreatePageContentProps = {
  breadcrumbs: IBreadcrumb[]
  account: Account
  invoiceDetails: InvoiceDetail[]
  expenses: Expense[]
  gasUnitPrice: number
  note: string
  status: FetchStatus
  isSaveCompleteModalShown: boolean
}

type InvoiceCreatePageContentStates = {
  isConfirmAllChecked: boolean
  isAccountInfoRegistered: boolean
  totalAmount: number
  totalAmountInvoiceDetails: number
  totalAmountInvoiceExpenses: number
  isValidated: boolean
  isModalShown: boolean
  targetRouteName: string | null
  isInputUpdated: boolean
  hasErrorDetails: boolean
  hasErrorExpenses: boolean
  shouldErrorMessageShow: boolean
  isSaveCompleteModalShown: boolean
}

export type InvoiceCreatePageContentEmitParams = {
  'update:invoice-details': InvoiceUpdateDetailParam
  'update:invoice-expenses': Expense[]
  validated: void
  'temporary-save': void
  'update:is-save-complete-modal-shown': boolean
}

type InvoiceCreatePageContentActions = {
  moveToAccount: () => Promise<void>
  moveToBankAccount: () => Promise<void>
  updateInvoiceDetails: (params: InvoiceDetailStates) => void
  updateInvoiceExpenses: (expenses: ExpenseStates) => void
  updateAllChecked: (isAllChecked: boolean) => void
  confirmPreview: () => Promise<void>
  temporarySave: () => void
  movePage: () => Promise<void>
  handleCloseModal: () => void
  moveBackPage: () => Promise<void>
}

export const useInvoiceCreatePageContentStates = (
  props: InvoiceCreatePageContentProps,
  emits: Emits<InvoiceCreatePageContentEmitParams>
) => {
  const states: InvoiceCreatePageContentStates = reactive<
    Computable<InvoiceCreatePageContentStates>
  >({
    isConfirmAllChecked: false,
    isAccountInfoRegistered: true,
    totalAmount: computed(
      () => states.totalAmountInvoiceDetails + states.totalAmountInvoiceExpenses
    ),
    totalAmountInvoiceDetails: 0,
    totalAmountInvoiceExpenses: 0,
    isValidated: false,
    isModalShown: false,
    targetRouteName: null,
    isInputUpdated: false,
    hasErrorDetails: false,
    hasErrorExpenses: false,
    shouldErrorMessageShow: computed(
      () =>
        states.hasErrorDetails ||
        states.hasErrorExpenses ||
        !states.isAccountInfoRegistered
    ),
    isSaveCompleteModalShown: computed({
      get() {
        return props.isSaveCompleteModalShown
      },
      set(value) {
        emits('update:is-save-complete-modal-shown', value)
      },
    }),
  })
  return states
}

const checkIsAccountInfoRegistered = (account: Account): boolean => {
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const { companyName, invoiceNumber, ...requiredAccount } = account
  const result = Object.entries(requiredAccount).find(([key, value]) => {
    return (
      (key === 'prefectureId' && value === 0) ||
      (key === 'accountType' && value === 0) ||
      value === ''
    )
  })
  if (!result) return true
  return false
}

const getTotalAmountExpenses = (expenses: Expense[]): number => {
  return expenses.reduce((accumulator, currentValue) => {
    if (currentValue.amount === null) return accumulator
    return accumulator + currentValue.amount
  }, 0)
}

const isRouteToInvoicePreview = (to: RouteLocationNormalized): boolean => {
  return to.name === ROUTE_NAME.INVOICE_PREVIEW
}

const canMovePage = (
  to: RouteLocationNormalized,
  states: InvoiceCreatePageContentStates
) => {
  return (
    isRouteToInvoicePreview(to) ||
    !states.isInputUpdated ||
    states.targetRouteName
  )
}

const handleOnBeforeRouteLeave = (
  states: InvoiceCreatePageContentStates
): void => {
  onBeforeRouteLeave((to, _, next) => {
    if (canMovePage(to, states)) {
      next()
    }
    if (typeof to.name !== 'string') return
    states.targetRouteName = to.name
    openModal(states)
  })
}

const moveToAccount = async (router: Router): Promise<void> => {
  await router.push({
    name: photographerRoutes.NAME.ACCOUNT_EDIT,
  })
}

const moveToBankAccount = async (router: Router): Promise<void> => {
  await router.push({
    name: photographerRoutes.NAME.ACCOUNT_EDIT_BANK,
  })
}

const getTaxAmountInvoiceDetails = (
  details: InvoiceDetail[],
  gasUnitPrice: number
): number => {
  const subTotal = details.reduce(
    (acc, detail) =>
      acc + Number(detail.amount) + Number(detail.mileage) * gasUnitPrice,
    0
  )
  const taxAmount = getTaxAmount(subTotal)
  return subTotal + taxAmount
}

const updateInvoiceDetails = (
  params: InvoiceDetailStates,
  states: InvoiceCreatePageContentStates,
  props: InvoiceCreatePageContentProps,
  emits: Emits<InvoiceCreatePageContentEmitParams>
): void => {
  states.isInputUpdated =
    JSON.stringify({
      note: params.note,
      details: params.details,
    }) !==
    JSON.stringify({
      note: props.note,
      details: props.invoiceDetails,
    })
  states.totalAmountInvoiceDetails = getTaxAmountInvoiceDetails(
    params.details,
    props.gasUnitPrice
  )
  states.hasErrorDetails = params.hasError
  emits('update:invoice-details', {
    note: params.note,
    details: params.details,
  })
}

const updateInvoiceExpenses = (
  params: ExpenseStates,
  states: InvoiceCreatePageContentStates,
  props: InvoiceCreatePageContentProps,
  emits: Emits<InvoiceCreatePageContentEmitParams>
): void => {
  const propsExpensesWithData = props.expenses.filter((expense: Expense) => {
    return (
      expense.date !== '' || expense.content !== '' || expense.amount !== null
    )
  })
  states.isInputUpdated =
    JSON.stringify(params.expenses) !== JSON.stringify(propsExpensesWithData)
  states.totalAmountInvoiceExpenses = getTotalAmountExpenses(params.expenses)
  states.hasErrorExpenses = params.hasError
  emits('update:invoice-expenses', params.expenses)
}

const updateAllChecked = (
  isAllChecked: boolean,
  states: InvoiceCreatePageContentStates
): void => {
  states.isConfirmAllChecked = isAllChecked
}

const validateConfirmPreview = (
  states: InvoiceCreatePageContentStates,
  props: InvoiceCreatePageContentProps,
  invoiceDetailRef: Ref<InstanceType<typeof InvoiceDetailComp>>,
  invoiceExpenseRef: Ref<InstanceType<typeof InvoiceExpenseComp>>
): boolean => {
  states.isAccountInfoRegistered = checkIsAccountInfoRegistered(props.account)
  return (
    states.isAccountInfoRegistered &&
    validateDetail(invoiceDetailRef) &&
    validateExpense(invoiceExpenseRef)
  )
}

const validateTemporarySave = (
  states: InvoiceCreatePageContentStates,
  invoiceDetailRef: Ref<InstanceType<typeof InvoiceDetailComp>>,
  invoiceExpenseRef: Ref<InstanceType<typeof InvoiceExpenseComp>>
): boolean => {
  return (
    states.isAccountInfoRegistered &&
    validateDetail(invoiceDetailRef) &&
    validateExpense(invoiceExpenseRef)
  )
}

const validateExpense = (
  invoiceExpenseRef: Ref<InstanceType<typeof InvoiceExpenseComp>>
) => {
  return invoiceExpenseRef.value.validate() as boolean
}

const validateDetail = (
  invoiceDetailRef: Ref<InstanceType<typeof InvoiceDetailComp>>
) => {
  return invoiceDetailRef.value.validate() as boolean
}

const confirmPreview = async (
  states: InvoiceCreatePageContentStates,
  props: InvoiceCreatePageContentProps,
  invoiceDetailRef: Ref<InstanceType<typeof InvoiceDetailComp>>,
  invoiceExpenseRef: Ref<InstanceType<typeof InvoiceExpenseComp>>,
  router: Router
): Promise<void> => {
  states.isValidated = validateConfirmPreview(
    states,
    props,
    invoiceDetailRef,
    invoiceExpenseRef
  )
  if (states.isValidated) {
    await router.push({ name: photographerRoutes.NAME.INVOICE_PREVIEW })
  }
}

const temporarySave = (
  states: InvoiceCreatePageContentStates,
  props: InvoiceCreatePageContentProps,
  emits: Emits<InvoiceCreatePageContentEmitParams>,
  invoiceDetailRef: Ref<InstanceType<typeof InvoiceDetailComp>>,
  invoiceExpenseRef: Ref<InstanceType<typeof InvoiceExpenseComp>>
) => {
  states.isValidated = validateTemporarySave(
    states,
    invoiceDetailRef,
    invoiceExpenseRef
  )
  if (states.isValidated) {
    emits('temporary-save')
  }
}

const moveToScheduleNavPage = async (router: Router): Promise<void> => {
  await router.push({
    name: ROUTE_NAME.SCHEDULE,
    params: { scheduleMonth: currentScheduleMonth() },
  })
}

const moveToOtherNavPage = async (
  states: InvoiceCreatePageContentStates,
  router: Router
): Promise<void> => {
  if (typeof states.targetRouteName !== 'string') return
  await router.push({
    name: states.targetRouteName,
  })
}

const openModal = (states: InvoiceCreatePageContentStates): void => {
  states.isModalShown = true
}

const closeModal = (states: InvoiceCreatePageContentStates): void => {
  states.isModalShown = false
}

const handleCloseModal = (states: InvoiceCreatePageContentStates): void => {
  closeModal(states)
  states.targetRouteName = null
}

const movePage = async (
  states: InvoiceCreatePageContentStates,
  router: Router
): Promise<void> => {
  closeModal(states)
  if (states.targetRouteName === ROUTE_NAME.SCHEDULE) {
    await moveToScheduleNavPage(router)
  } else {
    await moveToOtherNavPage(states, router)
  }
}

const moveBackPage = async (router: Router): Promise<void> => {
  await router.push({ name: photographerRoutes.NAME.INVOICES })
}

export const useInvoiceCreatePageContentActions = (
  states: InvoiceCreatePageContentStates,
  props: InvoiceCreatePageContentProps,
  emits: Emits<InvoiceCreatePageContentEmitParams>,
  invoiceDetailRef: Ref<InstanceType<typeof InvoiceDetailComp>>,
  invoiceExpenseRef: Ref<InstanceType<typeof InvoiceExpenseComp>>
): InvoiceCreatePageContentActions => {
  const router = useRouter()
  preventReload()
  handleOnBeforeRouteLeave(states)

  return {
    moveToAccount: () => moveToAccount(router),
    moveToBankAccount: () => moveToBankAccount(router),
    updateInvoiceDetails: (params: InvoiceDetailStates) =>
      updateInvoiceDetails(params, states, props, emits),
    updateInvoiceExpenses: (params: ExpenseStates) =>
      updateInvoiceExpenses(params, states, props, emits),
    updateAllChecked: (isAllChecked: boolean) =>
      updateAllChecked(isAllChecked, states),
    confirmPreview: () =>
      confirmPreview(
        states,
        props,
        invoiceDetailRef,
        invoiceExpenseRef,
        router
      ),
    temporarySave: () =>
      temporarySave(states, props, emits, invoiceDetailRef, invoiceExpenseRef),
    movePage: () => movePage(states, router),
    handleCloseModal: () => handleCloseModal(states),
    moveBackPage: () => moveBackPage(router),
  }
}
