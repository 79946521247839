import { initRouter } from '..'
import { Router, RouteRecordRaw, START_LOCATION } from 'vue-router'
import { photographerRoutes } from './routes'
import AfterLoginHeader from '@/vueComponents/photographer/organisms/header/AfterLoginHeader/index.vue'
import BeforeLoginHeader from '@/vueComponents/photographer/organisms/header/BeforeLoginHeader/index.vue'
import LoginHeader from '@/vueComponents/photographer/organisms/header/LoginHeader/index.vue'
import CommonFooter from '@/vueComponents/photographer/organisms/footer/CommonFooter/index.vue'
import Events from '@/vueComponents/photographer/pages/Events/index.vue'
import Event from '@/vueComponents/photographer/pages/Event/index.vue'
import Folder from '@/vueComponents/photographer/pages/Folder/index.vue'
import Login from '@/vueComponents/photographer/pages/Login/index.vue'
import PasswordReset from '@/vueComponents/photographer/pages/PasswordReset/index.vue'
import PasswordUpdate from '@/vueComponents/photographer/pages/PasswordUpdate/index.vue'
import ResetMailComplete from '@/vueComponents/photographer/pages/ResetMailComplete/index.vue'
import ResetMailForm from '@/vueComponents/photographer/pages/ResetMailForm/index.vue'
import NotFoundError from '@/vueComponents/photographer/pages/Error/NotFoundError/index.vue'
import Proposal from '@/vueComponents/photographer/pages/Proposal/index.vue'
import ProposalComplete from '@/vueComponents/photographer/pages/ProposalComplete/index.vue'
import Notifications from '@/vueComponents/photographer/pages/Notifications/index.vue'
import Top from '@/vueComponents/photographer/pages/Top/index.vue'
import Schedule from '@/vueComponents/photographer/pages/Schedule/index.vue'
import Account from '@/vueComponents/photographer/pages/Account/index.vue'
import AccountEdit from '@/vueComponents/photographer/pages/AccountEdit/index.vue'
import AccountEditBank from '@/vueComponents/photographer/pages/AccountEditBank/index.vue'
import Invoices from '@/vueComponents/photographer/pages/Invoices/index.vue'
import InvoiceCreate from '@/vueComponents/photographer/pages/InvoiceCreate/index.vue'
import InvoicePreview from '@/vueComponents/photographer/pages/InvoicePreview/index.vue'
import InvoiceComplete from '@/vueComponents/photographer/pages/InvoiceComplete/index.vue'
import NotifyDeparture from '@/vueComponents/photographer/pages/NotifyDeparture/index.vue'

const { PATH, NAME, META_TITLE } = photographerRoutes

/** ログイン画面 */
const loginPages: RouteRecordRaw[] = [
  {
    path: PATH.LOGIN,
    name: NAME.LOGIN,
    components: {
      default: Login,
      header: LoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.LOGIN,
    },
  },
  { path: '/', redirect: { name: NAME.LOGIN } },
]

/** パスワード再設定関連画面 */
const passwordResetPages: RouteRecordRaw[] = [
  {
    path: PATH.RESET_MAIL_FORM,
    name: NAME.RESET_MAIL_FORM,
    components: {
      default: ResetMailForm,
      header: BeforeLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.PASSWORD_RESET,
    },
  },
  {
    path: PATH.RESET_MAIL_COMPLETE,
    name: NAME.RESET_MAIL_COMPLETE,
    components: {
      default: ResetMailComplete,
      header: BeforeLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.PASSWORD_RESET,
    },
  },
  {
    path: PATH.PASSWORD_RESET,
    name: NAME.PASSWORD_RESET,
    components: {
      default: PasswordReset,
      header: BeforeLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.PASSWORD_RESET,
    },
  },
]

/** パスワード変更画面 */
const passwordUpdatePage: RouteRecordRaw = {
  path: PATH.PASSWORD_UPDATE,
  name: NAME.PASSWORD_UPDATE,
  components: {
    default: PasswordUpdate,
    header: BeforeLoginHeader,
    footer: CommonFooter,
  },
  props: true,
  meta: {
    title: META_TITLE.PASSWORD_UPDATE,
  },
}

/** イベント関連画面 */
const eventPages: RouteRecordRaw[] = [
  {
    path: PATH.EVENTS,
    name: NAME.EVENTS,
    components: {
      default: Events,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    props: true,
    meta: {
      title: META_TITLE.EVENTS,
    },
  },
  {
    path: PATH.EVENT,
    name: NAME.EVENT,
    components: {
      default: Event,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    props: true,
    meta: {
      title: META_TITLE.EVENT,
    },
  },
  {
    path: PATH.FOLDER,
    name: NAME.FOLDER,
    components: {
      default: Folder,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    props: true,
    meta: {
      title: META_TITLE.FOLDER,
    },
  },
]

/** アドレス不正 */
const errorPages: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    components: {
      default: NotFoundError,
      header: BeforeLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.NOT_FOUND_ERROR,
    },
  },
]

/** Top画面 */
const topPages: RouteRecordRaw[] = [
  {
    path: PATH.TOP,
    name: NAME.TOP,
    components: {
      default: Top,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.TOP,
    },
  },
]

/** 通知一覧画面 */
const notificationsPages: RouteRecordRaw[] = [
  {
    path: PATH.NOTIFICATIONS,
    name: NAME.NOTIFICATIONS,
    components: {
      default: Notifications,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.NOTIFICATIONS,
    },
  },
]

/** 案件打診関連画面 */
const proposalPages: RouteRecordRaw[] = [
  {
    path: PATH.PROPOSAL,
    name: NAME.PROPOSAL,
    components: {
      default: Proposal,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.PROPOSAL,
    },
  },
  {
    path: PATH.PROPOSAL_COMPLETE,
    name: NAME.PROPOSAL_COMPLETE,
    components: {
      default: ProposalComplete,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.PROPOSAL_COMPLETE,
    },
  },
]

/** スケジュール登録画面 */
const schedulePages: RouteRecordRaw[] = [
  {
    path: PATH.SCHEDULE,
    name: NAME.SCHEDULE,
    components: {
      default: Schedule,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.SCHEDULE,
    },
  },
]

/** アカウント関連画面 */
const accountPages: RouteRecordRaw[] = [
  {
    path: PATH.ACCOUNT,
    name: NAME.ACCOUNT,
    components: {
      default: Account,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.ACCOUNT,
    },
  },
  {
    path: PATH.ACCOUNT_EDIT,
    name: NAME.ACCOUNT_EDIT,
    components: {
      default: AccountEdit,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.ACCOUNT_EDIT,
    },
  },
  {
    path: PATH.ACCOUNT_EDIT_BANK,
    name: NAME.ACCOUNT_EDIT_BANK,
    components: {
      default: AccountEditBank,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.ACCOUNT_EDIT_BANK,
    },
  },
]

/** 請求書関連画面 */
const invoicePages: RouteRecordRaw[] = [
  {
    path: PATH.INVOICES,
    name: NAME.INVOICES,
    components: {
      default: Invoices,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.INVOICES,
    },
  },
  {
    path: PATH.INVOICE_CREATE,
    name: NAME.INVOICE_CREATE,
    components: {
      default: InvoiceCreate,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.INVOICE_CREATE,
    },
  },
  {
    path: PATH.INVOICE_PREVIEW,
    name: NAME.INVOICE_PREVIEW,
    components: {
      default: InvoicePreview,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.INVOICE_PREVIEW,
    },
    beforeEnter: (_to, from) => {
      if (from === START_LOCATION) {
        return PATH.INVOICE_CREATE
      }
      return true
    },
  },
  {
    path: PATH.INVOICE_COMPLETE,
    name: NAME.INVOICE_COMPLETE,
    components: {
      default: InvoiceComplete,
      header: AfterLoginHeader,
      footer: CommonFooter,
    },
    meta: {
      title: META_TITLE.INVOICE_COMPLETE,
    },
  },
]

/** 出発報告画面 */
const notifyDeparturePage: RouteRecordRaw = {
  path: PATH.NOTIFY_DEPARTURE,
  name: NAME.NOTIFY_DEPARTURE,
  components: {
    default: NotifyDeparture,
    header: BeforeLoginHeader,
  },
}

const routes: RouteRecordRaw[] = [
  ...loginPages,
  ...passwordResetPages,
  passwordUpdatePage,
  ...eventPages,
  ...errorPages,
  ...topPages,
  ...notificationsPages,
  ...proposalPages,
  ...schedulePages,
  ...accountPages,
  ...invoicePages,
  notifyDeparturePage,
]

export const photographerRouter: Router = initRouter(routes)
