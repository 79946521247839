<script setup lang="ts">
import { defineProps } from 'vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import Modal from '@/vueComponents/photographer/molecules/Modal/index.vue'
import {
  IDeleteButtonModalProps,
  useDeleteButtonModalActions,
  useDeleteButtonModalStates,
} from '.'

interface IProps {
  disabled: IDeleteButtonModalProps['disabled']
  onDelete: IDeleteButtonModalProps['onDelete']
}

const props = defineProps<IProps>()

const states = useDeleteButtonModalStates()

const actions = useDeleteButtonModalActions(props, states)
</script>

<template>
  <Button
    type="medium"
    color="secondary"
    :disabled="props.disabled"
    :on-click="actions.openModal"
    >選択したものを削除する</Button
  >
  <Modal v-model:shown="states.isModalOpened" :on-submit="actions.delete">
    <template #header><slot name="header" /></template>
    <slot />
    <template #submitLabel>削除する</template>
    <template #cancelLabel>キャンセル</template>
  </Modal>
</template>
