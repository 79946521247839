<script setup lang="ts">
import { defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import {
  InspectionDeliveryRequestTableRowProps,
  useInspectionDeliveryRequestTableRowStates,
  useInspectionDeliveryRequestTableRowActions,
} from '.'

interface IProps {
  type: InspectionDeliveryRequestTableRowProps['type']
  sellerName: InspectionDeliveryRequestTableRowProps['sellerName']
  eventName: InspectionDeliveryRequestTableRowProps['eventName']
  eventId: InspectionDeliveryRequestTableRowProps['eventId']
}

const props = defineProps<IProps>()

const states = useInspectionDeliveryRequestTableRowStates(props)

const actions = useInspectionDeliveryRequestTableRowActions(props)
</script>

<style lang="scss" module="css" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
}
.button {
  width: 64px;
}
</style>

<template>
  <TableRow>
    <TableCell :separatorable="false">
      <div :class="css.container">
        <Typography type="read-normal">{{ states.message }}</Typography>
        <div>
          <Button
            :class="css.button"
            :on-click="actions.toDetailPage"
            type="medium"
            color="secondary"
            >詳細</Button
          >
        </div>
      </div>
    </TableCell>
  </TableRow>
</template>
