<script setup lang="ts">
import { defineProps } from 'vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Message from '@/vueComponents/photographer/molecules/Message/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import { AccountProps, useAccountActions, useAccountStates } from '.'

interface IProps {
  account: AccountProps['account']
  isAccountInfoRegistered: AccountProps['isAccountInfoRegistered']
}
interface IEmits {
  (e: 'transition:account'): void
  (e: 'transition:bank-account'): void
}
const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const states = useAccountStates()
const actions = useAccountActions(states, props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;

.container {
  padding: space(8) space(8) space(13.75);
}
.message {
  margin-bottom: space(6);
}
.body {
  display: flex;
  gap: space(7);
}
.item {
  flex-basis: 50%;
}
.link-unit {
  display: flex;
  justify-content: flex-end;
}
dl {
  margin-top: space(5.5);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
dt,
dd {
  box-sizing: border-box;
  border-bottom: 1px solid colors.$border-color-separator-weakline;
}
dt {
  width: 35%;
  padding: space(3) space(2);
  display: flex;
  align-items: center;
}
dd {
  width: 65%;
  padding: 10px;
  padding: space(3);
}
.name,
.address {
  line-height: 20px;
}
.right-margin {
  margin-right: space(3);
}
</style>

<template>
  <ContentBox :class="css.container">
    <Message v-if="!props.isAccountInfoRegistered" :class="css.message">
      項目に未入力があります。修正してください。
    </Message>
    <div :class="css.body">
      <div :class="css.item">
        <div :class="css['link-unit']">
          <Link
            type="body-read-normal"
            display="inline-block"
            :class="css.link"
            @click="actions.transitionAccount"
          >
            編集する
          </Link>
        </div>
        <dl>
          <dt :separatorable="false">
            <Typography element="p" type="read-normal-bold">名前</Typography>
          </dt>
          <dd :separatorable="false">
            <Typography element="p" :class="css.name">{{
              props.account.companyName
            }}</Typography>
            <Typography element="p" :class="css.name">
              {{ props.account.name }}
            </Typography>
          </dd>
          <dt :separatorable="false">
            <Typography element="p" type="read-normal-bold">住所</Typography>
          </dt>
          <dd :separatorable="false">
            <Typography element="p" :class="css.address"
              >{{ states.displayZipcode }}<br />
              {{ states.displayAddress }}</Typography
            >
          </dd>
          <dt :separatorable="false">
            <Typography element="p" type="read-normal-bold"
              >インボイス登録番号</Typography
            >
          </dt>
          <dd :separatorable="false">
            <Typography element="p">{{
              props.account.invoiceNumber
            }}</Typography>
          </dd>
        </dl>
      </div>
      <div :class="css.item">
        <div :class="css['link-unit']">
          <Link
            type="body-read-normal"
            display="inline-block"
            :class="css.link"
            @click="actions.transitionBankAccount"
          >
            編集する
          </Link>
        </div>
        <dl>
          <dt :separatorable="false">
            <Typography element="p" type="read-normal-bold">銀行</Typography>
          </dt>
          <dd :separatorable="false">
            <Typography element="p">
              <span :class="css['right-margin']">
                {{ props.account.bankName }}
              </span>
              {{ props.account.branchName }}
            </Typography>
          </dd>
          <dt :separatorable="false">
            <Typography element="p" type="read-normal-bold">口座</Typography>
          </dt>
          <dd :separatorable="false">
            <Typography element="p">
              <span :class="css['right-margin']">
                {{ states.displayAccountType }}
              </span>
              {{ props.account.accountNumber }}
            </Typography>
          </dd>
          <dt :separatorable="false">
            <Typography element="p" type="read-normal-bold">名義</Typography>
          </dt>
          <dd :separatorable="false">
            <Typography element="p">{{
              props.account.accountHolder
            }}</Typography>
          </dd>
        </dl>
      </div>
    </div>
  </ContentBox>
</template>
