<script setup lang="ts">
import NotifyDepartureContent from '@/vueComponents/photographer/organisms/pageContent/NotifyDepartureContent/index.vue'
import { useNotifyDepartureStates, useNotifyDepartureActions } from '.'

const states = useNotifyDepartureStates()
const actions = useNotifyDepartureActions(states)

actions.fetchNotifyDeparture()
</script>

<template>
  <NotifyDepartureContent
    :expiry-status="states.expiryStatus"
    :is-notified="states.isNotified"
    @notify="actions.postNotifyDeparture"
  />
</template>
