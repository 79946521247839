import { ScheduleReplyStatus } from '@/utils/constants/enums/photographer/schedule'
import { reactive, watch } from 'vue'
import { Emits } from '@/types/utils'

export type ScheduleRegistrationButtonsProps = {
  scheduleReplyStatus: ScheduleReplyStatus
  disabled?: boolean
}

type ScheduleRegistrationButtonsStates = {
  scheduleReplyStatus: ScheduleReplyStatus
}

export type ScheduleRegistrationButtonsParams = {
  'update:scheduleReplyStatus': ScheduleRegistrationButtonsStates['scheduleReplyStatus']
}

export const useScheduleRegistrationButtonsStates = (
  props: ScheduleRegistrationButtonsProps
): ScheduleRegistrationButtonsStates =>
  reactive<ScheduleRegistrationButtonsStates>({
    scheduleReplyStatus: props.scheduleReplyStatus,
  })

export const useScheduleRegistrationButtonsActions = (
  states: ScheduleRegistrationButtonsStates,
  emits: Emits<ScheduleRegistrationButtonsParams>
): void => {
  watch(
    () => states.scheduleReplyStatus,
    () => {
      emits('update:scheduleReplyStatus', states.scheduleReplyStatus)
    }
  )
}
