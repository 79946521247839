<script setup lang="ts">
import { defineProps } from 'vue'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import Preview from './Preview/index.vue'
import {
  breadcrumbs,
  PreviewProps,
  usePreviewActions,
  usePreviewStates,
} from '.'

interface IProps {
  month: PreviewProps['month']
  account: PreviewProps['account']
  notes: PreviewProps['notes']
  details: PreviewProps['details']
  gasUnitPrice: PreviewProps['gasUnitPrice']
  expenses: PreviewProps['expenses']
}
interface IEmits {
  (e: 'submit'): void
}
const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = usePreviewStates()
const actions = usePreviewActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;

.title {
  margin-top: space(10);
}
.bread-crumbs {
  margin-top: space(6);
}
.preview {
  margin-top: space(13);
}
.bottom {
  @include position-mixins.flex-center;
  flex-direction: column;
}
.submit-button {
  margin-top: space(10);
}
.to-previous-page {
  margin-top: space(4);
  font-family: 'Noto Sans JP';
}
</style>

<template>
  <div>
    <Typography :class="css.title" type="h1"
      >{{ props.month }}月分の請求書作成</Typography
    >
    <PageBreadcrumbs :breadcrumbs="breadcrumbs" :class="css['bread-crumbs']" />
    <Preview
      :account="props.account"
      :notes="props.notes"
      :details="props.details"
      :gas-unit-price="props.gasUnitPrice"
      :expenses="props.expenses"
      :class="css.preview"
    />
    <div :class="css['bottom']">
      <Button
        width="288px"
        :class="css['submit-button']"
        @click="actions.onClickSubmitButton"
        >請求書を送信する</Button
      >
      <Button
        color="ternary"
        :class="css['to-previous-page']"
        :on-click="actions.moveBackPage"
      >
        前の画面へ
      </Button>
    </div>
  </div>
</template>
