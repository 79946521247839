import { IPhoto, IPhotoUploadingStatus } from '@/types/photographer/photo'
import { PhotoUploadStatus } from '@/utils/constants/enums/photographer/photo'
import { photographerConstants } from '@/utils/constants/photographerConstants'

const { FILE_UPLOAD } = photographerConstants

const MAX_STATUS_CHECK_COUNT =
  (10 * 1000) / FILE_UPLOAD.UPLOADING_STATUS_INTERVAL

export class UploadingPhoto implements IPhoto {
  public photoId = 0
  public imgName = ''
  public photographed: Date | undefined = undefined
  public status = 0
  public isCompleted = false
  public hasError = false
  public isUploaded = false
  private _statusCheckCount = 0
  private _progressRate = 0

  constructor(
    public readonly file: File,
    public readonly height: number = 0,
    public readonly width: number = 0
  ) {}

  public get orgImgName(): string {
    return this.file.name
  }

  public get size(): number {
    return this.file.size
  }

  public get progressRate(): number {
    if (this.isCompleted) return FILE_UPLOAD.PROGRESS_RATE.COMPLETED
    return this._progressRate * FILE_UPLOAD.PROGRESS_RATE.UPLOAD_COMPLETED
  }

  public get completedSize(): number {
    return Math.floor(this.size * this.progressRate)
  }

  /**
   * アップロード進捗率の更新
   * @param progressEvent プロセスの進捗
   */
  public setProgressRate({ loaded, total }: ProgressEvent): void {
    this._progressRate = loaded / total
  }

  /**
   * 写真アップロードステータスAPIから取得した情報を設定
   * @param photoUploadingStatus 写真アップロードステータス
   */
  public setPhotoUploadingStatus(
    photoUploadingStatus: IPhotoUploadingStatus | undefined
  ): void {
    if (++this._statusCheckCount >= MAX_STATUS_CHECK_COUNT) {
      this.status = PhotoUploadStatus.ERROR_TIMEOUT
      this.isCompleted = true
      this.hasError = true
      return
    }
    if (!photoUploadingStatus) return
    const { status } = photoUploadingStatus
    this.status = status
    this.photographed = photoUploadingStatus.photographed
    this.isCompleted = photoUploadingStatus.isCompleted || status >= 100
    this.hasError = photoUploadingStatus.hasError || status >= 900
  }
}
