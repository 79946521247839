<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Modal from '@/vueComponents/photographer/molecules/Modal/index.vue'
import UploadFailedDialog from './UploadFailedDialog/index.vue'
import UploadCompletedDialog from './UploadCompletedDialog/index.vue'
import UploadingProgressDialog from './UploadingProgressDialog/index.vue'
import {
  IUploadingModalProps,
  UploadingModalEmitParams,
  useUploadingModalStates,
} from '.'
import UploadDuplicationDialog from './UploadDuplicationDialog/index.vue'

interface IProps {
  shown: IUploadingModalProps['shown']
  uploadStatus: IUploadingModalProps['uploadStatus']
  uploadingCount: IUploadingModalProps['uploadingCount']
  completedCount: IUploadingModalProps['completedCount']
  failedCount: IUploadingModalProps['failedCount']
  progressRate: IUploadingModalProps['progressRate']
  duplicatedCount: IUploadingModalProps['duplicatedCount']
  slowModeFlg: IUploadingModalProps['slowModeFlg']
  onOverwriteDuplication: IUploadingModalProps['onOverwriteDuplication']
}

interface IEmits {
  (e: 'update:shown', shown: UploadingModalEmitParams['update:shown']): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = useUploadingModalStates(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.dialog {
  padding: space(8) space(8) space(8) space(6);
}
</style>

<template>
  <Modal
    v-model:shown="states.shown"
    :closeable="states.modalHeader.closeable"
    :on-submit="props.onOverwriteDuplication"
  >
    <template v-if="states.isDuplicating" #header
      >{{ props.duplicatedCount }}{{ states.modalHeader.title }}</template
    >
    <template v-else #header>{{ states.modalHeader.title }}</template>

    <template v-if="states.isFailed">
      <UploadFailedDialog
        :class="css.dialog"
        :upload-status="props.uploadStatus"
        :slow-mode-flg="props.slowModeFlg"
        @update:shown="(val) => (states.shown = val)"
      />
    </template>
    <template v-else-if="states.isCompleted">
      <UploadCompletedDialog
        :class="css.dialog"
        :failed-count="props.failedCount"
      />
    </template>
    <template v-else-if="states.isDuplicating">
      <UploadDuplicationDialog :class="css.dialog" />
    </template>
    <template v-else>
      <UploadingProgressDialog
        :class="css.dialog"
        :uploading-count="props.uploadingCount"
        :completed-count="props.completedCount"
        :progress-rate="props.progressRate"
      />
    </template>
    <template v-if="states.isDuplicating" #submitLabel>上書きする</template>
    <template v-if="states.isFailed || states.isCompleted" #cancelLabel
      >閉じる</template
    >
    <template v-else-if="states.isDuplicating" #cancelLabel
      >キャンセルして閉じる</template
    >
  </Modal>
</template>
