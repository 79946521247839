import {
  formatEmailAddress,
  validateEmail,
  validateNotEntered,
} from '@/utils/functions/validation/validation'
import { reactive } from 'vue'

export interface EmailInputProps {
  modelValue: string
}

export interface EmailInputStates {
  EmailErrorMessage: string
}

interface EmailInputEmits {
  (e: 'update:modelValue', value: string): void
}

export const useEmailInputStates = () => {
  return reactive<EmailInputStates>({
    EmailErrorMessage: '',
  })
}

type EmailActions = {
  handleInputEmail(e: Event): void
  handleBlurEmail(e: Event): void
}

export const useEmailInputActions = (
  states: EmailInputStates,
  emits: EmailInputEmits
): EmailActions => {
  return {
    handleInputEmail(e: Event) {
      states.EmailErrorMessage = ''
      const target = e.target as HTMLInputElement
      emits('update:modelValue', formatEmailAddress(target.value))
    },
    handleBlurEmail(e: Event) {
      const target = e.target as HTMLInputElement
      if (!validateEmail(target.value)) {
        states.EmailErrorMessage = 'メールアドレスの形式が正しくありません'
      }
      if (validateNotEntered(target.value)) {
        states.EmailErrorMessage = '未入力です'
      }
    },
  }
}
