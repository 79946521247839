import {
  formatInvoiceNumber,
  validateInvoiceNumber,
} from '@/utils/functions/validation/validation'
import { reactive } from 'vue'

export interface InvoiceNumberInputProps {
  modelValue: string
}

export interface InvoiceNumberInputStates {
  invoiceNumberErrorMessage: string
}

interface InvoiceNumberInputEmits {
  (e: 'update:modelValue', value: string): void
}

export const useInvoiceNumberInputStates = () => {
  return reactive<InvoiceNumberInputStates>({
    invoiceNumberErrorMessage: '',
  })
}

type InvoiceNumberActions = {
  handleInputInvoiceNumber(e: Event): void
  handleBlurInvoiceNumber(e: Event): void
}

export const useInvoiceNumberInputActions = (
  states: InvoiceNumberInputStates,
  emits: InvoiceNumberInputEmits
): InvoiceNumberActions => {
  return {
    handleInputInvoiceNumber(e: Event) {
      states.invoiceNumberErrorMessage = ''
      const target = e.target as HTMLInputElement
      const validatedValue = formatInvoiceNumber(target.value)
      target.value = validatedValue
      emits('update:modelValue', validatedValue)
    },
    handleBlurInvoiceNumber(e: Event) {
      const target = e.target as HTMLInputElement
      if (target.value && !validateInvoiceNumber(target.value)) {
        states.invoiceNumberErrorMessage =
          'インボイス登録番号はT＋13桁の番号で入力してください'
      }
    },
  }
}
