<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Textarea from '@/vueComponents/photographer/atoms/Textarea/index.vue'
import { NoteEmitParams, INoteProps, useNoteStates } from '.'

interface IProps {
  value: INoteProps['value']
}

interface IEmits {
  (e: 'update:value', value: NoteEmitParams['update:value']): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = useNoteStates(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/functions/space' as *;

.textareaInput {
  height: 95px;
  padding: space(4);
  margin-top: space(6);
  line-height: 20px;
  font-family: 'Noto Sans JP';
  resize: vertical;
}
</style>

<template>
  <div>
    <Typography element="p" type="h3">
      変更・修正に関するメッセージ
    </Typography>
    <Textarea
      v-model:value="states.value"
      name="note"
      :maxlength="500"
      placeholder="共有事項がございましたらご記入ください"
      :class="css.textareaInput"
    />
  </div>
</template>
