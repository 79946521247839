<script setup lang="ts">
import { defineProps, ref } from 'vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import RevisionMessage from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/RevisionMessage/index.vue'
import Checklist from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/Checklist/index.vue'
import TotalAmount from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/TotalAmount/index.vue'
import Account from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/Account/index.vue'
import InvoiceDetail from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/index.vue'
import InvoiceExpense from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/index.vue'
import InvoiceCreatePageMoveConfirmModal from '@/vueComponents/photographer/organisms/modal/InvoiceCreatePageMoveConfirmModal/index.vue'
import SaveCompleteModal from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/SaveCompleteModal/index.vue'
import {
  InvoiceCreatePageContentEmitParams,
  InvoiceCreatePageContentProps,
  useInvoiceCreatePageContentActions,
  useInvoiceCreatePageContentStates,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent'

interface IProps {
  breadcrumbs: InvoiceCreatePageContentProps['breadcrumbs']
  account: InvoiceCreatePageContentProps['account']
  invoiceDetails: InvoiceCreatePageContentProps['invoiceDetails']
  expenses: InvoiceCreatePageContentProps['expenses']
  gasUnitPrice: InvoiceCreatePageContentProps['gasUnitPrice']
  note: InvoiceCreatePageContentProps['note']
  status: InvoiceCreatePageContentProps['status']
  isSaveCompleteModalShown: InvoiceCreatePageContentProps['isSaveCompleteModalShown']
}

interface IEmits {
  (
    e: 'update:invoice-details',
    value: InvoiceCreatePageContentEmitParams['update:invoice-details']
  ): void
  (
    e: 'update:invoice-expenses',
    value: InvoiceCreatePageContentEmitParams['update:invoice-expenses']
  ): void
  (e: 'validated', value: InvoiceCreatePageContentEmitParams['validated']): void
  (
    e: 'temporary-save',
    value: InvoiceCreatePageContentEmitParams['temporary-save']
  ): void
  (
    e: 'update:is-save-complete-modal-shown',
    value: InvoiceCreatePageContentEmitParams['update:is-save-complete-modal-shown']
  ): void
  (e: 'continue-edit'): void
  (e: 'to-home'): void
}

const invoiceDetailRef = ref()
const invoiceExpenseRef = ref()
const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useInvoiceCreatePageContentStates(props, emits)
const actions = useInvoiceCreatePageContentActions(
  states,
  props,
  emits,
  invoiceDetailRef,
  invoiceExpenseRef
)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/font' as font-mixins;
@use '@/styles/sizes' as sizes;
@use '@/styles/colors' as colors;

.title {
  margin-top: space(10);
}
.bread-crumbs,
.revision-message {
  margin-top: space(6);
}
.content-box,
.total-amount-block,
.error-message,
.button-block {
  margin-top: space(8);
}
.total-amount-block {
  display: flex;
  justify-content: flex-end;
}
.checklist-block {
  margin-top: space(16);
}
.error-message {
  display: flex;
  justify-content: center;
}
.button-block {
  @include position-mixins.flex-center;
  flex-direction: column;
}
.temporary-save-button {
  margin-top: space(4);
}
.to-invoices-page {
  font-family: 'Noto Sans JP';
  margin-top: space(4);
}
</style>

<template>
  <div>
    <Typography v-if="props.status.month" :class="css.title" type="h1"
      >{{ props.status.month }}月分の請求書作成</Typography
    >
    <PageBreadcrumbs
      :breadcrumbs="props.breadcrumbs"
      :class="css['bread-crumbs']"
    />
    <RevisionMessage
      v-if="props.status.revisionMessage"
      :revision-message="props.status.revisionMessage"
      :class="css['revision-message']"
    />
    <ContentBox :class="css['content-box']">
      <Account
        :account="props.account"
        :is-account-info-registered="states.isAccountInfoRegistered"
        @transition:account="actions.moveToAccount"
        @transition:bank-account="actions.moveToBankAccount"
      />
    </ContentBox>
    <ContentBox :class="css['content-box']">
      <InvoiceDetail
        ref="invoiceDetailRef"
        :details="props.invoiceDetails"
        :gas-unit-price="props.gasUnitPrice"
        :note="props.note"
        @update:invoice-details="actions.updateInvoiceDetails"
      />
    </ContentBox>
    <ContentBox :class="css['content-box']"
      ><InvoiceExpense
        ref="invoiceExpenseRef"
        :expenses="props.expenses"
        @update:invoice-expenses="actions.updateInvoiceExpenses"
      />
    </ContentBox>
    <div :class="css['total-amount-block']">
      <TotalAmount :amount="states.totalAmount" />
    </div>
    <Checklist
      :all-checked="false"
      :class="css['checklist-block']"
      @update:all-checked="actions.updateAllChecked"
    />
    <Typography
      v-if="states.shouldErrorMessageShow"
      :class="css['error-message']"
      type="read-normal-attention"
      color="secondary"
      >項目にエラーがあります。修正してください。</Typography
    >
    <div :class="css['button-block']">
      <Button
        :disabled="!states.isConfirmAllChecked"
        width="288px"
        :on-click="actions.confirmPreview"
        >プレビューで確認する</Button
      >
      <Button
        color="secondary"
        width="288px"
        :class="css['temporary-save-button']"
        :on-click="actions.temporarySave"
        >一時保存する</Button
      >
      <Button
        color="ternary"
        :class="css['to-invoices-page']"
        :on-click="actions.moveBackPage"
      >
        前の画面へ
      </Button>
    </div>
    <InvoiceCreatePageMoveConfirmModal
      v-model:shown="states.isModalShown"
      @move-page="actions.movePage"
      @close="actions.handleCloseModal"
    />
    <SaveCompleteModal
      v-model:shown="states.isSaveCompleteModalShown"
      @continue-edit="emits('continue-edit')"
      @to-home="emits('to-home')"
    />
  </div>
</template>
