import { Computable } from '@/types/utils'
import { computed, reactive } from 'vue'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { BankAccountInfo } from '../../organisms/pageContent/AccountPageContent/BankAccountInfo'
import { SelfEntity } from '@/entities/SelfEntity'
import { BankAccountApi } from '@/domains/api/account/BankAccountApi'
import { onCreate } from '@/utils/functions/lifecycle'
import { AccountApi } from '@/domains/api/account/AccountApi'
import { IAccount, ICompany } from '@/types/photographer/account'

export interface IAccountStates {
  breadcrumbs: IBreadcrumb[]
  account: IAccount | null
  company: ICompany | null
  bankAccountInfo: BankAccountInfo | null
  invoiceGenerationFlg: boolean
}

export const useAccountStates = () => {
  const selfEntity = new SelfEntity()
  return reactive<Computable<IAccountStates>>({
    invoiceGenerationFlg: computed(() => selfEntity.invoiceGenerationFlg),
    breadcrumbs: [
      {
        label: 'ホーム',
        location: { name: photographerRoutes.NAME.TOP },
      },
      {
        label: 'アカウント',
      },
    ],
    company: null,
    account: null,
    bankAccountInfo: null,
  })
}

export const useAccountActions = (states: IAccountStates) => {
  const accountApi = new AccountApi()
  const bankAccountApi = new BankAccountApi()
  const createInitializer = async () => {
    states.account = await accountApi.fetchAccount()
    states.company = await accountApi.fetchCompany()
    states.bankAccountInfo = await bankAccountApi.fetchBankAccount()
  }
  onCreate(async () => {
    await createInitializer()
  })
}
