import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { reactive } from 'vue'
import { Computable, Emits } from '@/types/utils'
import { AccountInfoProps } from '../AccountPageContent/AccountInfo'
import {
  validateEmail,
  validateInvoiceNumber,
  validateKana,
  validateNotEntered,
  validateTel,
  validateZipcode,
} from '@/utils/functions/validation/validation'

export type AccountInfoEditPageContentProps = {
  accountInfo: AccountInfoProps
  breadcrumbs: IBreadcrumb[]
}

type AccountInfoEditPageContentStates = {
  hasErrorAccountInfoForm: boolean
  accountInfoData: AccountInfoProps
  validationFlg: boolean
}

export type AccountInfoEditPageContentEmitParams = {
  'save:account-info': AccountInfoProps
}

export const useAccountInfoEditPageContentStates = (
  props: AccountInfoEditPageContentProps
): AccountInfoEditPageContentStates => {
  const states: AccountInfoEditPageContentStates = reactive<
    Computable<AccountInfoEditPageContentStates>
  >({
    hasErrorAccountInfoForm: false,
    accountInfoData: props.accountInfo,
    validationFlg: false,
  })
  return states
}

/**
 * アカウント情報の保存ボタンを押せるか判定する
 * @param accountInfo アカウント情報
 * @returns boolean
 */
const handleHasValidationAccountForm = (accountInfo: AccountInfoProps) => {
  const account = accountInfo.account
  if (
    validateNotEntered(account.name) ||
    validateNotEntered(account.nameKana) ||
    !validateKana(account.nameKana) ||
    !validateEmail(account.email) ||
    !validateTel(account.tel) ||
    validateNotEntered(account.delivery.prefectureId) ||
    !validateZipcode(account.delivery.zipcode) ||
    validateNotEntered(account.delivery.address)
  ) {
    return true
  }
  return false
}

/**
 * インボイス登録番号のバリデーションチェック
 * @param accountInfo アカウント情報
 * @returns boolean
 */
const handleHasValidateInvoiceNumber = (accountInfo: AccountInfoProps) => {
  const account = accountInfo.account
  if (
    !validateNotEntered(account.invoiceNumber) &&
    !validateInvoiceNumber(account.invoiceNumber)
  ) {
    return true
  }
  return false
}

/**
 * 会社情報のフォームのバリデーションチェック
 * @param accountInfo アカウント情報
 * @returns boolean
 */
const handleHasValidateCompanyForm = (accountInfo: AccountInfoProps) => {
  const company = accountInfo.company
  if (
    validateNotEntered(company.name) ||
    validateNotEntered(company.nameKana) ||
    !validateKana(company.nameKana) ||
    validateNotEntered(company.location.address) ||
    !validateZipcode(company.location.zipcode) ||
    validateNotEntered(company.location.prefectureId)
  ) {
    return true
  }
  return false
}

/**
 * 会社情報のフォームが一つでも入力されているか判定する
 * @param accountInfo アカウント情報
 * @returns boolean
 */
const handleHasCompanyForm = (accountInfo: AccountInfoProps) => {
  const company = accountInfo.company
  if (
    company.name ||
    company.nameKana ||
    company.location.address ||
    company.location.zipcode ||
    company.location.prefectureId
  ) {
    return true
  }
  return false
}

export const useAccountInfoEditPageContentActions = (
  states: AccountInfoEditPageContentStates,
  emits: Emits<AccountInfoEditPageContentEmitParams>
) => {
  return {
    onUpdateAccountInfo(accountInfo: AccountInfoProps): void {
      states.accountInfoData = accountInfo
      states.hasErrorAccountInfoForm =
        handleHasValidationAccountForm(accountInfo)
    },
    onClickSaveButton(): void {
      const hasCompanyForm = handleHasCompanyForm(states.accountInfoData)
      const needsValidation =
        (hasCompanyForm &&
          handleHasValidateCompanyForm(states.accountInfoData)) ||
        handleHasValidateInvoiceNumber(states.accountInfoData)
      if (hasCompanyForm) {
        states.validationFlg = true
      }
      if (needsValidation) return
      emits('save:account-info', states.accountInfoData)
    },
    onValidationOff() {
      states.validationFlg = false
    },
  }
}
