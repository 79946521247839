import { IDirectoryInfo } from '@/types/photographer/directory'
import { IEventDetail } from '@/types/photographer/event'
import { Computable, Emits } from '@/types/utils'
import { parseDateYearToString } from '@/utils/functions/parser'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { TableRowProps } from '@/vueComponents/photographer/atoms/TableRow'
import { ITypographyProps } from '@/vueComponents/photographer/atoms/Typography'
import { CheckboxProps } from '@/vueComponents/photographer/molecules/Checkbox'
import { computed, reactive, watch } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { FolderTableNameCellProps } from '../FolderTableNameCell'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'

export type FolderTableRowProps = Omit<
  FolderTableNameCellProps,
  'folderName'
> & {
  dispatchId: IEventDetail['dispatchId']
  directory: Omit<IDirectoryInfo, 'inspectStatus'>
  eventStatus: EventStatusCode
}

export type FolderTableRowEmitParams = {
  'update:checked': {
    [folderId: FolderTableRowProps['directory']['id']]: CheckboxProps['checked']
  }
}

type FolderTableRowStates = {
  readonly updatedAtStr: string
  readonly rowType: TableRowProps['type']
  readonly textType: ITypographyProps['type']
  readonly photosCountTextType: ITypographyProps['type']
  readonly photosCount: number
  readonly buttonLabel: string
}

export const useFolderTableRowStates = (
  props: FolderTableRowProps
): FolderTableRowStates => {
  const states: FolderTableRowStates = reactive<
    Computable<FolderTableRowStates>
  >({
    updatedAtStr: computed(() =>
      parseDateYearToString(props.directory.updatedAt)
    ),
    rowType: computed(() =>
      props.disabled
        ? 'disabled'
        : props.directory.errorPhotoCount > 0
        ? 'warn'
        : 'default'
    ),
    textType: computed(() =>
      props.disabled ? 'read-normal-disabled' : 'read-normal'
    ),
    photosCountTextType: computed(() =>
      props.disabled
        ? 'read-normal-disabled'
        : states.photosCount === 0
        ? 'count-error'
        : 'read-normal'
    ),
    photosCount: computed(
      () => props.directory.photosCount + props.directory.errorPhotoCount
    ),
    buttonLabel: computed(() =>
      props.eventStatus === EventStatusCode.DELIVERED
        ? '画像を見る'
        : '画像の追加削除'
    ),
  })
  return states
}

type FolderTableActions = {
  selectFolder: () => Promise<void>
  emitCheckedFolderObj: (value: CheckboxProps['checked']) => void
}

export const useFolderTableRowActions = (
  props: FolderTableRowProps,
  emits: Emits<FolderTableRowEmitParams>
): FolderTableActions => {
  const router = useRouter()
  const eventId = props.dispatchId
  const folderId = props.directory.id

  const directoryPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.FOLDER,
    params: { eventId, folderId },
  }

  const emitCheckedFolderObj: FolderTableActions['emitCheckedFolderObj'] = (
    value
  ) => {
    const checkedFolderObj = { [folderId]: value }
    emits('update:checked', checkedFolderObj)
  }

  watch(() => props.checked, emitCheckedFolderObj)

  return {
    async selectFolder(): Promise<void> {
      await router.push(directoryPageLocation)
    },
    emitCheckedFolderObj,
  }
}
