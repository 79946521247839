<script setup lang="ts">
import { defineProps } from 'vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import {
  IAwaitingDeliveryNotificationProps,
  useAwaitingDeliveryNotificationStates,
} from '.'

interface IProps {
  type: IAwaitingDeliveryNotificationProps['type']
  sellerName: IAwaitingDeliveryNotificationProps['sellerName']
  eventName: IAwaitingDeliveryNotificationProps['eventName']
}

const props = defineProps<IProps>()

const states = useAwaitingDeliveryNotificationStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message {
  @include position-mixins.flex-center;
  gap: space(3);
}

.icon {
  margin-left: space(4);
}
</style>

<template>
  <div :class="css.container">
    <div :class="css.message">
      <Icon type="notification" />
      <Typography type="bread-crumb">{{ states.message }}</Typography>
    </div>
    <Icon :class="css.icon" type="arrow-medium-right" />
  </div>
</template>
