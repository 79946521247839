import { LoginApi } from '@/domains/api/account/LoginApi'
import { SelfApi } from '@/domains/api/account/SelfApi'
import { CreatingEntity } from '@/entities/global/CreatingEntity'
import { SelfEntity } from '@/entities/SelfEntity'
import { onCreate } from '@/utils/functions/lifecycle'
import { addPublicBasePath } from '@/utils/functions/vite'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { computed, reactive, watch } from 'vue'
import logoWhite from '@/media/img/logo_white.png'
import {
  RouteLocationNormalizedLoaded,
  Router,
  useRoute,
  useRouter,
} from 'vue-router'
import { currentScheduleMonth } from '@/utils/functions/createSchedulePrams'
import { HeaderButtonProps } from '@/vueComponents/photographer/molecules/HeaderButton'

const { NAME: ROUTE_NAME } = photographerRoutes

const HEADER_BUTTON = {
  NOTIFICATIONS: 0,
  EVENTS: 1,
  SCHEDULE: 2,
  ACCOUNT: 3,
  LOGOUT: 4,
} as const

type HeaderButton = {
  buttonType: HeaderButtonProps['buttonType']
  action: () => Promise<void>
}

export type AfterLoginHeaderStates = {
  userName: SelfEntity['name']
  initialPasswordFlg: SelfEntity['initialPasswordFlg']
  invoiceGenerationFlg: SelfEntity['invoiceGenerationFlg']
  logoPath: string
  topLocation: {
    name: 'top'
  }
  headerButtons: HeaderButton[]
}

const handleHeaderButtons = ({
  router,
  route,
  loginApi,
  creatingEntity,
}: {
  router: Router
  route: RouteLocationNormalizedLoaded
  loginApi: LoginApi
  creatingEntity: CreatingEntity
}) => {
  const headerButtons: {
    buttonType: HeaderButtonProps['buttonType']
    action: () => Promise<void>
  }[] = [
    {
      buttonType: 'header-bell',
      action: linkTo(router, ROUTE_NAME.NOTIFICATIONS),
    },
    {
      buttonType: 'header-camera',
      action: linkTo(router, ROUTE_NAME.EVENTS),
    },
    {
      buttonType: 'header-schedule',
      action: createLinkToSchedule(router, route),
    },
    {
      buttonType: 'header-account',
      action: linkTo(router, ROUTE_NAME.ACCOUNT),
    },
    {
      buttonType: 'header-logout',
      action: createLogoutOperator({ loginApi, router, creatingEntity }),
    },
  ]
  return headerButtons
}

export const useAfterLoginHeaderStates = () => {
  const selfEntity = new SelfEntity()
  const loginApi = new LoginApi()
  const creatingEntity = new CreatingEntity()
  const router = useRouter()
  const route = useRoute()

  return reactive({
    userName: computed(() => selfEntity.name),
    initialPasswordFlg: computed(() => selfEntity.initialPasswordFlg),
    invoiceGenerationFlg: computed(() => selfEntity.invoiceGenerationFlg),
    logoPath: addPublicBasePath(logoWhite),
    topLocation: { name: ROUTE_NAME.TOP },
    headerButtons: handleHeaderButtons({
      router,
      route,
      loginApi,
      creatingEntity,
    }),
  })
}

/**
 * ログイン後リダイレクト処理用関数の生成
 * @param payload ペイロードデータ
 * @returns ログイン後リダイレクト処理用関数
 */
const createLoggedInRedirection =
  (payload: { selfEntity: SelfEntity; router: Router }) => async () => {
    const { selfEntity, router } = payload
    if (!selfEntity.initialPasswordFlg || !selfEntity.name) return
    await router.push({ name: ROUTE_NAME.PASSWORD_UPDATE })
  }

/**
 * 初期化処理用関数の生成
 * @param payload ペイロードデータ
 * @returns 初期箇所利用関数
 */
const createInitializer =
  (payload: {
    selfApi: SelfApi
    selfEntity: SelfEntity
    route: RouteLocationNormalizedLoaded
    router: Router
    states: AfterLoginHeaderStates
  }) =>
  async () => {
    const { selfEntity, router, route, states } = payload
    const redirectOnLoggedIn = createLoggedInRedirection({ selfEntity, router })
    const data = await payload.selfApi.fetchSelf()
    selfEntity.setSelf(data)
    await redirectOnLoggedIn()
    if (
      !selfEntity.initialPasswordFlg &&
      route.name === ROUTE_NAME.PASSWORD_UPDATE
    ) {
      await router.push({ name: ROUTE_NAME.EVENTS })
    }
    if (
      !selfEntity.invoiceGenerationFlg &&
      route.path.includes(photographerRoutes.PATH.INVOICES)
    ) {
      await router.push({ name: ROUTE_NAME.TOP })
    }
    if (states.invoiceGenerationFlg) {
      states.headerButtons.splice(HEADER_BUTTON.ACCOUNT, 0, {
        buttonType: 'header-file-alt',
        action: linkTo(router, ROUTE_NAME.INVOICES),
      })
    }
    watch(() => selfEntity.initialPasswordFlg, redirectOnLoggedIn)
    watch(() => route.path, redirectOnLoggedIn)
  }

/**
 * ログアウト処理用関数の生成
 * @param payload ペイロードデータ
 * @returns ログアウト処理用関数
 */
const createLogoutOperator =
  (payload: {
    loginApi: LoginApi
    router: Router
    creatingEntity: CreatingEntity
  }) =>
  async () => {
    await payload.router.push({ name: ROUTE_NAME.LOGIN })
    const creatingKey = Symbol('logoutKey')
    payload.creatingEntity.addCreateSymbol(creatingKey)
    await payload.loginApi.postLogout()
    payload.creatingEntity.deleteCreateSymbol(creatingKey)
  }

/**
 * スケジュール遷移用関数の生成
 * @param router Router
 * @param route RouteLocationNormalizedLoaded
 * @returns スケジュール遷移用関数
 */
const createLinkToSchedule =
  (router: Router, route: RouteLocationNormalizedLoaded) => async () => {
    if (route.name === ROUTE_NAME.SCHEDULE) return
    await router.push({
      name: ROUTE_NAME.SCHEDULE,
      params: { scheduleMonth: currentScheduleMonth() },
    })
  }

const linkTo = (router: Router, to: string) => async () => {
  await router.push({ name: to })
}

/**
 * AfterLoginHeader用アクションフック
 * @returns アクション
 */
export const useAfterLoginHeaderActions = (states: AfterLoginHeaderStates) => {
  const selfApi = new SelfApi()
  const selfEntity = new SelfEntity()
  const route = useRoute()
  const router = useRouter()

  onCreate(createInitializer({ selfApi, selfEntity, route, router, states }))
}
