<script setup lang="ts">
import { defineProps, ref } from 'vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import { photographerConstants } from '@/utils/constants/photographerConstants'
import { RowProps, useRowStates, useRowActions } from '.'

interface IProps {
  rowNo: RowProps['rowNo']
  date: RowProps['date']
  content: RowProps['content']
  originalFileName: RowProps['originalFileName']
  file: RowProps['file']
  fetchFileUrl?: RowProps['fetchFileUrl']
  amount: RowProps['amount']
}
const props = defineProps<IProps>()
const states = useRowStates(props)

const fileDivElement = ref<HTMLDivElement>()
const actions = useRowActions(props, states, fileDivElement)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/hover' as hover-mixins;

.row {
  height: 54px;
  font-size: sizes.$font-size-md;
  color: colors.$font-color-primary;
}
.unit {
  margin-left: space(1);
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.file-cell {
  max-width: 136px;
  font-size: sizes.$font-size-sm;
}
.file-name {
  color: colors.$font-color-link;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.file-name-tooltip {
  position: absolute;
  background-color: colors.$background-color-tooltip;
  border: solid 1px colors.$border-color-tooltip;
  padding: space(1) space(2);
  color: colors.$font-color-tooltip;
  z-index: 10;
}
.file-name-link {
  color: colors.$font-color-link;
  @include hover-mixins.hover-opacity;
}
</style>

<template>
  <TableRow :class="css.row">
    <TableCell :separatorable="false" width="40px">
      {{ props.rowNo }}.
    </TableCell>
    <TableCell :separatorable="false" width="128px">
      {{ states.displayDate }}
    </TableCell>
    <TableCell :separatorable="false" width="432px">
      {{ props.content }}
    </TableCell>
    <TableCell width="144px" :class="css['file-cell']">
      <div ref="fileDivElement" :class="css['file-name']">
        <span
          :class="css['file-name-link']"
          @mouseover="states.isHover = true"
          @mouseleave="states.isHover = false"
          @click="actions.openFile"
        >
          {{ props.originalFileName }}
        </span>
      </div>
      <div
        v-if="states.isHover && states.isOverFlow"
        :class="css['file-name-tooltip']"
      >
        {{ props.originalFileName }}
      </div>
    </TableCell>
    <TableCell width="80px" :class="css.center">
      {{ photographerConstants.CONSUMPTION_TAX_RATE * 100
      }}<span :class="css.unit">%</span>
    </TableCell>
    <TableCell :separatorable="false" width="168px" :class="css.right">
      {{ states.displayAmount }}<span :class="css.unit">円</span>
    </TableCell>
  </TableRow>
</template>
