import { reactive, computed } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { Computable } from '@/types/utils'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import {
  parseJaDateYearToString,
  parseJaDateYearWithDayOfWeekToString,
} from '@/utils/functions/parser'
import { IDispatchOrder } from '@/types/photographer/dispatchOrder'

export type MatterRequestTableCellProps = {
  matterRequest: IDispatchOrder
  hasButton?: boolean
  isBold?: boolean
}

type MatterRequestTableCellState = {
  mailSendAt: string
  startDate: string
  replyStatus: string
  isDisabled: boolean
  titleTypographyType: 'read-normal' | 'read-normal-bold'
  replyDeadlineTypographyType:
    | 'read-small-attention'
    | 'read-small-attention-bold'
}

const overReplyDeadline = (replyDeadlineCount: number): boolean =>
  replyDeadlineCount < 0

const replyDeadlineStatus = (replyDeadlineCount: number): string =>
  overReplyDeadline(replyDeadlineCount)
    ? '回答期限切れ'
    : `回答期限：あと${replyDeadlineCount}日`

const replyStatusText = (props: MatterRequestTableCellProps): string => {
  const matterRequest = props.matterRequest
  if (matterRequest.isReplied) return '回答済み'
  if (
    (!matterRequest.isReplied && matterRequest.existsNewOrder) ||
    matterRequest.reachedCapacity ||
    !matterRequest.canReply
  )
    return '受付終了'
  return replyDeadlineStatus(props.matterRequest.replyDeadlineCount)
}

const isDisabledValue = (props: MatterRequestTableCellProps) => {
  const matterRequest = props.matterRequest
  return (
    overReplyDeadline(matterRequest.replyDeadlineCount) ||
    (!matterRequest.isReplied && matterRequest.existsNewOrder) ||
    matterRequest.reachedCapacity ||
    matterRequest.isReplied ||
    !matterRequest.canReply
  )
}

export const useMatterRequestTableCellState = (
  props: MatterRequestTableCellProps
) =>
  reactive<Computable<MatterRequestTableCellState>>({
    mailSendAt: computed(() =>
      parseJaDateYearToString(props.matterRequest.mailSendAt)
    ),
    startDate: computed(() =>
      parseJaDateYearWithDayOfWeekToString(props.matterRequest.startDate)
    ),
    replyStatus: computed(() => replyStatusText(props)),
    isDisabled: computed(() => isDisabledValue(props)),
    titleTypographyType: props.isBold ? 'read-normal-bold' : 'read-normal',
    replyDeadlineTypographyType: props.isBold
      ? 'read-small-attention-bold'
      : 'read-small-attention',
  })

export const useMatterRequestTableCellActions = (
  props: MatterRequestTableCellProps
) => {
  const router = useRouter()

  const proposalPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.PROPOSAL,
    params: { proposalId: props.matterRequest.dispatchOrderId },
  }

  return {
    async toDetailPage(): Promise<void> {
      await router.push(proposalPageLocation)
    },
  }
}
