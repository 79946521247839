import { removeNonKanaCharacters } from '@/utils/functions/validation/validation'

export interface CompanyNameKanaInputProps {
  modelValue: string
  companyNameKanaErrorMessage: string
}

interface CompanyNameKanaInputEmits {
  (e: 'update:modelValue', value: string): void
  (e: 'input:companyNameKana'): void
}

type CompanyNameKanaActions = {
  handleInputCompanyNameKana(e: Event): void
  handleBlurCompanyNameKana(e: Event): void
}

export const useCompanyNameKanaInputActions = (
  emits: CompanyNameKanaInputEmits
): CompanyNameKanaActions => {
  return {
    handleInputCompanyNameKana(e: Event) {
      const target = e.target as HTMLInputElement
      emits('update:modelValue', target.value)
      emits('input:companyNameKana')
    },
    handleBlurCompanyNameKana(e: Event) {
      const target = e.target as HTMLInputElement
      const validatedValue = removeNonKanaCharacters(target.value)
      target.value = validatedValue
      emits('update:modelValue', validatedValue)
    },
  }
}
