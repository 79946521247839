import { Expense } from './Table'

export type ExpenseProps = {
  expenses: Expense[]
  totalAmount: number
}

type ExpenseStates = {
  displayTotalAmount: string
}

export const useExpenseStates = (props: ExpenseProps): ExpenseStates => {
  return {
    displayTotalAmount: props.totalAmount.toLocaleString(),
  }
}
