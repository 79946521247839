import { computed, reactive } from 'vue'
import { Computable } from '@/types/utils'
import { DAY_OF_WEEK } from '@/utils/constants/messages/photographer/dayOfWeek'
import { ScheduleReplyStatus } from '@/utils/constants/enums/photographer/schedule/index'

export type CalendarProps = {
  todaysDate: string
  displayYearMonth: string
  schedules: {
    date: string
    plan: number
  }[]
  isLoading: boolean
  clickPrev: () => Promise<void>
  clickNext: () => Promise<void>
}

type CalendarItems = {
  month: number
  day: number
  isVacant: boolean
  isToday: boolean
  isCurrentMonth: boolean
}[]

type CalendarStates = {
  calendarItems: CalendarItems[]
  dayOfWeekItems: string[]
  displayYearMonth: string
  isDisabledLastMonth: boolean
}

const createDayOfWeekItems = [
  DAY_OF_WEEK.SUN,
  DAY_OF_WEEK.MON,
  DAY_OF_WEEK.TUE,
  DAY_OF_WEEK.WED,
  DAY_OF_WEEK.THU,
  DAY_OF_WEEK.FRI,
  DAY_OF_WEEK.SAT,
]

/**
 * カレンダー表示に必要なデータの生成
 * @param props プロップス
 * @returns カレンダー表示に必要な配列データ
 */
const createDayInfo = (props: CalendarProps): CalendarItems => {
  const result: CalendarItems = props.schedules.map(({ date, plan }) => ({
    month: Number(date.split('-')[1]),
    day: Number(date.split('-')[2]),
    isVacant: plan === ScheduleReplyStatus.OK,
    isToday: date === props.todaysDate,
    isCurrentMonth:
      date.split('-')[1] === props.displayYearMonth.replace(/\d+-(\d+)/, '$1'),
  }))
  return result
}

/**
 * カレンダー表示処理に必要なデータの生成
 * @param calendarItems カレンダー表示に必要な配列データ
 * @returns 一週間分の配列データを持った、6週間分の配列データ
 */
const createCalendarItems = (calendarItems: CalendarItems) => {
  const itemLength = calendarItems.length
  const weekItemNum = createDayOfWeekItems.length
  const result: CalendarStates['calendarItems'] = []

  for (let i = 0; i < Math.ceil(itemLength / weekItemNum); i++) {
    const beginningDate = i * weekItemNum
    const aWeekItem = calendarItems.slice(
      beginningDate,
      beginningDate + weekItemNum
    )
    result.push(aWeekItem)
  }
  return result
}

export const useCalendarStates = (props: CalendarProps) => {
  const regPattern = /(\d+)-(\d+)/
  const displayYear = computed(() =>
    props.displayYearMonth.replace(regPattern, '$1')
  )
  const displayMonth = computed(() =>
    Number(props.displayYearMonth.replace(regPattern, '$2'))
  )
  const states: CalendarStates = reactive<Computable<CalendarStates>>({
    calendarItems: computed(() => createCalendarItems(createDayInfo(props))),
    dayOfWeekItems: createDayOfWeekItems,
    displayYearMonth: computed(
      () => `${displayYear.value}年${displayMonth.value}月`
    ),
    isDisabledLastMonth: computed(
      () => props.displayYearMonth === props.todaysDate.slice(0, -3)
    ),
  })
  return states
}
