import {
  IEventTitle,
  IEventInfo,
  IEventDetail,
} from '@/types/photographer/event'
import { InjectionKey, ref } from 'vue'
import { BaseStore } from '../..'

export class EventStore extends BaseStore {
  dispatchId = ref<IEventDetail['dispatchId']>(null)
  eventTitle = ref<IEventTitle>({
    sellerName: '',
    eventName: '',
  })

  eventInfo = ref<IEventInfo>()
}

export const eventStoreKey: InjectionKey<EventStore> = Symbol('EventStoreKey')
