<script setup lang="ts">
import { defineProps } from 'vue'
import Select from '@/vueComponents/photographer/atoms/Select/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import {
  PrefectureInputProps,
  usePrefectureInputActions,
  usePrefectureInputStates,
} from '.'

interface IProps {
  modelValue: PrefectureInputProps['modelValue']
  prefecturesOptions: PrefectureInputProps['prefecturesOptions']
}

interface IEmits {
  (e: 'update:modelValue', value: number): void
}

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const states = usePrefectureInputStates()
const actions = usePrefectureInputActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as space-positions;
@use '@/styles/mixins/font' as font-mixins;

.title {
  @include font-mixins.font-weight-bold();
}
.table-cell div,
.col {
  @include space-positions.flex-horizontal(space(2));
}
.table-cell {
  padding: space(3) space(2);
  width: 256px;
}
.input-error {
  margin-top: space(1);
}
</style>

<template>
  <Select
    :value="props.modelValue"
    name="都道府県"
    width="144px"
    :options="props.prefecturesOptions"
    :disabled="false"
    :has-error="Boolean(states.PrefectureErrorMessage)"
    @update:value="actions.handleInputPrefecture"
  />
  <InputError
    v-if="states.PrefectureErrorMessage"
    type="attention"
    :class="css['input-error']"
    width="128px"
    >{{ states.PrefectureErrorMessage }}</InputError
  >
</template>
