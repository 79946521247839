<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import {
  INotifyDepartureContentProps,
  useNotifyDepartureContentStates,
  useNotifyDepartureContentActions,
  MESSAGE_TYPE,
} from '.'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'

interface IEmits {
  (e: 'notify'): void
}
const emits = defineEmits<IEmits>()

interface IProps {
  expiryStatus: INotifyDepartureContentProps['expiryStatus']
  isNotified: INotifyDepartureContentProps['isNotified']
}
const props = defineProps<IProps>()
const states = useNotifyDepartureContentStates(props)
const actions = useNotifyDepartureContentActions(emits, states)
</script>

<style lang="scss" module="css">
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/colors' as colors;
.container {
  text-align: center;
  font-size: sizes.$font-size-md;
  line-height: 26px;
  white-space: pre-line;
}
.message {
  margin-top: 100px;
}
.btn {
  @include position-mixins.flex-center(true, false);
  margin-top: 26px;
}
.errorMessage {
  margin-top: 26px;
  color: colors.$font-color-attention;
}
</style>

<template>
  <div :class="css.container">
    <div :class="css.message">
      <template v-if="states.messageType === MESSAGE_TYPE.UNNOTIFIED">
        開催当日、撮影場所への移動開始時にボタンをクリックしてください
      </template>
      <template v-else-if="states.messageType === MESSAGE_TYPE.NOTIFIED">
        出発報告が完了しました。<br />
        気を付けて行ってらっしゃいませ！
      </template>
      <template v-else>
        開催時刻がすぎています。事務局へご連絡ください。<br />
        <br />
        電話でのご連絡はこちらから<br />
        <a href="tel:03-6221-3068">03-6221-3068</a><br />
        お問い合わせ受付時間：平日10:00～18:00<br />
        (土日祝・盆時期・年末年始除く)<br />
        <br />
        メールでのご連絡はこちらから<br />
        E-mail： <a href="mailto:enphoto@uluru.jp">enphoto@uluru.jp</a>
      </template>
    </div>
    <div v-if="states.isShowButton">
      <div :class="css.btn">
        <Button :on-click="actions.notify" :disabled="!states.canPushButton"
          >出発報告</Button
        >
      </div>
      <p v-if="states.isShowAttentionMessage" :class="css.errorMessage">
        有効期限外です <br />
        集合時間の5時間前〜押下してください
      </p>
    </div>
  </div>
</template>
