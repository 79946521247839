<script setup lang="ts">
import { defineProps } from 'vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import Message from '@/vueComponents/photographer/molecules/Message/index.vue'
import DeliveryButton from './DeliveryButton/index.vue'
import FolderCreationButton from './FolderCreationButton/index.vue'
import FolderTable from './FolderTable/index.vue'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'
import { MESSAGE } from '@/utils/constants/messages/photographer/message'
import {
  EventPageFolderProps,
  useEventPageFolderStates,
  useEventPageFolderActions,
} from '.'

interface IProps {
  sellerName: EventPageFolderProps['sellerName']
  eventName: EventPageFolderProps['eventName']
  eventInfo: EventPageFolderProps['eventInfo']
  directories: EventPageFolderProps['directories']
  dispatchId: EventPageFolderProps['dispatchId']
  onCreateFolder: EventPageFolderProps['onCreateFolder']
  onUpdateFolderName: EventPageFolderProps['onUpdateFolderName']
  onDeliver: EventPageFolderProps['onDeliver']
  onDeleteFolder: EventPageFolderProps['onDeleteFolder']
}

const props = defineProps<IProps>()

const states = useEventPageFolderStates(props)

const actions = useEventPageFolderActions(props, states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/mixins/position' as position-mixins;
.container {
  padding: space(8);
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: space(3);
  &-button {
    max-width: 166px;
    margin-top: -2px;
  }
}
.separator {
  @include space-mixins.vertical-margin(3);
}
.table {
  @include space-mixins.vertical-margin(6, 14);
  @include space-mixins.side-margin(4);
}
.error-message {
  @include space-mixins.vertical-margin(0, 6);
}
.delete-button {
  @include space-mixins.vertical-margin(6);
  width: 154px;
}
.deliver-button {
  @include space-mixins.vertical-margin(2);
}
.no-folder {
  @include space-mixins.vertical-margin(27, 23);
  @include position-mixins.flex-vertical(space(5));
}
</style>

<template>
  <ContentBox>
    <div :class="css.container">
      <div :class="css.title">
        <Typography type="h2-simple">フォルダ一覧</Typography>
        <div v-if="!states.delivered" :class="css['title-button']">
          <FolderCreationButton
            :disabled="!states.editable"
            :event-date="props.eventInfo.startDate"
            :event-name="props.eventName"
            :on-submit="props.onCreateFolder"
          />
        </div>
      </div>
      <div :class="css.separator"><Separator /></div>
      <div :class="css.table">
        <div
          v-if="states.editable && states.hasError"
          :class="css['error-message']"
        >
          <Message>{{ MESSAGE.HAS_ERROR_PHOTOS }}</Message>
        </div>
        <template v-if="props.directories.length > 0">
          <FolderTable
            v-model:checked-id-set="states.checkedIdSet"
            :editable="states.editable"
            :event-date="props.eventInfo.startDate"
            :event-status="props.eventInfo.status"
            :event-name="props.eventName"
            :directories="props.directories"
            :dispatch-id="props.dispatchId"
            :on-update-folder-name="props.onUpdateFolderName"
            :on-delete="actions.deleteFolder"
          />
        </template>
        <template v-else>
          <div :class="css['no-folder']">
            <Typography>表示する内容がありません</Typography>
            <Typography type="read-small" element="p" text-align="center">
              全ての写真アップロードが完了したら<br />
              こちらから検品依頼ボタンを押してください
            </Typography>
          </div>
        </template>
        <div v-if="!states.delivered" :class="css['deliver-button']">
          <DeliveryButton
            :editable="states.editable"
            :deliverable="states.deliverable && !states.hasError"
            :seller-name="props.sellerName"
            :event-name="props.eventName"
            :sending-back="
              props.eventInfo.status === EventStatusCode.SENDING_BACK
            "
            :on-submit="props.onDeliver"
          />
        </div>
      </div>
    </div>
  </ContentBox>
</template>
