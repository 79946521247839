<script setup lang="ts">
import { defineProps } from 'vue'
import TableHead from '@/vueComponents/photographer/atoms/TableHead/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { EventTableProps, useEventTableStates } from '.'
import EventTableRow from '@/vueComponents/photographer/organisms/pageContent/TopPageContent/Event/EventTableRow/index.vue'
import EmptyMassage from '@/vueComponents/photographer/organisms/section/EmptyMassage/index.vue'

interface IProps {
  events: EventTableProps['events']
}

const props = defineProps<IProps>()

const states = useEventTableStates(props)

const columnHeads = [
  { title: '園名／イベント名', textLeftable: true },
  { title: '撮影日程', width: '96px' },
  { title: '開催種別', width: '96px' },
  { title: '写真納期', width: '96px' },
  { title: 'ステータス', width: '96px' },
  { title: '', width: '80px' },
]
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.table {
  @include space-mixins.vertical-margin(6);
}
</style>

<template>
  <div>
    <div v-if="props.events.length > 0" :class="css.table">
      <Table>
        <TableRow>
          <TableHead
            v-for="columnHead in columnHeads"
            :key="columnHead.title"
            :text-leftable="columnHead.textLeftable"
            :width="columnHead.width"
          >
            <Typography type="head">{{ columnHead.title }}</Typography>
          </TableHead>
        </TableRow>
        <EventTableRow
          v-for="event in states.sortedEvents"
          :key="event.dispatchId"
          :event="event"
        />
      </Table>
    </div>
    <EmptyMassage v-else height="200px" translate-y="16px" />
  </div>
</template>
