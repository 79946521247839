import { Computable } from '@/types/utils'
import { TabProps } from '@/vueComponents/photographer/molecules/Tab'
import { computed, reactive } from 'vue'
import { MatterRequestTableProps } from '../../../section/MatterRequestTable'

export type FiscalYearTabProps = {
  matterRequestsByYear: {
    year: string
    matterRequest: MatterRequestTableProps['matterRequests']
  }[]
}

type FiscalYearTabStates = {
  tabItems: TabProps['tabItems']
}

export const useFiscalYearTabStates = (props: FiscalYearTabProps) =>
  reactive<Computable<FiscalYearTabStates>>({
    tabItems: computed(() =>
      props.matterRequestsByYear.map((data) => data.year)
    ),
  })
