import { IPhotoUploadingStatus } from '@/types/photographer/photo'
import {
  EventStatusCode,
  InspectionStatusCode,
} from '@/utils/constants/enums/photographer/event'
import { PhotoUploadStatus } from '@/utils/constants/enums/photographer/photo'
import { NotFoundError } from '@/utils/modules/exceptions/NotFoundError'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { IFolderPageContentProps } from '@/vueComponents/photographer/organisms/pageContent/FolderPageContent'
import { RouteLocationRaw } from 'vue-router'
import { FolderPayload } from '..'

/**
 * 写真アップロードステータス更新関数の生成
 * @param payload ペイロードデータ
 * @returns 写真アップロードステータス更新関数
 */
export const createUploadingStatusUpdater =
  (
    payload: Pick<FolderPayload, 'photoApi'>
  ): IFolderPageContentProps['onUpdateUploadingStatus'] =>
  async (photoIds, uploadingPhotos) => {
    const photoStatuses = await payload.photoApi.fetchPhotosStatus(photoIds)
    for (const photoId of photoIds) {
      const uploadingPhoto = uploadingPhotos.find(
        (_uploadingPhoto) => _uploadingPhoto.photoId === photoId
      )
      if (!uploadingPhoto) continue
      const photoStatus = photoStatuses.find(
        (_photoStatus) => _photoStatus.id === photoId
      )
      uploadingPhoto.setPhotoUploadingStatus(photoStatus)
    }
  }

/**
 * FailedPhotosのアップロード中のuploadedPhotoを更新
 * @param payload ペイロードデータ
 */
export const updateFailedUploadedPhoto = (
  photoIds: number[],
  photoStatuses: IPhotoUploadingStatus[],
  payload: Pick<FolderPayload, 'states' | 'folderEntity'>
): void => {
  for (const photoId of photoIds) {
    const photoStatus = photoStatuses.find(
      (_photoStatus) => _photoStatus.id === photoId
    )
    const failedPhoto = payload.states.failedPhotos?.get(photoId)
    if (!photoStatus || !failedPhoto) continue
    const uploadedPhoto = Object.assign({}, failedPhoto, {
      photographed: photoStatus.photographed,
      status: photoStatus.status,
    })
    payload.folderEntity.updateUploadedPhoto(photoId, uploadedPhoto)
  }
}

/**
 * ステータスが20,30,40,1000のFailedPhotosがある場合APIを取得後更新関数の生成
 * @param payload ペイロードデータ
 * @returns true:ステータスが20,30,40,1000のFailedPhotosがある場合、false:ステータスが20,30,40,1000のFailedPhotosがない場合
 */
export const createFailedPhotosStatusUpdater =
  (
    payload: Pick<FolderPayload, 'photoApi' | 'states' | 'folderEntity'>
  ): IFolderPageContentProps['onUpdateFailedPhotosStatus'] =>
  async () => {
    const photoIds = Array.from(payload.states.failedPhotos ?? [])
      .filter(
        ([, { status }]) =>
          (status > PhotoUploadStatus.WAITING_UPLOAD_S3 &&
            status < PhotoUploadStatus.COMPLETE) ||
          status === PhotoUploadStatus.ERROR_TIMEOUT
      )
      .map(([photoId]) => photoId)
    if (!photoIds.length) return false
    const photoStatuses = await payload.photoApi.fetchPhotosStatus(photoIds)
    updateFailedUploadedPhoto(photoIds, photoStatuses, payload)
    return true
  }

/**
 * 写真アップロード用関数の生成
 * @param payload ペイロードデータ
 * @returns 写真アップロード用関数
 */
export const createFileUploader =
  (
    payload: Pick<FolderPayload, 'directoryId' | 'photoApi' | 'fileUploadApi'>
  ): IFolderPageContentProps['onUploadFile'] =>
  async (uploadingPhoto) => {
    try {
      const { photoId, presignedUrl, imgName } =
        await payload.photoApi.postPhoto(
          payload.directoryId,
          uploadingPhoto.orgImgName
        )
      uploadingPhoto.photoId = photoId
      uploadingPhoto.imgName = imgName
      await payload.fileUploadApi.putFile(
        presignedUrl,
        uploadingPhoto.file,
        (e) => uploadingPhoto.setProgressRate(e)
      )
      uploadingPhoto.isUploaded = true
    } catch (e) {
      uploadingPhoto.hasError = true
      throw e
    }
  }

/**
 * 写真削除処理の生成
 * @param payload ペイロードデータ
 * @returns 写真削除処理
 */
export const createPhotoDeleter =
  (
    payload: Pick<FolderPayload, 'creatingEntity' | 'photoApi' | 'folderEntity'>
  ): IFolderPageContentProps['onDeletePhoto'] =>
  async (photoIds: number[]) => {
    const { creatingEntity, photoApi, folderEntity } = payload
    const deleteKey = Symbol('deletePhoto')
    creatingEntity.addCreateSymbol(deleteKey)
    try {
      await photoApi.deletePhoto(photoIds)
      folderEntity.deleteUploadedPhotos(photoIds)
    } finally {
      creatingEntity.deleteCreateSymbol(deleteKey)
    }
  }

/**
 * イベント詳細画面へリダイレクト処理
 * @param payload ペイロードデータ
 * @returns true:強制リダイレクト false:リダイレクトなし
 */
const setRedirection = async (payload: FolderPayload): Promise<boolean> => {
  const { eventId, states, router, eventEntity } = payload
  const eventPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.EVENT,
    params: { eventId },
  }
  if (
    (eventEntity.eventInfo?.status === EventStatusCode.SENDING_BACK &&
      states.folderDetail.inspectStatus !==
        InspectionStatusCode.INSPECTION_COMPLETE) ||
    eventEntity.eventInfo?.status === EventStatusCode.BEFORE_DELIVERY ||
    eventEntity.eventInfo?.status === EventStatusCode.DELIVERED
  )
    return false
  await router.push(eventPageLocation)
  return true
}

/**
 * フォルダ情報取得時エラー処理の生成
 * @param payload ペイロードデータ
 */
const createFetchDirectoryErrorHandler =
  (payload: FolderPayload) => async (e: unknown) => {
    const { router, eventId } = payload
    if (!(e instanceof NotFoundError)) throw e
    await router.push({
      name: photographerRoutes.NAME.EVENT,
      params: { eventId },
    })
  }

/**
 * フォルダ情報の更新
 * @param payload ペイロードデータ
 * @returns true:更新失敗 false:更新完了
 */
const updateFolderDetail = async (payload: FolderPayload): Promise<boolean> => {
  const { directoryId, states, directoryApi } = payload
  try {
    states.folderDetail = await directoryApi.fetchDirectory(directoryId)
    return false
  } catch (e) {
    await createFetchDirectoryErrorHandler(payload)(e)
    return true
  }
}

/**
 * 派遣IDとフォルダIDの組み合わせ不正時処理
 * @param payload ペイロードデータ
 */
const assertDispatchId = (payload: FolderPayload) => {
  const { states, eventId } = payload
  if (states.folderDetail.dispatchId !== eventId) throw new NotFoundError()
}

/**
 * 初期表示時処理の生成
 * @param payload ペイロードデータ
 * @returns 初期表示時処理
 */
export const createInitializer = (payload: FolderPayload) => async () => {
  const { directoryId, eventId, eventEntity, folderEntity } = payload
  if (
    !eventEntity.eventTitle.eventName &&
    eventId !== eventEntity.dispatchId &&
    !eventEntity.eventInfo?.inspectMemo &&
    !eventEntity.eventInfo?.status &&
    !eventEntity.eventInfo?.startDate
  ) {
    eventEntity.setEvent(await payload.eventApi.fetchEvent(eventId))
  }
  if (await updateFolderDetail(payload)) return
  assertDispatchId(payload)
  if (await setRedirection(payload)) return
  folderEntity.initUploadedPhotos()
  folderEntity.setUploadedPhotos(
    await payload.photoApi.fetchPhotos(directoryId)
  )
}

/**
 * サムネイル画像取得
 * @param photoApi 写真API
 * @param photoId 写真ID
 * @returns サムネイルURL
 */
export const createThumbnailUrlGetter =
  (payload: FolderPayload): IFolderPageContentProps['thumbnailUrlGetter'] =>
  async (photo) => {
    const { thumbnailUrl } = await payload.photoApi.fetchThumbnailUrl({
      dispatchId: payload.eventId,
      directoryId: payload.directoryId,
      imgName: photo.imgName,
    })
    return thumbnailUrl
  }
