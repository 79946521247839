import { reactive } from 'vue'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { useRouter } from 'vue-router'
import { ValidationCode } from '@/utils/constants/enums/validation'
import {
  convertErrorMsgLoginId,
  convertErrorMsgPhotographerLoginPassword,
} from '@/utils/functions/errorMsg'
import { Promisable } from '@/types/utils'
import { createOnAsync } from '@/utils/functions/lifecycle'
import { CreatingEntity } from '@/entities/global/CreatingEntity'

export type LoginContentProps = {
  onClick?: (loginId: string, password: string) => Promisable<string[] | void>
}

type LoginContentStates = {
  loginId: string
  password: string
  loginIdValidationCode: ValidationCode
  passwordValidationCode: ValidationCode
  errorMessages: string[]
}

export const useLoginContentStates = () =>
  reactive<LoginContentStates>({
    loginId: '',
    password: '',
    loginIdValidationCode: ValidationCode.EMPTY,
    passwordValidationCode: ValidationCode.EMPTY,
    errorMessages: [],
  })

type LoginContentPayload = {
  states: LoginContentStates
  props: LoginContentProps
}

const validate = ({ states }: LoginContentPayload): boolean => {
  const validationMessages: string[] = []
  const { loginIdValidationCode, passwordValidationCode } = states
  if (loginIdValidationCode !== ValidationCode.SUCCESS) {
    validationMessages.push(convertErrorMsgLoginId(loginIdValidationCode))
  }
  if (passwordValidationCode !== ValidationCode.SUCCESS) {
    validationMessages.push(
      convertErrorMsgPhotographerLoginPassword(passwordValidationCode)
    )
  }
  if (validationMessages.length > 0) {
    states.errorMessages = validationMessages
    return false
  }
  return true
}

const login = async (payload: LoginContentPayload): Promise<boolean> => {
  const { states, props } = payload
  const errorMessages = await props.onClick?.(states.loginId, states.password)
  if (errorMessages) {
    states.errorMessages = errorMessages
    return false
  }
  return true
}

export const useLoginContentActions = (
  props: LoginContentProps,
  states: LoginContentStates
) => {
  const router = useRouter()
  const onAsync = createOnAsync(new CreatingEntity(), 'login')
  const payload: LoginContentPayload = {
    states,
    props,
  }

  return {
    updateLoginIdValidationCode(code: ValidationCode): void {
      states.loginIdValidationCode = code
    },
    updatePasswordValidationCode(code: ValidationCode): void {
      states.passwordValidationCode = code
    },
    async handleLoginButtonClick(): Promise<void> {
      if (!validate(payload)) return
      await onAsync(async () => {
        const loggedin = await login(payload)
        if (!loggedin) return
        await router.push({ name: photographerRoutes.NAME.TOP })
      })
    },
  }
}
