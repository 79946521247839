import { Emits } from '@/types/utils'
import { reactive, watchEffect } from 'vue'

export type ChecklistEmitParams = {
  'update:allChecked': boolean
}

export const checkContents = [
  '領収書のアップロード漏れはありませんか？',
  '間違った領収書をアップロードしていませんか？',
  '撮影日、内容、撮影費、金額など記入漏れはありませんか？',
]

type CheckItem = {
  content: string
  checked: boolean
}

type ChecklistStates = {
  checkItems: CheckItem[]
}

export const useChecklistStates = (): ChecklistStates => {
  return reactive({
    checkItems: checkContents.map((content) => ({
      content,
      checked: false,
    })),
  })
}

export const useChecklistActions = (
  emits: Emits<ChecklistEmitParams>,
  states: ChecklistStates
) => {
  watchEffect(() => {
    emits(
      'update:allChecked',
      states.checkItems.every((item) => item.checked)
    )
  })
}
