import { reactive, computed, watch } from 'vue'
import { Computable } from '@/types/utils'
import {
  RouteLocationNormalizedLoaded,
  Router,
  useRoute,
  useRouter,
} from 'vue-router'
import {
  parseExtendedStringToString,
  parseStringDateToDate,
} from '@/utils/functions/parser'
import { IScheduleReplyData } from '@/types/photographer/schedules'
import { ScheduleReplyPrams } from '../../organisms/pageContent/ScheduleContent/ScheduleRegistration'
import { createOnAsync } from '@/utils/functions/lifecycle'
import { CreatingEntity } from '@/entities/global/CreatingEntity'
import { ScheduleApi } from '@/domains/api/schedule/ScheduleApi'
import { isBefore, startOfMonth } from 'date-fns'
import { currentScheduleMonth } from '@/utils/functions/createSchedulePrams'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { SelfEntity } from '@/entities/SelfEntity'

const { NAME: ROUTE_NAME } = photographerRoutes

export type ScheduleStates = {
  scheduleMonth: Date
  scheduleReplyData: IScheduleReplyData[]
}

export type SchedulePayload = {
  states: ScheduleStates
  onAsync: ReturnType<typeof createOnAsync>
  scheduleApi: ScheduleApi
  router: Router
  route: RouteLocationNormalizedLoaded
  selfEntity: SelfEntity
}

export const useScheduleStates = () => {
  const route = useRoute()
  const states: ScheduleStates = reactive<Computable<ScheduleStates>>({
    scheduleMonth: computed(() =>
      parseStringDateToDate(
        parseExtendedStringToString(route.params.scheduleMonth)
      )
    ),
    scheduleReplyData: [],
  })
  return states
}

/**
 * ScheduleReplyDataの更新
 * @param nextScheduleMonth 遷移先のScheduleMonth
 * @param prevScheduleMonth 遷移前のScheduleMonth
 * @param payload ペイロード
 */
export const updateScheduleReplyData = async (
  nextScheduleMonth: string,
  prevScheduleMonth: string | undefined,
  payload: SchedulePayload
) => {
  const { onAsync, states, scheduleApi, router } = payload
  if (!nextScheduleMonth || nextScheduleMonth === prevScheduleMonth) return
  if (
    isBefore(parseStringDateToDate(nextScheduleMonth), startOfMonth(new Date()))
  ) {
    await router.push({
      name: ROUTE_NAME.SCHEDULE,
      params: { scheduleMonth: currentScheduleMonth() },
    })
    return
  }
  states.scheduleReplyData =
    (await onAsync(
      async () => await scheduleApi.fetchSchedulesOfYearMonth(nextScheduleMonth)
    )) ?? []
}

/**
 * スケジュールデータ保存処理の生成
 * @param payload ペイロードデータ
 */
export const createSaver =
  (payload: SchedulePayload) => async (params: ScheduleReplyPrams[]) => {
    const { scheduleApi, onAsync, states, route, selfEntity } = payload
    const scheduleMonth = parseExtendedStringToString(
      route.params.scheduleMonth
    )
    await onAsync(async () => {
      await scheduleApi.postSchedulesOfYearMonth(params)
      states.scheduleReplyData = await scheduleApi.fetchSchedulesOfYearMonth(
        scheduleMonth
      )
      selfEntity.setIsScheduleRegistered(true)
    })
  }

export const useScheduleActions = (states: ScheduleStates) => {
  const route = useRoute()
  const router = useRouter()
  const onAsync = createOnAsync(new CreatingEntity())
  const selfEntity = new SelfEntity()
  const payload: SchedulePayload = {
    states,
    onAsync,
    scheduleApi: new ScheduleApi(),
    router,
    route,
    selfEntity,
  }
  watch(
    () => parseExtendedStringToString(route.params.scheduleMonth),
    (next, prev) => updateScheduleReplyData(next, prev, payload),
    { immediate: true }
  )
  return {
    onSave: createSaver(payload),
  }
}
