<script setup lang="ts">
import { isAfter, startOfToday } from 'date-fns'
import { defineProps, defineEmits } from 'vue'
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import ScheduleTableRow from '@/vueComponents/photographer/organisms/pageContent/ScheduleContent/ScheduleTableRow/index.vue'
import {
  ScheduleTableParams,
  ScheduleTableProps,
  useScheduleTableActions,
  useScheduleTableStates,
} from '.'

interface IProps {
  scheduleReplyData: ScheduleTableProps['scheduleReplyData']
}

interface IEmits {
  (
    e: 'update:scheduleReplyPrams',
    value: ScheduleTableParams['update:scheduleReplyPrams']
  ): void
}

const props = defineProps<IProps>()
const states = useScheduleTableStates()
const emits = defineEmits<IEmits>()
const actions = useScheduleTableActions(states, emits, props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/media' as media;
.wrapper {
  display: grid;
  grid-template-rows: repeat(15, 1fr);
  grid-template-columns: repeat(2, 368px);
  column-gap: 32px;
  grid-auto-flow: column;
}
:nth-child(16) {
  grid-area: 1;
}
:nth-child(31) {
  grid-area: -1;
}
@include media.media-sp {
  .wrapper {
    // 16〜31日のスケジュールを表示できるようグリッドレイアウトを無効にする
    display: block;
  }
}
</style>

<template>
  <Table>
    <div :class="css.wrapper">
      <template v-for="data in props.scheduleReplyData" :key="data.date">
        <ScheduleTableRow
          :schedule-reply-status="data.plan"
          :date="data.date"
          :disabled="isAfter(startOfToday(), data.date)"
          @update:schedule-reply-obj="actions.createScheduleReplyPrams"
        />
      </template>
    </div>
  </Table>
</template>
