/**
 * ひらがなをカタカナに変換する
 * @param {string} text
 * @returns {string}
 **/
export const convertToFullWidthKatakana = (text: string) => {
  return text.replace(/[ぁ-ん]/g, (s: string) => {
    return String.fromCharCode(s.charCodeAt(0) + 0x60)
  })
}

/**
 * ッ, ャ, ュ, ョ, ヲをツ, ヤ, ユ, ヨ, オに変換する
 * @param {string} text
 * @returns {string}
 **/
export const replaceKatakanaSpecialCharacters = (text: string) => {
  const replaceMap = {
    ッ: 'ツ',
    ャ: 'ヤ',
    ュ: 'ユ',
    ョ: 'ヨ',
    ヲ: 'オ',
  }

  for (const [original, replacement] of Object.entries(replaceMap)) {
    text = text.replace(new RegExp(original, 'g'), replacement)
  }
  return text
}

/**
 * 全角記号を半角記号に変換する
 * @param {string} text
 * @returns {string}
 **/
export const convertAccountHolderSign = (text: string) => {
  const replacePairs = {
    '（': '(',
    '）': ')',
    '，': ',',
    '．': '.',
    '／': '/',
    '￥': '¥',
    '－': '-',
    '―': '-',
    ー: '-',
    '−': '-',
    '　': ' ',
  }

  return text.replace(/[（），．／￥－―−ー]/g, (match) => {
    return replacePairs[match as keyof typeof replacePairs]
  })
}

/**
 * 全角数字を半角数字に変換する
 * @param {string} text
 * @returns {string}
 **/
export const convertToHalfWidthNumbers = (text: string) => {
  return text.replace(/[０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  })
}

/**
 * 英字を大文字に変換する
 * @param {string} text
 * @returns {string}
 **/
export const convertToUpperCase = (text: string) => {
  return text.toUpperCase()
}
