<script setup lang="ts">
import { defineProps, withDefaults } from 'vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import {
  MatterRequestTableCellProps,
  useMatterRequestTableCellState,
  useMatterRequestTableCellActions,
} from '.'

interface IProps {
  matterRequest: MatterRequestTableCellProps['matterRequest']
  hasButton?: MatterRequestTableCellProps['hasButton']
  isBold?: MatterRequestTableCellProps['isBold']
}

const props = withDefaults(defineProps<IProps>(), {
  hasButton: true,
  isBold: false,
})

const states = useMatterRequestTableCellState(props)

const actions = useMatterRequestTableCellActions(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/mixins/position' as position-mixin;
@use '@/styles/functions/space' as *;
.detail {
  @include position-mixin.flex-horizontal(20px);
  min-height: 32px;
}
.date {
  flex-shrink: 0;
}
.matter-request-title-wrap {
  font-size: 0;
}
.matter-request-title {
  margin-right: space(5);
}
.text-inline-block {
  display: inline-block;
}
.icon-wrap {
  text-align: right;
}
.button {
  width: 64px;
  height: 30px;
  margin-left: auto;
}
</style>

<template>
  <TableCell :separatorable="false">
    <div :class="css.detail">
      <Typography :type="states.titleTypographyType" :class="css.date">{{
        states.mailSendAt
      }}</Typography>
      <div :class="css['matter-request-title-wrap']">
        <div :class="[css['text-inline-block'], css['matter-request-title']]">
          <Typography
            v-if="props.matterRequest.existsOldOrder"
            :type="states.titleTypographyType"
            >【再・案件依頼】{{ states.startDate }}案件依頼内容
            変更・確認のご連絡</Typography
          >
          <Typography v-else :type="states.titleTypographyType"
            >【案件依頼】{{ states.startDate }}案件のご相談</Typography
          >
        </div>
        <Typography
          :type="
            states.isDisabled
              ? 'read-small-disabled'
              : states.replyDeadlineTypographyType
          "
          :class="css['text-inline-block']"
          >【{{ states.replyStatus }}】</Typography
        >
      </div>
    </div>
  </TableCell>
  <TableCell :separatorable="false">
    <div v-if="props.hasButton" :class="css.button">
      <Button :on-click="actions.toDetailPage" type="medium" color="secondary"
        >詳細</Button
      >
    </div>
    <div v-else :class="css['icon-wrap']">
      <Icon :class="css.icon" type="arrow-medium-right" width="13px" />
    </div>
  </TableCell>
</template>
