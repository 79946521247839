<script setup lang="ts">
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import HeaderButton from '@/vueComponents/photographer/molecules/HeaderButton/index.vue'
import { useAfterLoginHeaderActions, useAfterLoginHeaderStates } from '.'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'

const states = useAfterLoginHeaderStates()
useAfterLoginHeaderActions(states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/media' as media-mixins;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/functions/space' as *;
.outer {
  @include space-mixins.side-padding(4);
  @include position-mixins.flex-center;
  &.title {
    @include space-mixins.vertical-padding(2);
    background: repeating-linear-gradient(
      to right,
      colors.$background-color-seller-main,
      colors.$background-color-seller-main 10px,
      colors.$background-color-header-accent 10px,
      colors.$background-color-header-accent 20px
    );
  }
  &.page-title {
    background-color: colors.$background-color-header-title;
    height: 65px;
  }
}
.inner {
  height: 100%;
  width: 100%;
  max-width: sizes.$width-header-content;
  display: flex;
  align-items: center;
  .title > & {
    justify-content: space-between;
  }
}
.img {
  display: block;
}
.buttons {
  display: flex;
}
.icon-base {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  @include position-mixins.flex-center;
  margin-right: space(3);
  background-color: colors.$background-color-primary;
  color: colors.$background-color-content-primary;
}
.name {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.honorific-title {
  margin-left: space(3);
}
</style>

<template>
  <div :class="[css.outer, css.title]">
    <div :class="css.inner">
      <Link :location="states.topLocation" hover-type="header-icon">
        <img :class="css.img" :src="states.logoPath" alt="えんフォト" />
      </Link>
      <div :class="css.buttons">
        <template v-for="(nav, index) in states.headerButtons" :key="index">
          <HeaderButton :button-type="nav.buttonType" @click="nav.action" />
        </template>
      </div>
    </div>
  </div>
  <div :class="[css.outer, css['page-title']]">
    <div :class="css.inner">
      <div :class="css['icon-base']">
        <Icon type="landmark" width="16px" />
      </div>
      <div :class="css.name">
        <Typography v-if="!states.initialPasswordFlg" type="read-large"
          >{{ states.userName }}
        </Typography>
        <Typography type="read-tiny" :class="css['honorific-title']"
          >様</Typography
        >
      </div>
    </div>
  </div>
</template>
