import { computed, ComputedRef, reactive } from 'vue'
import {
  FolderTableRowEmitParams,
  FolderTableRowProps,
} from '../FolderTableRow'
import { IDirectoryInfo } from '@/types/photographer/directory'
import { Computable, Emits } from '@/types/utils'
import {
  EventStatusCode,
  InspectionStatusCode,
} from '@/utils/constants/enums/photographer/event'
import { ITypographyProps } from '@/vueComponents/photographer/atoms/Typography'
import { FolderTableFootProps } from '../FolderTableFoot'

type OnUpdateName = FolderTableRowProps['onUpdateName']

export type FolderTableProps = {
  editable: boolean
  eventDate: FolderTableRowProps['eventDate']
  eventStatus: EventStatusCode
  eventName: FolderTableRowProps['eventName']
  directories: IDirectoryInfo[]
  dispatchId: number
  checkedIdSet: Set<number>
  onUpdateFolderName: (
    directoryId: number,
    ...args: Parameters<OnUpdateName>
  ) => ReturnType<OnUpdateName>
} & Pick<FolderTableFootProps, 'onDelete'>

type FolderTableStates = {
  readonly disabled: boolean
  readonly checkedAll: boolean
  readonly directories: FolderTableRowProps[]
  readonly headTextType: ITypographyProps['type']
  readonly delivered: boolean
  readonly photosCount: number
}

export type FolderTableEmitParams = {
  'update:checkedIdSet': FolderTableProps['checkedIdSet']
}

/**
 * 対象フォルダの非活性判定
 * @param props プロパティ
 * @param directory 対象フォルダ
 * @param disabled 非活性フラグ
 * @returns true:非活性 false:活性
 */
const isDirectoryDisabled = (
  props: FolderTableProps,
  directory: IDirectoryInfo,
  disabled: ComputedRef<boolean>
): boolean =>
  disabled.value ||
  (props.eventStatus === EventStatusCode.SENDING_BACK &&
    directory.inspectStatus === InspectionStatusCode.INSPECTION_COMPLETE)

/**
 * 全件選択済みステートの生成
 * @param props プロパティ
 * @param emits エミット
 * @param disabled 非活性フラグ
 * @returns 全件選択済みステート
 */
const defineCheckedAll = (
  props: FolderTableProps,
  emits: Emits<FolderTableEmitParams>,
  disabled: ComputedRef<boolean>
) => {
  const isDirectoryEditable = (directory: IDirectoryInfo): boolean =>
    !isDirectoryDisabled(props, directory, disabled)
  const getEditableDirectories = () =>
    props.directories.filter(isDirectoryEditable)

  return computed<FolderTableStates['checkedAll']>({
    get() {
      const editableDirectories = getEditableDirectories()
      return (
        editableDirectories.length > 0 &&
        editableDirectories.filter(isDirectoryEditable).length ===
          props.checkedIdSet.size
      )
    },
    set(value) {
      const checkedSet: Set<number> = new Set()
      if (value) {
        getEditableDirectories().forEach((val) => {
          checkedSet.add(val.id)
        })
      }
      emits('update:checkedIdSet', checkedSet)
    },
  })
}

/**
 * テーブル表示用フォルダ情報配列ステートを生成
 * @param props プロパティ
 * @param disabled 非活性フラグ
 * @returns フォルダ情報配列ステート
 */
const defineDirectories = (
  props: FolderTableProps,
  disabled: ComputedRef<boolean>
) => {
  const { eventDate, eventName } = props
  return computed<FolderTableStates['directories']>(() =>
    props.directories.map((directory) => ({
      disabled: isDirectoryDisabled(props, directory, disabled),
      checked: props.checkedIdSet.has(directory.id),
      eventDate,
      eventName,
      onUpdateName: (folderName) =>
        props.onUpdateFolderName(directory.id, folderName),
      directory,
      dispatchId: props.dispatchId,
      eventStatus: props.eventStatus,
    }))
  )
}

export const useFolderTableStates = (
  props: FolderTableProps,
  emits: Emits<FolderTableEmitParams>
): FolderTableStates => {
  const delivered = computed<FolderTableStates['delivered']>(
    () => EventStatusCode.DELIVERED === props.eventStatus
  )
  const disabled = computed<FolderTableStates['disabled']>(
    () => !props.editable && !delivered.value
  )

  return reactive<Computable<FolderTableStates>>({
    disabled,
    checkedAll: defineCheckedAll(props, emits, disabled),
    directories: defineDirectories(props, disabled),
    headTextType: computed(() =>
      disabled.value ? 'read-normal-disabled' : 'head'
    ),
    delivered,
    photosCount: computed(() =>
      props.directories.reduce(
        (sum, directory) => sum + directory.photosCount,
        0
      )
    ),
  })
}

type FolderTableActions = {
  checkFolder: (obj: FolderTableRowEmitParams['update:checked']) => void
}

export const useFolderTableActions = (
  props: FolderTableProps,
  emits: Emits<FolderTableEmitParams>
): FolderTableActions => ({
  checkFolder(obj) {
    const id = parseInt(Object.keys(obj)[0], 10)
    const checked = Object.values(obj)[0]
    const checkedIdSet = new Set([...props.checkedIdSet])
    checked
      ? checkedIdSet.add(id)
      : checkedIdSet.forEach((item) => {
          if (item !== id) return
          checkedIdSet.delete(item)
        })
    emits('update:checkedIdSet', checkedIdSet)
  },
})
