import { MESSAGES } from '@/utils/constants/messages'
import { computed, reactive } from 'vue'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { Computable } from '@/types/utils'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { ScheduleStates } from '@/vueComponents/photographer/pages/Schedule'
import { IScheduleReplyData } from '@/types/photographer/schedules'
import { ScheduleRegistrationProps } from './ScheduleRegistration'

export type ScheduleContentProps = {
  scheduleMonth: ScheduleStates['scheduleMonth']
  scheduleReplyData: IScheduleReplyData[]
  onSave: ScheduleRegistrationProps['onSave']
}

export type ScheduleContentStates = {
  breadcrumbs: IBreadcrumb[]
}

export const useScheduleContentStates = () =>
  reactive<Computable<ScheduleContentStates>>({
    breadcrumbs: computed(() => [
      {
        label: MESSAGES.PHOTOGRAPHER.PAGE_TITLE.TOP,
        location: { name: photographerRoutes.NAME.TOP },
      },
      {
        label: MESSAGES.PHOTOGRAPHER.PAGE_TITLE.SCHEDULE,
      },
    ]),
  })
