<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import TextareaInput from '@/vueComponents/photographer/molecules/TextareaInput/index.vue'
import {
  DeliverySettingDialogEmitParams,
  IDeliverySettingDialogProps,
  useDeliverySettingDialogStates,
} from '.'

interface IProps {
  sellerName: IDeliverySettingDialogProps['sellerName']
  eventName: IDeliverySettingDialogProps['eventName']
  value: IDeliverySettingDialogProps['value']
}

interface IEmits {
  (
    e: 'update:value',
    value: DeliverySettingDialogEmitParams['update:value']
  ): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = useDeliverySettingDialogStates(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.lead {
  @include space-mixins.vertical-margin(8);
}
.comfirmationText {
  @include space-mixins.vertical-margin(4);
}
.textareaInput {
  @include space-mixins.vertical-margin(6, 8);
}
</style>

<template>
  <Typography element="p" type="h3" :class="css.lead">
    「{{ props.sellerName }}
    {{ props.eventName }}」の内容をご確認の上、検品依頼をしてください。
  </Typography>
  <Typography element="p" :class="css.comfirmationText"
    >検品依頼後はイベントの編集作業（追加・削除）ができません。<br />
    本当に検品依頼をしてよろしいですか？<br />
    ※すべての写真をアップロードしてから、検品依頼をしてください。
  </Typography>

  <TextareaInput
    v-model:value="states.value"
    label="納品物に対しての説明をご記入ください。"
    :is-must="true"
    name="delivery-memo"
    :maxlength="500"
    placeholder="例）当日集合写真の撮影は無し、待機や予定外の移動があった、撮影時間が短かった、人数が少なかった等"
    :description="[
      '当日の集合写真の撮影有無をご記入ください。',
      '撮影時の状況を記入ください。',
      'マニュアルに記載の目安枚数（1時間約200～300枚）に満たない場合はご状況を記入ください。',
    ]"
    :class="css.textareaInput"
  />
</template>
