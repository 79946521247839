import { BaseApi } from '../BaseApi'

const PATH = {
  POST_DELIVERY: '/photographer/api/v1.0/events/:dispatch_id/confirm',
} as const

export interface IPostDeliveryRequest {
  dispatch_id: number
  delivery_memo: string
}

export class DeliveryApi extends BaseApi {
  /**
   * 納品ボタン押下時処理
   * @param dispatch_id 派遣ID
   * @param delivery_memo メモ
   */
  public async postDirectory(
    dispatch_id: IPostDeliveryRequest['dispatch_id'],
    delivery_memo: IPostDeliveryRequest['delivery_memo']
  ): Promise<void> {
    await this.post<IPostDeliveryRequest>(PATH.POST_DELIVERY, {
      dispatch_id,
      delivery_memo,
    })
  }
}
