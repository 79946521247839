export interface PrefectureInputProps {
  modelValue: number
  prefecturesOptions: {
    value: string | number
    label: string
    disabled?: boolean
  }[]
  locationPrefectureErrorMessage: string
}

interface PrefectureInputEmits {
  (e: 'update:modelValue', value: number): void
  (e: 'input:prefecture'): void
}

type PrefectureActions = {
  handleInputPrefecture(value: number): void
}

export const usePrefectureInputActions = (
  emits: PrefectureInputEmits
): PrefectureActions => {
  return {
    handleInputPrefecture(value: number) {
      emits('update:modelValue', Number(value))
      emits('input:prefecture')
    },
  }
}
