import { Computable, Emits, Promisable, ValueOf } from '@/types/utils'
import { FOLDER_NAME_SETTING_LABEL } from '@/utils/constants/messages/photographer/event'
import { parseJaDateToString } from '@/utils/functions/parser'
import { computed, reactive } from 'vue'

type OperationType = 'create' | 'update'

export interface IFolderNameSettingModalProps {
  shown: boolean
  type: OperationType
  folderName?: string
  eventDate: Date
  eventName: string
  onSubmit: (foldername: string) => Promisable<string | void>
}

export type FolderNameSettingModalEmitProps = {
  'update:shown': IFolderNameSettingModalProps['shown']
}

interface IFolderNameSettingModalStates {
  shown: IFolderNameSettingModalProps['shown']
  labels: ValueOf<typeof FOLDER_NAME_SETTING_LABEL>
  folderNamePrefix: string
  folderNameMain: string
  isSubmitting: boolean
  errorMessage: string
}

const computeFolderNamePrefix = ({
  eventDate,
  eventName,
}: IFolderNameSettingModalProps) =>
  `${parseJaDateToString(eventDate)}${'\u3000'}${eventName}_`

export const useFolderNameSettingModalStates = (
  props: IFolderNameSettingModalProps,
  emits: Emits<FolderNameSettingModalEmitProps>
) =>
  reactive<Computable<IFolderNameSettingModalStates>>({
    shown: computed({
      get() {
        return props.shown
      },
      set(value) {
        emits('update:shown', value)
      },
    }),
    labels: computed(() =>
      props.type === 'create'
        ? FOLDER_NAME_SETTING_LABEL.CREATE
        : FOLDER_NAME_SETTING_LABEL.UPDATE
    ),
    folderNamePrefix: computed(() => computeFolderNamePrefix(props)),
    folderNameMain: props.folderName ?? '',
    isSubmitting: false,
    errorMessage: '',
  })

export function useFolderNameSettingModalActions(
  props: IFolderNameSettingModalProps,
  states: IFolderNameSettingModalStates
) {
  async function submit(): Promise<void> {
    states.errorMessage = ''
    states.isSubmitting = true
    try {
      const errorMessage = await props.onSubmit(states.folderNameMain)
      if (errorMessage) {
        states.errorMessage = errorMessage
        return
      }
      states.shown = false
    } finally {
      states.isSubmitting = false
    }
  }
  return { submit }
}
