<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import { IPhotoInfoProps, PhotoInfoEmitParams, usePhotoInfoStates } from '.'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Thumbnail from '@/vueComponents/photographer/atoms/Thumbnail/index.vue'
import Checkbox from '@/vueComponents/photographer/molecules/Checkbox/index.vue'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'

interface IProps {
  status: IPhotoInfoProps['status']
  thumbnailUrlGetter?: IPhotoInfoProps['thumbnailUrlGetter']
  photoId: IPhotoInfoProps['photoId']
  checked: IPhotoInfoProps['checked']
  fileName: IPhotoInfoProps['fileName']
  photographed?: IPhotoInfoProps['photographed']
  eventStatus: IPhotoInfoProps['eventStatus']
}

interface IEmits {
  (e: 'update:checked', checked: PhotoInfoEmitParams['update:checked']): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = usePhotoInfoStates(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.checkbox-info {
  margin-top: space(1);
  display: inline-flex;
  gap: 8px;
}
.file-name {
  width: 110px;
  overflow-wrap: break-word;
}
.photo-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>

<template>
  <label>
    <Thumbnail
      :error-msg="states.errorMessage"
      :lazy-load-url-getter="states.thumbnailUrlGetter"
    />
    <div :class="css['checkbox-info']">
      <Checkbox
        v-if="props.eventStatus !== EventStatusCode.DELIVERED"
        v-model:checked="states.checked"
      />
      <div :class="css['photo-info']">
        <Typography :class="css['file-name']">{{ props.fileName }}</Typography>
        <Typography v-if="!states.errorMessage" type="read-tiny"
          >撮影日：{{ states.photographedStr }}</Typography
        >
      </div>
    </div>
  </label>
</template>
