import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { RouteLocationRaw, useRouter } from 'vue-router'

export type ToEventPageButtonProps = {
  eventId: string
}

export const useToEventPageButtonActions = (porps: ToEventPageButtonProps) => {
  const router = useRouter()
  const eventPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.EVENT,
    params: { eventId: porps.eventId },
  }
  return {
    async toEventPage(): Promise<void> {
      await router.push(eventPageLocation)
    },
  }
}
