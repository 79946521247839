<script setup lang="ts">
import { defineProps } from 'vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import AcceptedMassage from '@/vueComponents/photographer/organisms/pageContent/ProposalCompletePageContent/AcceptedMessage/index.vue'
import RejectedMassage from '@/vueComponents/photographer/organisms/pageContent/ProposalCompletePageContent/RejectedMessage/index.vue'
import {
  ProposalCompletePageContentProps,
  useProposalCompletePageContentStates,
  useProposalCompletePageContentActions,
} from '.'

interface IProps {
  isAccepted: ProposalCompletePageContentProps['isAccepted']
}

const props = defineProps<IProps>()

const states = useProposalCompletePageContentStates(props)

const actions = useProposalCompletePageContentActions()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/functions/space' as *;
.container {
  @include space-mixins.vertical-margin(10);
}
.inner {
  padding: space(8) space(8) space(14);
}
.message {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 280px;
}
.separator {
  @include space-mixins.vertical-margin(8);
}
.button {
  width: 288px;
  margin: space(8) auto 0;
}
</style>

<template>
  <div :class="css.container">
    <ContentBox>
      <div :class="css.inner">
        <div :class="css.message">
          <AcceptedMassage v-if="states.isAccepted" />
          <RejectedMassage v-else />
        </div>
        <div :class="css.separator">
          <Separator />
        </div>
        <div :class="css.button">
          <Button color="secondary" :on-click="actions.toTop">ホームへ</Button>
        </div>
      </div>
    </ContentBox>
  </div>
</template>
