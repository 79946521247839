import {
  convertToFullWidthKatakana,
  replaceKatakanaSpecialCharacters,
  convertAccountHolderSign,
  convertToHalfWidthNumbers,
  convertToUpperCase,
} from '@/utils/functions/textTransform'

export const prependZeroCode = (fieldValue: string, limitNum: number) => {
  if (!fieldValue) {
    return ''
  }
  if (fieldValue.length < limitNum) {
    return '0'.repeat(limitNum - fieldValue.length) + fieldValue
  }
  return fieldValue
}

export const validateAccountHolderInput = (targeValue: string) => {
  let convertedText = targeValue
  convertedText = convertToFullWidthKatakana(convertedText)
  convertedText = replaceKatakanaSpecialCharacters(convertedText)
  convertedText = convertAccountHolderSign(convertedText)
  convertedText = convertToHalfWidthNumbers(convertedText)
  convertedText = convertToUpperCase(convertedText)
  return convertedText
}

/**
 * 全角数字を半角に変換する
 * @param fieldValue
 * @returns string
 */
export const replaceFullWidthToHalfWidthNumbers = (fieldValue: string) => {
  return fieldValue.replace(/[０-９]/g, (char) => {
    return String.fromCharCode(char.charCodeAt(0) - 0xfee0)
  })
}

/**
 * 数字以外を削除する
 * @param fieldValue
 * @param length
 * @returns string
 */
export const removeNonNumbers = (fieldValue: string, length: number) => {
  return fieldValue.replace(/[^\d]/g, '').slice(0, length)
}

/**
 * 全角数字を半角に変換し、数字以外を削除する
 * @param fieldValue
 * @param length
 * @returns string
 */
export const formatNumberInput = (fieldValue: string, length: number) => {
  const halfWidthNumbers = replaceFullWidthToHalfWidthNumbers(fieldValue)
  return removeNonNumbers(halfWidthNumbers, length)
}

/**
 * ひらがなのみを許可する
 * @param fieldValue
 * @returns string
 */
export const removeNonKanaCharacters = (fieldValue: string) => {
  return fieldValue.replace(/[^ぁ-んー\s]/g, '')
}

/**
 * ひらがなのバリデーション
 * @param fieldValue
 * @returns boolean
 */
export const validateKana = (fieldValue: string) => {
  return fieldValue.match(/^[ぁ-んー\s]+$/)
}

/**
 * 入力されていないかどうかを判定する
 * @param fieldValue
 * @returns boolean
 */
export const validateNotEntered = (fieldValue: string | number) => {
  if (!fieldValue) {
    return true
  }
  return false
}

/**
 * emailのフォーマットを整える
 * @param fieldValue
 * @returns string
 */
export const formatEmailAddress = (fieldValue: string) => {
  return fieldValue.replace(/[^a-zA-Z0-9+_.@-]/g, '')
}

/**
 * emailのバリデーション
 * @param fieldValue
 * @returns boolean
 */
export const validateEmail = (fieldValue: string) => {
  return fieldValue.match(
    /^([a-zA-Z0-9+_.-]+)([a-zA-Z0-9+_.-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
  )
}

/**
 * 電話番号のフォーマットを整える
 * @param fieldValue
 * @returns string
 */
export const validateTel = (fieldValue: string) => {
  return fieldValue.match(/^[0-9]{10,11}$/)
}

/**
 * 郵便番号のフォーマットを整える
 * @param fieldValue
 * @returns string
 */
export const formatInvoiceNumber = (fieldValue: string) => {
  return fieldValue.replace(/[^T0-9t]/g, '').replace(/t/g, 'T')
}

/**
 * 郵便番号のバリデーション
 * @param fieldValue
 * @returns boolean
 */
export const validateZipcode = (fieldValue: string) => {
  return fieldValue.match(/^[0-9]{7}$/)
}

/**
 * 請求書番号のバリデーション
 * @param fieldValue
 * @returns boolean
 */
export const validateInvoiceNumber = (fieldValue: string) => {
  return fieldValue.match(/^T[0-9]{13}$/)
}
