import { MESSAGES } from '@/utils/constants/messages'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { TopStates } from '@/vueComponents/photographer/pages/Top'
import { computed, reactive } from 'vue'
import { Computable } from '@/types/utils'
import { NotificationsTabProps } from '../NotificationsPageContent/NotificationsTab'
import { createInspectionDeliveryRequests } from '@/utils/functions/createInspectionDeliveryRequests'
import { IDispatchOrder } from '@/types/photographer/dispatchOrder'
import { CalendarProps } from './Calendar/index'
import { IScheduleReplyData } from '@/types/photographer/schedules'
import { parseDateYearToHyphenString } from '@/utils/functions/parser'

export type TopPageContentProps = {
  noticeMatterRequests: TopStates['noticeMatterRequests']
  limitMatterRequests: TopStates['limitMatterRequests']
  events: TopStates['events']
  invoiceRequests: TopStates['invoiceRequests']
  isScheduleRegistered: boolean
  todaysDate: CalendarProps['todaysDate']
  displayYearMonth: CalendarProps['displayYearMonth']
  schedules: IScheduleReplyData[]
  isLoading: boolean
  invoiceGenerationFlg: boolean
  clickPrev: CalendarProps['clickPrev']
  clickNext: CalendarProps['clickNext']
}

export type TopPageContentStates = {
  breadcrumbs: IBreadcrumb[]
  inspectionDeliveryRequests: NotificationsTabProps['inspectionDeliveryRequests']
  noticeMatterRequests: IDispatchOrder[]
  schedules: CalendarProps['schedules']
}

/**
 * お知らせの案件依頼情報を期限内で絞り込み
 * @param noticeMatterRequests 案件依頼情報
 * @returns 回答期限内案件依頼情報
 */
const refinedNoticeMatterRequests = (noticeMatterRequests: IDispatchOrder[]) =>
  noticeMatterRequests.filter(
    ({ replyDeadlineCount, isReplied, existsNewOrder, reachedCapacity }) =>
      replyDeadlineCount >= 0 &&
      !isReplied &&
      !existsNewOrder &&
      !reachedCapacity
  )

export const useTopPageContentStatus = (props: TopPageContentProps) =>
  reactive<Computable<TopPageContentStates>>({
    breadcrumbs: computed(() => [
      {
        label: MESSAGES.PHOTOGRAPHER.PAGE_TITLE.TOP,
      },
    ]),
    inspectionDeliveryRequests: computed(() =>
      createInspectionDeliveryRequests(props.events)
    ),
    noticeMatterRequests: computed(() =>
      refinedNoticeMatterRequests(props.noticeMatterRequests)
    ),
    schedules: computed(() =>
      props.schedules.map(({ date, plan }) => ({
        date: parseDateYearToHyphenString(date),
        plan,
      }))
    ),
  })
