import { BaseApi } from '../BaseApi'

const PATH = {
  LOGIN: '/photographer/api/v1.0/auth/login',
  LOGOUT: '/photographer/api/v1.0/auth/logout',
} as const

export interface IPostLoginRequest {
  login_id: string
  password: string
}

interface IPostLoginResponse {
  loggedin: boolean
}

interface IPostLogoutResponse {
  loggedout: boolean
}

export class LoginApi extends BaseApi {
  public async postLogin(login_id: string, password: string): Promise<void> {
    await this.post<IPostLoginRequest, IPostLoginResponse>(PATH.LOGIN, {
      login_id,
      password,
    })
  }

  /**
   * ログアウト
   * @returns true:ログアウト成功 false:ログアウト失敗
   */
  public async postLogout(): Promise<boolean> {
    const { data } = await this.post<null, IPostLogoutResponse>(PATH.LOGOUT)
    return data.loggedout
  }
}
