<script setup lang="ts">
import { defineProps, withDefaults } from 'vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import FolderNameSettingModal from '../FolderNameSettingModal/index.vue'
import {
  IFolderCreationButtonProps,
  useFolderCreationButtonActions,
  useFolderCreationButtonStates,
} from '.'

interface IProps {
  disabled?: IFolderCreationButtonProps['disabled']
  eventDate: IFolderCreationButtonProps['eventDate']
  eventName: IFolderCreationButtonProps['eventName']
  onSubmit: IFolderCreationButtonProps['onSubmit']
}

const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
})

const states = useFolderCreationButtonStates()

const actions = useFolderCreationButtonActions(states)
</script>

<template>
  <Button :disabled="props.disabled" :on-click="actions.openModal"
    >フォルダを作成する</Button
  >
  <FolderNameSettingModal
    v-model:shown="states.isModalOpened"
    type="create"
    :event-date="props.eventDate"
    :event-name="props.eventName"
    :on-submit="props.onSubmit"
  />
</template>
