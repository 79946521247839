import { Computable } from '@/types/utils'
import { computed, reactive } from 'vue'

export interface IUploadCompletedDialogProps {
  failedCount: number
}

interface IUploadCompletedDialogStates {
  message: string
}

export const useUploadCompletedDialogStates = (
  props: IUploadCompletedDialogProps
) =>
  reactive<Computable<IUploadCompletedDialogStates>>({
    message: computed(() =>
      props.failedCount > 0
        ? `※${props.failedCount}枚のアップロードに失敗しました。`
        : ''
    ),
  })
