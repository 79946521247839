<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Modal from '@/vueComponents/photographer/molecules/Modal/index.vue'
import {
  IModalProps,
  ModalEmitParams,
  useInvoiceCreatePageMoveConfirmModalStates,
  useInvoiceCreatePageMoveConfirmModalActions,
} from '.'

interface IProps {
  shown: IModalProps['shown']
}

interface IEmits {
  (e: 'update:shown', shown: ModalEmitParams['update:shown']): void
  (e: 'move-page', shown: ModalEmitParams['move-page']): void
  (e: 'close', shown: ModalEmitParams['close']): void
}

const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useInvoiceCreatePageMoveConfirmModalStates(props, emits)
const actions = useInvoiceCreatePageMoveConfirmModalActions(emits)
</script>

<style lang="scss" module="css" scoped>
.body {
  padding: 32px 23px 88px;
}
</style>

<template>
  <Modal
    v-model:shown="states.shown"
    :on-submit="actions.close"
    :on-close="actions.close"
    :on-before-cancel="actions.movePage"
  >
    <template #header>まだ保存されていません</template>
    <div :class="css.body">
      編集した内容が削除されますが<br />
      保存せずにページを移動しますか？
    </div>
    <template #submitLabel>編集を続ける</template>
    <template #cancelLabel>ページを移動する</template>
  </Modal>
</template>
