<script setup lang="ts">
import { defineProps, ref } from 'vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import Dropzone from '@/vueComponents/photographer/atoms/Dropzone/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import UploadingModal from '../UploadingModal/index.vue'
import UploadRules from '../UploadRules/index.vue'
import {
  IPhotoUploaderProps,
  usePhotoUploaderActions,
  usePhotoUploaderStates,
} from '.'

interface IProps {
  onUploadFile: IPhotoUploaderProps['onUploadFile']
  onUpdateUploadingStatus: IPhotoUploaderProps['onUpdateUploadingStatus']
  onUpdateFailedPhotosStatus: IPhotoUploaderProps['onUpdateFailedPhotosStatus']
  onDeleteFiles: IPhotoUploaderProps['onDeleteFiles']
  slowModeFlg: IPhotoUploaderProps['slowModeFlg']
}

const props = defineProps<IProps>()

const states = usePhotoUploaderStates(props)

const inputElement = ref<HTMLInputElement>()

const actions = usePhotoUploaderActions(props, states, inputElement)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: space(9) 0 space(10);
}

.btn {
  @include space-mixins.vertical-margin(2, 6);
}
.upload-rules {
  @include space-mixins.vertical-margin(4);
}
</style>

<template>
  <Dropzone @drop="actions.onDrop">
    <div :class="css.container">
      <Typography text-align="center" type="upload-main"
        >ファイルをドラッグ＆ドロップしてください<br />
        または
      </Typography>
      <div :class="css.btn">
        <Button type="medium" @click="actions.onClickButton"
          >お使いのPCから追加</Button
        >
      </div>
      <Typography text-align="center" type="head"
        >アップロード画像の仕様について
      </Typography>
      <UploadRules :class="css['upload-rules']" />
    </div>
  </Dropzone>
  <input
    ref="inputElement"
    type="file"
    multiple
    accept="image/jpeg"
    hidden
    @input="actions.onSelect"
  />
  <UploadingModal
    v-model:shown="states.isModalShown"
    :upload-status="states.uploadStatus"
    :uploading-count="states.uploadingCount"
    :completed-count="states.completedCount"
    :failed-count="states.failedCount"
    :progress-rate="states.progressRate"
    :duplicated-count="states.duplicatedCount"
    :on-overwrite-duplication="actions.onOverwriteDuplication"
    :slow-mode-flg="props.slowModeFlg"
  />
</template>
