export type RowProps = {
  rowNo: number
  date: string
  content: string
  amount: number
  mileage: number
  gasUnitPrice: number
}

type RowStates = {
  displayDate: string
  displayAmount: string
  displayMileageAmount: string
}

export const useRowStates = (props: RowProps): RowStates => {
  return {
    displayDate: props.date.replace(/-/g, '/'),
    displayAmount: props.amount.toLocaleString(),
    displayMileageAmount: (props.mileage * props.gasUnitPrice).toLocaleString(),
  }
}
