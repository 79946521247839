import { InjectionKey, ref } from 'vue'
import { BaseStore } from '@/vue/stores'

export class SelfStore extends BaseStore {
  name = ref('')
  email = ref('')
  initialPasswordFlg = ref(false)
  isScheduleRegistered = ref(false)
  invoiceGenerationFlg = ref(false)
}

export const selfStoreKey: InjectionKey<SelfStore> = Symbol('SelfStoreKey')
