import { Detail } from './Table'

export type DetailProps = {
  notes: string
  details: Detail[]
  gasUnitPrice: number
  subTotal: number
  taxAmount: number
  totalAmount: number
}

type DetailStates = {
  displaySubTotal: string
  displayTaxAmount: string
  displayTotalAmount: string
}

export const useDetailStates = (props: DetailProps): DetailStates => {
  return {
    displaySubTotal: props.subTotal.toLocaleString(),
    displayTaxAmount: props.taxAmount.toLocaleString(),
    displayTotalAmount: props.totalAmount.toLocaleString(),
  }
}
