import {
  IDirectoryDetail,
  IDirectoryInfo,
} from '@/types/photographer/directory'
import { InspectionStatusCode } from '@/utils/constants/enums/photographer/event'
import { BaseApi } from '../BaseApi'

const PATH = {
  FETCH_DIRECTORIES:
    '/photographer/api/v1.0/directories?dispatch_id=:dispatch_id',
  DIRECTORIES: '/photographer/api/v1.0/directories',
} as const

interface IFetchDirectoryResponse {
  id: number
  dispatch_id: number
  name: string
  inspect_status: InspectionStatusCode
  inspect_memo: string
}

interface IFetchDirectoriesResponse
  extends Omit<IFetchDirectoryResponse, 'dispatch_id'> {
  photos_count: number
  updated_at: string
  error_photo_count: number
}

export interface IPostDirectoryRequest {
  dispatch_id: number
  name: string
}

interface IPostDirectoryResponse {
  directory_id: number
}

interface IDeleteDirectoryRequest {
  directory_id: number
}

export interface IPutDirectoryRequest {
  name: string
}

interface IFetchDirectoryRequest {
  directory_id: number
}

export class DirectoryApi extends BaseApi {
  /**
   * フォトグラファーディレクトリ一覧
   * @param dispatch_id 派遣ID
   * @returns 写真格納用のディレクトリの一覧
   */
  public async fetchDirectories(
    dispatch_id: number
  ): Promise<IDirectoryInfo[]> {
    const { data } = await this.get<IFetchDirectoriesResponse[]>(
      PATH.FETCH_DIRECTORIES,
      { dispatch_id }
    )
    return data.map((directory) => ({
      id: directory.id,
      name: directory.name,
      inspectStatus: directory.inspect_status,
      photosCount: directory.photos_count,
      updatedAt: new Date(directory.updated_at),
      inspectMemo: directory.inspect_memo,
      errorPhotoCount: directory.error_photo_count,
    }))
  }

  /**
   * フォトグラファーディレクトリ作成
   * @param dispatch_id 派遣ID
   * @param name ディレクトリ名
   * @returns 作成したディレクトリID
   */
  public async postDirectory(
    dispatch_id: IPostDirectoryRequest['dispatch_id'],
    name: IPostDirectoryRequest['name']
  ): Promise<number> {
    const { data } = await this.post<
      IPostDirectoryRequest,
      IPostDirectoryResponse
    >(PATH.DIRECTORIES, { dispatch_id, name })
    return data.directory_id
  }

  /**
   * フォトグラファーディレクトリ削除
   * @param directory_id ディレクトリID
   */
  public async deleteDirectory(
    directory_id: IDeleteDirectoryRequest['directory_id']
  ): Promise<void> {
    await this.delete<null>(`${PATH.DIRECTORIES}/${directory_id}`)
  }

  /**
   * フォトグラファーディレクトリ編集
   * @param directory_id ディレクトリID
   * @param name ディレクトリ名
   * @returns 編集したディレクトリID
   */
  public async putDirectory(
    directory_id: number,
    name: IPutDirectoryRequest['name']
  ): Promise<void> {
    await this.put<IPutDirectoryRequest>(
      `${PATH.DIRECTORIES}/${directory_id}`,
      {
        name,
      }
    )
  }

  /**
   * フォトグラファーディレクトリ詳細
   * @param directory_id ディレクトリID
   * @returns 写真格納用のディレクトリの詳細
   */
  public async fetchDirectory(
    directory_id: IFetchDirectoryRequest['directory_id']
  ): Promise<IDirectoryDetail> {
    const { data } = await this.get<IFetchDirectoryResponse>(
      `${PATH.DIRECTORIES}/${directory_id}`,
      { directory_id }
    )
    return {
      id: data.id,
      dispatchId: data.dispatch_id,
      name: data.name,
      inspectStatus: data.inspect_status,
      inspectMemo: data.inspect_memo,
    }
  }
}
