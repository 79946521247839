import { Account } from '@/vueComponents/photographer/organisms/pageContent/InvoicePreviewPageContent/Preview/Head'
import { Detail } from '@/vueComponents/photographer/organisms/pageContent/InvoicePreviewPageContent/Preview/Detail/Table'
import { Expense } from '@/vueComponents/photographer/organisms/pageContent/InvoicePreviewPageContent/Preview/Expense/Table'
import { Expense as InvoiceExpense } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable'
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
  onBeforeRouteLeave,
  useRouter,
} from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { InvoiceEntity } from '@/entities/photographer/InvoiceEntity'
import { reactive } from 'vue'
import { InvoiceDetail } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable'
import { defaultAccount } from '@/utils/functions/invoice'
import { InvoiceApi } from '@/domains/api/invoice/InvoiceApi'
import {
  convertEntityDetailsToPayload,
  convertEntityExpensesToPayload,
} from '@/utils/functions/invoice/convert'
import { preventReload } from '@/utils/functions/preventReload'

export type PreviewStates = {
  month: number
  account: Account
  note: string
  details: Detail[]
  gasUnitPrice: number
  expenses: Expense[]
  canMovePage: boolean
  moveTo: string
  isMovePageModalShown: boolean
  isSendConfirmModalShown: boolean
  loading: boolean
}

const handleDetails = (details: InvoiceDetail[]): Detail[] => {
  return details.map((detail: InvoiceDetail) => {
    return {
      date: detail.date,
      content: detail.content,
      amount: detail.amount ? detail.amount : 0,
      mileage: detail.mileage ? detail.mileage : 0,
    }
  })
}

const handleExpenses = (expenses: InvoiceExpense[]): Expense[] => {
  return expenses.map((expense: InvoiceExpense): Expense => {
    return {
      date: expense.date,
      content: expense.content,
      originalFileName: expense.originalFileName ?? '',
      file: expense.file,
      fetchFileUrl: expense.fetchFileUrl,
      amount: expense.amount ? expense.amount : 0,
    }
  })
}

export const usePreviewStates = (): PreviewStates => {
  const invoiceEntity = new InvoiceEntity()
  return reactive<PreviewStates>({
    month: invoiceEntity.status ? invoiceEntity.status.month : 1,
    account: invoiceEntity.account ?? defaultAccount,
    note: invoiceEntity.note,
    details: handleDetails(invoiceEntity.details),
    gasUnitPrice: invoiceEntity.gasUnitPrice,
    expenses: handleExpenses(invoiceEntity.expenses),
    canMovePage: false,
    moveTo: '',
    isMovePageModalShown: false,
    isSendConfirmModalShown: false,
    loading: false,
  })
}

type PreviewActions = {
  showSendConfirmModal: () => void
  movePage: () => Promise<void>
  sendInvoice: () => Promise<void>
}

const moveToComplete = async (router: Router) => {
  await router.push({ name: photographerRoutes.NAME.INVOICE_COMPLETE })
}

const sendInvoice = async (
  states: PreviewStates,
  invoiceEntity: InvoiceEntity,
  router: Router,
  invoiceApi: InvoiceApi
) => {
  if (states.loading) return
  states.loading = true
  const note = invoiceEntity.note
  const details = convertEntityDetailsToPayload(invoiceEntity.details)
  const expenses = convertEntityExpensesToPayload(invoiceEntity.expenses)
  await invoiceApi.postInvoices({ note, details, expenses })
  await invoiceApi.postInvoicesSubmit()
  await moveToComplete(router)
  states.loading = false
}

const showSendConfirmModal = (states: PreviewStates) => {
  states.isSendConfirmModalShown = true
}

const movePage = async (router: Router, states: PreviewStates) => {
  states.canMovePage = true
  await router.push({ name: states.moveTo })
}

export const usePreviewActions = (states: PreviewStates): PreviewActions => {
  onBeforeRouteLeave((to, _from, next) =>
    onBeforeRouteLeaveListener(to, next, states)
  )
  preventReload()

  const router = useRouter()
  const invoiceApi = new InvoiceApi()
  const invoiceEntity = new InvoiceEntity()

  return {
    showSendConfirmModal: () => showSendConfirmModal(states),
    movePage: () => movePage(router, states),
    sendInvoice: () => sendInvoice(states, invoiceEntity, router, invoiceApi),
  }
}

const onBeforeRouteLeaveListener = (
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  states: PreviewStates
) => {
  if (
    states.canMovePage ||
    to.name === photographerRoutes.NAME.INVOICE_COMPLETE ||
    to.name === photographerRoutes.NAME.INVOICE_CREATE
  ) {
    next()
  }
  states.moveTo = to.name?.toString() || ''
  states.isMovePageModalShown = true
}
