import { Computable, Emits } from '@/types/utils'
import { reactive, computed } from 'vue'
import { assertString } from '@/utils/functions/typeCheck'

export interface IDeliverySettingDialogProps {
  sellerName: string
  eventName: string
  value: string
}

export type DeliverySettingDialogEmitParams = {
  'update:value': IDeliverySettingDialogProps['value']
}

interface IDeliverySettingDialogStates {
  value: string
}

export const useDeliverySettingDialogStates = (
  props: IDeliverySettingDialogProps,
  emits: Emits<DeliverySettingDialogEmitParams>
) =>
  reactive<Computable<IDeliverySettingDialogStates>>({
    value: computed({
      get() {
        return props.value
      },
      set(value) {
        assertString(value)
        emits('update:value', value)
      },
    }),
  })
