<script setup lang="ts">
import { defineProps } from 'vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import SelectAllCheckbox from '@/vueComponents/photographer/molecules/SelectAllCheckbox/index.vue'
import PhotoDeleteButton from './PhotosList/PhotoDeleteButton/index.vue'
import Photos from './PhotosList/Photos/index.vue'
import PhotoUploader from './Upload/PhotoUploader/index.vue'
import ToEventPageButton from './PhotosList/ToEventPageButton/index.vue'
import UploadSlowModeLink from './PhotosList/UploadSlowModeLink/index.vue'
import SendingBackMessage from '@/vueComponents/photographer/organisms/section/SendingBackMessage/index.vue'
import {
  IFolderPageContentProps,
  useFolderPageContentActions,
  useFolderPageContentStates,
} from '.'

interface IProps {
  onUploadFile: IFolderPageContentProps['onUploadFile']
  onUpdateUploadingStatus: IFolderPageContentProps['onUpdateUploadingStatus']
  onUpdateFailedPhotosStatus: IFolderPageContentProps['onUpdateFailedPhotosStatus']
  photos: IFolderPageContentProps['photos']
  thumbnailUrlGetter: IFolderPageContentProps['thumbnailUrlGetter']
  failedPhotos: IFolderPageContentProps['failedPhotos']
  onDeletePhoto: IFolderPageContentProps['onDeletePhoto']
  folderInspectionMessage: IFolderPageContentProps['folderInspectionMessage']
  eventInspectionMessage: IFolderPageContentProps['eventInspectionMessage']
  eventId: IFolderPageContentProps['eventId']
  eventStatus: IFolderPageContentProps['eventStatus']
  eventDate: IFolderPageContentProps['eventDate']
  eventName: IFolderPageContentProps['eventName']
  slowModeFlg: IFolderPageContentProps['slowModeFlg']
}

const props = defineProps<IProps>()
const states = useFolderPageContentStates(props)
const actions = useFolderPageContentActions(props, states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/mixins/position' as position-mixins;
.content-box {
  padding: space(8);
  @include space-mixins.vertical-margin(6, 10);
}
.img {
  position: absolute;
  right: -6px;
  top: -6px;
  height: 101px;
  width: 101px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
}
.photo-uploder {
  position: relative;
}
.slow-mode-link {
  float: right;
  @include space-mixins.vertical-margin(1, 1);
}
.to-event-page-btn {
  @include space-mixins.vertical-margin(8, 8);
}
.h2 {
  display: flex;
  justify-content: space-between;
  &-title {
    @include position-mixins.flex-horizontal(space(6));
    &-photo-num {
      @include position-mixins.flex-horizontal(space(1));
    }
  }
}
.delete-button {
  width: 160px;
}
.separator {
  @include space-mixins.vertical-margin(4);
}
.no-photo {
  @include space-mixins.vertical-margin(32, 32);
}
.photos {
  @include space-mixins.side-margin(10);
}
</style>

<template>
  <SendingBackMessage
    v-if="props.eventInspectionMessage && !states.deliveredFlg"
    :inspect-memo="props.eventInspectionMessage"
    :sending-back-directories="[props.folderInspectionMessage]"
    :event-date="props.eventDate"
    :event-name="props.eventName"
  />
  <ContentBox :class="css['content-box']">
    <div v-if="!states.deliveredFlg">
      <div :class="css['photo-uploder']">
        <img
          v-if="props.slowModeFlg"
          :class="css.img"
          :src="states.labelSlowMode"
          alt="低速モード"
        />
        <PhotoUploader
          :on-upload-file="props.onUploadFile"
          :on-update-uploading-status="props.onUpdateUploadingStatus"
          :on-update-failed-photos-status="props.onUpdateFailedPhotosStatus"
          :on-delete-files="props.onDeletePhoto"
          :slow-mode-flg="props.slowModeFlg"
        />
      </div>
      <UploadSlowModeLink
        :class="css['slow-mode-link']"
        :slow-mode-flg="props.slowModeFlg"
      />
      <ToEventPageButton
        :class="css['to-event-page-btn']"
        :event-id="props.eventId"
      />
    </div>
    <div :class="css.h2">
      <div :class="css['h2-title']">
        <Typography type="h2-simple">写真一覧</Typography>
        <div :class="css['h2-title-photo-num']">
          <Typography v-if="states.photoNum" type="count-normal">{{
            states.photoNum
          }}</Typography>
          <Typography v-else type="count-error">0</Typography>
          <Typography>/ 500枚</Typography>
        </div>
      </div>
      <div v-if="!states.deliveredFlg" :class="css['h2-title']">
        <SelectAllCheckbox
          v-model:checked="states.selectedAll"
          :disabled="!states.photoNum"
        />
        <div :class="css['delete-button']">
          <PhotoDeleteButton
            :selected-all="states.selectedAll"
            :photo-num="states.checkedPhotoIds.size"
            :on-delete="actions.onDeletePhoto"
          />
        </div>
      </div>
    </div>
    <div :class="css.separator">
      <Separator />
    </div>
    <Typography
      v-if="!states.photoNum"
      :class="css['no-photo']"
      text-align="center"
      >表示する内容がありません</Typography
    >
    <div v-else :class="css.photos">
      <Photos
        v-model:checked-id-set="states.checkedPhotoIds"
        :photos="props.photos"
        :thumbnail-url-getter="thumbnailUrlGetter"
        :failed-photos="props.failedPhotos"
        :event-status="props.eventStatus"
      />
    </div>
    <Separator />
    <ToEventPageButton :event-id="props.eventId" />
  </ContentBox>
</template>
