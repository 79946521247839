import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { BankAccountInfo } from '@/vueComponents/photographer/organisms/pageContent/AccountPageContent/BankAccountInfo'
import { reactive } from 'vue'
import { Computable, Emits } from '@/types/utils'

export interface IBankAccountEditPageContentProps {
  bankAccountInfo: BankAccountInfo
  breadcrumbs: IBreadcrumb[]
}

type BankAccountEditPageContentStates = {
  hasErrorBankAccountForm: boolean
  bankAccountInfoData: BankAccountInfo | undefined
}

export type BankAccountEditPageContentEmitParams = {
  'update:bank-account-info': BankAccountInfo
}

export const useBankAccountEditPageContentStates = (
  props: IBankAccountEditPageContentProps
) => {
  const states: BankAccountEditPageContentStates = reactive<
    Computable<BankAccountEditPageContentStates>
  >({
    hasErrorBankAccountForm: handleHasErrorBankAccountForm(
      props.bankAccountInfo
    ),
    bankAccountInfoData: props.bankAccountInfo,
  })
  return states
}

const handleHasErrorBankAccountForm = (
  bankAccountInfo: BankAccountInfo
): boolean => {
  // someメソッドを使うためにオブジェクトから配列に変換
  const array = Object.entries(bankAccountInfo)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  if (array.some(([_, value]) => value === '' || value === 0)) {
    return true
  } else {
    return false
  }
}

export const useBankAccountEditPageContentActions = (
  states: BankAccountEditPageContentStates,
  emits: Emits<BankAccountEditPageContentEmitParams>
) => {
  return {
    onUpdateBankAccountInfo(bankAccountInfo: BankAccountInfo): void {
      states.hasErrorBankAccountForm =
        handleHasErrorBankAccountForm(bankAccountInfo)
      states.bankAccountInfoData = bankAccountInfo
    },
    onClickSaveButton(): void {
      if (!states.bankAccountInfoData) return
      emits('update:bank-account-info', states.bankAccountInfoData)
    },
  }
}
