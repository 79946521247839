import { reactive } from 'vue'
import { addPublicBasePath } from '@/utils/functions/vite'
import imgAccepted from '@/media/img/proposalComplete/img_accepted.svg'

type AcceptedMessageStates = {
  imgPath: string
}

export const useAcceptedMessageStates = () =>
  reactive<AcceptedMessageStates>({
    imgPath: addPublicBasePath(imgAccepted),
  })
