<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import {
  ILoginIdInputProps,
  LoginIdInputEmitParams,
  useLoginIdInputActions,
  useLoginIdInputStates,
} from '.'

interface IProps {
  loginId: ILoginIdInputProps['loginId']
}

interface IInputEmits {
  (e: 'update:login-id', value: LoginIdInputEmitParams['update:login-id']): void
  (
    e: 'update:validation-code',
    value: LoginIdInputEmitParams['update:validation-code']
  ): void
}

const props = defineProps<IProps>()
const emits = defineEmits<IInputEmits>()
const states = useLoginIdInputStates(props, emits)
useLoginIdInputActions(states, emits)
</script>

<template>
  <Input
    v-model:value="states.loginId"
    name="loginId"
    type="text"
    placeholder="ログインID"
    :is-shadow="true"
  ></Input>
</template>
