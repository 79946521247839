<script setup lang="ts">
import { defineProps } from 'vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import Tab from '@/vueComponents/photographer/molecules/Tab/index.vue'
import MatterRequestTable from '@/vueComponents/photographer/organisms/section/MatterRequestTable/index.vue'
import InspectionDeliveryRequestTable from '@/vueComponents/photographer/organisms/section/InspectionDeliveryRequestTable/index.vue'
import EmptyMassage from '@/vueComponents/photographer/organisms/section/EmptyMassage/index.vue'
import InvoiceRequestTable from '@/vueComponents/photographer/organisms/section/InvoiceRequestTable/index.vue'
import { NotificationListProps, useNotificationListStates } from '.'

interface IProps {
  matterRequests: NotificationListProps['matterRequests']
  inspectionDeliveryRequests: NotificationListProps['inspectionDeliveryRequests']
  invoiceRequests: NotificationListProps['invoiceRequests']
  invoiceGenerationFlg: NotificationListProps['invoiceGenerationFlg']
}

const props = defineProps<IProps>()
const states = useNotificationListStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/functions/space' as *;
.container {
  padding: space(8) space(8);
}
.title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.separator {
  @include space-mixins.vertical-margin(6);
}
.tab {
  @include space-mixins.vertical-margin(7);
}
.table {
  min-height: 200px;
}
</style>

<template>
  <ContentBox>
    <div :class="css.container">
      <div :class="css.title">
        <Typography type="h2-simple">最新の通知一覧</Typography>
        <Link display="block" :location="states.notificationPageLocation"
          >すべて見る</Link
        >
      </div>
      <Separator :class="css.separator" />
      <div :class="css.tab">
        <Tab :tab-items="states.tabItems" :has-separator="true">
          <template #1>
            <div v-if="props.matterRequests.length > 0" :class="css.table">
              <MatterRequestTable :matter-requests="props.matterRequests" />
            </div>
            <EmptyMassage v-else height="200px" translate-y="16px" />
          </template>
          <template #2>
            <div
              v-if="props.inspectionDeliveryRequests.length > 0"
              :class="css.table"
            >
              <InspectionDeliveryRequestTable
                :inspection-delivery-requests="props.inspectionDeliveryRequests"
              />
            </div>
            <EmptyMassage v-else height="200px" translate-y="16px" />
          </template>
          <template #3>
            <div v-if="props.invoiceRequests.length > 0" :class="css.table">
              <InvoiceRequestTable :invoice-requests="props.invoiceRequests" />
            </div>
            <EmptyMassage v-else height="200px" translate-y="16px" />
          </template>
        </Tab>
      </div>
    </div>
  </ContentBox>
</template>
