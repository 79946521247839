import { Promisable } from '@/types/utils'
import { ErrorMsg } from '@/utils/functions/errorMsg'
import { onMounted, onUnmounted, reactive, Ref, watch } from 'vue'
import errorThumbnailBg from '@/media/img/errorThumbnailBg/bg_snap_thumbnail_error.jpg'
import { addPublicBasePath } from '@/utils/functions/vite'

export interface IThumbnailProps {
  url?: string
  errorMsg?: ErrorMsg
  lazyLoadUrlGetter?: () => Promisable<string>
}

interface IThumbnailStates {
  thumbnailUrl: string
  observer?: IntersectionObserver
  errorBgImg: string
}

export const useThumbnailStates = (props: IThumbnailProps) =>
  reactive<IThumbnailStates>({
    thumbnailUrl: props?.url ?? '',
    errorBgImg: `url(${addPublicBasePath(errorThumbnailBg)}) repeat`,
  })

type ThumbnailActionPayload = {
  states: IThumbnailStates
  props: IThumbnailProps
  observerTarget: Ref<Element | undefined>
}

/**
 * URL取得
 * @param payload ペイロードデータ
 * @returns URL
 */
const obtainUrl = async (payload: ThumbnailActionPayload): Promise<string> =>
  (await payload.props.lazyLoadUrlGetter?.()) ?? ''

/**
 * 要素が画面に表示された際の処理を生成
 * @param payload ペイロードデータ
 * @returns 要素画面表示時処理
 */
const createLazyLoadHandler =
  (payload: ThumbnailActionPayload) =>
  (entries: IntersectionObserverEntry[]): void => {
    const { props, states } = payload
    if (!entries[0].isIntersecting || !props.lazyLoadUrlGetter) return
    states.observer?.disconnect()
    obtainUrl(payload)
      .then((url) => (states.thumbnailUrl = url))
      .catch((e) => {
        throw e
      })
  }

/**
 * Observerの設定
 * @param payload ペイロードデータ
 */
const setObserve = (payload: ThumbnailActionPayload) => {
  const { props, states, observerTarget } = payload
  if (!props.lazyLoadUrlGetter) return
  states.observer = new window.IntersectionObserver(
    createLazyLoadHandler(payload)
  )
  if (observerTarget.value) states.observer.observe(observerTarget.value)
}

/**
 * 遅延ローディング処理の設定
 * @param payload ペイロードデータ
 */
const setLazyLoad = (payload: ThumbnailActionPayload) => {
  onMounted(() => {
    setObserve(payload)
  })

  onUnmounted(() => {
    payload.states.observer?.disconnect()
  })
}

export const useThumbnailActions = (payload: ThumbnailActionPayload): void => {
  setLazyLoad(payload)

  watch(
    () => payload.props.url,
    (url) => url && (payload.states.thumbnailUrl = url)
  )
  watch(
    () => payload.props.lazyLoadUrlGetter,
    (newVal, oldVal) => {
      if (newVal && !oldVal) setObserve(payload)
    }
  )
}
