<script setup lang="ts">
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import ExpenseTable from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable/index.vue'
import Message from '@/vueComponents/photographer/molecules/Message/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import {
  ExpenseEmitParams,
  ExpenseProps,
  useExpenseActions,
  useExpenseStates,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense'
import { ref } from 'vue'

interface IProps {
  expenses: ExpenseProps['expenses']
}
interface IEmits {
  (
    e: 'update:invoice-expenses',
    value: ExpenseEmitParams['update:invoice-expenses']
  ): void
}

const expenseTableRef = ref()
const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useExpenseStates()
const actions = useExpenseActions(props, states, emits, expenseTableRef)

defineExpose({
  validate: actions.validate,
})
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/space' as space-mixins;
.container {
  padding: space(8);
  padding-bottom: space(14);
}
.description {
  font-size: sizes.$font-size-md;
  line-height: 22px;
}
.upper-separator {
  @include space-mixins.vertical-margin(6, 6);
}
.description {
  margin-bottom: space(8);
}
.error-message {
  margin-bottom: space(6);
}
</style>

<template>
  <ContentBox :class="css.container">
    <Typography type="h2-simple">ガソリン代以外の諸経費（任意）</Typography>
    <div :class="css['upper-separator']">
      <Separator />
    </div>
    <p :class="css.description">
      公共交通機関利用料（高速料金代、駐車場代、バス代、電車代等）や宿泊費用等が対象です。<br />
      以下の諸経費については、本請求書と合わせて領収書をご提出ください。
    </p>
    <Message v-if="states.hasError" :class="css['error-message']"
      >項目にエラーがあります。修正してください。</Message
    >
    <ExpenseTable
      ref="expenseTableRef"
      :expenses="states.expenses"
      @update:expenses="actions.updateExpenses"
    />
  </ContentBox>
</template>
