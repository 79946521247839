export const photographerRoutes = {
  /** ルートパス */
  PATH: {
    /** ログイン画面 */
    LOGIN: '/login',
    /** パスワードリセットメール送信画面 */
    RESET_MAIL_FORM: '/login/reset/form',
    /** パスワードリセットメール送信完了画面 */
    RESET_MAIL_COMPLETE: '/login/reset/complete',
    /** パスワード再設定画面 */
    PASSWORD_RESET: '/password/reset/:token',
    /** パスワード変更画面 */
    PASSWORD_UPDATE: '/password/update',
    /** イベント一覧画面 */
    EVENTS: '/events',
    /** イベント詳細画面 */
    EVENT: '/events/:eventId',
    /** フォルダ詳細(写真一覧)画面 */
    FOLDER: '/events/:eventId/:folderId',
    /** お問い合わせ画面 */
    CONTACT: '/contact',
    /** TOP画面 */
    TOP: '/top',
    /** 通知一覧画面 */
    NOTIFICATIONS: '/notifications',
    /** 案件打診詳細画面 */
    PROPOSAL: '/proposal/:proposalId',
    /** 案件打診完了画面 */
    PROPOSAL_COMPLETE: '/proposal/complete',
    /** スケジュール登録画面 */
    SCHEDULE: '/schedule/:scheduleMonth(\\d{4}\\-\\d{2})',
    /** アカウント画面 */
    ACCOUNT: '/account',
    /** アカウント登録情報編集画面 */
    ACCOUNT_EDIT: '/account/edit',
    /** アカウント振込先編集画面 */
    ACCOUNT_EDIT_BANK: '/account/edit/bank',
    /** 請求書一覧画面 */
    INVOICES: '/invoices',
    /** 請求書作成画面 */
    INVOICE_CREATE: '/invoices/create',
    /** 請求書プレビュー画面  */
    INVOICE_PREVIEW: '/invoices/preview',
    /** 請求書作成完了画面 */
    INVOICE_COMPLETE: '/invoices/complete',
    /** 出発報告画面 */
    NOTIFY_DEPARTURE: '/notify-departure/:token',
  },
  /** ルート名 */
  NAME: {
    /** ログイン画面 */
    LOGIN: 'login',
    /** パスワードリセットメール送信画面 */
    RESET_MAIL_FORM: 'resetMailForm',
    /** パスワードリセットメール送信完了画面 */
    RESET_MAIL_COMPLETE: 'resetMailComplete',
    /** パスワード再設定画面 */
    PASSWORD_RESET: 'passwordReset',
    /** パスワード変更画面 */
    PASSWORD_UPDATE: 'passwordUpdate',
    /** イベント一覧画面 */
    EVENTS: 'events',
    /** イベント詳細画面 */
    EVENT: 'event',
    /** フォルダ詳細(写真一覧)画面 */
    FOLDER: 'folder',
    /** お問い合わせ画面 */
    CONTACT: 'contact',
    /** TOP画面 */
    TOP: 'top',
    /** 通知一覧画面 */
    NOTIFICATIONS: 'notifications',
    /** 案件打診詳細画面 */
    PROPOSAL: 'proposal',
    /** 案件打診完了画面 */
    PROPOSAL_COMPLETE: 'proposalComplete',
    /** スケジュール登録画面 */
    SCHEDULE: 'schedule',
    /** アカウント画面 */
    ACCOUNT: 'account',
    /** アカウント編集画面 */
    ACCOUNT_EDIT: 'accountEdit',
    /** アカウント振込先編集画面 */
    ACCOUNT_EDIT_BANK: 'accountEditBank',
    /** 請求書一覧画面 */
    INVOICES: 'invoices',
    /** 請求書作成画面 */
    INVOICE_CREATE: 'invoiceCreate',
    /** 請求書プレビュー画面  */
    INVOICE_PREVIEW: 'invoicePreview',
    /** 請求書作成完了画面 */
    INVOICE_COMPLETE: 'invoiceComplete',
    /** 出発報告画面 */
    NOTIFY_DEPARTURE: 'notifyDeparture',
  },
  /** タイトル */
  META_TITLE: {
    /** ログイン */
    LOGIN: 'ログイン',
    /** パスワード再設定 */
    PASSWORD_RESET: 'パスワード再設定',
    /** パスワード変更 */
    PASSWORD_UPDATE: 'パスワード設定',
    /** イベント一覧 */
    EVENTS: 'イベント一覧',
    /** イベント詳細 */
    EVENT: 'イベント詳細',
    /** フォルダ詳細 */
    FOLDER: 'フォルダ詳細',
    /** お問い合わせ */
    CONTACT: 'お問い合わせ',
    /** アドレス不正 */
    NOT_FOUND_ERROR: 'お探しのページは見つかりません',
    /** TOP画面 */
    TOP: 'TOP',
    /** 通知一覧画面 */
    NOTIFICATIONS: '通知一覧',
    /** 案件打診詳細画面 */
    PROPOSAL: '案件打診詳細',
    /** 案件打診完了画面 */
    PROPOSAL_COMPLETE: 'Thanks',
    /** スケジュール登録画面 */
    SCHEDULE: 'スケジュール登録',
    /** アカウント画面 */
    ACCOUNT: 'アカウント',
    /** アカウント編集画面 */
    ACCOUNT_EDIT: '登録情報',
    /** アカウント振込先編集画面 */
    ACCOUNT_EDIT_BANK: '振込先',
    /** 請求書一覧画面 */
    INVOICES: ' 請求書一覧',
    /** 請求書作成画面 */
    INVOICE_CREATE: '請求書作成',
    /** 請求書プレビュー画面  */
    INVOICE_PREVIEW: '請求書プレビュー',
    /** 請求書作成完了画面 */
    INVOICE_COMPLETE: '請求書作成完了',
    /** 出発報告画面 */
    NOTIFY_DEPARTURE: '出発報告',
  },
} as const
