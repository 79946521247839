<script setup lang="ts">
import { defineEmits, defineProps, reactive, withDefaults } from 'vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import { ERRORMSG } from '@/utils/constants/messages/photographer/errorMsg'
import { IAccountTextInputProps, IAccountTextInputStates } from '.'
import { validateAccountHolderInput } from '@/utils/functions/validation/validation'

interface IProps {
  modelValue: IAccountTextInputProps['modelValue']
  name: IAccountTextInputProps['name']
  maxlength: IAccountTextInputProps['maxlength']
  placeholder?: IAccountTextInputProps['placeholder']
  width?: IAccountTextInputProps['width']
  type?: IAccountTextInputProps['type']
}

interface IInputEmits {
  (e: 'update:modelValue', value: string): void
}

const props = withDefaults(defineProps<IProps>(), {
  placeholder: '',
  width: '100%',
  type: 'default',
})
const states = reactive<IAccountTextInputStates>({
  hasError: false,
})
const emits = defineEmits<IInputEmits>()

const handleUpdate = (e: Event) => {
  const target = e.target as HTMLInputElement
  emits('update:modelValue', target.value)
  if (target.value && target.value !== '') {
    states.hasError = false
  }
}

const handleValidate = (e: Event) => {
  const target = e.target as HTMLInputElement
  if (!target.value) {
    states.hasError = true
  }
  if (props.type === 'default') {
    emits('update:modelValue', target.value)
  }
  if (props.type === 'account-holder') {
    emits('update:modelValue', validateAccountHolderInput(target.value))
  }
}
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;

.input-error {
  margin-top: space(1);
}
</style>

<template>
  <div>
    <Input
      type="text"
      :value="props.modelValue"
      :name="props.name"
      :placeholder="props.placeholder"
      :maxlength="props.maxlength"
      :has-error="states.hasError"
      :width="props.width"
      @input="handleUpdate"
      @blur="handleValidate"
    ></Input>
    <InputError
      v-if="states.hasError"
      type="attention"
      :class="css['input-error']"
      >{{ ERRORMSG.NOT_ENTERED }}</InputError
    >
  </div>
</template>
