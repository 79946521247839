export const bankAccountConstants = {
  ACCOUNT_TYPE: [
    {
      VALUE: 0,
      LABEL: '未設定',
    },
    {
      VALUE: 1,
      LABEL: '普通',
    },
    {
      VALUE: 2,
      LABEL: '当座',
    },
  ],
}
