import { photographerConstants } from '@/utils/constants/photographerConstants'
import { canBeErrorIgnored } from '@/utils/functions/errorHandler'
import { UploadPayload } from '.'
import { IPhotoUploaderStates } from '..'
import { UploadingPhoto } from '../UploadingPhoto'

const { FILE_UPLOAD } = photographerConstants

/**
 * アップロード処理の生成
 * @param payload ペイロードデータ
 * @returns true:アップロード成功 false:アップロード失敗
 */
const createUploader =
  (payload: UploadPayload) =>
  async (
    photo: UploadingPhoto,
    errorHandler: (e?: unknown) => void
  ): Promise<boolean> => {
    try {
      await payload.fileUploader(photo)
      return true
    } catch (e) {
      if (!canBeErrorIgnored(e)) throw e
      errorHandler(e)
      return false
    }
  }

/**
 * アップロード中写真枚数取得
 * @param uploadingPhotos アップロード中写真
 * @returns アップロード中写真数
 */
const getUploadingPhotoCount = (uploadingPhotos: UploadingPhoto[]): number => {
  return uploadingPhotos.filter(
    ({ progressRate }) =>
      progressRate < FILE_UPLOAD.PROGRESS_RATE.UPLOAD_COMPLETED
  ).length
}

/**
 * 2枚目以降の写真をアップロード
 * @param restPhotos 2枚目以降の写真
 * @param uploader アップロード処理
 */
async function uploadRestPhotos(
  restPhotos: UploadingPhoto[],
  uploader: ReturnType<typeof createUploader>,
  states: IPhotoUploaderStates
): Promise<void> {
  await new Promise((resolve, reject): void => {
    const uploadingPhotos: UploadingPhoto[] = []
    const waitingPhotos = [...restPhotos]
    const intervalId = window.setInterval(() => {
      const uploadingPhotoCount = getUploadingPhotoCount(uploadingPhotos)
      if (uploadingPhotoCount >= states.maxUploadingPhotoCount) return
      if (waitingPhotos.length > 0) {
        const photos = waitingPhotos.splice(
          0,
          states.maxUploadingPhotoCount - uploadingPhotoCount
        )
        for (const photo of photos) {
          uploader(photo, () => (photo.hasError = true)).catch(reject)
        }
        uploadingPhotos.push(...photos)
      }
      if (restPhotos.every((photo) => photo.isCompleted)) {
        window.clearInterval(intervalId)
        resolve(undefined)
      }
    }, FILE_UPLOAD.UPLOADING_INTERVAL)
  })
}

/**
 * アップロードステータス一括取得処理
 * @param payload ペイロードデータ
 * @returns 全写真サムネイル作成完了判定フラグ
 */
const checkUploadingStatus = async (
  payload: UploadPayload
): Promise<boolean> => {
  const uploadingPhotos = [...payload.states.uploadingPhotos]
  const targetPhotoIds = uploadingPhotos
    .filter((photo) => photo.isUploaded && !photo.isCompleted)
    .map(({ photoId }) => photoId)
  await payload.fileStatusUpdater(targetPhotoIds, uploadingPhotos)
  return uploadingPhotos.every((photo) => photo.isCompleted)
}

/**
 * アップロードステータス取得再帰処理
 * @param payload ペイロードデータ
 */
const checkAllUploadingStatus = async (
  payload: UploadPayload
): Promise<void> => {
  if (payload.states.uploadingPhotos.size === 0) return
  await new Promise((resolve, reject) => {
    const checkRecursive = () => {
      checkUploadingStatus(payload)
        .then((result) => {
          if (result) {
            resolve(true)
            return
          }
          window.setTimeout(
            checkRecursive,
            FILE_UPLOAD.UPLOADING_STATUS_INTERVAL
          )
        })
        .catch(reject)
    }
    checkRecursive()
  })
}

/**
 * アップロード完了後に10秒間隔で失敗した写真のステータスチェックする
 */
const checkFailedPhotosStatus = (payload: UploadPayload): void => {
  const intervalId = window.setInterval(() => {
    payload
      .fileFailedPhotosStatusUpdater()
      .then((hasFailedPhotosStatus) => {
        if (!hasFailedPhotosStatus) {
          window.clearInterval(intervalId)
        }
      })
      .catch(() => {
        window.clearInterval(intervalId)
      })
  }, FILE_UPLOAD.FAILED_PHOTOS_STATUS_INTERVAL)
}

/**
 * 複数ファイルのアップロード
 * @param payload ペイロードデータ
 * @returns true:サーバー接続成功 false:サーバー接続失敗
 */
export const upload = async (payload: UploadPayload): Promise<boolean> => {
  const { states } = payload
  const [firstPhoto, ...restPhotos] = [...states.uploadingPhotos]
  const uploader = createUploader(payload)
  const firstPhotoErrorHander = (e: unknown) => {
    throw e
  }
  if (!(await uploader(firstPhoto, firstPhotoErrorHander))) return false
  await Promise.all([
    uploadRestPhotos(restPhotos, uploader, states),
    checkAllUploadingStatus(payload),
  ])
  checkFailedPhotosStatus(payload)
  return true
}
