import { IEvent } from '@/types/photographer/event'
import { InjectionKey, ref } from 'vue'
import { BaseStore } from '../..'

export class EventsStore extends BaseStore {
  events = ref<IEvent[]>([])
}

export const eventsStoreKey: InjectionKey<EventsStore> =
  Symbol('EventsStoreKey')
