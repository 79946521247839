<script setup lang="ts">
import { defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import {
  TransmissionHistoryTableProps,
  useTransmissionHistoryTableActions,
  useTransmissionHistoryTableStates,
} from '.'

interface IProps {
  tableList: TransmissionHistoryTableProps['tableList']
}

interface IEmits {
  (e: 'download:pdf', submittedId: number): void
}

const props = defineProps<IProps>()
const states = useTransmissionHistoryTableStates(props)
const emits = defineEmits<IEmits>()
const actions = useTransmissionHistoryTableActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/mixins/position' as position-mixins;
.table {
  margin-top: space(6);
}
.filename {
  width: 792px;
}
.date {
  width: 200px;
}
.icon {
  width: 64px;
}
.button-warper {
  @include position-mixins.flex-center();
  width: 100%;
}
.button {
  width: 314px;
  @include space-mixins.vertical-margin(8, 6);
}
.icon-container {
  position: relative;
  width: 48px;
  height: 40px;
  @include position-mixins.flex-center();
}

.icon-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.icon-container:hover::before {
  background-color: colors.$background-color-icon-dark-gray;
}

.no-folder {
  @include space-mixins.vertical-margin(38.5, 32.5);
  @include position-mixins.flex-vertical(space(5));
}
</style>

<template>
  <div v-if="states.tableList.length">
    <div :class="css.table">
      <Table>
        <TableRow v-for="row in states.visibleTableList" :key="row.submittedId">
          <TableCell :separatorable="false" :class="css.filename">
            <Typography>{{ row.fileName }}</Typography>
          </TableCell>
          <TableCell :separatorable="false" :class="css.date">
            <Typography>
              {{ row.dateYear }}&emsp;{{ row.dateTime }}
            </Typography>
          </TableCell>
          <TableCell :separatorable="false" :class="css.icon">
            <div
              :class="[css['icon-container']]"
              @click="actions.onClickIcon(row.submittedId)"
            >
              <Icon type="download" />
            </div>
          </TableCell>
        </TableRow>
      </Table>
    </div>
    <div v-if="states.isButtonShow" :class="css['button-warper']">
      <div :class="css.button">
        <Button color="secondary" @click="actions.onClickButton">
          もっと見る
        </Button>
      </div>
    </div>
  </div>
  <div v-else :class="css['no-folder']">
    <Typography>表示する内容がありません</Typography>
  </div>
</template>
