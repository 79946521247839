<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import {
  IPhotosProps,
  PhotosEmitParams,
  usePhotosActions,
  usePhotosStates,
} from '.'
import PhotoInfo from '../PhotoInfo/index.vue'

interface IProps {
  photos: IPhotosProps['photos']
  failedPhotos: IPhotosProps['failedPhotos']
  checkedIdSet: IPhotosProps['checkedIdSet']
  eventStatus: IPhotosProps['eventStatus']
  thumbnailUrlGetter: IPhotosProps['thumbnailUrlGetter']
}

interface IEmits {
  (
    e: 'update:checkedIdSet',
    checked: PhotosEmitParams['update:checkedIdSet']
  ): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = usePhotosStates(props)

const actions = usePhotosActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.photos {
  padding: space(6) 0;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(136px, 1fr));
  column-gap: space(6);
  row-gap: space(6);
}
</style>

<template>
  <div :class="css.photos">
    <template v-if="props.failedPhotos?.size">
      <PhotoInfo
        v-for="[photoId, failedPhoto] in props.failedPhotos"
        :key="photoId"
        :photo-id="photoId"
        :status="failedPhoto.status"
        :checked="props.checkedIdSet.has(photoId)"
        :file-name="failedPhoto.orgImgName"
        :photographed="failedPhoto.photographed"
        :thumbnail-url-getter="() => props.thumbnailUrlGetter(failedPhoto)"
        :event-status="props.eventStatus"
        @update:checked="actions.checkedPhoto"
      />
    </template>
    <PhotoInfo
      v-for="[photoId, photo] in states.sortedPhotos"
      :key="photoId"
      :photo-id="photoId"
      :status="photo.status"
      :checked="props.checkedIdSet.has(photoId)"
      :file-name="photo.orgImgName"
      :photographed="photo.photographed"
      :thumbnail-url-getter="() => props.thumbnailUrlGetter(photo)"
      :event-status="props.eventStatus"
      @update:checked="actions.checkedPhoto"
    />
  </div>
</template>
