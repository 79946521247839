import { Computable, Emits } from '@/types/utils'
import { computed, watch, reactive, Ref } from 'vue'
import {
  handleInput,
  isOverFlow,
  openS3File,
  openLocalFile,
} from '@/utils/functions/invoice'
import { isUploadableFileSize } from '@/utils/functions/validation'

export type ExpenseInput = {
  content: string
  amount: string
  date: string
  file: File | null
  originalFileName: string
}

export type ExpenseTableRowProps = {
  id: number | null
  rowNo: number
  expenseInput: ExpenseInput
  fetchFileUrl?: () => Promise<string>
  hasRowError: boolean
  hasFileError: boolean
  fileName: string | null
}

export type SetFileParams = {
  file: ExpenseTableRowProps['expenseInput']['file']
  originalFileName: ExpenseTableRowProps['expenseInput']['originalFileName']
  isError: boolean
}

export type ExpenseTableRowEmitPrams = {
  'update:expense-input': ExpenseTableRowProps['expenseInput']
  'delete-row': void
  'set-file': SetFileParams
}

export type ExpenseTableRowStates = {
  expenseInput: ExpenseInput
  isHover: boolean
  isOverFlow: boolean
  tableRowBgColor: 'default' | 'warn'
  isShowFileErrorMessage: boolean
}

export const useExpenseTableRowStates = (
  props: ExpenseTableRowProps
): ExpenseTableRowStates => {
  const expenseInput = initExpenseInput(props)
  const tableRowBgColor = computed(() =>
    props.hasRowError ? 'warn' : 'default'
  )
  return reactive<Computable<ExpenseTableRowStates>>({
    expenseInput,
    isHover: false,
    isOverFlow: false,
    tableRowBgColor,
    isShowFileErrorMessage: false,
  })
}

const initExpenseInput = (props: ExpenseTableRowProps): ExpenseInput => ({
  content: props.expenseInput.content,
  amount: props.expenseInput.amount,
  date: props.expenseInput.date,
  file: props.expenseInput.file,
  originalFileName: props.expenseInput.originalFileName,
})

export const useExpenseTableRowActions = (
  props: ExpenseTableRowProps,
  states: ExpenseTableRowStates,
  emits: Emits<ExpenseTableRowEmitPrams>,
  fileInputElement: Ref<HTMLInputElement | undefined>,
  fileDivElement: Ref<HTMLDivElement | undefined>
) => {
  watchStatesExpenseInput(states, emits, fileDivElement)
  watchPropsHasFileError(props, states)
  const deleteRow = (): void => emits('delete-row')
  const clickFileInputElement = (): void => fileInputElement.value?.click()
  const handleAmountInput = (e: Event) => {
    states.expenseInput.amount = handleInput(e)
  }
  return {
    deleteRow,
    clickFileInputElement,
    setFile: () => setFile(fileInputElement, states, emits),
    handleAmountInput,
    openFile: () => openFile(states, props),
  }
}

const openFile = async (
  states: ExpenseTableRowStates,
  props: ExpenseTableRowProps
): Promise<void> => {
  if (states.expenseInput.file) {
    openLocalFile(states.expenseInput.file)
  } else {
    await openS3File(props.fetchFileUrl)
  }
}

const setFileDataToDefault = (states: ExpenseTableRowStates) => {
  states.isShowFileErrorMessage = true
  states.expenseInput.file = null
  states.expenseInput.originalFileName = ''
}

const setFileData = (states: ExpenseTableRowStates, file: File) => {
  states.isShowFileErrorMessage = false
  states.expenseInput.file = file
  states.expenseInput.originalFileName = file.name
}

const setFile = (
  fileInputElement: Ref<HTMLInputElement | undefined>,
  states: ExpenseTableRowStates,
  emits: Emits<ExpenseTableRowEmitPrams>
) => {
  const file = fileInputElement.value?.files?.[0]
  if (!file) return
  if (!isUploadableFileSize(file)) {
    setFileDataToDefault(states)
    emits('set-file', {
      file: null,
      originalFileName: '',
      isError: true,
    })
    return
  }
  setFileData(states, file)
  emits('set-file', {
    file,
    originalFileName: file.name,
    isError: false,
  })
}

const watchStatesExpenseInput = (
  states: ExpenseTableRowStates,
  emits: Emits<ExpenseTableRowEmitPrams>,
  fileDivElement: Ref<HTMLDivElement | undefined>
): void => {
  watch(
    () => states.expenseInput,
    async (newVal: ExpenseTableRowStates['expenseInput']) => {
      emits('update:expense-input', newVal)
      states.isOverFlow = await isOverFlow(fileDivElement)
    },
    { deep: true, immediate: true }
  )
}

const watchPropsHasFileError = (
  props: ExpenseTableRowProps,
  states: ExpenseTableRowStates
): void => {
  watch(
    () => props.hasFileError,
    (newVal: ExpenseTableRowProps['hasFileError']) => {
      states.isShowFileErrorMessage = newVal
    },
    { deep: true, immediate: true }
  )
}
