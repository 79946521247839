import { Computable, Promisable } from '@/types/utils'
import { computed, reactive, watch } from 'vue'
import {
  parseDateYearToHyphenString,
  parseJaMonthYearToString,
  parseMonthYearToHyphenString,
} from '@/utils/functions/parser'
import { ScheduleStates } from '@/vueComponents/photographer/pages/Schedule'
import { IScheduleReplyData } from '@/types/photographer/schedules'
import {
  NavigationGuardNext,
  onBeforeRouteLeave,
  RouteLocationRaw,
  Router,
  useRouter,
} from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { addMonths, isThisMonth, subMonths } from 'date-fns'
import { ScheduleReplyStatus } from '@/utils/constants/enums/photographer/schedule'

export type ScheduleReplyPrams = {
  date: string
  plan: ScheduleReplyStatus
}

export type ScheduleRegistrationProps = {
  scheduleMonth: ScheduleStates['scheduleMonth']
  scheduleReplyData: IScheduleReplyData[]
  onSave: (params: ScheduleReplyPrams[]) => Promisable<void>
}

export type ScheduleRegistrationStates = {
  title: string
  preMonthLocation: RouteLocationRaw
  nextMonthLocation: RouteLocationRaw
  isModalOpened: boolean
  isSaved: boolean
  scheduleReplyPrams: ScheduleReplyPrams[]
  toPage: NavigationGuardNext
}

type ScheduleRegistrationPayload = {
  states: ScheduleRegistrationStates
  props: ScheduleRegistrationProps
  router: Router
}

export const useScheduleRegistrationStates = (
  props: ScheduleRegistrationProps
) =>
  reactive<Computable<ScheduleRegistrationStates>>({
    title: computed(() => parseJaMonthYearToString(props.scheduleMonth)),
    preMonthLocation: computed(() => ({
      name: photographerRoutes.NAME.SCHEDULE,
      params: {
        scheduleMonth: parseMonthYearToHyphenString(
          subMonths(props.scheduleMonth, 1)
        ),
      },
    })),
    nextMonthLocation: computed(() => ({
      name: photographerRoutes.NAME.SCHEDULE,
      params: {
        scheduleMonth: parseMonthYearToHyphenString(
          addMonths(props.scheduleMonth, 1)
        ),
      },
    })),
    isModalOpened: false,
    isSaved: false,
    scheduleReplyPrams: [],
    toPage: () => undefined,
  })

/**
 * props.scheduleReplyDataのdataプロパティをString型に変換
 * @param scheduleReplyData IScheduleReplyData[]
 * @returns dataプロパティをString型に変換された配列
 */
const parseScheduleReplyToString = (
  scheduleReplyData: IScheduleReplyData[]
): ScheduleReplyPrams[] =>
  scheduleReplyData.map(({ date, plan }) => ({
    date: parseDateYearToHyphenString(date),
    plan,
  }))

/**
 * APIから呼び出したスケジュール登録データと差分があるか判定する
 * @param states ScheduleRegistrationStates
 * @returns 差分があるかどうか
 */
const isDiff = (
  states: ScheduleRegistrationStates,
  props: ScheduleRegistrationProps
): boolean => {
  const preData = parseScheduleReplyToString(props.scheduleReplyData)
  return preData.some((pre) =>
    states.scheduleReplyPrams.some(
      (next) => next.date === pre.date && next.plan !== pre.plan
    )
  )
}

/**
 * VuuRoter管理画面に遷移時処理
 * 保存処理がされていない場合にモーダル表示
 * @param states ScheduleRegistrationStates
 * @param props ScheduleRegistrationProps
 */
const onSchedulePageBeforeRouteLeave = (
  states: ScheduleRegistrationStates,
  props: ScheduleRegistrationProps
) => {
  onBeforeRouteLeave((_to, _from, next) => {
    if (isDiff(states, props)) {
      ;[states.isSaved, states.isModalOpened] = [false, true]
      states.toPage = next
    } else {
      next()
    }
  })
}

/**
 * 翌月前月ボタン押下時処理
 * 保存処理がされていない場合にモーダル表示
 * @param payload ScheduleRegistrationPayload
 * @param isPreMonth 前月ボタン押下時true
 */
const toSchedule = async (
  payload: ScheduleRegistrationPayload,
  isPreMonth: boolean
) => {
  const { states, props, router } = payload
  if (isPreMonth && isThisMonth(props.scheduleMonth)) return
  const toSchedulePage = () =>
    router.push(isPreMonth ? states.preMonthLocation : states.nextMonthLocation)
  if (!isDiff(states, props)) {
    await toSchedulePage()
    return
  }
  ;[states.isSaved, states.isModalOpened] = [false, true]
  states.toPage = async () => await toSchedulePage()
}

const topPageLocation: RouteLocationRaw = {
  name: photographerRoutes.NAME.TOP,
}

export const useScheduleRegistrationActions = (
  states: ScheduleRegistrationStates,
  props: ScheduleRegistrationProps
) => {
  const router = useRouter()
  const payload: ScheduleRegistrationPayload = {
    states,
    props,
    router,
  }
  watch(
    () => props.scheduleReplyData,
    () => (states.scheduleReplyPrams = [])
  )
  onSchedulePageBeforeRouteLeave(states, props)
  return {
    async toTop(): Promise<void> {
      await router.push(topPageLocation)
    },
    async onSave(): Promise<void> {
      await props.onSave(states.scheduleReplyPrams)
      ;[states.isSaved, states.isModalOpened] = [true, true]
    },
    toSchedule: (isPreMonth: boolean) => toSchedule(payload, isPreMonth),
  }
}
