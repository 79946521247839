<script setup lang="ts">
import { defineProps, defineEmits, withDefaults, computed } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Checkbox from '@/vueComponents/photographer/molecules/Checkbox/index.vue'
import { CheckboxProps } from '@/vueComponents/photographer/molecules/Checkbox'
import { ISelectAllCheckboxProps } from '.'

interface IProps {
  value?: ISelectAllCheckboxProps['value']
  checked?: ISelectAllCheckboxProps['checked']
  disabled?: ISelectAllCheckboxProps['disabled']
}

interface IEmits {
  (e: 'update:checked', value: CheckboxProps['checked']): void
}

const props = withDefaults(defineProps<IProps>(), {
  value: '',
  checked: false,
  disabled: false,
})

const emits = defineEmits<IEmits>()

const selectAll = computed({
  get: () => props.checked,
  set: (val: CheckboxProps['checked']) => {
    emits('update:checked', val)
  },
})
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
.label {
  display: flex;
  gap: space(2);
}
.is-disabled {
  color: colors.$font-color-disabled;
}
</style>

<template>
  <label :class="css.label">
    <Checkbox v-model:checked="selectAll" :disabled="props.disabled" />
    <Typography
      :class="{
        [css['is-disabled']]: props.disabled,
      }"
      >すべて選択</Typography
    >
  </label>
</template>
