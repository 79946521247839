import { IDirectoryInfo } from '@/types/photographer/directory'
import {
  IEventDetail,
  IEventInfo,
  IEventTitle,
} from '@/types/photographer/event'
import { Computable, Promisable } from '@/types/utils'
import {
  EventStatusCode,
  InspectionStatusCode,
} from '@/utils/constants/enums/photographer/event'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { computed, reactive } from 'vue'
import { DeliveryButtonProps } from './Folder/DeliveryButton'
import { IFolderCreationButtonProps } from './Folder/FolderCreationButton'
import { FolderTableProps } from './Folder/FolderTable'

export interface IEventPageContentProps {
  dispatchId: IEventDetail['dispatchId']
  eventName: IEventTitle['eventName']
  sellerName: IEventTitle['sellerName']
  eventInfo: IEventInfo
  directories: IDirectoryInfo[]
  onCreateFolder: IFolderCreationButtonProps['onSubmit']
  onDeleteFolder: (directoryIds: number[]) => Promisable<void>
  onUpdateFolderName: FolderTableProps['onUpdateFolderName']
  onDelivery: DeliveryButtonProps['onSubmit']
}

type EventPageContentStates = {
  readonly sendingBackFlag: boolean
  readonly breadcrumbs: IBreadcrumb[]
  readonly sendingBackDirectories: IDirectoryInfo[]
}

/**
 * フォルダが差し戻し中であるかを判定
 * @param directory フォルダ情報
 * @returns true:差し戻し中 false:差し戻し中でない
 */
const isSendingBackDirectory = ({ inspectStatus }: IDirectoryInfo) =>
  inspectStatus === InspectionStatusCode.SENDING_BACK

export const useEventPageContentStates = (
  props: IEventPageContentProps
): EventPageContentStates =>
  reactive<Computable<EventPageContentStates>>({
    sendingBackFlag: computed(
      () =>
        props.eventInfo.status === EventStatusCode.SENDING_BACK &&
        props.directories.some(isSendingBackDirectory)
    ),
    breadcrumbs: computed(() => [
      {
        label: 'ホーム',
        location: { name: photographerRoutes.NAME.TOP },
      },
      {
        label: 'イベント一覧',
        location: { name: photographerRoutes.NAME.EVENTS },
      },
      {
        label: `${props.sellerName} ${props.eventName}`,
      },
    ]),
    sendingBackDirectories: computed(() =>
      props.directories.filter(isSendingBackDirectory)
    ),
  })
