import { BaseEntity } from '@/entities/BaseEntity'
import { EventStore, eventStoreKey } from '@/vue/stores/photographer/EventStore'
import {
  IEventTitle,
  IEventInfo,
  IEventDetail,
} from '@/types/photographer/event'

export class EventEntity extends BaseEntity<EventStore> {
  constructor() {
    super(eventStoreKey)
  }

  public get dispatchId(): IEventDetail['dispatchId'] {
    return this.store.dispatchId.value
  }

  public get eventTitle(): IEventTitle {
    return this.store.eventTitle.value
  }

  public get eventInfo(): IEventInfo | undefined {
    return this.store.eventInfo.value
  }

  public setEvent(event: IEventDetail) {
    this.store.dispatchId.value = event.dispatchId
    this.store.eventTitle.value = event.eventTitle
    this.store.eventInfo.value = event.eventInfo
  }
}
