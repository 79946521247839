import { Computable, Emits } from '@/types/utils'
import { AccountType } from '@/utils/constants/enums/photographer/account'
import { PREFECTURES_MAP } from '@/utils/constants/global/prefectures'
import { reactive, watch } from 'vue'
import { BankAccountInfo } from '../../AccountPageContent/BankAccountInfo'
import { displayZipcode } from '@/utils/functions/invoice'

export type Account = {
  name: string
  zipcode: string
  prefectureId: number
  address: string
  bankName: string
  branchName: string
  accountType: number
  accountNumber: string
  accountHolder: string
  companyName: string
  invoiceNumber: string
}

export type AccountProps = {
  account: Account
  isAccountInfoRegistered: boolean
}

type AccountStates = {
  displayZipcode: string
  displayAddress: string
  displayAccountType: string
}

const accountTypeMap = {
  0: AccountType.NOT_SET_ACCOUNT,
  1: AccountType.SAVING_ACCOUNT,
  2: AccountType.CHECKING_ACCOUNT,
}

const accountTypeText = (accountType: BankAccountInfo['accountType']) => {
  return accountTypeMap[accountType as keyof typeof accountTypeMap]
}

const getPrefectureName = (prefectureId: number) =>
  PREFECTURES_MAP.get(prefectureId) || ''

const displayAddress = (prefectureId: number, address: string) => {
  return `${getPrefectureName(prefectureId)}${address}`
}

export const useAccountStates = (): AccountStates => {
  const states: AccountStates = reactive<Computable<AccountStates>>({
    displayZipcode: '',
    displayAddress: '',
    displayAccountType: '',
  })
  return states
}

export type AccountParams = {
  'transition:account': void
  'transition:bank-account': void
}

export const useAccountActions = (
  states: AccountStates,
  props: AccountProps,
  emits: Emits<AccountParams>
) => {
  watch(
    () => props.account,
    (newVal: Account) => {
      states.displayZipcode = displayZipcode(newVal.zipcode)
      states.displayAddress = displayAddress(
        newVal.prefectureId,
        newVal.address
      )
      states.displayAccountType = accountTypeText(newVal.accountType)
    },
    { immediate: true }
  )
  const validate = () => {
    return props.isAccountInfoRegistered
  }
  return {
    transitionAccount: () => {
      emits('transition:account')
    },
    transitionBankAccount: () => {
      emits('transition:bank-account')
    },
    validate,
  }
}
