<script setup lang="ts">
import PageTemplate from '@/vueComponents/photographer/templates/PageTemplate/index.vue'
import { usePhotographerAppStates, usePhotographerAppAction } from '.'

const states = usePhotographerAppStates()
usePhotographerAppAction(states)
</script>

<template>
  <PageTemplate>
    <template #header>
      <router-view name="header" />
    </template>
    <template v-if="!states.errorComponent">
      <router-view name="default" />
    </template>
    <template v-else>
      <component :is="states.errorComponent" />
    </template>
    <template #footer>
      <router-view name="footer" />
    </template>
  </PageTemplate>
</template>
