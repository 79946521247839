<script setup lang="ts">
import ProposalMessage from './ProposalMessage/index.vue'
import ProposalDetail from './ProposalDetail/index.vue'
import ProposalMemo from './ProposalMemo/index.vue'
import ProposalCancelMessage from './ProposalCancelMessage/index.vue'
import SelectTransportation from './SelectTransportation/index.vue'
import AcceptAndRejectButton from './AcceptAndRejectButton/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { ProposalContentProps, useProposalContentStates } from '.'
import { defineProps } from 'vue'

interface IProps {
  userName: ProposalContentProps['userName']
  dispatchOrderId: number
  dispatchOrderMessage: ProposalContentProps['dispatchOrderMessage']
  dispatchOrderDetail: ProposalContentProps['dispatchOrderDetail']
  dispatchOrderMemo: string
  dispatchOrderTransportation: ProposalContentProps['dispatchOrderTransportation']
  dispatchOrderStatus: ProposalContentProps['dispatchOrderStatus']
  onReply: ProposalContentProps['onReply']
}

const props = defineProps<IProps>()
const states = useProposalContentStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
.breadcrubs {
  @include space-mixins.vertical-margin(10, 6);
}

.container {
  padding: space(8);
}
.box {
  position: relative;
}
.label {
  position: absolute;
  width: 101px;
  right: -8px;
  top: -8px;
}
.notification-link {
  margin: space(3);
}
.message-box {
  margin: space(8) auto space(4) auto;
  width: 440px;
  @include space-mixins.vertical-padding(2);
}
</style>

<template>
  <div>
    <div>
      <PageBreadcrumbs
        :breadcrumbs="states.breadcrubs"
        :class="css.breadcrubs"
      />
    </div>
    <div :class="css.box">
      <img v-if="states.labelImg" :class="css.label" :src="states.labelImg" />
      <ContentBox>
        <div :class="css.container">
          <ProposalMessage
            :user-name="props.userName"
            :dispatch-order-message="props.dispatchOrderMessage"
            :dispatch-order-can-reply="props.dispatchOrderStatus.canReply"
          />
          <Separator />
          <ProposalDetail :dispatch-order-detail="props.dispatchOrderDetail" />
          <ProposalCancelMessage />
          <Separator />
          <ProposalMemo :memo="props.dispatchOrderMemo" />
          <Separator />
          <SelectTransportation
            v-model:transportation="states.selectedTransportation"
            v-model:other-transportation="states.otherTransportation"
            :reply-status="props.dispatchOrderStatus.replyStatus"
            :reached-capacity="props.dispatchOrderStatus.reachedCapacity"
            :can-reply="props.dispatchOrderStatus.canReply"
          />
          <Separator />
          <!--回答ボタン非表示+テキスト表示：未回答かつ定員or回答済みの場合-->
          <div v-if="states.isActiveStatusMessage">
            <ContentBox :class="css['message-box']" type="subinfo">
              <Typography text-align="center">{{
                states.statusMessage
              }}</Typography>
            </ContentBox>
          </div>
          <!--回答ボタン表示（非活性含む）-->
          <div v-else>
            <AcceptAndRejectButton
              :disable-accept="
                !props.dispatchOrderStatus.canReply || !states.isAcceptance
              "
              :disable-reject="!props.dispatchOrderStatus.canReply"
              :on-reply="props.onReply"
              :dispatch-order-id="props.dispatchOrderId"
              :transportation="states.selectedTransportation"
              :other-transportation="states.otherTransportation"
            />
          </div>
          <Link
            :class="css['notification-link']"
            :location="states.notificationsLocation"
            display="block"
            ><Typography text-align="center" type="read-large"
              >通知一覧へ</Typography
            ></Link
          >
        </div>
      </ContentBox>
    </div>
  </div>
</template>
