import { Promisable } from '@/types/utils'
import { TransportationCode } from '@/utils/constants/enums/photographer/transportation'

type params = {
  replyStatus: number
  transportation: TransportationCode
  otherTransportation: string
}

export type AcceptAndRejectButtonProps = {
  disableAccept: boolean
  disableReject: boolean
  onReply: (dispatchOrderId: number, params: params) => Promisable<void>
  dispatchOrderId: number
  transportation: TransportationCode
  otherTransportation: string
}

export const useAcceptAndRejectButtonActions = (
  props: AcceptAndRejectButtonProps
) => {
  return {
    async reply(replyStatus: number) {
      const params = {
        replyStatus,
        transportation: props.transportation,
        otherTransportation: props.otherTransportation,
      }
      await props.onReply(props.dispatchOrderId, params)
    },
  }
}
