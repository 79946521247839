import { reactive } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { Promisable } from '@/types/utils'
import { ValidationCode } from '@/utils/constants/enums/validation'
import { ERRORMSG } from '@/utils/constants/messages/photographer/errorMsg'
import { convertErrorMsgPasswordConfirm } from '@/utils/functions/errorMsg'
import { SelfEntity } from '@/entities/SelfEntity'
import { createOnAsync } from '@/utils/functions/lifecycle'
import { CreatingEntity } from '@/entities/global/CreatingEntity'

export type PasswordUpdateContentProps = {
  message: string
  buttonLabel: string
  onClickButton: (password: string) => Promisable<string[] | void>
  nextLocation: RouteLocationRaw
}

type PasswordUpdateContentStates = {
  password: string
  validationCode: ValidationCode
  errorMessages: string[]
  isModalShown: boolean
}

export const usePasswordUpdateContentStates = () =>
  reactive<PasswordUpdateContentStates>({
    password: '',
    validationCode: ValidationCode.EMPTY,
    errorMessages: [],
    isModalShown: false,
  })

type PasswordUpdateContentPayload = {
  states: PasswordUpdateContentStates
}

/**
 * バリデーション
 * @param payload
 * @returns バリデーション結果 true: バリデーションOK false: バリデーションNG
 */
const validate = ({ states }: PasswordUpdateContentPayload): boolean => {
  const errorMessage = convertErrorMsgPasswordConfirm(states.validationCode)
  states.errorMessages = errorMessage === ERRORMSG.EMPTY ? [] : [errorMessage]
  if (states.errorMessages.length) return false
  return true
}

export const usePasswordUpdateContentActions = (
  props: PasswordUpdateContentProps,
  states: PasswordUpdateContentStates
) => {
  const selfEntity = new SelfEntity()
  const onAsync = createOnAsync(new CreatingEntity(), 'password update')
  return {
    updatePassword: (password: string): void => {
      states.password = password
    },
    updateValidationCode: (code: ValidationCode): void => {
      states.validationCode = code
    },
    submit: async (): Promise<void> => {
      if (!validate({ states })) return
      await onAsync(async () => {
        const errorMessages = await props.onClickButton(states.password)
        if (errorMessages) {
          states.errorMessages = errorMessages
          return
        }
        states.isModalShown = true
        selfEntity.initialPasswordFlg = false
      })
    },
  }
}
