<script setup lang="ts">
import { defineProps } from 'vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import {
  ZipcodeInputProps,
  useZipcodeInputActions,
  useZipcodeInputStates,
} from '.'

interface IProps {
  modelValue: ZipcodeInputProps['modelValue']
}

interface IEmits {
  (e: 'update:modelValue', value: string): void
}

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const states = useZipcodeInputStates()
const actions = useZipcodeInputActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as space-positions;
@use '@/styles/mixins/font' as font-mixins;

.title {
  @include font-mixins.font-weight-bold();
}
.table-cell div,
.col {
  @include space-positions.flex-horizontal(space(2));
}
.table-cell {
  padding: space(3) space(2);
  width: 256px;
}
.input-error {
  margin-top: space(1);
}
</style>

<template>
  <Input
    :value="props.modelValue"
    type="text"
    zipcode="Zipcode"
    placeholder="1040053"
    maxlength="7"
    width="96px"
    :has-error="Boolean(states.ZipcodeErrorMessage)"
    @input="actions.handleInputZipcode"
    @blur="actions.handleBlurZipcode"
  ></Input>
  <InputError
    v-if="states.ZipcodeErrorMessage"
    type="attention"
    :class="css['input-error']"
    width="128px"
    >{{ states.ZipcodeErrorMessage }}</InputError
  >
</template>
