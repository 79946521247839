<script setup lang="ts">
import { defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { MESSAGES } from '@/utils/constants/messages'
import { ScheduleContentProps, useScheduleContentStates } from '.'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import ScheduleRegistration from '@/vueComponents/photographer/organisms/pageContent/ScheduleContent/ScheduleRegistration/index.vue'

interface IProps {
  scheduleMonth: ScheduleContentProps['scheduleMonth']
  scheduleReplyData: ScheduleContentProps['scheduleReplyData']
  onSave: ScheduleContentProps['onSave']
}
const props = defineProps<IProps>()
const states = useScheduleContentStates()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.h1 {
  @include space-mixins.vertical-margin(10);
}
.bread-crumbs {
  @include space-mixins.vertical-margin(6, 6);
}
</style>

<template>
  <Typography :class="css.h1" type="h1">{{
    MESSAGES.PHOTOGRAPHER.PAGE_TITLE.SCHEDULE
  }}</Typography>
  <PageBreadcrumbs
    :class="css['bread-crumbs']"
    :breadcrumbs="states.breadcrumbs"
  />
  <ScheduleRegistration
    :schedule-month="props.scheduleMonth"
    :schedule-reply-data="props.scheduleReplyData"
    :on-save="props.onSave"
  />
</template>
