<script setup lang="ts">
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { defineProps, withDefaults } from 'vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import LoginIdInput from '@/vueComponents/photographer/organisms/input/LoginIdInput/index.vue'
import PasswordInput from '@/vueComponents/photographer/organisms/input/PasswordInput/index.vue'
import { MESSAGES } from '@/utils/constants/messages'
import {
  LoginContentProps,
  useLoginContentActions,
  useLoginContentStates,
} from '.'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import Form from '@/vueComponents/photographer/atoms/Form/index.vue'

const { NAME: ROUTE_NAME } = photographerRoutes

interface IProps {
  onClick?: LoginContentProps['onClick']
}

const props = withDefaults(defineProps<IProps>(), {
  onClick: () => undefined,
})
const states = useLoginContentStates()
const actions = useLoginContentActions(props, states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/space' as space-mixins;
.wrapper {
  @include position-mixins.flex-center(true, false);
}
.container {
  max-width: 100%;
  width: 554px;
  margin-top: 41px;
}
.heading {
  height: 26px;
  line-height: 26px;
  margin: 5px 0;
  padding-left: 9px;
  font-size: 20px;
  font-weight: bold;
  color: colors.$font-color-primary;
  border-left: solid 5px colors.$background-color-seller-main;
}
.inputs {
  display: flex;
  gap: 30px;
  margin-top: 7px;
}
.button-block {
  margin-top: 22px;
}
.link {
  color: colors.$background-color-seller-main;
  line-height: 26px;
  text-decoration: none;
}
.link-text {
  margin-left: 3px;
  font-size: 12px;
}
</style>

<template>
  <div :class="css.wrapper">
    <Form :class="css.container" @enter="actions.handleLoginButtonClick">
      <h1 :class="css.heading">{{ MESSAGES.PHOTOGRAPHER.PAGE_TITLE.LOGIN }}</h1>
      <div :class="css.inputs">
        <LoginIdInput
          v-model:login-id="states.loginId"
          @update:validation-code="actions.updateLoginIdValidationCode"
        />
        <PasswordInput
          v-model:password="states.password"
          :with-label="false"
          :with-help="false"
          :with-error-messages="false"
          @update:validation-code="actions.updatePasswordValidationCode"
        />
      </div>
      <div>
        <InputError
          v-for="errorMessage in states.errorMessages"
          :key="errorMessage"
          >{{ errorMessage }}</InputError
        >
      </div>
      <div :class="css['button-block']">
        <router-link
          :class="css.link"
          :to="{ name: ROUTE_NAME.RESET_MAIL_FORM }"
          ><Icon type="login-page-link" /><span :class="css['link-text']">{{
            MESSAGES.PHOTOGRAPHER.MESSAGE.TO_RESET_PASSWORD
          }}</span></router-link
        >
        <Button
          type="login"
          color="old"
          :on-click="actions.handleLoginButtonClick"
          >{{ MESSAGES.PHOTOGRAPHER.MESSAGE.TO_LOGIN }}</Button
        >
      </div>
    </Form>
  </div>
</template>
