import { Computable, Emits } from '@/types/utils'
import { computed, reactive } from 'vue'
import { NavigationGuardNext, useRouter } from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'

const { NAME: ROUTE_NAME } = photographerRoutes

export type ScheduleSaveModalProps = {
  shown: boolean
  isSaved: boolean
  toPage: NavigationGuardNext
}

export type ScheduleSaveModalEmitParams = {
  'update:shown': ScheduleSaveModalProps['shown']
}

type ScheduleSaveModalStates = {
  shown: boolean
  readonly title: string
  readonly cancelLabel: string
}

export const useScheduleSaveModalStates = (
  props: ScheduleSaveModalProps,
  emits: Emits<ScheduleSaveModalEmitParams>
): ScheduleSaveModalStates => {
  return reactive<Computable<ScheduleSaveModalStates>>({
    shown: computed({
      get() {
        return props.shown
      },
      set(value) {
        emits('update:shown', value)
      },
    }),
    title: computed(() =>
      props.isSaved ? '保存が完了しました' : 'まだ保存されていません'
    ),
    cancelLabel: computed(() =>
      props.isSaved ? 'ホームへ戻る' : 'ページを移動する'
    ),
  })
}

export const useScheduleSaveModalActions = (
  states: ScheduleSaveModalStates
) => {
  const router = useRouter()

  return {
    submit() {
      states.shown = false
    },
    async toTop() {
      await router.push({ name: ROUTE_NAME.TOP })
    },
  }
}
