import { Computable, Emits } from '@/types/utils'
import { computed, reactive, watch } from 'vue'
import {
  ExpenseTableRowProps,
  SetFileParams,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTableRow'
import {
  handleDeleteRow,
  isAmountInputInvalid,
  isContentInputInvalid,
  isDateInputInvalid,
  isInvoiceCommonInputEmpty,
  isOriginalFileNameInputInvalid,
} from '@/utils/functions/invoice'
import { InvoiceApi } from '@/domains/api/invoice/InvoiceApi'
import { FileUploadApi } from '@/domains/api/serverless/FileUploadApi'

export type Expense = {
  id: number | null
  content: string
  amount: number | null
  date: string
  file: File | null
  fileName: string | null
  originalFileName: string | null
  fetchFileUrl?: () => Promise<string>
  hasRowError: boolean
  hasFileError: boolean
}

export type ExpenseTableProps = {
  expenses: Expense[]
}

type ExpenseTableStates = {
  expenses: ExpenseTableRowProps[]
  totalAmount: number
  nextMaximumRowNo: ExpenseTableRowProps['rowNo']
}

export type ExpenseTableEmitParams = {
  'update:expenses': Expense[]
}

const handleTotalAmount = (states: ExpenseTableStates): number => {
  if (!states.expenses.length) return 0
  return states.expenses.reduce(
    (acc, expense) => acc + Number(expense.expenseInput.amount),
    0
  )
}

const handleNextMaximumRowNo = (states: ExpenseTableStates): number => {
  if (!states.expenses.length) return 1
  return states.expenses.reduce((a, b) => (a > b ? a : b)).rowNo + 1
}

export const useExpenseTableStates = () => {
  const states: ExpenseTableStates = reactive<Computable<ExpenseTableStates>>({
    expenses: [],
    totalAmount: computed(() => handleTotalAmount(states)),
    nextMaximumRowNo: computed(() => handleNextMaximumRowNo(states)),
  })
  return states
}

const getDefaultTableRow = (
  rowNo: ExpenseTableRowProps['rowNo']
): ExpenseTableRowProps => {
  return {
    expenseInput: {
      content: '',
      amount: '',
      date: '',
      file: null,
      originalFileName: '',
    },
    id: null,
    rowNo,
    hasRowError: false,
    hasFileError: false,
    fileName: null,
  }
}

const isEveryInputEmpty = (expense: ExpenseTableRowProps): boolean => {
  const { expenseInput, hasFileError } = expense
  return (
    isInvoiceCommonInputEmpty(expenseInput) &&
    expenseInput.file === null &&
    expenseInput.originalFileName === '' &&
    hasFileError === false
  )
}

const isAtLeastOneInputInValid = (
  expenseInput: ExpenseTableRowProps['expenseInput']
): boolean => {
  return (
    isDateInputInvalid(expenseInput.date) ||
    isContentInputInvalid(expenseInput.content) ||
    isAmountInputInvalid(expenseInput.amount) ||
    isOriginalFileNameInputInvalid(expenseInput.originalFileName)
  )
}

const isInputValid = (expense: ExpenseTableRowProps): boolean => {
  const { expenseInput, hasFileError } = expense
  if (isEveryInputEmpty(expense)) {
    return true
  }
  if (isAtLeastOneInputInValid(expenseInput) || hasFileError) {
    return false
  }
  return true
}

const validate = (states: ExpenseTableStates): boolean => {
  states.expenses.forEach((expense: ExpenseTableRowProps, index: number) => {
    if (isInputValid(expense)) {
      states.expenses[index].hasRowError = false
    } else {
      states.expenses[index].hasRowError = true
    }
  })
  const hasError = states.expenses.some(
    (detail: ExpenseTableRowProps) => detail.hasRowError === true
  )
  return !hasError
}

const convertExpenseInput = (
  expense: Expense
): ExpenseTableRowProps['expenseInput'] => {
  return {
    content: expense.content,
    amount: expense.amount === null ? '' : String(expense.amount),
    date: expense.date,
    file: expense.file,
    originalFileName: expense.originalFileName ?? '',
  }
}

const watchPropsExpenses = (
  props: ExpenseTableProps,
  states: ExpenseTableStates
) => {
  watch(
    () => props.expenses,
    (newVal: Expense[]) => {
      states.expenses = newVal.map((expense: Expense, index: number) => {
        return {
          expenseInput: convertExpenseInput(expense),
          id: expense.id,
          rowNo: index + 1,
          fetchFileUrl: expense.fetchFileUrl,
          hasRowError: expense.hasRowError,
          hasFileError: expense.hasFileError,
          fileName: expense.fileName,
        }
      })
    },
    {
      deep: true,
      immediate: true,
    }
  )
}

const expensesFilteredByNotEmpty = (
  expenses: ExpenseTableStates['expenses']
): ExpenseTableRowProps[] => {
  return expenses.filter((expense: ExpenseTableRowProps) => {
    return !isEveryInputEmpty(expense)
  })
}

const expensesEmitParams = (
  expenses: ExpenseTableRowProps[]
): ExpenseTableEmitParams['update:expenses'] => {
  return expenses.map((expense: ExpenseTableRowProps): Expense => {
    return {
      id: expense.id,
      content: expense.expenseInput.content,
      amount:
        expense.expenseInput.amount === ''
          ? null
          : Number(expense.expenseInput.amount),
      date: expense.expenseInput.date,
      file: expense.expenseInput.file,
      fileName: expense.fileName,
      originalFileName: expense.expenseInput.originalFileName || null,
      fetchFileUrl: expense.fetchFileUrl,
      hasRowError: expense.hasRowError,
      hasFileError: expense.hasFileError,
    }
  })
}

const watchStatesExpenses = (
  states: ExpenseTableStates,
  emits: Emits<ExpenseTableEmitParams>
) => {
  watch(
    () => states.expenses,
    (newVal: ExpenseTableStates['expenses']) => {
      const expenses = expensesFilteredByNotEmpty(newVal)
      emits('update:expenses', expensesEmitParams(expenses))
    },
    {
      deep: true,
      immediate: true,
    }
  )
}

const expensesFindIndexByRowNo = (
  expenses: ExpenseTableRowProps[],
  rowNo: number
): number => {
  return expenses.findIndex((expense: ExpenseTableRowProps) => {
    return expense.rowNo === rowNo
  })
}

const setFile = async (
  params: SetFileParams,
  rowNo: number,
  states: ExpenseTableStates,
  invoiceApi: InvoiceApi,
  fileUploadApi: FileUploadApi
) => {
  const targetIndex = expensesFindIndexByRowNo(states.expenses, rowNo)
  if (params.isError) {
    states.expenses[targetIndex].hasFileError = true
    states.expenses[targetIndex].fileName = null
    return
  }
  const receiptUploadUrl = await invoiceApi.fetchReceiptUploadUrl(
    params.originalFileName
  )
  if (!params.file) return
  await fileUploadApi.putFile(receiptUploadUrl.presignedUrl, params.file)
  states.expenses[targetIndex].hasFileError = false
  states.expenses[targetIndex].fileName = receiptUploadUrl.fileName
}

export const useExpenseTableActions = (
  props: ExpenseTableProps,
  states: ExpenseTableStates,
  emits: Emits<ExpenseTableEmitParams>
) => {
  const invoiceApi = new InvoiceApi()
  const fileUploadApi = new FileUploadApi()

  watchPropsExpenses(props, states)
  watchStatesExpenses(states, emits)
  const deleteRow = (rowNo: number) => {
    states.expenses = handleDeleteRow(states.expenses, rowNo)
  }
  const addRow = () => {
    states.expenses.push(getDefaultTableRow(states.nextMaximumRowNo))
  }
  return {
    deleteRow,
    addRow,
    validate: () => validate(states),
    setFile: (params: SetFileParams, rowNo: ExpenseTableRowProps['rowNo']) =>
      setFile(params, rowNo, states, invoiceApi, fileUploadApi),
  }
}
