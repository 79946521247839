import { Computable } from '@/types/utils'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'
import { computed, reactive } from 'vue'

export interface IAwaitingDeliveryNotificationProps {
  type: EventStatusCode.BEFORE_DELIVERY | EventStatusCode.SENDING_BACK
  sellerName: string
  eventName: string
}

interface IAwaitingDeliveryNotificationStates {
  message: string
}

export const useAwaitingDeliveryNotificationStates = (
  props: IAwaitingDeliveryNotificationProps
) => {
  const isBeforeDelivery = computed(
    () => props.type === EventStatusCode.BEFORE_DELIVERY
  )

  return reactive<Computable<IAwaitingDeliveryNotificationStates>>({
    message: computed(() =>
      isBeforeDelivery.value
        ? `「${props.sellerName} ${props.eventName}」が納品前です。写真をアップロードし、検品依頼をお願いします。`
        : `「${props.sellerName} ${props.eventName}」に差し戻しがあります。修正内容をご確認の上、再度検品依頼をお願いします。`
    ),
  })
}
