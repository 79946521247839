<script setup lang="ts">
import { defineProps } from 'vue'
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import {
  useBankAccountFormStates,
  BankAccountFormProps,
  useBankAccountFormActions,
  BankAccountFormEmitParams,
} from '.'
import MustLabel from '@/vueComponents/photographer/atoms/MustLabel/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import RadioWithLabel from '@/vueComponents/photographer/molecules/RadioWithLabel/index.vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import AccountNumberInput from '@/vueComponents/photographer/organisms/input/AccountNumberInput/index.vue'
import AccountTextInput from '@/vueComponents/photographer/organisms/input/AccountTextInput/index.vue'

interface IProps {
  bankAccountInfo: BankAccountFormProps['bankAccountInfo']
}

interface IEmits {
  (
    e: 'update:bank-account-info',
    bankAccountInfo: BankAccountFormEmitParams['update:bank-account-info']
  ): void
}

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const states = useBankAccountFormStates(props)
useBankAccountFormActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as space-positions;
@use '@/styles/mixins/font' as font-mixins;
@use '@/styles/colors' as colors;

.title {
  @include font-mixins.font-weight-bold();
}
.table-cell div,
.col {
  @include space-positions.flex-horizontal(space(2));
}
.table-cell {
  padding: space(3) space(2);
  width: 256px;
}
.radio-group {
  @include space-positions.flex-horizontal(space(10));
}
.icon {
  margin-left: space(1);
}
.text-input-error {
  border-radius: 2px;
  border: 1px solid colors.$border-color-attention;
  background: colors.$background-color-danger;
}
.input-error {
  margin-top: space(1);
}
</style>
<template>
  <Table>
    <TableRow>
      <TableCell :separatorable="false" :class="css['table-cell']">
        <Typography :class="css.title"
          >金融機関コード
          <MustLabel />
        </Typography>
      </TableCell>
      <TableCell :separatorable="false">
        <div :class="css.col">
          <AccountNumberInput
            v-model="states.bankAccountData.bankCode"
            name="bankCode"
            placeholder="0123"
            :maxlength="4"
            width="96px"
          >
            <template #link>
              <Link href="https://zengin.ajtw.net/" :target-blank="true"
                >金融機関コードを調べる
                <Icon type="external-link-alt" :class="css.icon" />
              </Link>
            </template>
          </AccountNumberInput>
        </div>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell :separatorable="false" :class="css['table-cell']">
        <Typography :class="css.title">銀行名 <MustLabel /></Typography>
      </TableCell>
      <TableCell :separatorable="false">
        <AccountTextInput
          v-model="states.bankAccountData.bankName"
          name="bankName"
          placeholder="うるる銀行"
          :maxlength="50"
          width="256px"
        >
        </AccountTextInput>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell :separatorable="false" :class="css['table-cell']">
        <Typography :class="css.title"
          >支店番号
          <MustLabel />
        </Typography>
      </TableCell>
      <TableCell :separatorable="false">
        <div :class="css.col">
          <AccountNumberInput
            v-model="states.bankAccountData.branchNumber"
            name="branchNumber"
            placeholder="012"
            :maxlength="3"
            width="96px"
          >
            <template #link>
              <Link href="https://zengin.ajtw.net/" :target-blank="true"
                >支店番号を調べる
                <Icon type="external-link-alt" :class="css.icon" />
              </Link>
            </template>
          </AccountNumberInput>
        </div>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell :separatorable="false" :class="css['table-cell']">
        <Typography :class="css.title"
          >支店名
          <MustLabel />
        </Typography>
      </TableCell>
      <TableCell :separatorable="false">
        <AccountTextInput
          v-model="states.bankAccountData.branchName"
          name="branchName"
          placeholder="えんフォト支店"
          :maxlength="50"
          width="256px"
        >
        </AccountTextInput>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell :separatorable="false" :class="css['table-cell']">
        <Typography :class="css.title">口座種類 <MustLabel /></Typography>
      </TableCell>
      <TableCell :separatorable="false">
        <div :class="css['radio-group']">
          <RadioWithLabel
            v-model:selected-radio-value="states.bankAccountData.accountType"
            v-model:radio-value="states.savingAccount"
            name="accountType"
            >普通</RadioWithLabel
          >
          <RadioWithLabel
            v-model:selected-radio-value="states.bankAccountData.accountType"
            v-model:radio-value="states.checkingAccount"
            name="accountType"
            >当座</RadioWithLabel
          >
        </div>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell :separatorable="false" :class="css['table-cell']">
        <Typography :class="css.title"
          >口座番号（7桁） <MustLabel
        /></Typography>
      </TableCell>
      <TableCell :separatorable="false">
        <AccountNumberInput
          v-model="states.bankAccountData.accountNumber"
          name="accountNumber"
          placeholder="1234567"
          :maxlength="7"
          width="96px"
        >
        </AccountNumberInput>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell :separatorable="false" :class="css['table-cell']">
        <Typography :class="css.title"
          >口座名義（カタカナ）
          <MustLabel />
        </Typography>
      </TableCell>
      <TableCell :separatorable="false">
        <AccountTextInput
          v-model="states.bankAccountData.accountHolder"
          name="accountHolder"
          placeholder="ウルルタロウ"
          :maxlength="250"
          width="256px"
          type="account-holder"
        >
        </AccountTextInput>
      </TableCell>
    </TableRow>
  </Table>
</template>
