<script setup lang="ts">
import DetailTable from './Table/index.vue'
import { photographerConstants } from '@/utils/constants/photographerConstants'
import { DetailProps, useDetailStates } from '.'

interface IProps {
  notes: DetailProps['notes']
  details: DetailProps['details']
  gasUnitPrice: DetailProps['gasUnitPrice']
  subTotal: DetailProps['subTotal']
  taxAmount: DetailProps['taxAmount']
  totalAmount: DetailProps['totalAmount']
}
const props = defineProps<IProps>()
const states = useDetailStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/font' as font-mixins;

.container {
  color: colors.$font-color-primary;
}
.title {
  font-size: sizes.$font-size-contentbox-title2;
  @include font-mixins.font-weight-bold;
  margin-bottom: space(6);
}
.table {
  margin-bottom: space(8);
}
.bottom {
  display: flex;
  justify-content: space-between;
}
.notes {
  width: 687px;
  min-height: 114px;
  box-sizing: border-box;
  padding: space(4);
  border: 1px solid colors.$input-color-main-border;
  border-radius: 2px;
}
.notes-title {
  font-size: sizes.$font-size-xl;
  line-height: 26px;
  @include font-mixins.font-weight-bold;
  margin-bottom: space(4);
}
.notes-content {
  font-size: sizes.$font-size-md;
  line-height: 14px;
  color: colors.$font-color-gray;
  word-wrap: break-word;
}
.amount {
  width: 289px;
  height: 114px;
  box-sizing: border-box;
  background-color: colors.$background-color-gray;
  font-size: sizes.$font-size-md;
  line-height: 20px;
  padding: space(4);
  > div {
    display: flex;
    justify-content: space-between;
  }
}
.total-amount {
  @include font-mixins.font-weight-bold;
}
.unit {
  margin-left: space(2);
}
</style>

<template>
  <div :class="css.container">
    <div :class="css.title">●請求内訳</div>
    <DetailTable
      :details="props.details"
      :gas-unit-price="props.gasUnitPrice"
      :class="css.table"
    ></DetailTable>
    <div :class="css.bottom">
      <div :class="css.notes">
        <div :class="css['notes-title']">変更・修正に関するメッセージ</div>
        <div :class="css['notes-content']">{{ props.notes }}</div>
      </div>
      <div :class="css.amount">
        <div>
          <div>小計</div>
          <div>
            {{ states.displaySubTotal }}<span :class="css.unit">円</span>
          </div>
        </div>
        <div>
          <div>適用税率</div>
          <div>
            {{ photographerConstants.CONSUMPTION_TAX_RATE * 100
            }}<span :class="css.unit">%</span>
          </div>
        </div>
        <div>
          <div>消費税額</div>
          <div>
            {{ states.displayTaxAmount }}<span :class="css.unit">円</span>
          </div>
        </div>
        <div :class="css['total-amount']">
          <div>税額総額</div>
          <div>
            {{ states.displayTotalAmount }}<span :class="css.unit">円</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
