import { Computable } from '@/types/utils'
import { reactive } from 'vue'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { BankAccountInfo } from '../../organisms/pageContent/AccountPageContent/BankAccountInfo'
import { BankAccountApi } from '@/domains/api/account/BankAccountApi'
import { onCreate } from '@/utils/functions/lifecycle'
import { useRouter } from 'vue-router'

export interface IAccountStates {
  breadcrumbs: IBreadcrumb[]
  bankAccountInfo: BankAccountInfo
}

export const useAccountEditBankStates = () => {
  return reactive<Computable<IAccountStates>>({
    breadcrumbs: [
      {
        label: 'ホーム',
        location: { name: photographerRoutes.NAME.TOP },
      },
      {
        label: 'アカウント',
        location: { name: photographerRoutes.NAME.ACCOUNT },
      },
      {
        label: '振込先編集',
      },
    ],
    bankAccountInfo: {
      bankCode: '',
      bankName: '',
      branchNumber: '',
      branchName: '',
      accountType: 1,
      accountNumber: '',
      accountHolder: '',
    },
  })
}

const createBankAccountParams = (bankAccountInfo: BankAccountInfo) => {
  return {
    bank_code: bankAccountInfo.bankCode,
    bank_name: bankAccountInfo.bankName,
    branch_number: bankAccountInfo.branchNumber,
    branch_name: bankAccountInfo.branchName,
    account_type: bankAccountInfo.accountType,
    account_number: bankAccountInfo.accountNumber,
    account_holder: bankAccountInfo.accountHolder,
  }
}

export const useAccountEditBankActions = (states: IAccountStates) => {
  const router = useRouter()
  const bankAccountApi = new BankAccountApi()
  const createInitializer = async () => {
    states.bankAccountInfo = await bankAccountApi.fetchBankAccount()
  }
  onCreate(async () => {
    await createInitializer()
  })
  const putBankAccount = async (bankAccountInfo: BankAccountInfo) => {
    const bankAccountParams = createBankAccountParams(bankAccountInfo)
    await bankAccountApi.putBankAccount(bankAccountParams)
    await router.push({
      name: photographerRoutes.NAME.ACCOUNT,
    })
  }
  return {
    async onUpdateBankAccountInfo(bankAccountInfo: BankAccountInfo) {
      await putBankAccount(bankAccountInfo)
    },
  }
}
