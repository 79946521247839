<script setup lang="ts">
import { defineProps } from 'vue'
import Tab from '@/vueComponents/photographer/molecules/Tab/index.vue'
import MatterRequestTable from '@/vueComponents/photographer/organisms/section/MatterRequestTable/index.vue'
import EmptyMassage from '@/vueComponents/photographer/organisms/section/EmptyMassage/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import { FiscalYearTabProps, useFiscalYearTabStates } from '.'

interface IProps {
  matterRequestsByYear: FiscalYearTabProps['matterRequestsByYear']
}
const props = defineProps<IProps>()
const states = useFiscalYearTabStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.container {
  padding: space(8);
  min-height: 280px;
}
.tab-container {
  padding: space(8);
  min-height: 324px;
}
</style>

<template>
  <ContentBox
    v-if="props.matterRequestsByYear.length > 1"
    :class="css['tab-container']"
  >
    <Tab :tab-items="states.tabItems" tab-item-type="link">
      <template
        v-for="(matterRequestByYear, index) in props.matterRequestsByYear"
        :key="index"
        #[index+1]
      >
        <EmptyMassage
          v-if="!matterRequestByYear.matterRequest[0]"
          height="280px" />
        <MatterRequestTable
          v-else
          :matter-requests="matterRequestByYear.matterRequest"
        ></MatterRequestTable
      ></template>
    </Tab>
  </ContentBox>
  <ContentBox v-else :class="css.container">
    <EmptyMassage v-if="!props.matterRequestsByYear[0]" height="280px" />
    <MatterRequestTable
      v-else
      :matter-requests="props.matterRequestsByYear[0].matterRequest"
    ></MatterRequestTable>
  </ContentBox>
</template>
