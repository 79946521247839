import { Emits } from '@/types/utils'
import { InvoicesStates } from '@/vueComponents/photographer/pages/Invoices'

export type InvoicesPageContentProps = InvoicesStates

export type InvoicesPageContentEmitsParams = {
  'download:pdf': number
  'invoice:create:transition': void
}

type InvoicesPageContentActions = {
  downloadPdf: (submittedId: number) => void
  invoiceCreateTransition: () => void
}

export const useInvoicesPageContentActions = (
  emits: Emits<InvoicesPageContentEmitsParams>
): InvoicesPageContentActions => {
  return {
    downloadPdf: (submittedId: number) => {
      emits('download:pdf', submittedId)
    },
    invoiceCreateTransition: () => {
      emits('invoice:create:transition')
    },
  }
}
