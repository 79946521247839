import { Emits } from '@/types/utils'
import { ScheduleReplyStatus } from '@/utils/constants/enums/photographer/schedule'

export type ScheduleRegistrationButtonProps = {
  id: number
  activeId: number
  disabled?: boolean
  type: 'cross' | 'circle'
}

export type ScheduleRegistrationButtonParams = {
  'update:activeId': ScheduleRegistrationButtonProps['id']
}

export const useScheduleRegistrationButtonActions = (
  props: ScheduleRegistrationButtonProps,
  emits: Emits<ScheduleRegistrationButtonParams>
) => ({
  onClick() {
    if (props.disabled) return
    props.id !== props.activeId
      ? emits('update:activeId', props.id)
      : emits('update:activeId', ScheduleReplyStatus.UNSELECTED)
  },
})
