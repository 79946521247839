<script setup lang="ts">
import { defineProps } from 'vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import { CompleteProps, useCompleteActions, useCompleteStates } from '.'
import { photographerRoutes } from '@/vue/routers/photographer/routes'

interface IProps {
  month: CompleteProps['month']
}
const props = defineProps<IProps>()
const states = useCompleteStates()
const actions = useCompleteActions()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/mixins/font' as font-mixins;
@use '@/styles/sizes' as sizes;
@use '@/styles/colors' as colors;

.container {
  margin-top: space(10);
  text-align: center;
  padding: space(8) space(8) space(25);
  @include font-mixins.font-weight-medium;
  color: colors.$font-color-primary;
}
.img {
  display: block;
  width: 170px;
  height: 130px;
  margin: auto;
}
.title {
  margin-top: space(6);
  font-size: sizes.$font-size-contentbox-title2;
}
.message {
  margin-top: space(6);
  line-height: 20px;
}
.remarks {
  margin-top: space(6);
  font-size: sizes.$font-size-sm;
  line-height: 17px;
}
.separator {
  @include space-mixins.vertical-margin(8, 8);
}
.bottom {
  @include position-mixins.flex-center;
  flex-direction: column;
}
.to-home {
  margin-top: space(6);
}
.to-home-text {
  font-size: sizes.$font-size-lg;
  color: colors.$font-color-primary;
}
</style>

<template>
  <ContentBox :class="css.container">
    <img :class="css.img" :src="states.imgPath" alt="" />
    <div :class="css.title">お疲れ様でした！</div>
    <div :class="css.message">
      {{ props.month }}月分の請求書の送信が完了しました。<br />
      修正がある場合はご連絡いたします。<br />
      引き続き、よろしくお願いいたします。
    </div>
    <div :class="css.remarks">
      ※請求書は、7年分保存されます。<br />
      それ以降を過ぎますと自動的に削除されますのでご注意ください。
    </div>
    <Separator :class="css.separator" />
    <div :class="css.bottom">
      <Button width="288px" color="secondary" @click="actions.toInvoices"
        >送信履歴一覧へ</Button
      >
      <Link
        type="footer"
        :location="{ name: photographerRoutes.NAME.TOP }"
        :class="css['to-home']"
      >
        <span :class="css['to-home-text']">ホームへ</span>
      </Link>
    </div>
  </ContentBox>
</template>
