import { RouteLocationRaw, useRouter } from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { IInvoiceRequest } from '@/types/photographer/invoice'
import { Computable } from '@/types/utils'
import { reactive } from 'vue'
import {
  parseJaDateYearToString,
  parseStringDateDayToDate,
} from '@/utils/functions/parser'

// 1日のミリ秒数
const MILLI_SECOND_PER_DAY = 1000 * 60 * 60 * 24

// 提出期限切れとなる日
const EXPIRY_DATE = 3

// 提出期限切れとなる日の何日前からカウントダウンが開始されるかを設定する
const COUNTDOWN_DATE = 3

export type InvoiceRequestTableRowProps = {
  invoiceRequest: IInvoiceRequest
}

type InvoiceRequestTableRowState = {
  formattedDate: string
  submissionStatus: string
  invoiceRequestText: string
  submissionStatusTypographyType:
    | 'read-normal'
    | 'read-small-disabled'
    | 'read-small-attention'
}

// 今日が提出期限を過ぎているか
const isTodayPassedDeadline = (today: Date, expiryDateObject: Date) => {
  return expiryDateObject.getTime() <= today.getTime()
}

// 今日が提出期限のカウントダウンされる日か
const isTodayCountdownDay = (today: Date, expiryDateObject: Date) => {
  const cloneExpiryDate = new Date(expiryDateObject.getTime())
  cloneExpiryDate.setDate(cloneExpiryDate.getDate() - COUNTDOWN_DATE)
  return today.getTime() >= cloneExpiryDate.getTime()
}

// 提出期限までの日数を取得
const getDiffExpiryDateToday = (today: Date, expiryDateObject: Date) => {
  // カウントダウン表示は7/31 あと2日、8/1 あと1日、8/2 あと0日のように表示するため、提出期限日 - (今日の日付 + 1日)で計算
  today.setDate(today.getDate() + 1)
  const diffExpiryDateTodayTime = expiryDateObject.getTime() - today.getTime()
  return Math.ceil(diffExpiryDateTodayTime / MILLI_SECOND_PER_DAY)
}

const handleSubmissionStatus = (props: InvoiceRequestTableRowProps) => {
  const { isSubmittedFlg, revisionRequestFlg, requestedDate } =
    props.invoiceRequest
  const today = new Date()
  const parsedRequestedDate = parseStringDateDayToDate(requestedDate)
  const expiryDateObject = new Date(
    parsedRequestedDate.getFullYear(),
    parsedRequestedDate.getMonth() + 1,
    EXPIRY_DATE
  )
  if (revisionRequestFlg) return ''
  if (isSubmittedFlg) return '【提出済み】'
  if (isTodayPassedDeadline(today, expiryDateObject)) return '【提出期限切れ】'
  if (isTodayCountdownDay(today, expiryDateObject)) {
    return `【提出日：あと${getDiffExpiryDateToday(
      today,
      expiryDateObject
    )}日】`
  }
  return ''
}

const handleSubmissionStatusTypographyType = (
  invoiceRequest: InvoiceRequestTableRowProps['invoiceRequest']
) => {
  const { isSubmittedFlg, revisionRequestFlg } = invoiceRequest
  if (revisionRequestFlg) return 'read-normal'
  if (isSubmittedFlg) return 'read-small-disabled'
  if (!isSubmittedFlg) return 'read-small-attention'
  return 'read-normal'
}

const handleInvoiceRequestText = (
  invoiceRequest: InvoiceRequestTableRowProps['invoiceRequest']
) => {
  const { year, month, revisionRequestFlg } = invoiceRequest
  const revisionOrRequestText = revisionRequestFlg ? '修正' : '提出'
  return `【請求書依頼】${year}年度 ${month}月分の請求書${revisionOrRequestText}のご依頼`
}

export const useInvoiceRequestTableRowState = (
  props: InvoiceRequestTableRowProps
) =>
  reactive<Computable<InvoiceRequestTableRowState>>({
    formattedDate: parseJaDateYearToString(
      parseStringDateDayToDate(props.invoiceRequest.requestedDate)
    ),
    invoiceRequestText: handleInvoiceRequestText(props.invoiceRequest),
    submissionStatus: handleSubmissionStatus(props),
    submissionStatusTypographyType: handleSubmissionStatusTypographyType(
      props.invoiceRequest
    ),
  })

export const useInvoiceRequestTableRowActions = (
  props: InvoiceRequestTableRowProps
) => {
  const router = useRouter()

  const invoicesPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.INVOICES,
  }

  return {
    async toInvoicesPage(): Promise<void> {
      if (props.invoiceRequest.isSubmittedFlg) {
        invoicesPageLocation.hash = '#table'
      }
      await router.push({
        ...invoicesPageLocation,
      })
      await router.push(invoicesPageLocation)
    },
  }
}
