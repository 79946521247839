<script setup lang="ts">
import { defineProps } from 'vue'
import AccountPageTable from '@/vueComponents/photographer/organisms/pageContent/AccountPageContent/AccountPageTable/index.vue'
import { useAccountInfoStates, AccountInfoProps } from '.'

interface IProps {
  company: AccountInfoProps['company']
  account: AccountInfoProps['account']
}

const props = defineProps<IProps>()

const states = useAccountInfoStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/font' as font-mixins;

.table-account-info-list {
  margin-top: space(12);
}
</style>

<template>
  <div>
    <AccountPageTable :group="states.companyInfoList" />
    <AccountPageTable
      :group="states.accountInfoList"
      :class="css['table-account-info-list']"
    />
  </div>
</template>
