<script setup lang="ts">
import { defineProps, withDefaults } from 'vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import FolderNameSettingModal from '../FolderNameSettingModal/index.vue'
import {
  IFolderNameChangeLinkProps,
  useFolderNameChangeLinkActions,
  useFolderNameChangeLinkStates,
} from '.'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'

interface IProps {
  disabled?: IFolderNameChangeLinkProps['disabled']
  eventDate: IFolderNameChangeLinkProps['eventDate']
  eventName: IFolderNameChangeLinkProps['eventName']
  folderName: IFolderNameChangeLinkProps['folderName']
  onSubmit: IFolderNameChangeLinkProps['onSubmit']
}

const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
})

const states = useFolderNameChangeLinkStates()

const actions = useFolderNameChangeLinkActions(states)
</script>

<style lang="scss" module="css" scoped>
.container {
  white-space: nowrap;
}
.link {
  &-disabled {
    cursor: not-allowed;
  }
}
</style>

<template>
  <div
    :class="{ [css.container]: true, [css['link-disabled']]: props.disabled }"
  >
    <template v-if="!props.disabled">
      <Link type="edit" display="block" @click="actions.openModal"
        >[フォルダ名の変更]</Link
      >
    </template>
    <template v-else>
      <Typography type="read-normal-disabled">[編集不可]</Typography>
    </template>
  </div>
  <FolderNameSettingModal
    v-model:shown="states.isModalOpened"
    type="update"
    :event-date="props.eventDate"
    :event-name="props.eventName"
    :folder-name="props.folderName"
    :on-submit="props.onSubmit"
  />
</template>
