import { IEvent, IEventDetail } from '@/types/photographer/event'
import {
  EventStatusCode,
  EventTypeCode,
} from '@/utils/constants/enums/photographer/event'
import { BaseApi } from '../BaseApi'

const PATH = {
  EVENTS: '/photographer/api/v1.0/events',
  EVENT: '/photographer/api/v1.0/events/:dispatch_id',
} as const

interface IFetchEventsResponse {
  dispatch_id: number
  seller_name: string
  event_name: string
  start_date: string
  end_date: string
  type: EventTypeCode
  type_normal_start_date: string
  delivery_deadline: string
  status: EventStatusCode
  inspect_memo: string
}

interface IFetchEventResponse extends IFetchEventsResponse {
  photo_children_number: number
  venue_name: string
  venue_zipcode: number
  venue_address: string
  meeting_point_name: string
  meeting_point_zipcode: number
  meeting_point_address: string
  meeting_point_time: string
}

export class EventsApi extends BaseApi {
  /**
   * イベント一覧情報取得
   * @returns イベント一覧情報
   */
  public async fetchEvents(): Promise<IEvent[]> {
    const { data } = await this.get<IFetchEventsResponse[]>(PATH.EVENTS)
    return data.map((event) => ({
      dispatchId: event.dispatch_id,
      sellerName: event.seller_name,
      eventName: event.event_name,
      startDate: new Date(event.start_date),
      endDate: new Date(event.end_date),
      type: event.type,
      typeNormalStartDate: new Date(event.type_normal_start_date),
      deliveryDeadline: new Date(event.delivery_deadline),
      status: event.status,
    }))
  }

  /**
   * イベント詳細情報取得
   * @param dispatch_id 派遣ID
   * @returns イベント詳細情報取得
   */
  public async fetchEvent(dispatch_id: number): Promise<IEventDetail> {
    const { data } = await this.get<IFetchEventResponse>(PATH.EVENT, {
      dispatch_id,
    })
    return {
      dispatchId: data.dispatch_id,
      eventTitle: { sellerName: data.seller_name, eventName: data.event_name },
      eventInfo: {
        startDate: new Date(data.start_date),
        endDate: new Date(data.end_date),
        type: data.type,
        typeNormalStartDate: new Date(data.type_normal_start_date),
        deliveryDeadline: new Date(data.delivery_deadline),
        status: data.status,
        inspectMemo: data.inspect_memo,
        photoChildrenNumber: data.photo_children_number,
        venueName: data.venue_name,
        venueZipcode: data.venue_zipcode,
        venueAddress: data.venue_address,
        meetingPointName: data.meeting_point_name,
        meetingPointZipcode: data.meeting_point_zipcode,
        meetingPointAddress: data.meeting_point_address,
        meetingPointTime: data.meeting_point_time,
      },
    }
  }
}
