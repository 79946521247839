<script setup lang="ts"></script>

<style lang="scss" module="css" scoped>
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/space' as space-mixins;
.text {
  font-size: sizes.$font-size-md;
  line-height: 26px;
  margin-top: 7px;
  margin-bottom: 10px;
}
</style>

<template>
  <p :class="css.text">
    該当ログインIDの連絡用メールアドレスに再設定の手続きのご案内を送信しました。
  </p>
</template>
