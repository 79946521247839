<script setup lang="ts">
import { defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { MESSAGES } from '@/utils/constants/messages'
import {
  NotificationsPageContentProps,
  useNotificationsPageContentStates,
} from '.'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import NotificationsTab from '@/vueComponents/photographer/organisms/pageContent/NotificationsPageContent/NotificationsTab/index.vue'

interface IProps {
  matterRequests: NotificationsPageContentProps['matterRequests']
  events: NotificationsPageContentProps['events']
  invoiceRequests: NotificationsPageContentProps['invoiceRequests']
  invoiceGenerationFlg: NotificationsPageContentProps['invoiceGenerationFlg']
}
const props = defineProps<IProps>()
const states = useNotificationsPageContentStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.h1 {
  @include space-mixins.vertical-margin(10);
}
.bread-crumbs {
  @include space-mixins.vertical-margin(6, 6);
}
</style>

<template>
  <Typography :class="css.h1" type="h1">{{
    MESSAGES.PHOTOGRAPHER.PAGE_TITLE.NOTIFICATIONS
  }}</Typography>
  <PageBreadcrumbs
    :class="css['bread-crumbs']"
    :breadcrumbs="states.breadcrumbs"
  />
  <NotificationsTab
    :matter-requests-by-year="states.matterRequestsByYear"
    :inspection-delivery-requests="states.inspectionDeliveryRequests"
    :invoice-requests="props.invoiceRequests"
    :invoice-generation-flg="props.invoiceGenerationFlg"
  />
</template>
