import { FolderEntity } from '@/entities/photographer/FolderEntity'
import { Promisable } from '@/types/utils'
import { UploadingModalStatus } from '@/utils/constants/enums/photographer/folder'
import { ValidationCode } from '@/utils/constants/enums/validation'
import { uniformizeFileNames } from '@/utils/functions/file'
import { validateFile } from '@/utils/functions/validation'
import { NetworkError } from '@/utils/modules/exceptions/NetworkError'
import { IPhotoUploaderStates } from '..'
import { UploadingPhoto } from '../UploadingPhoto'
import { upload } from './upload'
import { PhotoUploadStatus } from '@/utils/constants/enums/photographer/photo'

export type UploadPayload = {
  files: File[]
  folderEntity: FolderEntity
  fileUploader: (uploadingPhoto: UploadingPhoto) => Promisable<void>
  fileStatusUpdater: (
    photoIds: number[],
    uploadingPhotos: UploadingPhoto[]
  ) => Promisable<void>
  fileFailedPhotosStatusUpdater: () => Promise<boolean>
  filesDeleter: (photoIds: number[]) => Promisable<void>
  states: IPhotoUploaderStates
}

/**
 * アップロード中情報の初期化
 * @param payload ペイロードデータ
 */
const initialize = (payload: UploadPayload): void => {
  const { states } = payload
  states.uploadingPhotos.clear()
  states.isModalShown = true
  states.uploadStatus = UploadingModalStatus.UPLOADING
  states.failedCount = 0
}

/**
 * ファイル数超過判定
 * @param payload ペイロードデータ
 * @returns true:アップロード可 false:アップロード不可
 */
const checkExcess = (payload: UploadPayload): boolean => {
  const { folderEntity, files, states } = payload
  if (!folderEntity.isUploadingCountExceeded(files.length)) return true
  states.uploadStatus = UploadingModalStatus.FILE_COUNT_EXCEEDED
  return false
}

/**
 * 名称重複画像IDの絞り込み
 * @param payload ペイロードデータ
 * @returns 画像IDリスト
 */
const filterDuplicatedPhotoIds = (payload: UploadPayload) => {
  const { files, folderEntity } = payload
  const uploadingFileNames = files.map(({ name }) => name.toLowerCase())
  return [...folderEntity.allUploadedPhotos]
    .filter(([, { orgImgName }]) =>
      uploadingFileNames.includes(orgImgName.toLowerCase())
    )
    .map(([id]) => id)
}

/**
 * 名称重複ファイルの削除
 * @param payload ペイロードデータ
 * @param photoIds 削除対象写真ID
 */
const deleteDuplicatedFiles = async (
  payload: UploadPayload,
  photoIds: number[]
): Promise<void> => {
  const { folderEntity, filesDeleter } = payload
  await filesDeleter(photoIds)
  folderEntity.deleteUploadedPhotos(photoIds)
}

/**
 * ファイル名重複判定
 * @param payload ペイロードデータ
 * @returns true:アップロード可 false:アップロード不可
 */
const checkDuplication = async (payload: UploadPayload): Promise<boolean> => {
  const { states, files } = payload
  const uniformedFiles = uniformizeFileNames(files)
  const duplicatedFileCount = files.length - uniformedFiles.length
  const duplicatedPhotoIds = filterDuplicatedPhotoIds(payload)
  states.duplicatedCount = duplicatedFileCount + duplicatedPhotoIds.length
  if (states.duplicatedCount === 0) return true
  const overwriteFlg = await new Promise<boolean>((resolve) => {
    states.duplicationResolver = resolve
    states.uploadStatus = UploadingModalStatus.DUPLICATING
  })
  if (overwriteFlg) {
    payload.files = uniformedFiles
    await deleteDuplicatedFiles(payload, duplicatedPhotoIds)
    states.uploadStatus = UploadingModalStatus.UPLOADING
    return true
  }
  states.uploadStatus = UploadingModalStatus.CANCELLED
  states.isModalShown = false
  return false
}

/**
 * 複数ファイルのバリデーションとステートへの保存
 * @param payload ペイロードデータ
 * @returns true:アップロード可 false:アップロード不可
 */
const validateAndStore = async (payload: UploadPayload): Promise<boolean> => {
  const { files, states } = payload
  const validator = async (file: File): Promise<ValidationCode> => {
    const { validationCode, height, width } = await validateFile(file)
    if (validationCode !== ValidationCode.SUCCESS) return validationCode
    const uploadingPhoto = new UploadingPhoto(file, height, width)
    states.uploadingPhotos.add(uploadingPhoto)
    return ValidationCode.SUCCESS
  }
  states.failedCount = (await Promise.all(files.map(validator))).filter(
    (validationCode) => validationCode !== ValidationCode.SUCCESS
  ).length
  if (states.failedCount > 0) {
    states.uploadStatus = UploadingModalStatus.ILLEGAL
    return false
  }
  return true
}

/**
 * アップロード完了ファイルをアップロード済または失敗ストアへ分配
 * @param payload ペイロードデータ
 */
const distribute = (payload: UploadPayload): void => {
  const { folderEntity, states } = payload
  let failedCount = 0
  states.uploadingPhotos.forEach((photo) => {
    if (photo.hasError && photo.status !== PhotoUploadStatus.ERROR_TIMEOUT)
      failedCount++
    folderEntity.distributeUploadedPhoto(photo)
  })
  states.uploadingPhotos.clear()
  if (failedCount > 0) {
    states.failedCount = failedCount
    states.uploadStatus = UploadingModalStatus.PARTIALLY_FAILED
    return
  }
  states.uploadStatus = UploadingModalStatus.SUCCEEDED
}

/**
 * 複数ファイルのアップロード
 * @param payload
 */
export const uploadFiles = async (payload: UploadPayload): Promise<void> => {
  initialize(payload)
  if (!checkExcess(payload)) return
  if (!(await checkDuplication(payload))) return
  if (!(await validateAndStore(payload))) return
  try {
    if (!(await upload(payload))) return
  } catch (e) {
    if (e instanceof NetworkError) {
      payload.states.uploadStatus = UploadingModalStatus.NETWORK_ERROR
      return
    }
    throw e
  }
  distribute(payload)
}
