<script setup lang="ts">
import ScheduleContent from '@/vueComponents/photographer/organisms/pageContent/ScheduleContent/index.vue'
import { useScheduleStates, useScheduleActions } from '.'

const states = useScheduleStates()
const actions = useScheduleActions(states)
</script>

<template>
  <ScheduleContent
    :schedule-month="states.scheduleMonth"
    :schedule-reply-data="states.scheduleReplyData"
    :on-save="actions.onSave"
  />
</template>
