import {
  FetchDetails,
  FetchStatus,
  FetchExpenses,
  FetchOriginalFile,
  FetchSubmittedHistories,
  FetchReceiptUploadUrl,
  FetchInvoiceUrl,
} from '@/types/photographer/invoice'
import { BaseApi } from '../BaseApi'
import { InvoiceStatus } from '@/utils/constants/enums/photographer/invoice'
import { parseDateYearToHyphenString } from '@/utils/functions/parser'

const PATH = {
  STATUS: '/photographer/api/v1.0/invoices/status',
  DETAILS: 'photographer/api/v1.0/invoices/details',
  EXPENSES: 'photographer/api/v1.0/invoices/expenses',
  ORIGINAL_FILE:
    'photographer/api/v1.0/invoices/expenses/:expense_id/original_file',
  INVOICES: 'photographer/api/v1.0/invoices',
  SUBMITTED_HISTORIES: 'photographer/api/v1.0/invoices/submitted_histories',
  RECEIPTS_UPLOAD_URL:
    'photographer/api/v1.0/invoices/expenses/receipts/upload_url',
  INVOICE_DOWNLOAD:
    'photographer/api/v1.0/invoices/submitted_histories/:submitted_id/download',
  SUBMIT: 'photographer/api/v1.0/invoices/submit',
} as const

type IFetchStatusResponse = {
  month: number
  status: InvoiceStatus
  revision_message: string
}

type IFetchDetails = {
  id: number | null
  date: string
  content: string
  amount: number
  mileage: number | null
  can_edit: boolean
  dispatch_cameraman_id: number | null
}

type IFetchDetailsResponse = {
  note: string
  gas_unit_price: number
  details: IFetchDetails[]
}

type IFetchExpenses = {
  id: number
  date: string
  content: string
  amount: number
  file_name: string | null
  original_file_name: string | null
}

type IFetchExpensesResponse = IFetchExpenses[]

type IFetchOriginalFileResponse = {
  original_file_url: string
}

type IFetchSubmittedHistoryResponse = {
  file_name: string
  submitted_date: string
  submitted_id: number
}

type IFetchSubmittedHistoriesResponse = IFetchSubmittedHistoryResponse[]

type IFetchReceiptUploadUrlResponse = {
  presigned_url: string
  file_name: string
}

type IFetchInvoiceUrlResponse = {
  pdf_url: string
}

export type IPostInvoicesRequestDetail = {
  dispatch_cameraman_id: number | null
  id: number | null
  date: string
  content: string
  amount: number
  mileage: number | null
}

export type IPostInvoicesRequestExpense = {
  id: number | null
  date: string
  amount: number
  content: string
  file_name: string | null
  original_file_name: string | null
}

type IPostInvoicesRequest = {
  note: string
  details: IPostInvoicesRequestDetail[]
  expenses: IPostInvoicesRequestExpense[]
}

export class InvoiceApi extends BaseApi {
  /**
   * 請求書ステータス取得
   * @returns 請求書ステータス
   */
  public async fetchStatus(): Promise<FetchStatus> {
    const { data } = await this.get<IFetchStatusResponse>(PATH.STATUS)
    return {
      month: data.month,
      status: data.status,
      revisionMessage: data.revision_message,
    }
  }

  /**
   * 請求内訳取得
   * @returns 請求内訳
   */
  public async fetchDetails(): Promise<FetchDetails> {
    const { data } = await this.get<IFetchDetailsResponse>(PATH.DETAILS)
    const details = data.details.map((detail: IFetchDetails) => {
      return {
        id: detail.id,
        date: parseDateYearToHyphenString(new Date(detail.date)),
        content: detail.content,
        amount: detail.amount,
        mileage: detail.mileage,
        canEdit: detail.can_edit,
        dispatchCameramanId: detail.dispatch_cameraman_id,
      }
    })
    return {
      note: data.note,
      gasUnitPrice: data.gas_unit_price,
      details,
    }
  }

  /**
   * 諸経費取得
   * @returns 諸経費一覧
   */
  public async fetchExpenses(): Promise<FetchExpenses> {
    const { data } = await this.get<IFetchExpensesResponse>(PATH.EXPENSES)
    const expenses = data.map((expense: IFetchExpenses) => {
      return {
        id: expense.id,
        date: parseDateYearToHyphenString(new Date(expense.date)),
        content: expense.content,
        amount: expense.amount,
        fileName: expense.file_name,
        originalFileName: expense.original_file_name,
      }
    })
    return expenses
  }

  /**
   * 領収書オリジナルファイル取得
   * @return url
   */
  public async fetchOriginalFile(
    expenseId: number
  ): Promise<FetchOriginalFile> {
    const { data } = await this.get<IFetchOriginalFileResponse>(
      PATH.ORIGINAL_FILE,
      {
        expense_id: expenseId,
      }
    )
    return {
      originalFileUrl: data.original_file_url,
    }
  }

  /**
   * 請求書登録更新
   * @param payload 請求書登録更新情報
   */
  public async postInvoices(payload: IPostInvoicesRequest): Promise<void> {
    await this.post<IPostInvoicesRequest>(PATH.INVOICES, payload)
  }

  /**
   * 請求書送信履歴一覧取得
   * @returns 請求書送信履歴一覧
   * */
  public async fetchSubmittedHistories(): Promise<FetchSubmittedHistories> {
    const { data } = await this.get<IFetchSubmittedHistoriesResponse>(
      PATH.SUBMITTED_HISTORIES
    )
    const submittedHistories = data.map(
      (data: IFetchSubmittedHistoryResponse) => {
        return {
          fileName: data.file_name,
          submittedDate: data.submitted_date,
          submittedId: data.submitted_id,
        }
      }
    )
    return submittedHistories
  }

  /**
   * 領収アップロード用署名付きURL取得
   * @param originalFileName オリジナルファイル名
   * @returns 署名つきURL,生成したファイル名
   * */
  public async fetchReceiptUploadUrl(
    originalFileName: string
  ): Promise<FetchReceiptUploadUrl> {
    const params = new URLSearchParams()
    params.append('original_file_name', originalFileName.toString())

    const { data } = await this.get<IFetchReceiptUploadUrlResponse>(
      `${PATH.RECEIPTS_UPLOAD_URL}?${params.toString()}`
    )
    return {
      presignedUrl: data.presigned_url,
      fileName: data.file_name,
    }
  }

  /*
   * 請求書PDFの取得
   * @param submittedId 提出ID
   * @returns 署名付きURL
   * */
  public async fetchInvoiceUrl(submittedId: number): Promise<FetchInvoiceUrl> {
    const { data } = await this.get<IFetchInvoiceUrlResponse>(
      PATH.INVOICE_DOWNLOAD,
      {
        submitted_id: submittedId,
      }
    )
    return {
      pdfUrl: data.pdf_url,
    }
  }

  /**
   * 請求書提出
   * */
  public async postInvoicesSubmit(): Promise<void> {
    await this.post(PATH.SUBMIT)
  }
}
