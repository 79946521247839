<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import ScheduleRegistrationButton from '@/vueComponents/photographer/molecules/ScheduleRegistrationButton/index.vue'
import {
  ScheduleRegistrationButtonsParams,
  ScheduleRegistrationButtonsProps,
  useScheduleRegistrationButtonsActions,
  useScheduleRegistrationButtonsStates,
} from '.'
import { ScheduleReplyStatus } from '@/utils/constants/enums/photographer/schedule'

interface IProps {
  scheduleReplyStatus: ScheduleRegistrationButtonsProps['scheduleReplyStatus']
  disabled?: ScheduleRegistrationButtonsProps['disabled']
}

interface IEmits {
  (
    e: 'update:scheduleReplyStatus',
    value: ScheduleRegistrationButtonsParams['update:scheduleReplyStatus']
  ): void
}

const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useScheduleRegistrationButtonsStates(props)
useScheduleRegistrationButtonsActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.container {
  display: flex;
  align-items: center;
  gap: space(2);
}
</style>

<template>
  <div :class="css.container">
    <ScheduleRegistrationButton
      :id="ScheduleReplyStatus.OK"
      v-model:activeId="states.scheduleReplyStatus"
      type="circle"
      :disabled="props.disabled"
    />
    <ScheduleRegistrationButton
      :id="ScheduleReplyStatus.NG"
      v-model:activeId="states.scheduleReplyStatus"
      type="cross"
      :disabled="props.disabled"
    />
  </div>
</template>
