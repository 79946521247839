<script setup lang="ts">
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import { useAddRowButtonStates, useAddRowButtonActions } from '.'

interface IEmits {
  (e: 'add-row'): void
}

const emits = defineEmits<IEmits>()
const states = useAddRowButtonStates()
const actions = useAddRowButtonActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;

.button {
  display: block;
}
.plus-icon {
  color: colors.$border-color-primary;
  margin-right: 4px;
}
</style>

<template>
  <div>
    <Button
      type="medium"
      color="secondary"
      width="128px"
      :class="css.button"
      :on-click="actions.addRow"
      @mouseover="actions.mouseover"
      @mouseleave="actions.mouseleave"
      ><slot>
        <span>
          <icon
            type="green-plus"
            :hover-type="states.isHover ? 'green-plus' : undefined"
            :class="css['plus-icon']"
          />
          <span>行を追加する</span>
        </span>
      </slot>
    </Button>
  </div>
</template>
