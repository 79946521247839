<script setup lang="ts">
import Head from './Head/index.vue'
import Detail from './Detail/index.vue'
import Expense from './Expense/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'

import { PreviewProps, usePreviewStates } from '.'

interface IProps {
  account: PreviewProps['account']
  notes: PreviewProps['notes']
  details: PreviewProps['details']
  gasUnitPrice: PreviewProps['gasUnitPrice']
  expenses: PreviewProps['expenses']
}
const props = defineProps<IProps>()
const states = usePreviewStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/font' as font-mixins;

.container {
  width: 1120px;
  box-sizing: border-box;
  padding: space(20) space(16);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
}
.head {
  margin-bottom: space(20);
}
.detail {
  margin-bottom: space(20);
}
</style>

<template>
  <ContentBox :class="css.container">
    <Head
      :account="props.account"
      :total-amount="states.totalAmount"
      :class="css.head"
    ></Head>
    <Detail
      :notes="props.notes"
      :details="props.details"
      :gas-unit-price="props.gasUnitPrice"
      :sub-total="states.detailSubTotal"
      :tax-amount="states.detailTaxAmount"
      :total-amount="states.detailTotalAmount"
      :class="css.detail"
    ></Detail>
    <Expense
      v-if="props.expenses.length"
      :expenses="props.expenses"
      :total-amount="states.expenseTotalAmount"
    ></Expense>
  </ContentBox>
</template>
