<script setup lang="ts">
import { defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import { IEventInfoProps, useEventInfoStates } from '.'
import { EventTypeCode } from '@/utils/constants/enums/photographer/event'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import DataList from '@/vueComponents/photographer/atoms/DataList/index.vue'
import EventInfoData from '../EventInfoData/index.vue'

interface IProps {
  eventInfo: IEventInfoProps['eventInfo']
}

const props = defineProps<IProps>()

const states = useEventInfoStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/grid' as grid-mixins;
@use '@/styles/mixins/media' as media-mixins;
@use '@/styles/mixins/position' as position-mixins;
.container {
  @include position-mixins.flex-horizontal(space(5));
  padding: space(5) space(8) space(7);
  overflow-x: auto;
}
.left {
  flex-basis: 772px;
  min-width: 360px;
}
.right {
  flex-basis: 224px;
  flex-grow: 1;
}
.event-date {
  @include position-mixins.flex-horizontal(space(4));

  &-time {
    @include position-mixins.flex-horizontal(space(1));
  }
}
.event-type {
  @include position-mixins.flex-horizontal(space(4));
}
</style>

<template>
  <ContentBox>
    <div :class="css.container">
      <div :class="css.left">
        <DataList>
          <EventInfoData icon-type="camera" term="撮影日程">
            <div :class="css['event-date']">
              <Typography>{{ states.eventDateStr }}</Typography>
              <div :class="css['event-date-time']">
                <Icon type="clock" /><Typography type="read-tiny"
                  >{{ states.eventStartTime }}~{{
                    states.eventEndTime
                  }}</Typography
                >
              </div>
            </div>
          </EventInfoData>
          <EventInfoData icon-type="event-info-clock" term="集合時間">
            <Typography>{{ props.eventInfo.meetingPointTime }}</Typography>
          </EventInfoData>
          <EventInfoData icon-type="campground" term="撮影場所">
            <div>
              <Typography>{{ props.eventInfo.venueName }}</Typography>
              <Typography>{{ states.venueAddress }}</Typography>
            </div>
          </EventInfoData>
        </DataList>
      </div>
      <div :class="css.right">
        <DataList>
          <EventInfoData icon-type="child" term="撮影園児数">
            <Typography>{{ props.eventInfo.photoChildrenNumber }}人</Typography>
          </EventInfoData>
          <EventInfoData icon-type="calendar" term="開催種別">
            <div :class="css['event-type']">
              <Typography>{{ states.eventTypeStr }}</Typography>
              <Typography
                v-if="props.eventInfo.type === EventTypeCode.POSTPONED"
                type="read-tiny"
                >通常開催 {{ states.plannedStartDateStr }}</Typography
              >
            </div>
          </EventInfoData>
          <EventInfoData icon-type="deadline" term="期日">
            <Typography>{{ states.deliveryDeadlineStr }}</Typography>
          </EventInfoData>
        </DataList>
      </div>
    </div>
  </ContentBox>
</template>
