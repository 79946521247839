<script setup lang="ts">
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import { defineProps } from 'vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import {
  InvoiceRequestTableRowProps,
  useInvoiceRequestTableRowState,
  useInvoiceRequestTableRowActions,
} from '.'

interface IProps {
  invoiceRequest: InvoiceRequestTableRowProps['invoiceRequest']
}

const props = defineProps<IProps>()
const states = useInvoiceRequestTableRowState(props)
const actions = useInvoiceRequestTableRowActions(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/mixins/position' as position-mixin;
@use '@/styles/functions/space' as *;
.detail {
  @include position-mixin.flex-horizontal(20px);
  min-height: 32px;
}
.date {
  flex-shrink: 0;
}
.text-block {
  font-size: 0;
}
.text {
  margin-right: space(5);
}
.text-inline-block {
  display: inline-block;
}
.button {
  width: 64px;
  height: 30px;
  margin-left: auto;
}
</style>

<template>
  <TableRow>
    <TableCell :separatorable="false">
      <div :class="css.detail">
        <Typography class="css.date">{{ states.formattedDate }}</Typography>
        <div :class="css['text-block']">
          <div :class="[css['text-inline-block'], css.text]">
            <Typography>
              {{ states.invoiceRequestText }}
            </Typography>
          </div>
          <Typography
            :type="states.submissionStatusTypographyType"
            :class="css['text-inline-block']"
            >{{ states.submissionStatus }}</Typography
          >
        </div>
      </div>
    </TableCell>
    <TableCell :separatorable="false">
      <div :class="css.button">
        <Button
          :on-click="actions.toInvoicesPage"
          type="medium"
          color="secondary"
          >詳細</Button
        >
      </div>
    </TableCell>
  </TableRow>
</template>
