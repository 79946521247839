<script setup lang="ts">
import { defineProps, withDefaults, defineEmits } from 'vue'
import { TabItemEmitParams, TabItemProps, useTabItemActions } from '.'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'

interface IProps {
  activeTabId: TabItemProps['activeTabId']
  tabId: TabItemProps['tabId']
  tabItem: TabItemProps['tabItem']
  type: TabItemProps['type']
  width?: TabItemProps['width']
  height?: TabItemProps['height']
  hasSeparator?: TabItemProps['hasSeparator']
}

interface IEmits {
  (
    e: 'update:activeTabId',
    tabId: TabItemEmitParams['update:activeTabId']
  ): void
}
const props = withDefaults(defineProps<IProps>(), {
  width: '160px',
  height: '40px',
  hasSeparator: false,
})
const emits = defineEmits<IEmits>()
const actions = useTabItemActions(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/hover' as hover-mixins;

$tab-width: v-bind('props.width');
$tab-height: v-bind('props.height');
.normal {
  width: $tab-width;
  height: $tab-height;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  box-sizing: border-box;
  user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  padding: space(3) space(2) space(2);
  &-active {
    padding-top: 9px;
    background-color: colors.$background-color-content-primary;
    border: 1px solid colors.$border-color-tab;
    border-top: 4px solid colors.$background-color-primary;
    border-bottom: none;
    &.has-separator {
      position: relative;
      &::after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: -1px;
        border-bottom: 1px solid colors.$border-color-white;
      }
    }
  }
  &-inactive {
    background-color: colors.$background-color-subinfo;
    border: 1px solid colors.$border-color-tab;
    border-bottom: none;
    @include hover-mixins.hover-opacity($cursor: pointer);
  }
}
.link {
  border-bottom: solid 3px colors.$background-color-primary;
  padding: space(0) space(2) space(1);
}
</style>

<template>
  <div
    v-if="props.type === 'normal'"
    :class="[
      css.normal,
      props.activeTabId === props.tabId
        ? css['normal-active']
        : css['normal-inactive'],
      { [css['has-separator']]: props.hasSeparator },
    ]"
    @click="actions.onClick"
  >
    <Typography type="head"> {{ props.tabItem }}</Typography>
  </div>
  <div v-else-if="props.type === 'link'">
    <Typography
      v-if="props.activeTabId === props.tabId"
      :class="css.link"
      type="head"
      text-align="center"
    >
      {{ props.tabItem }}</Typography
    >
    <Link
      v-else
      type="body-read-normal"
      display="block"
      @click="actions.onClick"
    >
      {{ props.tabItem }}</Link
    >
  </div>
</template>
