/**
 * 回答ステータス
 */
export const enum ReplyStatus {
  /** 未回答 */
  UNANSWERED = 1,
  /** 受諾 */
  ACCEPTANCE = 2,
  /** 引き受けない */
  REFUSE = 3,
}

/**
 * 回答可能か
 */
export const enum CanReply {
  /** 不可 */
  INADVISABLE = 0,
  /** 可 */
  ACCEPTABLE = 1,
}
