<script setup lang="ts">
import PasswordUpdateContent from '@/vueComponents/photographer/organisms/pageContent/PasswordUpdateContent/index.vue'
import { PHOTOGRAPHER } from '@/utils/constants/messages/photographer'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { usePasswordUpdateActions } from '.'
import BeforeLoginContentBox from '@/vueComponents/photographer/organisms/contentBox/BeforeLoginContentBox/index.vue'

const actions = usePasswordUpdateActions()
</script>

<template>
  <BeforeLoginContentBox title="パスワード設定">
    <PasswordUpdateContent
      :message="PHOTOGRAPHER.MESSAGE.PASSWORD_UPDATING"
      button-label="設定"
      :on-click-button="actions.updatePassword"
      :next-location="{ name: photographerRoutes.NAME.TOP }"
    />
  </BeforeLoginContentBox>
</template>
