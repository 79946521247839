import { Computable, Emits, Promisable } from '@/types/utils'
import { computed, reactive } from 'vue'

export interface IModalProps {
  shown: boolean
}

export type ModalEmitParams = {
  'update:shown': IModalProps['shown']
  submit: void
  close: void
}

type IModalStates = {
  shown: boolean
}

export const useInvoiceSendConfirmModalStates = (
  props: IModalProps,
  emits: Emits<ModalEmitParams>
): IModalStates => {
  return reactive<Computable<IModalStates>>({
    shown: computed({
      get() {
        return props.shown
      },
      set(value) {
        emits('update:shown', value)
      },
    }),
  })
}

type ModalActions = {
  close: () => Promisable<void>
  submit: () => Promisable<void>
}

export const useInvoiceSendConfirmModalActions = (
  emits: Emits<ModalEmitParams>
): ModalActions => {
  return {
    async close() {
      await emits('update:shown', false)
      await emits('close')
    },
    async submit() {
      await emits('update:shown', false)
      await emits('submit')
    },
  }
}
