<script setup lang="ts">
import { defineProps } from 'vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import {
  ToScheduleButtonProps,
  useScheduleButtonStates,
  useToScheduleButtonActions,
} from '.'

interface IProps {
  targetYearMonth: ToScheduleButtonProps['targetYearMonth']
}

const props = defineProps<IProps>()
const states = useScheduleButtonStates(props)
const actions = useToScheduleButtonActions(states)
</script>

<template>
  <Button :on-click="actions.toSchedulePage" type="medium"
    >スケジュール登録・編集する</Button
  >
</template>
