import { Computable, Emits } from '@/types/utils'
import { assertString } from '@/utils/functions/typeCheck'
import { IInputProps } from '@/vueComponents/photographer/atoms/Input'
import { computed, reactive } from 'vue'

export interface ITextInputProps {
  value: string
  name: string
  placeholder?: string
  disabled?: boolean
  grayOut?: boolean
  label?: string
  errorMessage?: string
  width?: string
}

export type TextInputEmitParams = {
  'update:value': ITextInputProps['value']
}

interface ITextInputStates {
  value: IInputProps['value']
}

export const useTextInputStates = (
  props: ITextInputProps,
  emits: Emits<TextInputEmitParams>
) =>
  reactive<Computable<ITextInputStates>>({
    value: computed({
      get() {
        return props.value
      },
      set(value) {
        assertString(value)
        emits('update:value', value)
      },
    }),
  })
