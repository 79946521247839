<script setup lang="ts">
import { useAccountInfoEditStates, useAccountInfoEditActions } from '.'
import AccountInfoEditPageContent from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/index.vue'

const states = useAccountInfoEditStates()
const actions = useAccountInfoEditActions(states)
</script>

<template>
  <AccountInfoEditPageContent
    v-if="states.accountInfo.account && states.accountInfo.company"
    :account-info="states.accountInfo"
    :breadcrumbs="states.breadcrumbs"
    @save:account-info="actions.onUpdateAccountInfo"
  />
</template>
