import { IEventInfo, IEventTitle } from '@/types/photographer/event'
import { Computable, Promisable } from '@/types/utils'
import {
  EventStatusCode,
  InspectionStatusCode,
} from '@/utils/constants/enums/photographer/event'
import { computed, reactive, ref } from 'vue'
import { DeliveryButtonProps } from './DeliveryButton'
import { IFolderCreationButtonProps } from './FolderCreationButton'
import { FolderTableProps } from './FolderTable'

export type EventPageFolderProps = {
  sellerName: DeliveryButtonProps['sellerName']
  eventName: IEventTitle['eventName']
  eventInfo: Pick<IEventInfo, 'startDate' | 'status'>
  directories: FolderTableProps['directories']
  dispatchId: FolderTableProps['dispatchId']
  onCreateFolder: IFolderCreationButtonProps['onSubmit']
  onUpdateFolderName: FolderTableProps['onUpdateFolderName']
  onDeliver: DeliveryButtonProps['onSubmit']
  onDeleteFolder: (directoryIds: number[]) => Promisable<void>
}

type EventPageFolderStates = {
  readonly editable: boolean
  readonly deliverable: boolean
  readonly hasError: boolean
  readonly deleteButtonActive: boolean
  readonly delivered: boolean
  checkedIdSet: FolderTableProps['checkedIdSet']
}

const EDITABLE_STATUSES = [
  EventStatusCode.BEFORE_DELIVERY,
  EventStatusCode.SENDING_BACK,
]

export const useEventPageFolderStates = (
  props: EventPageFolderProps
): EventPageFolderStates => {
  const checkedIdSet = ref<EventPageFolderStates['checkedIdSet']>(new Set())
  return reactive<Computable<EventPageFolderStates>>({
    editable: computed(() =>
      EDITABLE_STATUSES.includes(props.eventInfo.status)
    ),
    deliverable: computed(
      () =>
        props.directories.length > 0 &&
        props.directories.every(
          (directory) =>
            directory.inspectStatus ===
              InspectionStatusCode.INSPECTION_COMPLETE ||
            directory.photosCount > 0
        )
    ),
    hasError: computed(() =>
      props.directories.some(({ errorPhotoCount }) => errorPhotoCount > 0)
    ),
    deleteButtonActive: computed(() => checkedIdSet.value.size > 0),
    delivered: computed(
      () => EventStatusCode.DELIVERED === props.eventInfo.status
    ),
    checkedIdSet,
  })
}

type EventPageFolderActions = {
  deleteFolder: () => Promise<void>
}

export const useEventPageFolderActions = (
  props: EventPageFolderProps,
  states: EventPageFolderStates
): EventPageFolderActions => ({
  async deleteFolder() {
    const checkedDirectoryIds = Array.from(states.checkedIdSet)
    await props.onDeleteFolder(checkedDirectoryIds)
    states.checkedIdSet = new Set()
  },
})
