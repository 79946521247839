import { Computable } from '@/types/utils'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'
import { createInspectionDeliveryRequests } from '@/utils/functions/createInspectionDeliveryRequests'
import { IEventsStates } from '@/vueComponents/photographer/pages/Events'
import { computed, reactive } from 'vue'

export type NotificationInfo = {
  sellerName: string
  eventName: string
  type: EventStatusCode.BEFORE_DELIVERY | EventStatusCode.SENDING_BACK
  eventId: number
}

export interface IEventsPageContentProps {
  events: IEventsStates['events']
}

interface IEventsPageContentStates {
  notificationInfos: NotificationInfo[]
}

export const useEventsPageContentStates = (props: IEventsPageContentProps) =>
  reactive<Computable<IEventsPageContentStates>>({
    notificationInfos: computed(() =>
      createInspectionDeliveryRequests(props.events)
    ),
  })
