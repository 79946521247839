import { Computable, Emits } from '@/types/utils'
import { parseJaDateToString } from '@/utils/functions/parser'
import { computed, reactive } from 'vue'
import { IFolderNameChangeLinkProps } from '../FolderNameChangeLink'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'

export type FolderTableNameCellProps = {
  disabled?: boolean
  checked: boolean
  eventDate: Date
  eventName: string
  folderName: string
  eventStatus?: number
  onUpdateName: IFolderNameChangeLinkProps['onSubmit']
}

export type FolderTableNameCellEmitParams = {
  'update:checked': FolderTableNameCellProps['checked']
}

type FolderTableNameCellStates = {
  checked: boolean
  delivered: boolean
  folderNamePrefix: string
}

export const useFolderTableNameCellStates = (
  props: FolderTableNameCellProps,
  emits: Emits<FolderTableNameCellEmitParams>
): FolderTableNameCellStates =>
  reactive<Computable<FolderTableNameCellStates>>({
    checked: computed({
      get() {
        return props.checked
      },
      set(value) {
        emits('update:checked', value)
      },
    }),
    delivered: computed(() => EventStatusCode.DELIVERED === props.eventStatus),
    folderNamePrefix: computed(
      () =>
        `${parseJaDateToString(props.eventDate)}${'\u3000'}${props.eventName}_${
          props.folderName
        }`
    ),
  })
