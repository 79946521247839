import { validateNotEntered } from '@/utils/functions/validation/validation'
import { reactive } from 'vue'

export interface AddressInputProps {
  modelValue: string
}

export interface AddressInputStates {
  AddressErrorMessage: string
}

interface AddressInputEmits {
  (e: 'update:modelValue', value: string): void
}

export const useAddressInputStates = () => {
  return reactive<AddressInputStates>({
    AddressErrorMessage: '',
  })
}

type AddressActions = {
  handleInputAddress(e: Event): void
  handleBlurAddress(e: Event): void
}

export const useAddressInputActions = (
  states: AddressInputStates,
  emits: AddressInputEmits
): AddressActions => {
  return {
    handleInputAddress(e: Event) {
      states.AddressErrorMessage = ''
      const target = e.target as HTMLInputElement
      emits('update:modelValue', target.value)
    },
    handleBlurAddress(e: Event) {
      const target = e.target as HTMLInputElement
      if (validateNotEntered(target.value)) {
        states.AddressErrorMessage = '未入力です'
      }
    },
  }
}
