import {
  IDispatchOrder,
  IDispatchOrderDetailBase,
  IDispatchOrderRequestParams,
} from '@/types/photographer/dispatchOrder'
import { BaseApi } from '../BaseApi'

const PATH = {
  FETCH_DISPATCH_ORDERS: '/photographer/api/v1.0/dispatch_orders',
  FETCH_DISPATCH_ORDER:
    '/photographer/api/v1.0/dispatch_orders/:dispatch_order_id',
} as const

interface IFetchDispatchOrderResponse {
  dispatch_order_id: number
  mail_send_at: string
  start_date: string
  reply_deadline_count: number
  exists_old_order: boolean
  exists_new_order: boolean
  is_replied: boolean
  reached_capacity: boolean
  can_reply: boolean
}

interface IFetchDispatchOrderDetailResponse {
  dispatch_order_id: number
  seller_name: string
  event_name: string
  start_date: string
  end_date: string
  type: number
  type_normal_start_date: string
  photo_children_number: number
  venue_name: string
  venue_zipcode: number
  venue_address: string
  meeting_point_name: string
  meeting_point_zipcode: number
  meeting_point_address: string
  meeting_point_time: string
  trial_user_flg: boolean
  transportation: number
  other_transportation: string
  consultation_amount: number
  reply_deadline: string
  reply_deadline_count: number
  memo: string
  mail_send_at: string
  mail_send_by_name: string
  reply_status: number
  reached_capacity: boolean
  can_reply: boolean
  before_dispatch_order_id: number
  current_dispatch_order_id: number
}

interface IPostDispatchOrderRequestParam {
  reply_status: number
  transportation: number
  other_transportation: string
}

interface IPostDispatchOrderRequest extends IPostDispatchOrderRequestParam {
  dispatch_order_id: number
}

export class DispatchOrderApi extends BaseApi {
  /**
   * 派遣依頼情報
   * @param limit 表示件数
   * @param replyStatus 回答ステータス
   * @param canReply 回答可能フラグ
   * @returns 派遣依頼詳細情報の一覧
   */
  public async fetchDispatchOrder(
    limit?: number,
    replyStatus?: number,
    canReply?: number
  ): Promise<IDispatchOrder[]> {
    const params = new URLSearchParams()
    if (limit) params.append('limit', limit.toString())
    if (replyStatus) params.append('reply_status', replyStatus.toString())
    if (canReply) params.append('can_reply', canReply.toString())

    const { data } = await this.get<IFetchDispatchOrderResponse[]>(
      `${PATH.FETCH_DISPATCH_ORDERS}?${params.toString()}`
    )
    return data.map((dispatchOrder) => ({
      dispatchOrderId: dispatchOrder.dispatch_order_id,
      mailSendAt: new Date(dispatchOrder.mail_send_at),
      startDate: new Date(dispatchOrder.start_date),
      replyDeadlineCount: dispatchOrder.reply_deadline_count,
      existsOldOrder: dispatchOrder.exists_old_order,
      existsNewOrder: dispatchOrder.exists_new_order,
      isReplied: dispatchOrder.is_replied,
      reachedCapacity: dispatchOrder.reached_capacity,
      canReply: dispatchOrder.can_reply,
    }))
  }

  /**
   * 派遣依頼詳細情報
   * @param dispatch_order_id 派遣依頼ID
   * @returns 派遣依頼詳細情報
   */
  public async fetchDispatchOrderDetail(
    dispatch_order_id: number
  ): Promise<IDispatchOrderDetailBase> {
    const { data } = await this.get<IFetchDispatchOrderDetailResponse>(
      PATH.FETCH_DISPATCH_ORDER,
      { dispatch_order_id }
    )
    return {
      dispatchOrderId: data.dispatch_order_id,
      eventName: data.event_name,
      startDate: new Date(data.start_date),
      endDate: new Date(data.end_date),
      type: data.type,
      typeNormalStartDate: new Date(data.type_normal_start_date),
      venueName: data.venue_name,
      venueZipcode: data.venue_zipcode,
      venueAddress: data.venue_address,
      meetingPointName: data.meeting_point_name,
      meetingPointZipcode: data.meeting_point_zipcode,
      meetingPointAddress: data.meeting_point_address,
      meetingPointTime: data.meeting_point_time,
      transportation: data.transportation,
      otherTransportation: data.other_transportation,
      consultationAmount: data.consultation_amount,
      replyDeadlineCount: data.reply_deadline_count,
      memo: data.memo,
      mailSendByName: data.mail_send_by_name,
      replyStatus: data.reply_status,
      reachedCapacity: data.reached_capacity,
      canReply: data.can_reply,
      beforeDispatchOrderId: data.before_dispatch_order_id,
      currentDispatchOrderId: data.current_dispatch_order_id,
    }
  }

  /**
   * 回答ボタン押下時処理
   * @param dispatch_order_id 依頼詳細ID
   * @param params 受諾ステータス,交通手段,その他の交通手段
   */
  public async postDispatchOrder(
    dispatch_order_id: IPostDispatchOrderRequest['dispatch_order_id'],
    params: IDispatchOrderRequestParams
  ): Promise<void> {
    const {
      replyStatus: reply_status,
      transportation,
      otherTransportation: other_transportation,
    } = params
    await this.post<IPostDispatchOrderRequest>(PATH.FETCH_DISPATCH_ORDER, {
      dispatch_order_id,
      reply_status,
      transportation,
      other_transportation,
    })
  }
}
