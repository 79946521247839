<script setup lang="ts">
import { defineProps } from 'vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import MustLabel from '@/vueComponents/photographer/atoms/MustLabel/index.vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import { NameInputProps, useNameInputActions, useNameInputStates } from '.'

interface IProps {
  modelValue: NameInputProps['modelValue']
}

interface IEmits {
  (e: 'update:modelValue', value: string): void
}

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const states = useNameInputStates()
const actions = useNameInputActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as space-positions;
@use '@/styles/mixins/font' as font-mixins;

.title {
  @include font-mixins.font-weight-bold();
}
.table-cell div,
.col {
  @include space-positions.flex-horizontal(space(2));
}
.table-cell {
  padding: space(3) space(2);
  width: 256px;
}
.input-error {
  margin-top: space(1);
}
</style>

<template>
  <TableRow>
    <TableCell :separatorable="false" :class="css['table-cell']">
      <Typography :class="css.title"
        >氏名
        <MustLabel />
      </Typography>
    </TableCell>
    <TableCell :separatorable="false">
      <Input
        :value="props.modelValue"
        type="text"
        name="Name"
        placeholder="うるる太郎"
        maxlength="50"
        width="256px"
        :has-error="Boolean(states.NameErrorMessage)"
        @input="actions.handleInputName"
        @blur="actions.handleBlurName"
      ></Input>
      <InputError
        v-if="states.NameErrorMessage"
        type="attention"
        :class="css['input-error']"
        >{{ states.NameErrorMessage }}</InputError
      >
    </TableCell>
  </TableRow>
</template>
