import { computed, reactive, Ref, watch } from 'vue'
import { FolderEntity } from '@/entities/photographer/FolderEntity'
import { Promisable } from '@/types/utils'
import { UploadingModalStatus } from '@/utils/constants/enums/photographer/folder'
import { DropzoneEmitParams } from '@/vueComponents/photographer/atoms/Dropzone'
import { IUploadingModalProps } from '../UploadingModal'
import { uploadFiles, UploadPayload } from './uploadAction'
import type { UploadingPhoto } from './UploadingPhoto'
import {
  calcUploadCompletedCount,
  calcWholeUploadingProgressRate,
} from './stateComputer'
import { photographerConstants } from '@/utils/constants/photographerConstants'

const { FILE_UPLOAD } = photographerConstants

export type MaxUploadingPhotoCount =
  | typeof FILE_UPLOAD.SLOW_MODE_MAX_UPLOADING_PHOTO_COUNT
  | typeof FILE_UPLOAD.FAST_MODE_MAX_UPLOADING_PHOTO_COUNT

export interface IPhotoUploaderProps {
  onUploadFile: UploadPayload['fileUploader']
  onUpdateUploadingStatus: UploadPayload['fileStatusUpdater']
  onUpdateFailedPhotosStatus: UploadPayload['fileFailedPhotosStatusUpdater']
  onDeleteFiles: UploadPayload['filesDeleter']
  slowModeFlg: boolean
}

export interface IPhotoUploaderStates {
  isModalShown: IUploadingModalProps['shown']
  uploadStatus: IUploadingModalProps['uploadStatus']
  readonly uploadingPhotos: Set<UploadingPhoto>
  readonly uploadingCount: IUploadingModalProps['uploadingCount']
  readonly completedCount: IUploadingModalProps['completedCount']
  failedCount: IUploadingModalProps['failedCount']
  readonly progressRate: IUploadingModalProps['progressRate']
  duplicatedCount: IUploadingModalProps['duplicatedCount']
  duplicationResolver: (result: boolean) => void
  maxUploadingPhotoCount: MaxUploadingPhotoCount
}

export const usePhotoUploaderStates = (
  props: IPhotoUploaderProps
): IPhotoUploaderStates => {
  const states: IPhotoUploaderStates = reactive({
    isModalShown: false,
    uploadStatus: UploadingModalStatus.INITIAL,
    uploadingPhotos: new Set(),
    uploadingCount: computed(() => states.uploadingPhotos.size),
    completedCount: computed(() =>
      calcUploadCompletedCount(states.uploadingPhotos)
    ),
    failedCount: 0,
    progressRate: computed(() =>
      calcWholeUploadingProgressRate(states.uploadingPhotos)
    ),
    duplicatedCount: 0,
    duplicationResolver: () => undefined,
    maxUploadingPhotoCount: computed(() =>
      props.slowModeFlg
        ? FILE_UPLOAD.SLOW_MODE_MAX_UPLOADING_PHOTO_COUNT
        : FILE_UPLOAD.FAST_MODE_MAX_UPLOADING_PHOTO_COUNT
    ),
  })
  return states
}

type Uploader = (files: File[]) => Promisable<void>

/**
 * ファイル選択時処理の生成
 * @param fileUploader ファイルアップロード処理
 * @returns ファイル選択時処理
 */
const createOnSelectHandler =
  (fileUploader: Uploader) => async (event: Event) => {
    const element = event.target
    if (!element || !(element instanceof HTMLInputElement)) return
    const fileList = element.files
    if (!fileList) return
    await fileUploader(Array.from(fileList))
    element.value = ''
  }

type PhotoUploaderActions = {
  onClickButton: () => void
  onDrop: (files: DropzoneEmitParams['drop']) => Promisable<void>
  onSelect: (event: Event) => Promisable<void>
  onOverwriteDuplication: IUploadingModalProps['onOverwriteDuplication']
}

export const usePhotoUploaderActions = (
  props: IPhotoUploaderProps,
  states: IPhotoUploaderStates,
  inputElement: Ref<HTMLInputElement | undefined>
): PhotoUploaderActions => {
  const payload: UploadPayload = {
    files: [],
    folderEntity: new FolderEntity(),
    fileUploader: props.onUploadFile,
    fileStatusUpdater: props.onUpdateUploadingStatus,
    fileFailedPhotosStatusUpdater: props.onUpdateFailedPhotosStatus,
    filesDeleter: props.onDeleteFiles,
    states,
  }

  const uploader: Uploader = async (files) => {
    await uploadFiles(Object.assign(payload, { files }))
  }

  const resetValue = (isModalShown: IPhotoUploaderStates['isModalShown']) => {
    if (isModalShown) return
    if (inputElement.value) inputElement.value.value = ''
  }

  watch(() => states.isModalShown, resetValue)

  return {
    onClickButton: () => inputElement.value?.click(),
    onDrop: uploader,
    onSelect: createOnSelectHandler(uploader),
    onOverwriteDuplication: () => states.duplicationResolver(true),
  }
}
