<script setup lang="ts">
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { useAcceptedMessageStates } from '.'

const states = useAcceptedMessageStates()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.container {
  text-align: center;
}
.img {
  display: block;
  width: 170px;
  height: 130px;
  margin: auto;
}
.text {
  @include space-mixins.vertical-margin(6);
}
</style>

<template>
  <div :class="css.container">
    <img :class="css.img" :src="states.imgPath" alt="" />
    <div :class="css.text">
      <Typography element="p" type="unique-fullmodal-close">
        お引き受けいただき<br />ありがとうございます。
      </Typography>
    </div>
    <div :class="css.text">
      <Typography element="p">
        えんフォト事務局にて回答を承りました。<br />
        園との条件調整などが完了次第「受注済み」になります。<br /><br />
        また、園の同意後に確定情報の発注書を<br />
        メールにてお送りいたしますため、ご確認くださいませ。<br /><br />
        撮影当日は何卒よろしくおねがいいたします。
      </Typography>
    </div>
    <div :class="css.text">
      <Typography element="p" type="read-small">
        ※なお、撮影時間が前後する等、依頼内容が変更する場合がございます。<br />
        万が一変更がありましたら、【案件依頼の内容
        変更・確認のご連絡】をお送りしますので<br />
        再度調整後の条件のご確認をおねがいいたします。</Typography
      >
    </div>
  </div>
</template>
