import { DispatchOrderApi } from '@/domains/api/dispatch_order/DispatchOrderApi'
import { EventsApi } from '@/domains/api/event/EventsApi'
import { EventsEntity } from '@/entities/photographer/EventsEntity'
import { SelfEntity } from '@/entities/SelfEntity'
import { IDispatchOrder } from '@/types/photographer/dispatchOrder'
import { IEvent } from '@/types/photographer/event'
import { Computable } from '@/types/utils'
import { onCreate } from '@/utils/functions/lifecycle'
import { reactive, computed } from 'vue'
import { photographerConstants } from '@/utils/constants/photographerConstants'
import {
  CanReply,
  ReplyStatus,
} from '@/utils/constants/enums/photographer/dispatchOrder'
import { TopPageContentProps } from '@/vueComponents/photographer/organisms/pageContent/TopPageContent/index'
import { ScheduleApi } from '@/domains/api/schedule/ScheduleApi'
import {
  parseDateYearToHyphenString,
  parseMonthYearToHyphenString,
  parseStringDateToDate,
} from '@/utils/functions/parser'
import { IScheduleReplyData } from '@/types/photographer/schedules'
import { addMonths, subMonths } from 'date-fns'
import { IInvoiceRequest } from '@/types/photographer/invoice'
import { CreateRequestApi } from '@/domains/api/invoice/CreateRequestApi'

const limit = photographerConstants.TOP_DISPLAYS_NUM

export type TopStates = {
  limitMatterRequests: IDispatchOrder[]
  noticeMatterRequests: IDispatchOrder[]
  events: IEvent[]
  invoiceRequests: IInvoiceRequest[]
  isScheduleRegistered: TopPageContentProps['isScheduleRegistered']
  todaysDate: TopPageContentProps['todaysDate']
  displayYearMonth: TopPageContentProps['displayYearMonth']
  schedules: IScheduleReplyData[]
  isLoading: boolean
  invoiceGenerationFlg: boolean
}

type TopActions = {
  clickPrev: TopPageContentProps['clickPrev']
  clickNext: TopPageContentProps['clickNext']
}

const today = new Date()
const todayWithHyphen: string = parseDateYearToHyphenString(today)
const todayYearMonth = parseMonthYearToHyphenString(today)

export const useTopStates = () => {
  const eventsEntity = new EventsEntity()
  const selfEntity = new SelfEntity()
  return reactive<Computable<TopStates>>({
    limitMatterRequests: [],
    noticeMatterRequests: [],
    events: computed(() => eventsEntity.events),
    invoiceRequests: [],
    isScheduleRegistered: computed(() => selfEntity.isScheduleRegistered),
    todaysDate: todayWithHyphen,
    displayYearMonth: todayYearMonth,
    schedules: [],
    isLoading: false,
    invoiceGenerationFlg: computed(() => selfEntity.invoiceGenerationFlg),
  })
}

/**
 * states.schedulesにfetchSchedulesOfYearMonthのレスポンスをセット
 * isLoadingの制御
 * @param states pages/Topのstates
 * @param api ScheduleApi
 */
export const setStatesSchedule = async (
  states: TopStates,
  api: ScheduleApi
): Promise<void> => {
  states.isLoading = true
  states.schedules = await api.fetchSchedulesOfYearMonth(
    states.displayYearMonth,
    true
  )
  states.isLoading = false
}

/**
 * 表示月を前月or翌月に変更してsetStatesScheduleを実行
 * @param states pages/Topのstates
 * @param api ScheduleApi
 * @param clickButton クリック対象（'prev' or 'next'）
 */
export const getDisplayMonth = async (
  states: TopStates,
  api: ScheduleApi,
  clickButton: string
): Promise<void> => {
  let targetMonth: Date = parseStringDateToDate(states.displayYearMonth)
  switch (clickButton) {
    case 'prev':
      targetMonth = subMonths(parseStringDateToDate(states.displayYearMonth), 1)
      break
    case 'next':
      targetMonth = addMonths(parseStringDateToDate(states.displayYearMonth), 1)
      break
  }
  states.displayYearMonth = parseMonthYearToHyphenString(targetMonth)
  await setStatesSchedule(states, api)
}

export const useTopActions = (states: TopStates): TopActions => {
  const eventsEntity = new EventsEntity()
  const eventsApi = new EventsApi()
  const dispatchOrderApi = new DispatchOrderApi()
  const scheduleApi = new ScheduleApi()
  const createRequestApi = new CreateRequestApi()

  onCreate(async () => {
    eventsEntity.events = await eventsApi.fetchEvents()
    states.limitMatterRequests = await dispatchOrderApi.fetchDispatchOrder(
      limit
    )
    states.noticeMatterRequests = await dispatchOrderApi.fetchDispatchOrder(
      undefined,
      ReplyStatus.UNANSWERED,
      CanReply.ACCEPTABLE
    )
    states.invoiceRequests = await createRequestApi.fetchInvoices(limit)
    await setStatesSchedule(states, scheduleApi)
  })

  return {
    clickPrev: async () => await getDisplayMonth(states, scheduleApi, 'prev'),
    clickNext: async () => await getDisplayMonth(states, scheduleApi, 'next'),
  }
}
