import { Computable } from '@/types/utils'
import { onCreate } from '@/utils/functions/lifecycle'
import { nextTick, reactive } from 'vue'
import { IBreadcrumb } from '../../molecules/PageBreadcrumbs'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { InvoiceDetail } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable'
import { InvoiceDetailStates } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail'
import { Expense } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable'
import { Account } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/Account'
import { InvoiceEntity } from '@/entities/photographer/InvoiceEntity'
import {
  FetchDetail,
  FetchDetails,
  FetchStatus,
  FetchExpense,
  FetchExpenses,
} from '@/types/photographer/invoice'
import { Router, useRouter } from 'vue-router'
import { InvoiceStatus } from '@/utils/constants/enums/photographer/invoice'
import { AccountApi } from '@/domains/api/account/AccountApi'
import { BankAccountApi } from '@/domains/api/account/BankAccountApi'
import { InvoiceApi } from '@/domains/api/invoice/InvoiceApi'
import { IBankAccount } from '@/types/photographer/bankAccount'
import { IAccount, ICompany } from '@/types/photographer/account'
import { defaultStatus } from '@/vue/stores/photographer/InvoiceStore'
import { defaultAccount } from '@/utils/functions/invoice'
import {
  convertEntityDetailsToPayload,
  convertEntityExpensesToPayload,
} from '@/utils/functions/invoice/convert'

export const INITIAL_EXPENSE_ROW = 3

export const initialExpense = {
  id: null,
  content: '',
  amount: null,
  date: '',
  file: null,
  fileName: null,
  originalFileName: null,
  hasRowError: false,
  hasFileError: false,
}

export const defaultDetails = {
  details: [],
  note: '',
  gasUnitPrice: 0,
}

export const defaultInvoicesUpdateParams = {
  invoiceDetails: {
    details: [],
    note: '',
  },
  invoiceExpenses: [],
}

type InvoiceUpdateDetail = {
  id: number | null
  date: string
  content: string
  amount: number | null
  mileage: number | null
  dispatchCameramanId: number | null
}

export type InvoiceUpdateDetailParam = {
  note: string
  details: InvoiceUpdateDetail[]
}

type invoicesUpdateParams = {
  invoiceDetails: {
    details: InvoiceDetail[]
    note: string
  }
  invoiceExpenses: Expense[]
}

type DetailsStates = {
  details: InvoiceDetail[]
  note: string
  gasUnitPrice: number
}

export type InvoiceCreateStates = {
  breadcrumbs: IBreadcrumb[]
  status: FetchStatus
  account: Account
  details: DetailsStates
  expenses: Expense[]
  invoicesUpdateParams: invoicesUpdateParams
  isSaveCompleteModalShown: boolean
  loading: boolean
}

export const useInvoiceCreateStates = () => {
  const states: InvoiceCreateStates = reactive<Computable<InvoiceCreateStates>>(
    {
      breadcrumbs: [
        {
          label: 'ホーム',
          location: { name: photographerRoutes.NAME.TOP },
        },
        {
          label: '請求書',
          location: { name: photographerRoutes.NAME.INVOICES },
        },
        {
          label: '請求書作成',
        },
      ],
      status: defaultStatus,
      account: defaultAccount,
      details: defaultDetails,
      expenses: [],
      invoicesUpdateParams: defaultInvoicesUpdateParams,
      isSaveCompleteModalShown: false,
      loading: false,
    }
  )
  return states
}

export const moveToInvoices = async (router: Router) => {
  await router.push({ name: photographerRoutes.NAME.INVOICES })
}

const generateInvoiceDetail = (details: FetchDetail[]) => {
  return details.map((detail: FetchDetail) => {
    return {
      ...detail,
      hasRowError: false,
    }
  })
}

const handleSetDetailsParams = (details: FetchDetails) => {
  return {
    details: generateInvoiceDetail(details.details),
    note: details.note,
    gasUnitPrice: details.gasUnitPrice,
  }
}

const convertExpenses = (
  invoiceApi: InvoiceApi,
  expenses: FetchExpenses
): Expense[] => {
  return expenses.map((expense: FetchExpense) => {
    const entityExpense: Expense = {
      id: expense.id,
      content: expense.content,
      amount: expense.amount,
      date: expense.date,
      file: null,
      fileName: expense.fileName,
      originalFileName: expense.originalFileName,
      hasRowError: false,
      hasFileError: false,
    }
    if (expense.originalFileName) {
      entityExpense.fetchFileUrl = () => fetchFileUrl(invoiceApi, expense.id)
    }
    return entityExpense
  })
}

const fetchFileUrl = async (
  invoiceApi: InvoiceApi,
  expenseId: number
): Promise<string> => {
  const file = await invoiceApi.fetchOriginalFile(expenseId)
  return file.originalFileUrl
}

const addInitialExpenses = (expenses: Expense[]) => {
  const dataCountToBeAdded = INITIAL_EXPENSE_ROW - expenses.length
  for (let i = 0; i < dataCountToBeAdded; i++) {
    expenses.push(initialExpense)
  }
  return expenses
}

const handleSetAccountParams = (
  account: IAccount,
  company: ICompany,
  bankAccount: IBankAccount
) => {
  return {
    companyName: company.name,
    name: account.name,
    zipcode: company.location.zipcode
      ? company.location.zipcode
      : account.delivery.zipcode,
    prefectureId: company.location.prefectureId
      ? company.location.prefectureId
      : account.delivery.prefectureId,
    address: company.location.address
      ? company.location.address
      : account.delivery.address,
    invoiceNumber: account.invoiceNumber,
    bankName: bankAccount.bankName,
    branchName: bankAccount.branchName,
    accountType: bankAccount.accountType,
    accountNumber: bankAccount.accountNumber,
    accountHolder: bankAccount.accountHolder,
  }
}

const isRoutePathFromInvoicePreview = (router: Router) => {
  return (
    router.options.history.state.back ===
    photographerRoutes.PATH.INVOICE_PREVIEW
  )
}

const isInvoiceEntityNotDefault = (invoiceEntity: InvoiceEntity) => {
  return (
    invoiceEntity.account ||
    invoiceEntity.status ||
    invoiceEntity.details.length ||
    invoiceEntity.expenses.length ||
    invoiceEntity.note !== '' ||
    invoiceEntity.gasUnitPrice !== 0
  )
}

const setInvoiceEntityToStates = (
  invoiceEntity: InvoiceEntity,
  states: InvoiceCreateStates
) => {
  if (invoiceEntity.account) {
    states.account = invoiceEntity.account
  }
  if (invoiceEntity.status) {
    states.status = invoiceEntity.status
  }
  states.details = {
    details: invoiceEntity.details,
    note: invoiceEntity.note,
    gasUnitPrice: invoiceEntity.gasUnitPrice,
  }
  if (invoiceEntity.expenses.length) {
    states.expenses = invoiceEntity.expenses
  } else {
    states.expenses = addInitialExpenses(invoiceEntity.expenses)
  }
}

const setInvoiceDataToStore = (
  states: InvoiceCreateStates,
  invoiceEntity: InvoiceEntity
) => {
  invoiceEntity.setDetails({
    details: states.invoicesUpdateParams.invoiceDetails.details,
    note: states.invoicesUpdateParams.invoiceDetails.note,
    gasUnitPrice: states.details.gasUnitPrice,
  })
  invoiceEntity.setExpenses(states.invoicesUpdateParams.invoiceExpenses)
  invoiceEntity.setAccount(states.account)
  invoiceEntity.setStatus(states.status)
}

const removeHyphen = (date: string): string => {
  return date.replace(/-/g, '')
}

const compareDate = (
  a: InvoiceDetail | Expense,
  b: InvoiceDetail | Expense
): number => {
  return Number(removeHyphen(a.date)) - Number(removeHyphen(b.date))
}

const updateInvoiceDetails = (
  params: Omit<InvoiceDetailStates, 'hasError'>,
  states: InvoiceCreateStates,
  invoiceEntity: InvoiceEntity
) => {
  const sortedDetails = params.details.sort(
    (a: InvoiceDetail, b: InvoiceDetail) => compareDate(a, b)
  )
  states.invoicesUpdateParams.invoiceDetails = {
    details: sortedDetails,
    note: params.note,
  }
  setInvoiceDataToStore(states, invoiceEntity)
}

const updateInvoiceExpenses = (
  expenses: Expense[],
  states: InvoiceCreateStates,
  invoiceEntity: InvoiceEntity
) => {
  const sortedExpenses = expenses.sort((a: Expense, b: Expense) =>
    compareDate(a, b)
  )
  states.invoicesUpdateParams.invoiceExpenses = sortedExpenses
  setInvoiceDataToStore(states, invoiceEntity)
}

const setDetails = async (invoiceApi: InvoiceApi): Promise<DetailsStates> => {
  const details = await invoiceApi.fetchDetails()
  return handleSetDetailsParams(details)
}

const setExpenses = async (invoiceApi: InvoiceApi): Promise<Expense[]> => {
  const fetchExpenses = await invoiceApi.fetchExpenses()
  const entityExpenses = convertExpenses(invoiceApi, fetchExpenses)
  return addInitialExpenses(entityExpenses)
}

const setAccount = async (
  accountApi: AccountApi,
  bankAccountApi: BankAccountApi
): Promise<Account> => {
  const account = await accountApi.fetchAccount()
  const company = await accountApi.fetchCompany()
  const bankAccount = await bankAccountApi.fetchBankAccount()
  return handleSetAccountParams(account, company, bankAccount)
}

const setStatus = async (invoiceApi: InvoiceApi): Promise<FetchStatus> => {
  return await invoiceApi.fetchStatus()
}

const handleCallApi = async (
  states: InvoiceCreateStates,
  router: Router,
  accountApi: AccountApi,
  bankAccountApi: BankAccountApi,
  invoiceApi: InvoiceApi
): Promise<void> => {
  states.status = await setStatus(invoiceApi)
  if (states.status.status === InvoiceStatus.CANNOT_CREATE) {
    return moveToInvoices(router)
  }
  states.account = await setAccount(accountApi, bankAccountApi)
  states.details = await setDetails(invoiceApi)
  states.expenses = await setExpenses(invoiceApi)
}

const temporarySave = async (
  invoiceApi: InvoiceApi,
  invoiceEntity: InvoiceEntity,
  states: InvoiceCreateStates
) => {
  if (states.loading) return
  states.loading = true
  const note = invoiceEntity.note
  const details = convertEntityDetailsToPayload(invoiceEntity.details)
  const expenses = convertEntityExpensesToPayload(invoiceEntity.expenses)
  await invoiceApi.postInvoices({ note, details, expenses })
  states.isSaveCompleteModalShown = true
  states.loading = false
}

const continueEdit = async (
  states: InvoiceCreateStates,
  invoiceApi: InvoiceApi,
  invoiceEntity: InvoiceEntity
) => {
  const updatedDetails = await setDetails(invoiceApi)
  states.details = defaultDetails
  await nextTick()
  states.details = updatedDetails
  invoiceEntity.setDetails({
    details: states.details.details,
    note: states.details.note,
    gasUnitPrice: states.details.gasUnitPrice,
  })

  const updatedExpenses = await setExpenses(invoiceApi)
  states.expenses = []
  await nextTick()
  states.expenses = updatedExpenses
  invoiceEntity.setExpenses(states.expenses)
}

const moveToHome = async (router: Router) => {
  await router.push({ name: photographerRoutes.NAME.TOP })
}

export const useInvoiceCreateActions = (states: InvoiceCreateStates) => {
  const router = useRouter()
  const invoiceEntity = new InvoiceEntity()
  const accountApi = new AccountApi()
  const bankAccountApi = new BankAccountApi()
  const invoiceApi = new InvoiceApi()

  onCreate(async () => {
    if (!isRoutePathFromInvoicePreview(router)) {
      invoiceEntity.setToDefault()
    }
    if (isInvoiceEntityNotDefault(invoiceEntity)) {
      setInvoiceEntityToStates(invoiceEntity, states)
      return
    }
    await handleCallApi(states, router, accountApi, bankAccountApi, invoiceApi)
  })

  return {
    updateInvoiceDetails: (params: Omit<InvoiceDetailStates, 'hasError'>) =>
      updateInvoiceDetails(params, states, invoiceEntity),
    updateInvoiceExpenses: (expenses: Expense[]) =>
      updateInvoiceExpenses(expenses, states, invoiceEntity),
    temporarySave: () => temporarySave(invoiceApi, invoiceEntity, states),
    continueEdit: () => continueEdit(states, invoiceApi, invoiceEntity),
    toHome: () => moveToHome(router),
  }
}
