<script setup lang="ts">
import InvoicePreviewPageContent from '@/vueComponents/photographer/organisms/pageContent/InvoicePreviewPageContent/index.vue'
import InvoiceCreatePageMoveConfirmModal from '@/vueComponents/photographer/organisms/modal/InvoiceCreatePageMoveConfirmModal/index.vue'
import InvoiceSendConfirmModal from '@/vueComponents/photographer/organisms/modal/InvoiceSendConfirmModal/index.vue'
import { usePreviewStates, usePreviewActions } from '.'

const states = usePreviewStates()
const actions = usePreviewActions(states)
</script>

<template>
  <InvoicePreviewPageContent
    :month="states.month"
    :account="states.account"
    :notes="states.note"
    :details="states.details"
    :gas-unit-price="states.gasUnitPrice"
    :expenses="states.expenses"
    @submit="actions.showSendConfirmModal"
  />
  <InvoiceCreatePageMoveConfirmModal
    v-model:shown="states.isMovePageModalShown"
    @move-page="actions.movePage"
  />
  <InvoiceSendConfirmModal
    v-model:shown="states.isSendConfirmModalShown"
    @submit="actions.sendInvoice"
  />
</template>
