import { Computable } from '@/types/utils'
import { computed, reactive } from 'vue'
import { LocationQueryValue, useRoute } from 'vue-router'

type ProposalCompleteStates = {
  isAccepted: LocationQueryValue | LocationQueryValue[]
}

export const useProposalCompleteStates = () => {
  const route = useRoute()
  return reactive<Computable<ProposalCompleteStates>>({
    isAccepted: computed(() => route.query.accept),
  })
}
