<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import { useChecklistStates, useChecklistActions, ChecklistEmitParams } from '.'
import CheckboxWithLabel from '@/vueComponents/photographer/molecules/CheckboxWithLabel/index.vue'

interface IProps {
  allChecked: boolean
}
defineProps<IProps>()

interface IEmits {
  (
    e: 'update:allChecked',
    value: ChecklistEmitParams['update:allChecked']
  ): void
}
const emits = defineEmits<IEmits>()

const states = useChecklistStates()
useChecklistActions(emits, states)
</script>

<style lang="scss" module="css">
@use '@/styles/colors' as colors;
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/font' as font-mixins;
.container {
  border-radius: 8px;
  border: 3px solid colors.$border-color-white;
  color: colors.$font-color-primary;
  text-align: center;
}
.title {
  font-size: 26px;
  @include font-mixins.font-weight-bold;
  margin-top: space(9);
}
.checklist {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  gap: space(4);
  margin: space(9) 0;
}
</style>

<template>
  <div :class="css.container">
    <div :class="css.title">入力お疲れ様でした。確認漏れはありませんか？</div>
    <div :class="css.checklist">
      <CheckboxWithLabel
        v-for="(item, index) in states.checkItems"
        :key="index"
        v-model:checked="item.checked"
        >{{ item.content }}</CheckboxWithLabel
      >
    </div>
  </div>
</template>
