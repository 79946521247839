import { Computable } from '@/types/utils'
import { reactive } from 'vue'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { InvoiceStatus } from '@/utils/constants/enums/photographer/invoice'
import { TransmissionHistoryTable } from '../../organisms/pageContent/InvoicesPageContent/TransmissionHistoryTable'
import { useRouter } from 'vue-router'
import { invoiceStatusInfo } from '../../organisms/pageContent/InvoicesPageContent/MaterialPreparation'
import { InvoiceApi } from '@/domains/api/invoice/InvoiceApi'
import { onCreate } from '@/utils/functions/lifecycle'
import { parseYearDateHourToString } from '@/utils/functions/parser'
import { DownloadApi } from '@/domains/api/download/DownloadApi'
import { downloadFile } from '@/utils/functions/invoice'

export type InvoicesStates = {
  breadcrumbs: IBreadcrumb[]
  tableList: TransmissionHistoryTable[]
  invoiceStatusInfo: invoiceStatusInfo
}

export const useInvoicesStates = () => {
  return reactive<Computable<InvoicesStates>>({
    breadcrumbs: [
      {
        label: 'ホーム',
        location: { name: photographerRoutes.NAME.TOP },
      },
      {
        label: '請求書',
      },
    ],
    invoiceStatusInfo: {
      month: 0,
      status: InvoiceStatus.CANNOT_CREATE,
    },
    tableList: [],
  })
}

const formatSubmittedDate = (submittedDate: string): string => {
  const dateObject = new Date(submittedDate)
  return parseYearDateHourToString(dateObject)
}

const findSubmittedDate = (
  states: InvoicesStates,
  submittedId: number
): string | undefined => {
  return states.tableList.find(
    (table: TransmissionHistoryTable) => table.submittedId === submittedId
  )?.submittedDate
}

const handleDownloadFile = async (
  submittedId: number,
  states: InvoicesStates,
  invoiceApi: InvoiceApi,
  download: DownloadApi
): Promise<void> => {
  const pdfUrl = (await invoiceApi.fetchInvoiceUrl(submittedId)).pdfUrl
  const pdfUrlBlob = await download.downloadZip(pdfUrl)
  let submittedDate = findSubmittedDate(states, submittedId)
  if (!submittedDate) return
  submittedDate = formatSubmittedDate(submittedDate)
  downloadFile(pdfUrlBlob, `${submittedDate}出張撮影請求書.pdf`)
}

export const useInvoicesActions = (states: InvoicesStates) => {
  const router = useRouter()
  const invoiceApi = new InvoiceApi()
  const download = new DownloadApi()
  onCreate(async () => {
    states.tableList = await invoiceApi.fetchSubmittedHistories()
    const invoiceStatus = await invoiceApi.fetchStatus()
    states.invoiceStatusInfo.month = invoiceStatus.month
    states.invoiceStatusInfo.status = invoiceStatus.status
  })
  return {
    handleDownloadFile: async (submittedId: number) =>
      handleDownloadFile(submittedId, states, invoiceApi, download),
    async toInvoiceCreate(): Promise<void> {
      await router.push({ name: photographerRoutes.NAME.INVOICE_CREATE })
    },
  }
}
