import { Computable, Emits } from '@/types/utils'
import { ValidationCode } from '@/utils/constants/enums/validation'
import { validateLoginId } from '@/utils/functions/validation'
import { computed, reactive, watch } from 'vue'

export interface ILoginIdInputProps {
  loginId: string
}

interface ILoginIdInputStates {
  loginId: string
  validationCode: ValidationCode
}

export type LoginIdInputEmitParams = {
  'update:login-id': ILoginIdInputProps['loginId']
  'update:validation-code': ILoginIdInputStates['validationCode']
}

export const useLoginIdInputStates = (
  props: ILoginIdInputProps,
  emits: Emits<LoginIdInputEmitParams>
) =>
  reactive<Computable<ILoginIdInputStates>>({
    loginId: computed({
      get() {
        return props.loginId
      },
      set(value) {
        emits('update:login-id', value)
      },
    }),
    validationCode: ValidationCode.EMPTY,
  })

export const useLoginIdInputActions = (
  states: ILoginIdInputStates,
  emits: Emits<LoginIdInputEmitParams>
) => {
  const validationCodeEmit = (): void => {
    states.validationCode = validateLoginId(states.loginId)
    emits('update:validation-code', states.validationCode)
  }

  watch(() => states.loginId, validationCodeEmit)
}
