import { Computable, Emits } from '@/types/utils'
import { handleInput } from '@/utils/functions/invoice'
import { computed, reactive, ref, watch } from 'vue'

export const MILEAGE_INPUT_MAX_LENGTH = 3
export const inputNumberPlaceholder = '0'
export const contentInputPlaceholder = 'うるる幼稚園'

export type InvoiceDetailInput = {
  date: string
  content: string
  amount: string
  mileage: string
}

export type InvoiceDetailTableRowProps = {
  detailInput: InvoiceDetailInput
  gasUnitPrice: number
  id: number | null
  rowNo: number
  canEdit: boolean
  hasRowError: boolean
  dispatchCameramanId: number | null
}

type InvoiceDetailTableRowStates = {
  detailInput: InvoiceDetailInput
  mileageAmount: number
  tableRowBgColor: 'default' | 'warn'
}

export type InvoiceDetailTableRowEmitParams = {
  'update:detail-input': InvoiceDetailInput
  'delete-row': void
}

export const useInvoiceDetailTableRowStates = (
  props: InvoiceDetailTableRowProps
) => {
  const states: InvoiceDetailTableRowStates = reactive<
    Computable<InvoiceDetailTableRowStates>
  >({
    detailInput: ref({
      date: props.detailInput.date,
      content: props.detailInput.content,
      amount: props.detailInput.amount,
      mileage: props.detailInput.mileage,
    }),
    mileageAmount: computed(
      () => Number(states.detailInput.mileage) * props.gasUnitPrice
    ),
    tableRowBgColor: computed(() => (props.hasRowError ? 'warn' : 'default')),
  })
  return states
}

export const useInvoiceDetailTableRowActions = (
  states: InvoiceDetailTableRowStates,
  emits: Emits<InvoiceDetailTableRowEmitParams>
) => {
  watch(
    () => states.detailInput,
    (newVal: InvoiceDetailTableRowStates['detailInput']) => {
      emits('update:detail-input', {
        ...newVal,
      })
    },
    {
      deep: true,
    }
  )
  const handleAmountInput = (e: Event) => {
    states.detailInput.amount = handleInput(e)
  }
  const handleMileageInput = (e: Event) => {
    states.detailInput.mileage = handleInput(e)
  }
  const deleteRow = () => {
    emits('delete-row')
  }
  return { handleAmountInput, handleMileageInput, deleteRow }
}
