import { BaseApi } from '../BaseApi'

export class DownloadApi extends BaseApi {
  /**
   * 納品ZIPファイルダウンロード
   * @param zipUrl ZIPファイルURL
   * @returns ZIPファイル
   */
  public async downloadZip(zipUrl: string) {
    const { data } = await this.get<Blob>(zipUrl, undefined, {
      responseType: 'blob',
    })
    return data
  }
}
