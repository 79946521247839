import { Computable, Emits } from '@/types/utils'
import { reactive } from 'vue'

interface IButtonStates {
  isHover: boolean
}

export type IButtonEmitParams = {
  'add-row': void
}

export const useAddRowButtonStates = () =>
  reactive<Computable<IButtonStates>>({
    isHover: false,
  })

export const useAddRowButtonActions = (
  states: IButtonStates,
  emits: Emits<IButtonEmitParams>
) => {
  const addRow = () => {
    emits('add-row')
  }
  const mouseover = () => {
    states.isHover = true
  }
  const mouseleave = () => {
    states.isHover = false
  }
  return { mouseover, mouseleave, addRow }
}
