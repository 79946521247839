import { IPostLoginRequest, LoginApi } from '@/domains/api/account/LoginApi'
import { isUnprocessableEntityError } from '@/utils/modules/exceptions/UnprocessableEntityError'

export const useLoginActions = () => {
  const loginApi = new LoginApi()

  return {
    async login(loginId: string, password: string): Promise<string[] | void> {
      try {
        await loginApi.postLogin(loginId, password)
      } catch (e) {
        if (isUnprocessableEntityError<IPostLoginRequest>(e) && e.errors) {
          const { errors } = e
          const loginIdMessage = errors.login_id || []
          const passwordMessage = errors.password || []
          return [...loginIdMessage, ...passwordMessage]
        }
        throw e
      }
    },
  }
}
