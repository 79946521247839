<script setup lang="ts">
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import FooterLinks from '@/vueComponents/photographer/molecules/FooterLinks/index.vue'
import { IFooterLinksProps } from '@/vueComponents/photographer/molecules/FooterLinks'
import Copyright from '@/vueComponents/photographer/atoms/Copyright/index.vue'

const { NAME: ROUTE_NAME } = photographerRoutes
const URLS = {
  COMPANY_ABOUT: 'https://www.uluru.biz/company/company_about',
  POLICY: 'https://www.uluru.biz/policy',
  REQUIRE_ENV: 'https://en-photo.net/require_env',
}

const links: IFooterLinksProps['links'] = [
  { label: 'ホーム', location: { name: ROUTE_NAME.TOP } },
  { label: '運営・販売会社', href: URLS.COMPANY_ABOUT },
  { label: 'プライバシーポリシー', href: URLS.POLICY },
  { label: '推奨環境', href: URLS.REQUIRE_ENV },
]
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: space(4) 0;
}
.copyright {
  @include space-mixins.vertical-margin(4);
}
</style>

<template>
  <div :class="css.container">
    <FooterLinks :links="links" />
    <Copyright :class="css.copyright" />
  </div>
</template>
