import { Computable } from '@/types/utils'
import { reactive } from 'vue'
import { IFolderNameSettingModalProps } from '../FolderNameSettingModal'

export interface IFolderCreationButtonProps {
  disabled?: boolean
  eventDate: Date
  eventName: string
  onSubmit: IFolderNameSettingModalProps['onSubmit']
}

interface IFolderCreationButtonStates {
  isModalOpened: boolean
}

export const useFolderCreationButtonStates = () =>
  reactive<Computable<IFolderCreationButtonStates>>({
    isModalOpened: false,
  })

export const useFolderCreationButtonActions = (
  states: IFolderCreationButtonStates
) => {
  return {
    openModal(): void {
      states.isModalOpened = true
    },
  }
}
