import {
  validateNotEntered,
  removeNonKanaCharacters,
} from '@/utils/functions/validation/validation'
import { reactive } from 'vue'

export interface NameKanaInputProps {
  modelValue: string
}

export interface NameKanaInputStates {
  NameKanaErrorMessage: string
}

interface NameKanaInputEmits {
  (e: 'update:modelValue', value: string): void
}

export const useNameKanaInputStates = () => {
  return reactive<NameKanaInputStates>({
    NameKanaErrorMessage: '',
  })
}

type NameKanaActions = {
  handleInputNameKana(e: Event): void
  handleBlurNameKana(e: Event): void
}

export const useNameKanaInputActions = (
  states: NameKanaInputStates,
  emits: NameKanaInputEmits
): NameKanaActions => {
  return {
    handleInputNameKana(e: Event) {
      states.NameKanaErrorMessage = ''
      const target = e.target as HTMLInputElement
      emits('update:modelValue', target.value)
    },
    handleBlurNameKana(e: Event) {
      const target = e.target as HTMLInputElement
      const validatedValue = removeNonKanaCharacters(target.value)
      if (validateNotEntered(target.value)) {
        states.NameKanaErrorMessage = '未入力です'
      }
      target.value = validatedValue
      emits('update:modelValue', validatedValue)
    },
  }
}
