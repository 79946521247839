<script setup lang="ts">
import { defineProps } from 'vue'
import TableHead from '@/vueComponents/photographer/atoms/TableHead/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import { IEventTableProps, useEventTableStates } from '.'
import EventTableRow from '@/vueComponents/photographer/organisms//pageContent/EventsPageContent/Events/EventTableRow/index.vue'

interface IProps {
  events: IEventTableProps['events']
}

const props = defineProps<IProps>()

const states = useEventTableStates(props)

const columnHeads = [
  { title: '園名／イベント名', textLeftable: true },
  { title: '撮影日程', width: '96px' },
  { title: '開催種別', width: '96px' },
  { title: '写真納期', width: '96px' },
  { title: 'ステータス', width: '96px' },
  { title: '', width: '120px' },
]
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/mixins/position' as position-mixins;
.container {
  padding: space(8) space(8) space(9);
  min-height: 260px;
}
.no-folder {
  @include space-mixins.vertical-margin(27, 23);
  @include position-mixins.flex-vertical(space(5));
}
</style>

<template>
  <ContentBox :class="css.container">
    <Table>
      <TableRow>
        <TableHead
          v-for="columnHead in columnHeads"
          :key="columnHead.title"
          :text-leftable="columnHead.textLeftable"
          :width="columnHead.width"
        >
          <Typography type="head">{{ columnHead.title }}</Typography>
        </TableHead>
      </TableRow>
      <EventTableRow
        v-for="event in states.sortedEvents"
        :key="event.dispatchId"
        :event="event"
      />
    </Table>
    <div v-if="props.events.length === 0" :class="css['no-folder']">
      <Typography>表示する内容がありません</Typography>
    </div>
  </ContentBox>
</template>
