<script setup lang="ts">
import { defineProps, withDefaults } from 'vue'
import { TabProps, useTabStates } from '.'
import TabItem from '@/vueComponents/photographer/molecules/TabItem/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'

interface IProps {
  tabItems: TabProps['tabItems']
  tabItemType?: TabProps['tabItemType']
  tabItemMargin?: TabProps['tabItemMargin']
  hasSeparator?: TabProps['hasSeparator']
}

const props = withDefaults(defineProps<IProps>(), {
  tabItemType: 'normal',
  tabItemMargin: '0px',
  hasSeparator: false,
})
const states = useTabStates()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;

$tab-item-margin: v-bind('props.tabItemMargin');
.tab-item {
  display: flex;
  &-normal {
    gap: space(1);
    margin-left: $tab-item-margin;
  }
  &-link {
    margin-left: space(4);
    gap: space(8);
    padding-bottom: space(5);
  }
}
.separator {
  margin: 0;
}
</style>

<template>
  <div>
    <div
      :class="[
        css['tab-item'],
        props.tabItemType === 'normal'
          ? css['tab-item-normal']
          : css['tab-item-link'],
      ]"
    >
      <template v-for="(tabItem, index) in props.tabItems" :key="index">
        <TabItem
          v-model:activeTabId="states.activeTabId"
          :tab-id="index + 1"
          :tab-item="tabItem"
          :type="props.tabItemType"
          :has-separator="props.hasSeparator"
        />
      </template>
    </div>
    <Separator v-if="props.hasSeparator" :class="css.separator" />
    <template v-for="(tab, index) in tabItems" :key="index">
      <div v-if="index + 1 === states.activeTabId">
        <slot :name="index + 1" />
      </div>
    </template>
  </div>
</template>
