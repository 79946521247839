<script setup lang="ts">
import { defineProps } from 'vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import { MESSAGES } from '@/utils/constants/messages'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import MaterialPreparation from '@/vueComponents/photographer/organisms/pageContent/InvoicesPageContent/MaterialPreparation/index.vue'
import TransmissionHistoryTable from '@/vueComponents/photographer/organisms/pageContent/InvoicesPageContent/TransmissionHistoryTable/index.vue'

import { InvoicesPageContentProps, useInvoicesPageContentActions } from '.'

interface IProps {
  breadcrumbs: InvoicesPageContentProps['breadcrumbs']
  invoiceStatusInfo: InvoicesPageContentProps['invoiceStatusInfo']
  tableList: InvoicesPageContentProps['tableList']
}

interface IEmits {
  (e: 'download:pdf', value: number): void
  (e: 'invoice:create:transition', value: void): void
}
const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()

const actions = useInvoicesPageContentActions(emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/mixins/position' as position-mixins;
.h1 {
  margin-top: space(10);
}
.bread-crumbs {
  margin-top: space(6);
}

.material-preparation-content-box {
  @include space-mixins.vertical-margin(6, 8);
}
.transmission-history-content-box {
  padding: space(8);
}
</style>

<template>
  <Typography :class="css.h1" type="h1">{{
    MESSAGES.PHOTOGRAPHER.PAGE_TITLE.INVOICES
  }}</Typography>
  <PageBreadcrumbs
    :class="css['bread-crumbs']"
    :breadcrumbs="props.breadcrumbs"
  />
  <div :class="css['material-preparation-content-box']">
    <MaterialPreparation
      :invoice-status-info="props.invoiceStatusInfo"
      @invoice-create:transition="actions.invoiceCreateTransition"
    />
  </div>
  <ContentBox id="table" :class="css['transmission-history-content-box']">
    <Typography type="h2">送信履歴</Typography>
    <TransmissionHistoryTable
      :table-list="props.tableList"
      @download:pdf="actions.downloadPdf"
    />
  </ContentBox>
</template>
