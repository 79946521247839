<script setup lang="ts">
import ProposalCompletePageContent from '@/vueComponents/photographer/organisms/pageContent/ProposalCompletePageContent/index.vue'
import { useProposalCompleteStates } from '.'

const states = useProposalCompleteStates()
</script>

<template>
  <div>
    <ProposalCompletePageContent :is-accepted="states.isAccepted" />
  </div>
</template>
