import { onBeforeUnmount, onMounted, Ref } from 'vue'

/**
 * 要素が対象要素に含まれるかを判定
 * @param element 要素
 * @param targetElement 対象要素
 * @returns true:含まれる false:含まれない
 */
const isElementContainedByTarget = (
  element: Element,
  targetElement: Ref<Element | undefined>
): boolean => {
  if (!element) return false
  const target = targetElement.value
  if (!target) return false
  if (target === element) return true
  return Array.from(target.children).includes(element)
}

/**
 * 対象要素外のクリック検知処理を追加
 * @param callback 要素外クリック時処理
 * @param targetElement 対象要素
 */
export const useOutsideClickDetection = (
  callback: () => void,
  targetElement: Ref<Element | undefined>
) => {
  const eventListener = (mouseEvent: MouseEvent): void => {
    const isContained = isElementContainedByTarget(
      mouseEvent.target as Element,
      targetElement
    )
    if (!isContained) callback()
  }
  onMounted(() => window.addEventListener('click', eventListener))
  onBeforeUnmount(() => window.removeEventListener('click', eventListener))
}
