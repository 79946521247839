import { BaseApi } from '../BaseApi'
import { IBankAccount } from '@/types/photographer/bankAccount'

const PATH = {
  GET_BANK_ACCOUNT: '/photographer/api/v1.0/bank_account',
} as const

export interface IGetBankAccountResponse {
  bank_code: string
  bank_name: string
  branch_number: string
  branch_name: string
  account_type: number
  account_number: string
  account_holder: string
}

export class BankAccountApi extends BaseApi {
  public async fetchBankAccount(): Promise<IBankAccount> {
    const { data } = await this.get<IGetBankAccountResponse>(
      PATH.GET_BANK_ACCOUNT
    )
    return {
      bankCode: data.bank_code,
      bankName: data.bank_name,
      branchNumber: data.branch_number,
      branchName: data.branch_name,
      accountType: data.account_type,
      accountNumber: data.account_number,
      accountHolder: data.account_holder,
    }
  }

  public async putBankAccount(
    bankAccountInfo: IGetBankAccountResponse
  ): Promise<void> {
    await this.put<IGetBankAccountResponse>(
      PATH.GET_BANK_ACCOUNT,
      bankAccountInfo
    )
  }
}
