import { Computable, Emits } from '@/types/utils'
import { Ref, reactive, watch } from 'vue'
import { Expense } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable'
import ExpenseTable from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable/index.vue'

export type ExpenseProps = {
  expenses: Expense[]
}

export type ExpenseStates = {
  expenses: Expense[]
  hasError: boolean
}

export type ExpenseEmitParams = {
  'update:invoice-expenses': ExpenseStates
}

export const useExpenseStates = () => {
  const states: ExpenseStates = reactive<Computable<ExpenseStates>>({
    expenses: [],
    hasError: false,
  })
  return states
}

export const useExpenseActions = (
  props: ExpenseProps,
  states: ExpenseStates,
  emits: Emits<ExpenseEmitParams>,
  expenseTableRef: Ref<InstanceType<typeof ExpenseTable>>
) => {
  watch(
    () => props.expenses,
    (newVal: Expense[]) => {
      states.expenses = newVal
    },
    { deep: true, immediate: true }
  )
  const updateExpenses = (expenses: Expense[]) => {
    states.hasError = expenses.some(
      (expense: Expense) => expense.hasRowError === true
    )
    emits('update:invoice-expenses', {
      expenses,
      hasError: states.hasError,
    })
  }
  const validate = () => {
    return expenseTableRef.value.validate() as boolean
  }
  return { updateExpenses, validate }
}
