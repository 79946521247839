<script setup lang="ts">
import { defineProps } from 'vue'
import { MESSAGE } from '@/utils/constants/messages/photographer/message'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import DeliveryModal from '@/vueComponents/photographer/organisms/modal/DeliveryModal/index.vue'
import CheckboxWithLabel from '@/vueComponents/photographer/molecules/CheckboxWithLabel/index.vue'
import {
  DeliveryButtonProps,
  useDeliveryButtonActions,
  useDeliveryButtonStates,
} from '.'

interface IProps {
  editable: DeliveryButtonProps['editable']
  deliverable: DeliveryButtonProps['deliverable']
  sellerName: DeliveryButtonProps['sellerName']
  eventName: DeliveryButtonProps['eventName']
  sendingBack: DeliveryButtonProps['sendingBack']
  onSubmit: DeliveryButtonProps['onSubmit']
}

const props = defineProps<IProps>()

const states = useDeliveryButtonStates(props)

const actions = useDeliveryButtonActions(states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/mixins/position' as position-mixins;
.container {
  @include position-mixins.flex-vertical;
}
.fixed {
  @include position-mixins.flex-vertical(space(4));
  @include space-mixins.vertical-margin(0, 8);
}
.required {
  @include space-mixins.vertical-margin(0, 6);
}
.button {
  width: 288px;
}
</style>

<template>
  <div :class="css.container">
    <template v-if="props.editable">
      <div v-if="props.sendingBack" :class="css.fixed">
        <Typography
          type="read-small"
          :line-breakable="true"
          text-align="center"
          >{{ MESSAGE.SENDING_BACK_FIX_REQUIRED }}</Typography
        >
        <CheckboxWithLabel
          v-model:checked="states.fixed"
          :disabled="!props.deliverable"
          >修正を完了した</CheckboxWithLabel
        >
      </div>
      <div :class="css.required">
        <Typography type="read-small" text-align="center">{{
          MESSAGE.PHOTO_UPLOAD_REQUIRED
        }}</Typography>
      </div>
    </template>
    <div :class="css.button">
      <Button :disabled="states.disabled" :on-click="actions.openModal">{{
        states.buttonLabel
      }}</Button>
    </div>
  </div>
  <DeliveryModal
    v-model:shown="states.isModalOpened"
    :seller-name="props.sellerName"
    :event-name="props.eventName"
    :on-submit="props.onSubmit"
  />
</template>
