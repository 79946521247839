export interface AddressInputProps {
  modelValue: string
  locationAddressErrorMessage: string
}

interface AddressInputEmits {
  (e: 'update:modelValue', value: string): void
  (e: 'input:address'): void
}

type AddressActions = {
  handleInputAddress(e: Event): void
}

export const useAddressInputActions = (
  emits: AddressInputEmits
): AddressActions => {
  return {
    handleInputAddress(e: Event) {
      const target = e.target as HTMLInputElement
      emits('update:modelValue', target.value)
      emits('input:address')
    },
  }
}
