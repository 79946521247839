import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { TabProps } from '@/vueComponents/photographer/molecules/Tab'
import { MatterRequestTableProps } from '@/vueComponents/photographer/organisms/section/MatterRequestTable/index'
import { computed, reactive } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { InspectionDeliveryRequestTableProps } from '../../../section/InspectionDeliveryRequestTable'
import { InvoiceRequestTableProps } from '../../../section/InvoiceRequestTable'
import { Computable } from '@/types/utils'
import { handleTabItems } from '@/utils/functions/notification'

export type NotificationListProps = {
  matterRequests: MatterRequestTableProps['matterRequests']
  inspectionDeliveryRequests: InspectionDeliveryRequestTableProps
  invoiceRequests: InvoiceRequestTableProps['invoiceRequests']
  invoiceGenerationFlg: boolean
}

type NotificationListStates = {
  notificationPageLocation: RouteLocationRaw
  tabItems: TabProps['tabItems']
}

export const useNotificationListStates = (props: NotificationListProps) =>
  reactive<Computable<NotificationListStates>>({
    notificationPageLocation: {
      name: photographerRoutes.NAME.NOTIFICATIONS,
    },
    tabItems: computed(() => handleTabItems(props.invoiceGenerationFlg)),
  })
