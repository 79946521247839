import { Computable } from '@/types/utils'
import { PREFECTURES_MAP } from '@/utils/constants/global/prefectures'
import { reactive } from 'vue'
import { AccountPageTableProps } from '../AccountPageTable/index.vue'
import { Delivery, IAccount, ICompany } from '@/types/photographer/account'

export type AccountInfoProps = {
  company: ICompany
  account: IAccount
}

type AccountInfoStates = {
  companyInfoList: AccountPageTableProps[]
  accountInfoList: AccountPageTableProps[]
}
const formatZipcode = (zipcode: string) => {
  const firstHalfZipcode = zipcode.substring(0, 3)
  const secondHalfZipcode = zipcode.substring(3)
  return `${firstHalfZipcode}-${secondHalfZipcode}`
}

const getPrefectureName = (prefectureId: number) =>
  PREFECTURES_MAP.get(prefectureId) || ''
const handleLocation = (
  zipcode: string,
  prefectureId: number,
  address: string
) => {
  if (!zipcode) return ''
  return `〒${formatZipcode(zipcode)} ${getPrefectureName(
    prefectureId
  )}${address} `
}
const handleCompanyLocation = (location: Delivery) => {
  return handleLocation(
    location.zipcode,
    location.prefectureId,
    location.address
  )
}
const handleAccountLocation = (delivery: Delivery) => {
  return handleLocation(
    delivery.zipcode,
    delivery.prefectureId,
    delivery.address
  )
}

export const useAccountInfoStates = (props: AccountInfoProps) => {
  const states: AccountInfoStates = reactive<Computable<AccountInfoStates>>({
    companyInfoList: [
      { title: '法人名・事業所名・団体名', value: props.company.name },
      {
        title: '法人名・事業所名・団体名（かな）',
        value: props.company.nameKana,
      },
      { title: '所在地', value: handleCompanyLocation(props.company.location) },
    ],
    accountInfoList: [
      { title: '氏名', value: props.account.name },
      { title: '氏名（かな）', value: props.account.nameKana },
      { title: 'メールアドレス', value: props.account.email },
      { title: '電話番号', value: props.account.tel },
      { title: '住所', value: handleAccountLocation(props.account.delivery) },
      { title: 'インボイス登録番号', value: props.account.invoiceNumber },
    ],
  })
  return states
}
