import { Computable } from '@/types/utils'
import { TopStates } from '@/vueComponents/photographer/pages/Top'
import { computed, reactive } from 'vue'

export type EventTableProps = {
  events: TopStates['events']
}

type EventTableStates = {
  sortedEvents: TopStates['events']
}

export const useEventTableStates = (props: EventTableProps) =>
  reactive<Computable<EventTableStates>>({
    sortedEvents: computed(() =>
      props.events.sort((a, b) => (b.startDate > a.startDate ? 1 : -1))
    ),
  })
