import { Computable, Emits, ValueOf } from '@/types/utils'
import { UploadingModalStatus } from '@/utils/constants/enums/photographer/folder'
import { ModalProps } from '@/vueComponents/photographer/molecules/Modal'
import { computed, reactive } from 'vue'
import { IUploadingProgressDialogProps } from './UploadingProgressDialog'
import { UPLOAD_MODAL_HEADER_TITLE } from '@/utils/constants/messages/photographer/event'
import { convertUploadModalHeader } from '@/utils/functions/convertUploadModalHeader'
import { IUploadCompletedDialogProps } from './UploadCompletedDialog'

export interface IUploadingModalProps {
  shown: ModalProps['shown']
  uploadStatus: UploadingModalStatus
  uploadingCount: IUploadingProgressDialogProps['uploadingCount']
  completedCount: IUploadingProgressDialogProps['completedCount']
  failedCount: IUploadCompletedDialogProps['failedCount']
  progressRate: IUploadingProgressDialogProps['progressRate']
  duplicatedCount: number
  slowModeFlg: boolean
  onOverwriteDuplication: () => void
}

export const uploadHeaderType = {
  completed: {
    title: UPLOAD_MODAL_HEADER_TITLE.COMPLETED,
    closeable: true,
  },
  duplication: {
    title: UPLOAD_MODAL_HEADER_TITLE.DUPLICATION,
    closeable: true,
  },
  failed: {
    title: UPLOAD_MODAL_HEADER_TITLE.FAILED,
    closeable: true,
  },
  progress: {
    title: UPLOAD_MODAL_HEADER_TITLE.PROGRESS,
    closeable: false,
  },
} as const

export type UploadingModalEmitParams = {
  'update:shown': IUploadingModalProps['shown']
}

interface IUploadingModalStates {
  shown: boolean
  isFailed: boolean
  isCompleted: boolean
  isDuplicating: boolean
  modalHeader: ValueOf<typeof uploadHeaderType>
}

const FAILED_STATUSES = [
  UploadingModalStatus.FILE_COUNT_EXCEEDED,
  UploadingModalStatus.ILLEGAL,
  UploadingModalStatus.NETWORK_ERROR,
]

const COMPLETED_STATUSES = [
  UploadingModalStatus.SUCCEEDED,
  UploadingModalStatus.PARTIALLY_FAILED,
]

export const useUploadingModalStates = (
  props: IUploadingModalProps,
  emits: Emits<UploadingModalEmitParams>
) =>
  reactive<Computable<IUploadingModalStates>>({
    shown: computed({
      get() {
        return props.shown
      },
      set(value) {
        emits('update:shown', value)
      },
    }),
    isFailed: computed(() => FAILED_STATUSES.includes(props.uploadStatus)),
    isCompleted: computed(() =>
      COMPLETED_STATUSES.includes(props.uploadStatus)
    ),
    isDuplicating: computed(
      () => props.uploadStatus === UploadingModalStatus.DUPLICATING
    ),
    modalHeader: computed(() => convertUploadModalHeader(props.uploadStatus)),
  })
