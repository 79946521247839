import {
  IDispatchOrderMessage,
  IDispatchOrderDetail,
  IDispatchOrderTransportation,
  IDispatchOrderStatus,
} from '@/types/photographer/dispatchOrder'
import { computed, reactive } from 'vue'
import { Computable } from '@/types/utils'
import { RouteLocationRaw } from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { TransportationCode } from '@/utils/constants/enums/photographer/transportation'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { MESSAGES } from '@/utils/constants/messages'
import { AcceptAndRejectButtonProps } from './AcceptAndRejectButton'
import { ReplyStatus } from '@/utils/constants/enums/photographer/dispatchOrder'
import imgLabelAnswered from '@/media/img/proposalContent/label_answered.png'
import imgLabelEnd from '@/media/img/proposalContent/label_end.png'
import imgLabelExpired from '@/media/img/proposalContent/label_expired.png'
import { addPublicBasePath } from '@/utils/functions/vite'
import { createTitle } from './ProposalMessage'

export type ProposalContentProps = {
  userName: string
  dispatchOrderId: number
  dispatchOrderMessage: IDispatchOrderMessage
  dispatchOrderDetail: IDispatchOrderDetail
  dispatchOrderMemo: string
  dispatchOrderTransportation: IDispatchOrderTransportation
  dispatchOrderStatus: IDispatchOrderStatus
  onReply: AcceptAndRejectButtonProps['onReply']
}

type ProposalContentStates = {
  breadcrubs: IBreadcrumb[]
  selectedTransportation: TransportationCode
  otherTransportation: string
  isAcceptance: boolean
  notificationsLocation: RouteLocationRaw
  breadcrubsTitle: string
  isActiveStatusMessage: boolean
  statusMessage: string // 定員or回答済みの際に表示するメッセージ
  labelImg: string
}

/**
 * パンくずリストの生成
 * @param breadcrubsTitle パンくずリストタイトル
 * @returns パンくずリスト
 */
const createBreadcrubs = (
  breadcrubsTitle: ProposalContentStates['breadcrubsTitle']
) => [
  {
    label: MESSAGES.PHOTOGRAPHER.PAGE_TITLE.TOP,
    location: { name: photographerRoutes.NAME.TOP },
  },
  {
    label: MESSAGES.PHOTOGRAPHER.PAGE_TITLE.NOTIFICATIONS,
    location: { name: photographerRoutes.NAME.NOTIFICATIONS },
  },
  { label: breadcrubsTitle },
]

const createIsAcceptance = (states: ProposalContentStates) => {
  /** 交通手段が選択されているか */
  return states.selectedTransportation !== TransportationCode.INITIAL_VALUE
    ? /** 「その他」以外の交通手段が選択されているか */
      states.selectedTransportation !== TransportationCode.OTHER
      ? true
      : /** 「その他」が選択されている状態で交通手段が記入されているか */
        states.otherTransportation !== ''
    : false
}

/** 回答済みフラグ（受諾・拒否含む） */
const isAnswered = (props: ProposalContentProps) => {
  return props.dispatchOrderStatus.replyStatus !== ReplyStatus.UNANSWERED
}

/** 再打診された古い依頼内容であることを判定するフラグ */
const isBeforeDispatchOrder = (props: ProposalContentProps) => {
  return !props.dispatchOrderMessage.currentDispatchOrderId
}

const createStatusMessage = (props: ProposalContentProps) => {
  let message = ''
  if (isAnswered(props)) {
    if (props.dispatchOrderStatus.replyStatus === ReplyStatus.ACCEPTANCE) {
      message = 'この案件は「引き受ける」と回答をいただいてます'
    } else {
      message = 'この案件は「今回は断る」と回答をいただいてます'
    }
  } else {
    message = 'この案件は担当者が確定したため受付終了となりました'
  }
  return message
}

const createLabelImg = (props: ProposalContentProps) => {
  let labelImgName = ''
  if (isAnswered(props)) {
    labelImgName = imgLabelAnswered
  } else if (!props.dispatchOrderStatus.canReply) {
    labelImgName = imgLabelEnd
  } else if (props.dispatchOrderMessage.replyDeadlineCount < 0) {
    labelImgName = imgLabelExpired
  }
  return labelImgName
}

const notificationsLocation = {
  name: photographerRoutes.NAME.NOTIFICATIONS,
}

export const useProposalContentStates = (props: ProposalContentProps) => {
  const states: ProposalContentStates = reactive<
    Computable<ProposalContentStates>
  >({
    breadcrubs: computed(() => createBreadcrubs(states.breadcrubsTitle)),
    /** 受諾ボタンが押下可能かの判定 */
    isAcceptance: computed(() => createIsAcceptance(states)),
    selectedTransportation: props.dispatchOrderTransportation.transportation,
    otherTransportation: props.dispatchOrderTransportation.otherTransportation,
    notificationsLocation: notificationsLocation,
    breadcrubsTitle: computed(() => createTitle(props.dispatchOrderMessage)),
    /** 依頼状態メッセージ表示（=回答ボタン非表示）フラグ */
    isActiveStatusMessage: computed(
      () =>
        isBeforeDispatchOrder(props) &&
        (isAnswered(props) || props.dispatchOrderStatus.reachedCapacity)
    ),
    /** 定員or回答済みの際に表示するメッセージを返す */
    statusMessage: computed(() => createStatusMessage(props)),
    /** 特定の依頼状態の際に表示するラベルを返す */
    labelImg: computed(() =>
      createLabelImg(props) ? addPublicBasePath(createLabelImg(props)) : ''
    ),
  })
  return states
}
