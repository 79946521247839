<script setup lang="ts">
import { defineProps } from 'vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import { RowProps, useRowStates } from '.'

interface IProps {
  rowNo: RowProps['rowNo']
  date: RowProps['date']
  content: RowProps['content']
  amount: RowProps['amount']
  mileage: RowProps['mileage']
  gasUnitPrice: RowProps['gasUnitPrice']
}
const props = defineProps<IProps>()
const states = useRowStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;

.row {
  height: 54px;
  font-size: sizes.$font-size-md;
  color: colors.$font-color-primary;
}
.unit {
  margin-left: space(1);
}
.right {
  text-align: right;
}
</style>

<template>
  <TableRow :class="css.row">
    <TableCell :separatorable="false" width="40px">
      {{ props.rowNo }}.
    </TableCell>
    <TableCell :separatorable="false" width="128px">
      {{ states.displayDate }}
    </TableCell>
    <TableCell :separatorable="false" width="432px">
      {{ props.content }}
    </TableCell>
    <TableCell width="136px" :class="css.right">
      {{ states.displayAmount }}<span :class="css.unit">円</span>
    </TableCell>
    <TableRow :class="css.row">
      <TableCell width="96px" :class="css.right">
        {{ props.mileage }}<span :class="css.unit">km</span>
      </TableCell>
      <TableCell width="64px" :class="css.right">
        {{ props.gasUnitPrice }}<span :class="css.unit">円</span>
      </TableCell>
      <TableCell :separatorable="false" width="96px" :class="css.right">
        {{ states.displayMileageAmount }}<span :class="css.unit">円</span>
      </TableCell>
    </TableRow>
  </TableRow>
</template>
