import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { useRouter } from 'vue-router'
import { NotificationInfo } from '../..'

export interface IDeliveryEventNotificationProps {
  notificationInfos: NotificationInfo[]
}

export const useDeliveryEventNotificationActions = () => {
  const router = useRouter()

  return {
    async selectNotification(eventId: number): Promise<void> {
      await router.push({
        name: photographerRoutes.NAME.EVENT,
        params: { eventId },
      })
    },
  }
}
