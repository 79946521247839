<script setup lang="ts">
import { defineProps } from 'vue'
import { IUploadCompletedDialogProps, useUploadCompletedDialogStates } from '.'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'

type Props = {
  failedCount: IUploadCompletedDialogProps['failedCount']
}

const props = defineProps<Props>()
const states = useUploadCompletedDialogStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.text {
  margin: space(2) 0 space(17) space(2);
  display: flex;
  gap: space(2);
  flex-direction: column;
}
</style>

<template>
  <div :class="css.text">
    <Typography>アップロードが完了しました。内容をご確認ください。 </Typography>
    <Typography type="read-small-attention">{{ states.message }} </Typography>
  </div>
</template>
