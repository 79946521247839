<script setup lang="ts">
import { defineEmits, defineProps, withDefaults } from 'vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import CheckboxWithLabel from '@/vueComponents/photographer/molecules/CheckboxWithLabel/index.vue'
import FolderNameChangeLink from '../FolderNameChangeLink/index.vue'
import {
  FolderTableNameCellEmitParams,
  FolderTableNameCellProps,
  useFolderTableNameCellStates,
} from '.'

interface IProps {
  disabled?: FolderTableNameCellProps['disabled']
  checked: FolderTableNameCellProps['checked']
  eventDate: FolderTableNameCellProps['eventDate']
  eventName: FolderTableNameCellProps['eventName']
  folderName: FolderTableNameCellProps['folderName']
  eventStatus?: FolderTableNameCellProps['eventStatus']
  onUpdateName: FolderTableNameCellProps['onUpdateName']
}

interface IEmits {
  (
    e: 'update:checked',
    checked: FolderTableNameCellEmitParams['update:checked']
  ): void
}

const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
  eventStatus: undefined,
})

const emits = defineEmits<IEmits>()

const states = useFolderTableNameCellStates(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.cell-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 320px;
  height: 100%;
  gap: space(4);
}
.link {
  flex-basis: 124px;
}
</style>

<template>
  <TableCell :colspan="2">
    <div :class="css['cell-content']">
      <CheckboxWithLabel
        v-model:checked="states.checked"
        :disabled="props.disabled"
        :hide-checkbox="states.delivered"
        >{{ states.folderNamePrefix }}</CheckboxWithLabel
      >
      <div v-if="!states.delivered" :class="css.link">
        <FolderNameChangeLink
          :disabled="props.disabled"
          :event-date="props.eventDate"
          :event-name="props.eventName"
          :folder-name="props.folderName"
          :on-submit="props.onUpdateName"
        />
      </div>
    </div>
  </TableCell>
</template>
