import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { useRouter } from 'vue-router'
import { computed, reactive } from 'vue'
import { Computable } from '@/types/utils'

export type ToScheduleButtonProps = {
  targetYearMonth: string
}

type ToScheduleButtonStates = {
  targetYearMonth: string
}

export const useScheduleButtonStates = (props: ToScheduleButtonProps) => {
  const states: ToScheduleButtonStates = reactive<
    Computable<ToScheduleButtonStates>
  >({
    targetYearMonth: computed(() => props.targetYearMonth),
  })
  return states
}

export const useToScheduleButtonActions = (states: ToScheduleButtonStates) => {
  const router = useRouter()

  return {
    async toSchedulePage(): Promise<void> {
      await router.push({
        name: photographerRoutes.NAME.SCHEDULE,
        params: {
          scheduleMonth: states.targetYearMonth,
        },
      })
    },
  }
}
