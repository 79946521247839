<script setup lang="ts">
import { defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import {
  InvoiceDetailTableRowProps,
  useInvoiceDetailTableRowActions,
  useInvoiceDetailTableRowStates,
  InvoiceDetailTableRowEmitParams,
  MILEAGE_INPUT_MAX_LENGTH,
  inputNumberPlaceholder,
  contentInputPlaceholder,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTableRow'

interface IProps {
  detailInput: InvoiceDetailTableRowProps['detailInput']
  gasUnitPrice: InvoiceDetailTableRowProps['gasUnitPrice']
  id: InvoiceDetailTableRowProps['id']
  rowNo: InvoiceDetailTableRowProps['rowNo']
  canEdit: InvoiceDetailTableRowProps['canEdit']
  hasRowError: InvoiceDetailTableRowProps['hasRowError']
  dispatchCameramanId: InvoiceDetailTableRowProps['dispatchCameramanId']
}

interface IEmits {
  (
    e: 'update:detail-input',
    value: InvoiceDetailTableRowEmitParams['update:detail-input']
  ): void
  (e: 'delete-row'): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = useInvoiceDetailTableRowStates(props)
const actions = useInvoiceDetailTableRowActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/colors' as colors;
@use '@/styles/mixins/hover' as hover-mixins;

.input-container {
  @include position-mixins.flex-center;
}
.unit {
  margin-left: space(1);
}
.row-no-cell {
  width: 40px;
  min-width: 40px;
}
.date-cell {
  width: 159px;
  min-width: 159px;
}
.content-cell {
  width: 432px;
}
.amount-cell {
  width: 136px;
  min-width: 136px;
}
.mileage-cell {
  width: 96px;
  min-width: 96px;
}
.unit-price-cell {
  width: 64px;
  min-width: 64px;
}
.mileage-amount-cell {
  width: 96px;
  min-width: 96px;
}
.delete-cell {
  width: 64px;
  min-width: 64px;
  text-align: center;
}

.date-input {
  font-family: 'Noto Sans JP';
  min-width: 143px;
  box-sizing: border-box;
}
.amount-input,
.mileage-input {
  text-align: right;
}
tr {
  height: 54px;
  &:not(.mileage-row) {
    border-bottom: 1px solid colors.$table-border-bottom-color;
  }
}
td {
  border-bottom: none;
}
.delete-icon-block {
  cursor: pointer;
}
.delete-icon {
  color: colors.$font-color-primary;
  @include hover-mixins.hover-opacity;
}
</style>

<template>
  <TableRow :type="states.tableRowBgColor">
    <TableCell :separatorable="false" :class="css['row-no-cell']">
      <Typography>{{ props.rowNo }}.</Typography>
    </TableCell>
    <TableCell :separatorable="false" :class="css['date-cell']">
      <Input
        v-if="props.canEdit"
        v-model:value="states.detailInput.date"
        type="date"
        name="date-input"
        :class="css['date-input']"
      />
      <Typography v-else>{{ props.detailInput.date }}</Typography>
    </TableCell>
    <TableCell :separatorable="false" :class="css['content-cell']">
      <Input
        v-if="props.canEdit"
        v-model:value="states.detailInput.content"
        type="text"
        name="content-input"
        maxlength="50"
        :placeholder="contentInputPlaceholder"
      />
      <Typography v-else>{{ props.detailInput.content }}</Typography>
    </TableCell>
    <TableCell :class="css['amount-cell']">
      <div :class="css['input-container']">
        <Input
          v-model:value="states.detailInput.amount"
          type="text"
          name="amount-input"
          :placeholder="inputNumberPlaceholder"
          :class="css['amount-input']"
          @input="actions.handleAmountInput"
        />
        <Typography :class="css.unit">円</Typography>
      </div>
    </TableCell>
    <TableRow :type="states.tableRowBgColor" :class="css['mileage-row']">
      <TableCell :class="css['mileage-cell']">
        <div :class="css['input-container']">
          <Input
            v-model:value="states.detailInput.mileage"
            type="text"
            name="mileage-input"
            :maxlength="MILEAGE_INPUT_MAX_LENGTH"
            :placeholder="inputNumberPlaceholder"
            :class="css['mileage-input']"
            @input="actions.handleMileageInput"
          />
          <Typography :class="css.unit">km</Typography>
        </div>
      </TableCell>
      <TableCell :class="css['unit-price-cell']">
        <Typography text-align="center">{{ props.gasUnitPrice }} 円</Typography>
      </TableCell>
      <TableCell :class="css['mileage-amount-cell']">
        <Typography text-align="right"
          >{{ states.mileageAmount }} 円</Typography
        >
      </TableCell>
    </TableRow>
    <TableCell :separatorable="false" :class="css['delete-cell']">
      <div
        v-if="props.canEdit"
        :class="css['delete-icon-block']"
        @click="actions.deleteRow"
      >
        <Icon type="trash-alt" is-block :class="css['delete-icon']" />
      </div>
    </TableCell>
  </TableRow>
</template>
