import { BaseApi } from '../BaseApi'
import { IAccount, ICompany } from '@/types/photographer/account'

const PATH = {
  GET_ACCOUNT: '/photographer/api/v1.0/account',
  GET_COMPANY: '/photographer/api/v1.0/company',
} as const

interface Delivery {
  address: string
  zipcode: string
  prefecture_id: number
}

export interface IGetCompanyResponse {
  name: string
  name_kana: string
  location: Delivery
}

export interface IGetAccountResponse {
  name: string
  name_kana: string
  email: string
  tel: string
  delivery: Delivery
  invoice_number: string
}

interface IPutCompanyDelivery {
  address: string | null
  zipcode: string | null
  prefecture_id: number | null
}

interface IPutCompanyParams {
  name: string | null
  name_kana: string | null
  location: IPutCompanyDelivery
}

export interface IPutAccountParams {
  company: IPutCompanyParams
  name: string
  name_kana: string
  email: string
  tel: string
  delivery: Delivery
  invoice_number: string | null
}

export class AccountApi extends BaseApi {
  public async fetchAccount(): Promise<IAccount> {
    const { data } = await this.get<IGetAccountResponse>(PATH.GET_ACCOUNT)
    const { name, name_kana, email, tel, delivery, invoice_number } = data
    const { address, zipcode, prefecture_id } = delivery

    return {
      name,
      nameKana: name_kana,
      email,
      tel,
      delivery: {
        address,
        zipcode,
        prefectureId: prefecture_id,
      },
      invoiceNumber: invoice_number,
    }
  }

  public async fetchCompany(): Promise<ICompany> {
    const { data } = await this.get<IGetCompanyResponse>(PATH.GET_COMPANY)
    const { name, name_kana, location } = data
    const { address, zipcode, prefecture_id } = location
    return {
      name,
      nameKana: name_kana,
      location: {
        address,
        zipcode,
        prefectureId: prefecture_id,
      },
    }
  }

  public async updateAccount(account: IPutAccountParams): Promise<void> {
    await this.put<IPutAccountParams>(PATH.GET_ACCOUNT, account)
  }
}
