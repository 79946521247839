import { Component, onErrorCaptured, shallowReactive, watch } from 'vue'
import { NotFoundError } from '@/utils/modules/exceptions/NotFoundError'
import { InternalServerError } from '@/utils/modules/exceptions/InternalServerError'
import { FailedDependencyError } from '@/utils/modules/exceptions/FailedDependencyError'
import { ServiceUnavailableError } from '@/utils/modules/exceptions/ServiceUnavailableError'
import { ResetTokenExpiredError } from '@/utils/modules/exceptions/ResetTokenExpiredError'
import NetworkErrorPage from '@/vueComponents/photographer/pages/Error/NetworkError/index.vue'
import NotFoundErrorPage from '@/vueComponents/photographer/pages/Error/NotFoundError/index.vue'
import InternalServerErrorPage from '@/vueComponents/photographer/pages/Error/InternalServerError/index.vue'
import ServiceUnavailableErrorPage from '@/vueComponents/photographer/pages/Error/ServiceUnavailableError/index.vue'
import ResetTokenExpiredErrorPage from '@/vueComponents/photographer/pages/Error/ResetTokenExpiredError/index.vue'
import UnexpectedErrorPage from '@/vueComponents/photographer/pages/Error/UnexpectedError/index.vue'
import { isUnauthorizedError } from '@/utils/modules/exceptions/UnauthorizedError'
import { Router, useRoute, useRouter } from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { NetworkError } from '@/utils/modules/exceptions/NetworkError'
import { UnexpectedServerError } from '@/utils/modules/exceptions/UnexpectedServerError'

const { NAME: ROUTE_NAME } = photographerRoutes

interface IStates {
  errorComponent: Component | null
}

export const usePhotographerAppStates = () =>
  shallowReactive<IStates>({
    errorComponent: null,
  })

/**
 * セッション切れエラーハンドリング
 * @param error エラーオブジェクト
 * @param router Vueルーター
 * @returns true:セッション切れ発生 false:セッション切れ未発生
 */
const handleUnauthorizedError = (error: Error, router: Router): boolean => {
  if (!isUnauthorizedError(error)) return false
  router.push({ name: ROUTE_NAME.LOGIN }).catch((e) => {
    throw e
  })
  return true
}

/**
 * エラーページコンポーネントの取得
 * @param error エラーオブジェクト
 * @returns エラーページコンポーネントまたはnull
 */
const obtainErrorComponent = (error: Error): IStates['errorComponent'] => {
  switch (error.constructor) {
    case NetworkError:
      return NetworkErrorPage
    case NotFoundError:
      return NotFoundErrorPage
    case FailedDependencyError:
    case InternalServerError:
      return InternalServerErrorPage
    case ServiceUnavailableError:
      return ServiceUnavailableErrorPage
    case ResetTokenExpiredError:
      return ResetTokenExpiredErrorPage
    case UnexpectedServerError:
      return UnexpectedErrorPage
    default:
      return null
  }
}

export const usePhotographerAppAction = (states: IStates) => {
  const router = useRouter()
  const route = useRoute()

  onErrorCaptured((error: Error) => {
    if (handleUnauthorizedError(error, router)) return false
    states.errorComponent = obtainErrorComponent(error)
    if (states.errorComponent) return false
    return true
  })

  watch(
    () => route.path,
    () => (states.errorComponent = null)
  )
}
