import { Computable, Emits } from '@/types/utils'
import { computed, reactive } from 'vue'
import receipt from '@/media/img/invoice/receipt.png'
import purchaseOrder from '@/media/img/invoice/purchase_order.png'
import memo from '@/media/img/invoice/memo.png'
import { addPublicBasePath } from '@/utils/functions/vite'
import { InvoiceStatus } from '@/utils/constants/enums/photographer/invoice'

export type invoiceStatusInfo = {
  status: InvoiceStatus
  month: number
}

export type MaterialPreparationProps = {
  invoiceStatusInfo: invoiceStatusInfo
}

type MaterialPreparationStates = {
  buttonMessage: string
  receipt: string
  purchaseOrder: string
  memo: string
}

const createButtonMessage = (
  status: MaterialPreparationProps['invoiceStatusInfo']['status']
) => {
  switch (status) {
    case InvoiceStatus.CANNOT_CREATE:
    case InvoiceStatus.CREATEABLE:
      return '請求書を作成する'
    case InvoiceStatus.IN_PROGRESS:
      return '請求書を編集する'
    case InvoiceStatus.MODIFYING:
      return '請求書を修正する'
    default:
      return ''
  }
}

export const useMaterialPreparationStates = (
  props: MaterialPreparationProps
) => {
  const states: MaterialPreparationStates = reactive<
    Computable<MaterialPreparationStates>
  >({
    buttonMessage: computed(() =>
      createButtonMessage(props.invoiceStatusInfo.status)
    ),
    receipt: addPublicBasePath(receipt),
    purchaseOrder: addPublicBasePath(purchaseOrder),
    memo: addPublicBasePath(memo),
  })
  return states
}

export type MaterialPreparationEmitsParams = {
  'invoice-create:transition': void
}

type MaterialPreparationActions = {
  onClickButton: () => void
}

export const useMaterialPreparationActions = (
  emits: Emits<MaterialPreparationEmitsParams>
): MaterialPreparationActions => {
  return {
    onClickButton: () => {
      emits('invoice-create:transition')
    },
  }
}
