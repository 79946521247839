import { Computable, Emits } from '@/types/utils'
import { reactive, watch } from 'vue'
import { BankAccountInfo } from '@/vueComponents/photographer/organisms/pageContent/AccountPageContent/BankAccountInfo'
import { bankAccountConstants } from '@/utils/constants/bank-account'

export type BankAccountFormProps = {
  bankAccountInfo: BankAccountInfo
}

type AccountData = {
  bankCode: string
  bankName: string
  branchNumber: string
  branchName: string
  accountType: number
  accountNumber: string
  accountHolder: string
}

export type BankAccountFormEmitParams = {
  'update:bank-account-info': AccountData
}

type BankAccountFormStates = {
  savingAccount: number
  checkingAccount: number
  bankAccountData: AccountData
}

const notSetDataAccountType = bankAccountConstants.ACCOUNT_TYPE[1].VALUE
const savingAccountType = bankAccountConstants.ACCOUNT_TYPE[1].VALUE
const checkingAccountType = bankAccountConstants.ACCOUNT_TYPE[2].VALUE

const handleBankAccountType = (accountType: number): number =>
  accountType === notSetDataAccountType ? savingAccountType : accountType

export const useBankAccountFormStates = (
  props: BankAccountFormProps
): BankAccountFormStates => {
  const states: BankAccountFormStates = reactive<
    Computable<BankAccountFormStates>
  >({
    savingAccount: savingAccountType,
    checkingAccount: checkingAccountType,
    bankAccountData: {
      bankCode: props.bankAccountInfo.bankCode,
      bankName: props.bankAccountInfo.bankName,
      branchNumber: props.bankAccountInfo.branchNumber,
      branchName: props.bankAccountInfo.branchName,
      accountType: handleBankAccountType(props.bankAccountInfo.accountType),
      accountNumber: props.bankAccountInfo.accountNumber,
      accountHolder: props.bankAccountInfo.accountHolder,
    },
  })
  return states
}

export const useBankAccountFormActions = (
  states: BankAccountFormStates,
  emits: Emits<BankAccountFormEmitParams>
): void => {
  watch(
    () => states.bankAccountData,
    () => {
      emits('update:bank-account-info', states.bankAccountData)
    },
    { deep: true }
  )
}
