import { IDispatchOrderMessage } from '@/types/photographer/dispatchOrder'
import { Computable } from '@/types/utils'
import { computed, reactive } from 'vue'
import { parseJaDateYearWithDayOfWeekToString } from '@/utils/functions/parser'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { RouteLocationRaw } from 'vue-router'

export type ProposalMessageProps = {
  userName: string
  dispatchOrderMessage: IDispatchOrderMessage
  dispatchOrderCanReply: boolean
}

type ProposalMessageStates = {
  title: string
  beforeDispatchOrderLocation: RouteLocationRaw
  currentDispatchOrderLocation: RouteLocationRaw
}

export const createTitle = (dispatchOrderMessage: IDispatchOrderMessage) => {
  const reProposalTitle = `【再・案件依頼】 
  ${parseJaDateYearWithDayOfWeekToString(dispatchOrderMessage.startDate)}
   案件依頼内容 変更・確認のご連絡`

  const proposalTitle = `【案件依頼】 
  ${parseJaDateYearWithDayOfWeekToString(dispatchOrderMessage.startDate)}
  案件のご相談`

  return dispatchOrderMessage.beforeDispatchOrderId
    ? reProposalTitle
    : proposalTitle
}

export const useProposalMessageStates = (props: ProposalMessageProps) =>
  reactive<Computable<ProposalMessageStates>>({
    title: computed(() => createTitle(props.dispatchOrderMessage)),
    beforeDispatchOrderLocation: computed(() => ({
      name: photographerRoutes.NAME.PROPOSAL,
      params: {
        proposalId: props.dispatchOrderMessage.beforeDispatchOrderId,
      },
    })),
    currentDispatchOrderLocation: computed(() => ({
      name: photographerRoutes.NAME.PROPOSAL,
      params: {
        proposalId: props.dispatchOrderMessage.currentDispatchOrderId,
      },
    })),
  })
