import { BaseApi } from '../BaseApi'

export interface IFileUploadRequest {
  file: File
}

/** ファイルアップロードAPI */
export class FileUploadApi extends BaseApi {
  /**
   * ファイルアップロード
   * @param presignedUrl 署名付きURL
   * @param file アップロードファイル
   * @param onUploadProgress アップロード進捗コールバック
   */
  public async putFile(
    presignedUrl: string,
    file: IFileUploadRequest['file'],
    onUploadProgress?: (e: ProgressEvent) => void
  ): Promise<void> {
    await this.put<IFileUploadRequest['file']>(presignedUrl, file, {
      onUploadProgress,
    })
  }
}
