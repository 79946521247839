import { IScheduleReplyData } from '@/types/photographer/schedules'
import { ScheduleReplyStatus } from '@/utils/constants/enums/photographer/schedule'
import { BaseApi } from '../BaseApi'

const PATH = {
  FETCH_SCHEDULES: '/photographer/api/v1.0/schedules/:yearMonth',
  POST_SCHEDULES: '/photographer/api/v1.0/schedules',
}

type FetchSchedulesItem = {
  date: string
  plan: ScheduleReplyStatus
}

/** スケジュールAPI */
export class ScheduleApi extends BaseApi {
  /**
   * 月次スケジュール一覧の取得
   * @param yearMonth 年月
   * @param isDateNear 前後月の日付が必要か
   * @returns スケジュール一覧
   */
  public async fetchSchedulesOfYearMonth(
    yearMonth: string,
    isDateNear = false
  ): Promise<IScheduleReplyData[]> {
    const queryParams = isDateNear ? '?is_date_near=1' : ''
    const { data } = await this.get<FetchSchedulesItem[]>(
      PATH.FETCH_SCHEDULES + queryParams,
      {
        yearMonth,
      }
    )
    return data.map(({ date, plan }) => ({ date: new Date(date), plan }))
  }

  /**
   * スケジュール登録/更新
   * @param params スケジュール登録データ
   */
  public async postSchedulesOfYearMonth(
    params: FetchSchedulesItem[]
  ): Promise<void> {
    await this.post<FetchSchedulesItem[]>(PATH.POST_SCHEDULES, params)
  }
}
