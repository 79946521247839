import { Computable, Emits } from '@/types/utils'
import { reactive, computed, ref } from 'vue'
import { ExpiryStatus } from '@/utils/constants/enums/photographer/notifyDeparture/expiryStatus'

export const MESSAGE_TYPE = {
  UNNOTIFIED: 0,
  NOTIFIED: 1,
  EXPIRED: 2,
}

export interface INotifyDepartureContentProps {
  expiryStatus: number
  isNotified: boolean
}

type NotifyDepartureContentStates = {
  readonly isShowButton: boolean
  readonly messageType: number
  readonly canPushButton: boolean
  readonly isShowAttentionMessage: boolean
  isPushed: boolean
}

export const useNotifyDepartureContentStates = (
  props: INotifyDepartureContentProps
): NotifyDepartureContentStates => {
  const isBefore = computed(() => props.expiryStatus === ExpiryStatus.BEFORE)
  const isDuring = computed(() => props.expiryStatus === ExpiryStatus.DURING)
  const isAfter = computed(() => props.expiryStatus === ExpiryStatus.AFTER)
  const computedIsShowButton = computed(() =>
    isShowButton(isAfter.value, props.isNotified)
  )
  const isPushed = ref(false)
  const computedCanPushButton = computed(() =>
    canPushButton(isDuring.value, props.isNotified, isPushed.value)
  )
  const isShowAttentionMessage = computed(() => isBefore.value)
  const computedMessageType = computed(() =>
    messageType(props.isNotified, isAfter.value)
  )

  return reactive<Computable<NotifyDepartureContentStates>>({
    isShowButton: computedIsShowButton,
    messageType: computedMessageType,
    canPushButton: computedCanPushButton,
    isShowAttentionMessage,
    isPushed,
  })
}

const isShowButton = (isAfter: boolean, isNotified: boolean): boolean =>
  !isAfter && !isNotified

const canPushButton = (
  isDuring: boolean,
  isNotified: boolean,
  isPushed: boolean
): boolean => isDuring && !isNotified && !isPushed

const messageType = (isNotified: boolean, isAfter: boolean): number => {
  if (isNotified) return MESSAGE_TYPE.NOTIFIED
  if (isAfter) return MESSAGE_TYPE.EXPIRED
  return MESSAGE_TYPE.UNNOTIFIED
}

export const useNotifyDepartureContentActions = (
  emits: Emits<{ notify: void }>,
  states: NotifyDepartureContentStates
) => {
  const notify = (): void => {
    states.isPushed = true
    emits('notify')
  }

  return { notify }
}
