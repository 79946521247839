import { EventsApi } from '@/domains/api/event/EventsApi'
import { EventsEntity } from '@/entities/photographer/EventsEntity'
import { IEvent } from '@/types/photographer/event'
import { Computable } from '@/types/utils'
import { onCreate } from '@/utils/functions/lifecycle'
import { computed, reactive } from 'vue'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { photographerRoutes } from '@/vue/routers/photographer/routes'

export interface IEventsStates {
  events: IEvent[]
  breadcrumbs: IBreadcrumb[]
}

export const useEventsStates = () => {
  const eventsEntity = new EventsEntity()

  return reactive<Computable<IEventsStates>>({
    events: computed(() => eventsEntity.events),
    breadcrumbs: [
      {
        label: 'ホーム',
        location: { name: photographerRoutes.NAME.TOP },
      },
      {
        label: 'イベント一覧',
      },
    ],
  })
}

export const useEventsActions = () => {
  const eventsEntity = new EventsEntity()
  const eventsApi = new EventsApi()

  onCreate(async () => {
    if (!eventsEntity.events[0]) {
      eventsEntity.events = await eventsApi.fetchEvents()
    }
  })
}
