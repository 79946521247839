import { Emits } from '@/types/utils'
import { computed, reactive, watch } from 'vue'

export type RadioWithLabelProps = {
  radioValue: string | number
  selectedRadioValue: string | number
  inputValue: string
  disabled?: boolean
  disabledInput?: boolean
  name: string
  hasInput?: boolean
  inputWidth?: string
  placeholder?: string
}

export type RadioWithLabelEmitParams = {
  'update:selectedRadioValue': RadioWithLabelProps['selectedRadioValue']
  'update:inputValue': RadioWithLabelProps['inputValue']
}

type RadioWithLabelStates = {
  selectedRadioValue: RadioWithLabelProps['selectedRadioValue']
  inputValue: RadioWithLabelProps['inputValue']
}

export const useRadioWithLabelStates = (
  props: RadioWithLabelProps,
  emits: Emits<RadioWithLabelEmitParams>
): RadioWithLabelStates =>
  reactive({
    selectedRadioValue: computed({
      get: () => props.selectedRadioValue,
      set: (selectedRadioValue: RadioWithLabelProps['selectedRadioValue']) =>
        emits('update:selectedRadioValue', selectedRadioValue),
    }),
    inputValue: computed({
      get: () => props.inputValue,
      set: (inputValue: RadioWithLabelProps['inputValue']) => {
        emits('update:inputValue', inputValue)
      },
    }),
  })

export const useRadioWithLabelActions = (
  props: RadioWithLabelProps,
  states: RadioWithLabelStates
) => {
  watch(
    () => props.disabledInput,
    () => {
      if (props.disabledInput) {
        states.inputValue = ''
      }
    }
  )
}
