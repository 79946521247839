import { computed, CSSProperties, reactive } from 'vue'
import { Computable } from '@/types/utils'
import progressBarImgUrl from '@/media/img/progress_bar.png'
import { addPublicBasePath } from '@/utils/functions/vite'

export interface IProgressBarProps {
  progressRate: number
}

interface IProgressBarStates {
  progressing: boolean
  style: CSSProperties
}

export const useProgressBarStates = (props: IProgressBarProps) => {
  const progressing = computed(() => props.progressRate < 1)

  return reactive<Computable<IProgressBarStates>>({
    progressing,
    style: computed(() => ({
      width: `${props.progressRate * 100}%`,
      background: progressing.value
        ? `url(${addPublicBasePath(progressBarImgUrl)})`
        : undefined,
    })),
  })
}
