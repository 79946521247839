import { RouteLocationRaw, useRouter } from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { TopStates } from '@/vueComponents/photographer/pages/Top'

export type EventProps = {
  events: TopStates['events']
}

export const useEventActions = () => {
  const router = useRouter()

  const eventsPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.EVENTS,
  }

  return {
    async toEvents(): Promise<void> {
      await router.push(eventsPageLocation)
    },
  }
}
