import { onBeforeUnmount, onMounted } from 'vue'

export const preventReload = () => {
  const beforeUnloadListener = (event: BeforeUnloadEvent) => {
    event.preventDefault()
  }
  onMounted(() => {
    window.addEventListener('beforeunload', beforeUnloadListener)
  })
  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', beforeUnloadListener)
  })
}
