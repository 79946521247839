<script setup lang="ts">
import { defineEmits, defineProps, reactive, withDefaults, useSlots } from 'vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import { ERRORMSG } from '@/utils/constants/messages/photographer/errorMsg'
import { IAccountNumberInputProps, IAccountNumberInputStates } from '.'
import {
  formatNumberInput,
  prependZeroCode,
} from '@/utils/functions/validation/validation'

interface IProps {
  modelValue: IAccountNumberInputProps['modelValue']
  name: IAccountNumberInputProps['name']
  maxlength: IAccountNumberInputProps['maxlength']
  placeholder?: IAccountNumberInputProps['placeholder']
  width?: IAccountNumberInputProps['width']
}

interface IInputEmits {
  (e: 'update:modelValue', value: string): void
}

const props = withDefaults(defineProps<IProps>(), {
  placeholder: '',
  width: '100%',
})
const states = reactive<IAccountNumberInputStates>({
  hasError: false,
})
const emits = defineEmits<IInputEmits>()
const slots = useSlots()

const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement
  const validatedValue = formatNumberInput(
    target.value,
    props.maxlength as number
  )
  target.value = validatedValue
  emits('update:modelValue', validatedValue)
  if (target.value && target.value !== '') {
    states.hasError = false
  }
}

const handleBlur = (e: Event) => {
  const target = e.target as HTMLInputElement
  emits(
    'update:modelValue',
    prependZeroCode(target.value, props.maxlength as number)
  )
  if (!target.value) {
    states.hasError = true
  }
}
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;

.input-error {
  margin-top: space(1);
}

.link {
  margin-left: space(2);
  display: inline-block;
}
</style>

<template>
  <div>
    <Input
      type="text"
      :value="props.modelValue"
      :name="props.name"
      :placeholder="props.placeholder"
      :maxlength="props.maxlength"
      :has-error="states.hasError"
      :width="props.width"
      @input="handleInput"
      @blur="handleBlur"
    ></Input>
    <div v-if="!!slots.link" :class="css.link">
      <slot name="link" />
    </div>
    <InputError
      v-if="states.hasError"
      type="attention"
      :class="css['input-error']"
      >{{ ERRORMSG.NOT_ENTERED }}</InputError
    >
  </div>
</template>
