import { SelfStore, selfStoreKey } from '@/vue/stores/photographer/SelfStore'
import { BaseEntity } from '@/entities/BaseEntity'
import { ISelf } from '@/types/photographer/self'

export class SelfEntity extends BaseEntity<SelfStore> {
  constructor() {
    super(selfStoreKey)
  }

  public get name(): string {
    return this.store.name.value
  }

  public get email(): string {
    return this.store.email.value
  }

  public get initialPasswordFlg(): boolean {
    return this.store.initialPasswordFlg.value
  }

  public set initialPasswordFlg(initialPasswordFlg: boolean) {
    this.store.initialPasswordFlg.value = initialPasswordFlg
  }

  public get isScheduleRegistered(): boolean {
    return this.store.isScheduleRegistered.value
  }

  public get invoiceGenerationFlg(): boolean {
    return this.store.invoiceGenerationFlg.value
  }

  public setIsScheduleRegistered(
    isScheduleRegistered: ISelf['isScheduleRegistered']
  ): void {
    this.store.isScheduleRegistered.value = isScheduleRegistered
  }

  public setSelf(self: ISelf): void {
    this.store.name.value = self.name
    this.store.email.value = self.email
    this.initialPasswordFlg = self.initialPasswordFlg
    this.store.isScheduleRegistered.value = self.isScheduleRegistered
    this.store.invoiceGenerationFlg.value = self.invoiceGenerationFlg
  }
}
