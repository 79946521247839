import {
  isUnprocessableEntityError,
  UnprocessableEntityError,
} from '@/utils/modules/exceptions/UnprocessableEntityError'

/**
 * エラーを無視可能か判定
 * @param e エラーオブジェクト
 * @returns true:無視しても問題ない false:通知かハンドリングが必須
 */
export const canBeErrorIgnored = (e: unknown): boolean =>
  !e || e instanceof UnprocessableEntityError

/**
 * APIバリデーションエラーメッセージの抽出
 * @template T APIリクエストの型
 * @param e エラーオブジェクト
 * @param propNames バリデーション対象プロパティ名
 * @returns エラーメッセージ配列
 * @throws {e} 対象プロパティのエラーメッセージ未設定の場合再throw
 */
export const obtainEntityErrorMessage = <T extends object>(
  e: unknown,
  ...propNames: (keyof T)[]
): string[] => {
  if (!(isUnprocessableEntityError<T>(e) && e.errors)) throw e
  const errorMessages = propNames
    .flatMap((param) => e.errors?.[param] ?? [])
    .filter((message) => message)
  if (errorMessages.length === 0) throw e
  return errorMessages
}
