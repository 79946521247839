<script setup lang="ts">
import { defineProps } from 'vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import { ToEventPageButtonProps, useToEventPageButtonActions } from '.'

type Props = {
  eventId: ToEventPageButtonProps['eventId']
}

const props = defineProps<Props>()

const actions = useToEventPageButtonActions(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/space' as space-mixins;
.container {
  @include position-mixins.flex-center(true, false);
  @include space-mixins.vertical-margin(6, 6);
}
.btn {
  width: 288px;
}
</style>

<template>
  <div :class="css.container">
    <Button :class="css.btn" color="secondary" @click="actions.toEventPage"
      >フォルダ一覧へ</Button
    >
  </div>
</template>
