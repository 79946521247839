import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { BankAccountInfo } from './BankAccountInfo'
import { useRouter, RouteLocationRaw } from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { IEvent } from '@/types/photographer/event'
import { IAccount, ICompany } from '@/types/photographer/account'

export interface IAccountPageContentProps {
  account: IAccount
  company: ICompany
  bankAccountInfo: BankAccountInfo
  breadcrumbs: IBreadcrumb[]
  invoiceGenerationFlg: boolean
  events: IEvent[]
}

export const useAccountPageContentActions = () => {
  const router = useRouter()
  const accountEditPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.ACCOUNT_EDIT,
  }
  const accountEditBankPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.ACCOUNT_EDIT_BANK,
  }
  return {
    async toAccountEdit(): Promise<void> {
      await router.push(accountEditPageLocation)
    },
    async toAccountEditBank(): Promise<void> {
      await router.push(accountEditBankPageLocation)
    },
  }
}
