import { IPhoto } from '@/types/photographer/photo'

export class UploadedPhoto {
  constructor(
    public readonly imgName: IPhoto['imgName'],
    public readonly orgImgName: IPhoto['orgImgName'],
    public readonly photographed: IPhoto['photographed'],
    public readonly status: IPhoto['status'],
    public readonly height: IPhoto['height'],
    public readonly width: IPhoto['width']
  ) {}

  public static createByPhotoData(photo: IPhoto) {
    return new UploadedPhoto(
      photo.imgName,
      photo.orgImgName,
      photo.photographed,
      photo.status,
      photo.height,
      photo.width
    )
  }
}
