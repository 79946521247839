import { RowProps } from './Row'

export type Detail = {
  date: string
  content: string
  amount: number
  mileage: number
}

export type TableProps = {
  details: Detail[]
  gasUnitPrice: number
}

type TableStates = {
  details: RowProps[]
}

export const useTableStates = (props: TableProps): TableStates => {
  return {
    details: props.details.map((detail, index) => {
      return {
        rowNo: index + 1,
        date: detail.date,
        content: detail.content,
        amount: detail.amount,
        mileage: detail.mileage,
        gasUnitPrice: props.gasUnitPrice,
      }
    }),
  }
}
