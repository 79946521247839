import { CreatingEntity } from '@/entities/global/CreatingEntity'
import { Promisable } from '@/types/utils'
import { ValidationCode } from '@/utils/constants/enums/validation'
import { convertErrorMsgLoginId } from '@/utils/functions/errorMsg'
import { createOnAsync } from '@/utils/functions/lifecycle'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { reactive } from 'vue'
import { Router, useRouter } from 'vue-router'

const { NAME: ROUTE_NAME } = photographerRoutes

export interface IResetMailFormContentProps {
  onClick?: (loginId: string) => Promisable<string[] | void>
}

interface IResetMailFormContentStates {
  loginId: string
  loginIdValidationCode: ValidationCode
  errorMessages: string[]
  isSendingEmail: boolean
}

export const useResetMailFormContentStates = () =>
  reactive<IResetMailFormContentStates>({
    loginId: '',
    loginIdValidationCode: ValidationCode.EMPTY,
    errorMessages: [],
    isSendingEmail: false,
  })

type ResetMailFormContentActions = {
  updateLoginIdValidationCode: (code: ValidationCode) => void
  handleSendMailButtonClick: () => Promise<void>
}

type SendMailPayload = {
  props: IResetMailFormContentProps
  states: IResetMailFormContentStates
  router: Router
  onAsync: (action: () => Promise<void>) => Promise<void>
}

/**
 * 入力項目のバリデーション
 * @param payload ペイロードデータ
 * @returns true:エラーなし false:エラーあり
 */
const validate = ({ states }: SendMailPayload): boolean => {
  const validationMessages: string[] = []
  const { loginIdValidationCode } = states
  if (loginIdValidationCode !== ValidationCode.SUCCESS) {
    validationMessages.push(convertErrorMsgLoginId(loginIdValidationCode))
  }
  if (validationMessages.length > 0) {
    states.errorMessages = validationMessages
    return false
  }
  return true
}

/**
 * メール送信
 * @param payload ペイロードデータ
 * @returns true:送信成功 false:送信失敗
 */
const sendMail = async (payload: SendMailPayload): Promise<boolean> => {
  const { props, states } = payload
  states.isSendingEmail = true
  const errorMessages = await props.onClick?.(states.loginId)
  states.isSendingEmail = false
  if (errorMessages) {
    states.errorMessages = errorMessages
    return false
  }
  return true
}

/**
 * メール送信ボタン押下時処理を生成
 * @param payload ペイロードデータ
 */
const createSendMailButtonClickHandler =
  (
    payload: SendMailPayload
  ): ResetMailFormContentActions['handleSendMailButtonClick'] =>
  async () =>
    await payload.onAsync(async () => {
      if (!validate(payload)) return
      const sendMailin = await sendMail(payload)
      if (!sendMailin) return
      await payload.router.push({ name: ROUTE_NAME.RESET_MAIL_COMPLETE })
    })

export const useResetMailFormContentActions = (
  props: IResetMailFormContentProps,
  states: IResetMailFormContentStates
): ResetMailFormContentActions => {
  const payload: SendMailPayload = {
    props,
    states,
    router: useRouter(),
    onAsync: createOnAsync(new CreatingEntity(), 'send mail'),
  }

  return {
    updateLoginIdValidationCode: (code) => {
      states.loginIdValidationCode = code
    },
    handleSendMailButtonClick: createSendMailButtonClickHandler(payload),
  }
}
