import { BaseEntity } from '@/entities/BaseEntity'
import { IEvent } from '@/types/photographer/event'
import {
  EventsStore,
  eventsStoreKey,
} from '@/vue/stores/photographer/EventsStore'

export class EventsEntity extends BaseEntity<EventsStore> {
  constructor() {
    super(eventsStoreKey)
  }

  public set events(events: IEvent[]) {
    this.store.events.value = events
  }

  public get events(): IEvent[] {
    return this.store.events.value
  }
}
