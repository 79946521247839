import {
  IPostPasswordUpdateRequest,
  ResetPasswordApi,
} from '@/domains/api/account/ResetPasswordApi'
import { isUnprocessableEntityError } from '@/utils/modules/exceptions/UnprocessableEntityError'
import { PasswordUpdateContentProps } from '@/vueComponents/photographer/organisms/pageContent/PasswordUpdateContent'

export const usePasswordUpdateActions = () => {
  const resetPasswordApi = new ResetPasswordApi()
  const updatePassword: PasswordUpdateContentProps['onClickButton'] = async (
    password: string
  ): Promise<void | string[]> => {
    try {
      await resetPasswordApi.postPasswordUpdate(password, password)
    } catch (e) {
      if (
        isUnprocessableEntityError<IPostPasswordUpdateRequest>(e) &&
        e.errors
      ) {
        const { errors } = e
        const passwordMessage = errors.password || []
        const passwordConfirmationMessage = errors.password_confirmation || []
        return [...passwordMessage, ...passwordConfirmationMessage]
      }
      throw e
    }
  }
  return { updatePassword }
}
