import { photographerConstants } from '@/utils/constants/photographerConstants'
import { UploadingPhoto } from '../UploadingPhoto'

const { FILE_UPLOAD } = photographerConstants

/**
 * アップロード処理済みファイル数算出
 * @param uploadingPhotos アップロードファイルセット
 * @returns ファイル数
 */
export const calcUploadCompletedCount = (
  uploadingPhotos: Set<UploadingPhoto>
): number =>
  [...uploadingPhotos].filter(
    (uploadingPhoto) => uploadingPhoto.isCompleted || uploadingPhoto.hasError
  ).length

/**
 * アップロード全体進捗率
 * @param uploadingPhotos アップロードファイルセット
 * @returns 進捗率
 */
export const calcWholeUploadingProgressRate = (
  uploadingPhotos: Set<UploadingPhoto>
): number => {
  if (uploadingPhotos.size === 0) return 0
  return (
    [...uploadingPhotos].reduce(
      (sum, uploadingPhoto) => sum + uploadingPhoto.progressRate,
      0
    ) / uploadingPhotos.size
  )
}

/**
 * アップロード対象ファイルサイズ合計算出
 * @param uploadingPhotos アップロードファイルセット
 * @returns ファイルサイズ
 */
// TODO 現在使われていないが仕様変更の可能性があるため残しておく
export const calcTotalUploadingFileSize = (
  uploadingPhotos: Set<UploadingPhoto>
): number => {
  if (uploadingPhotos.size === 0) return 0
  const fileSize = [...uploadingPhotos].reduce(
    (sum, uploadingPhoto) => sum + uploadingPhoto.size,
    0
  )
  return Math.ceil(fileSize / FILE_UPLOAD.SIZE_UNIT)
}

/**
 * アップロード処理済みファイルサイズ合計算出
 * @param uploadingPhotos アップロードファイルセット
 * @returns ファイルサイズ
 */
// TODO 現在使われていないが仕様変更の可能性があるため残しておく
export const calcCompletedUploadingFileSize = (
  uploadingPhotos: Set<UploadingPhoto>
): number => {
  if (uploadingPhotos.size === 0) return 0
  const fileSize = [...uploadingPhotos].reduce(
    (sum, uploadingPhoto) => sum + uploadingPhoto.completedSize,
    0
  )
  return Math.ceil(fileSize / FILE_UPLOAD.SIZE_UNIT)
}
