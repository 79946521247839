export interface CompanyNameInputProps {
  modelValue: string
  companyNameErrorMessage: string
}

interface CompanyNameInputEmits {
  (e: 'update:modelValue', value: string): void
  (e: 'input:companyName'): void
}

type CompanyNameActions = {
  handleInputCompanyName(e: Event): void
}

export const useCompanyNameInputActions = (
  emits: CompanyNameInputEmits
): CompanyNameActions => {
  return {
    handleInputCompanyName(e: Event) {
      const target = e.target as HTMLInputElement
      emits('update:modelValue', target.value)
      emits('input:companyName')
    },
  }
}
