<script setup lang="ts">
import { defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import CalendarHeader from '@/vueComponents/photographer/organisms/pageContent/TopPageContent/Calendar/Header/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import ToScheduleButton from '@/vueComponents/photographer/organisms/pageContent/TopPageContent/ToScheduleButton/index.vue'
import { CalendarProps, useCalendarStates } from '.'
import VueElementLoading from 'vue-element-loading'

interface IProps {
  todaysDate: CalendarProps['todaysDate']
  displayYearMonth: CalendarProps['displayYearMonth']
  schedules: CalendarProps['schedules']
  isLoading: CalendarProps['isLoading']
  clickPrev: CalendarProps['clickPrev']
  clickNext: CalendarProps['clickNext']
}

const props = defineProps<IProps>()
const states = useCalendarStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;

.container {
  width: 256px;
  background: colors.$background-color-content-primary;
  border-radius: 4px;
  padding-bottom: space(4);
}
.calendar {
  margin-top: 5px;
  padding: 11px 0 space(4);
}
.month {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 space(6);
}
.date-area {
  display: table;
  width: 100%;
  min-height: 191px;
  margin-top: space(4);
  padding: 0 space(4);
  box-sizing: border-box;
}
.day-of-week-items {
  display: table-row;
}
.day-of-week-item {
  display: table-cell;
  text-align: center;
  padding-bottom: space(2);
}
.dates {
  display: table-row-group;
}
.week {
  display: table-row;
}
.day {
  display: table-cell;
  text-align: center;
}
.day-number {
  z-index: 1;
}
.is-today {
  position: relative;
  &::after {
    z-index: -999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -52%);
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: colors.$background-color-primary;
  }
}
.sign-area {
  height: 8px;
  margin-top: 3px;
  padding-bottom: 3px;
  .sign {
    width: 6px;
    height: 6px;
    margin: auto;
  }
}
.is-vacant {
  border-radius: 3px;
  background: colors.$background-color-primary;
}
.is-translucent {
  opacity: 0.5;
}
.separator {
  margin: 0 space(4);
}
.footer {
  margin: auto;
  width: 192px;
}
.button {
  margin-top: space(4);
}
</style>

<template>
  <div :class="css.container">
    <CalendarHeader />
    <div :class="css.calendar">
      <div :class="css.month">
        <Link
          v-show="!states.isDisabledLastMonth"
          :class="css.prev"
          @click.capture="props.clickPrev"
          >前月</Link
        >
        <Typography
          v-show="states.isDisabledLastMonth"
          :class="css.prev"
          type="read-small-disabled"
          element="span"
          >前月</Typography
        >
        <Typography :class="css.current" type="head-large">{{
          states.displayYearMonth
        }}</Typography>
        <Link :class="css.next" @click.capture="props.clickNext">翌月</Link>
      </div>
      <div :class="css['date-area']">
        <div :class="css['day-of-week-items']">
          <div
            v-for="(dayOfWeekItem, dayOfWeekItemIndex) in states.dayOfWeekItems"
            :key="dayOfWeekItemIndex"
            :class="css['day-of-week-item']"
          >
            <Typography type="read-small-disabled-deep">{{
              dayOfWeekItem
            }}</Typography>
          </div>
        </div>
        <div :class="css.dates">
          <div
            v-for="(calendarItem, calendarItemIndex) in states.calendarItems"
            :key="calendarItemIndex"
            :class="css.week"
          >
            <div
              v-for="(weekItem, weekItemIndex) in calendarItem"
              :key="weekItemIndex"
              :class="css.day"
            >
              <div
                :class="[
                  css['day-number'],
                  { [css['is-today']]: weekItem.isToday },
                ]"
              >
                <Typography
                  :type="
                    weekItem.isToday
                      ? 'read-small-white'
                      : weekItem.isCurrentMonth
                      ? 'read-small'
                      : 'read-small-disabled-deep'
                  "
                  >{{ weekItem.day }}</Typography
                >
              </div>
              <div :class="css['sign-area']">
                <div
                  :class="[
                    css.sign,
                    { [css['is-vacant']]: weekItem.isVacant },
                    { [css['is-translucent']]: !weekItem.isCurrentMonth },
                  ]"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VueElementLoading
        :style="{ zIndex: 1 }"
        :active="props.isLoading"
        :is-full-screen="false"
        spinner="spinner"
        size="37"
        duration="0.7"
        color="#6DB043"
      />
    </div>
    <div :class="css.separator">
      <Separator />
    </div>
    <div :class="css.footer">
      <ToScheduleButton
        :class="css.button"
        :target-year-month="props.displayYearMonth"
      />
    </div>
  </div>
</template>
