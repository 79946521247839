import { InjectionKey, ref } from 'vue'
import { BaseStore } from '../..'
import { UploadedPhoto } from './UploadedPhoto'

export class FolderStore extends BaseStore {
  failedPhotos = ref(new Map<number, UploadedPhoto>())
  uploadedPhotos = ref(new Map<number, UploadedPhoto>())
}

export const folderStoreKey: InjectionKey<FolderStore> =
  Symbol('FolderStoreKey')
