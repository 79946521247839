<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { ScheduleReplyStatus } from '@/utils/constants/enums/photographer/schedule'
import ScheduleRegistrationButtons from '@/vueComponents/photographer/organisms/pageContent/ScheduleContent/ScheduleRegistrationButtons/index.vue'
import {
  ScheduleTableRowParams,
  ScheduleTableRowProps,
  useScheduleTableRowStates,
} from '.'

interface IProps {
  date: ScheduleTableRowProps['date']
  scheduleReplyStatus: ScheduleTableRowProps['scheduleReplyStatus']
  disabled?: ScheduleTableRowProps['disabled']
}

interface IEmits {
  (
    e: 'update:scheduleReplyObj',
    value: ScheduleTableRowParams['update:scheduleReplyObj']
  ): void
}

const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useScheduleTableRowStates(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
.row {
  background-color: colors.$background-color-schedule-row;
}
.cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
}
</style>

<template>
  <TableRow
    :class="{
      [css.row]:
        states.scheduleReplyStatus === ScheduleReplyStatus.OK && !disabled,
    }"
  >
    <TableCell :class="css.cell" :separatorable="false">
      <Typography :type="disabled ? 'read-normal-disabled' : 'read-normal'">{{
        states.date
      }}</Typography>
      <ScheduleRegistrationButtons
        v-model:schedule-reply-status="states.scheduleReplyStatus"
        :disabled="props.disabled"
      />
    </TableCell>
  </TableRow>
</template>
