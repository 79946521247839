import {
  IPostPasswordEmailRequest,
  ResetPasswordApi,
} from '@/domains/api/account/ResetPasswordApi'
import { isUnprocessableEntityError } from '@/utils/modules/exceptions/UnprocessableEntityError'

export const useResetMailFormActions = () => {
  const resetPasswordApi = new ResetPasswordApi()
  return {
    async passwordLoginId(loginId: string): Promise<string[] | void> {
      try {
        await resetPasswordApi.postPasswordEmail(loginId)
      } catch (e) {
        if (
          isUnprocessableEntityError<IPostPasswordEmailRequest>(e) &&
          e.errors
        ) {
          const { errors } = e
          const loginIdMessage = errors.login_id || []
          return [...loginIdMessage]
        }
        throw e
      }
    },
  }
}
