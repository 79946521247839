<script setup lang="ts">
import LoginContent from '@/vueComponents/photographer/organisms/pageContent/LoginContent/index.vue'
import { useLoginActions } from '.'

const actions = useLoginActions()
</script>

<template>
  <div>
    <LoginContent :on-click="actions.login" />
  </div>
</template>
