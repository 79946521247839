import { Computable, Emits } from '@/types/utils'
import { reactive, watch } from 'vue'
import { AccountInfoProps } from '@/vueComponents/photographer/organisms/pageContent/AccountPageContent/AccountInfo'
import {
  validateNotEntered,
  validateZipcode,
} from '@/utils/functions/validation/validation'

export type AccountInfoFormProps = {
  account: AccountInfoProps['account']
  company: AccountInfoProps['company']
  validationFlg: boolean
}

type CompanyErrorMessages = {
  companyNameErrorMessage: string
  companyNameKanaErrorMessage: string
  locationZipcodeErrorMessage: string
  locationAddressErrorMessage: string
  locationPrefectureErrorMessage: string
}

type AccountInfoFormStates = {
  account: AccountInfoProps['account']
  company: AccountInfoProps['company']
} & CompanyErrorMessages

export type AccountInfoFormEmitParams = {
  'update:account-info': AccountInfoProps
  'validation:off': void
}

const validationCheck = (states: AccountInfoFormStates) => {
  const {
    name: companyName,
    nameKana: companyNameKana,
    location,
  } = states.company
  const { zipcode, prefectureId, address } = location

  const isCompanyFormEmpty =
    '法人情報・事務局名・団体名を登録する場合は入力してください'
  const zipcodeFormatErrorMessage = '郵便番号は7桁で入力してください'

  if (validateNotEntered(companyName)) {
    states.companyNameErrorMessage = isCompanyFormEmpty
  }
  if (validateNotEntered(companyNameKana)) {
    states.companyNameKanaErrorMessage = isCompanyFormEmpty
  }
  if (validateNotEntered(zipcode) || !validateZipcode(zipcode)) {
    states.locationZipcodeErrorMessage = validateNotEntered(zipcode)
      ? isCompanyFormEmpty
      : zipcodeFormatErrorMessage
  }
  if (validateNotEntered(prefectureId)) {
    states.locationPrefectureErrorMessage = isCompanyFormEmpty
  }
  if (validateNotEntered(address)) {
    states.locationAddressErrorMessage = isCompanyFormEmpty
  }
}

export const useAccountInfoFormStates = (
  props: AccountInfoProps
): AccountInfoFormStates => {
  const states: AccountInfoFormStates = reactive<
    Computable<AccountInfoFormStates>
  >({
    account: props.account,
    company: props.company,
    companyNameErrorMessage: '',
    companyNameKanaErrorMessage: '',
    locationZipcodeErrorMessage: '',
    locationAddressErrorMessage: '',
    locationPrefectureErrorMessage: '',
  })
  return states
}

type AccountInfoFormActions = {
  handleInputField(fieldName: string): void
  validateCompanyForm(): void
}

type AccountInfoFormPayload = {
  states: AccountInfoFormStates
  props: { validationFlg: boolean }
  emits: Emits<AccountInfoFormEmitParams>
}

const handleInputFieldErrorMessage = (
  states: AccountInfoFormStates,
  fieldName: string
): void => {
  switch (fieldName) {
    case 'companyName': {
      states.companyNameErrorMessage = ''
      break
    }
    case 'companyNameKana': {
      states.companyNameKanaErrorMessage = ''
      break
    }
    case 'locationZipcode': {
      states.locationZipcodeErrorMessage = ''
      break
    }
    case 'locationAddress': {
      states.locationAddressErrorMessage = ''
      break
    }
    case 'locationPrefecture': {
      states.locationPrefectureErrorMessage = ''
      break
    }
  }
}

const handleWatchAccountInfo = ({
  states,
  emits,
}: {
  states: AccountInfoFormPayload['states']
  emits: AccountInfoFormPayload['emits']
}) => {
  watch(
    () => [states.account, states.company],
    () => {
      emits('update:account-info', {
        account: states.account,
        company: states.company,
      })
    },
    { deep: true }
  )
}

const handleWatchValidationFlg = ({
  states,
  props,
  emits,
}: AccountInfoFormPayload) => {
  watch(
    () => props.validationFlg,
    () => {
      if (props.validationFlg) {
        validationCheck(states)
        emits('validation:off')
      }
    }
  )
}

export const useAccountInfoFormActions = (
  states: AccountInfoFormStates,
  props: { validationFlg: boolean },
  emits: Emits<AccountInfoFormEmitParams>
): AccountInfoFormActions => {
  handleWatchAccountInfo({ states, emits })
  handleWatchValidationFlg({ states, props, emits })

  return {
    handleInputField(fieldName: string) {
      handleInputFieldErrorMessage(states, fieldName)
    },
    validateCompanyForm() {
      validationCheck(states)
    },
  }
}
