<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Modal from '@/vueComponents/photographer/molecules/Modal/index.vue'
import {
  IModalProps,
  ModalEmitParams,
  useModalStates,
  useModalActions,
} from '.'

interface IProps {
  shown: IModalProps['shown']
}

interface IEmits {
  (e: 'update:shown', shown: ModalEmitParams['update:shown']): void
  (e: 'to-home'): void
  (e: 'continue-edit'): void
}

const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useModalStates(props, emits)
const actions = useModalActions(emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;

.body {
  padding: space(8) space(6);
  min-height: 160px;
  box-sizing: border-box;
}
</style>

<template>
  <Modal
    v-model:shown="states.shown"
    :on-submit="actions.continueEdit"
    :on-close="actions.continueEdit"
    :on-before-cancel="actions.toHome"
  >
    <template #header>保存が完了しました</template>
    <div :class="css.body">
      請求書が保存されました。<br />
      編集を続けますか？
    </div>
    <template #submitLabel>編集を続ける</template>
    <template #cancelLabel>ホームへ戻る</template>
  </Modal>
</template>
