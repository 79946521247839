<script setup lang="ts">
import { defineProps } from 'vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import { CompanyNameInputProps, useCompanyNameInputActions } from '.'

interface IProps {
  modelValue: CompanyNameInputProps['modelValue']
  companyNameErrorMessage: CompanyNameInputProps['companyNameErrorMessage']
}

interface IEmits {
  (e: 'update:modelValue', value: string): void
  (e: 'input:companyName'): void
}

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const actions = useCompanyNameInputActions(emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as space-positions;
@use '@/styles/mixins/font' as font-mixins;

.title {
  @include font-mixins.font-weight-bold();
}
.table-cell div,
.col {
  @include space-positions.flex-horizontal(space(2));
}
.table-cell {
  padding: space(3) space(2);
  width: 256px;
}
.input-error {
  margin-top: space(1);
}
</style>

<template>
  <TableRow>
    <TableCell :separatorable="false" :class="css['table-cell']">
      <Typography :class="css.title">法人名・事業所名・団体名</Typography>
    </TableCell>
    <TableCell :separatorable="false">
      <Input
        :value="props.modelValue"
        type="text"
        name="companyName"
        placeholder="株式会社うるる"
        maxlength="50"
        width="256px"
        :has-error="Boolean(props.companyNameErrorMessage)"
        @input="actions.handleInputCompanyName"
      ></Input>
      <InputError
        v-if="props.companyNameErrorMessage"
        type="attention"
        :class="css['input-error']"
        >{{ props.companyNameErrorMessage }}</InputError
      >
    </TableCell>
  </TableRow>
</template>
