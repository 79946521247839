import {
  validateTel,
  validateNotEntered,
  formatNumberInput,
} from '@/utils/functions/validation/validation'
import { reactive } from 'vue'

const TELL_NUMBER_LENGTH = 11

export interface TelInputProps {
  modelValue: string
}

export interface TelInputStates {
  TelErrorMessage: string
}

interface TelInputEmits {
  (e: 'update:modelValue', value: string): void
}

export const useTelInputStates = () => {
  return reactive<TelInputStates>({
    TelErrorMessage: '',
  })
}

type TelActions = {
  handleInputTel(e: Event): void
  handleBlurTel(e: Event): void
}

export const useTelInputActions = (
  states: TelInputStates,
  emits: TelInputEmits
): TelActions => {
  return {
    handleInputTel(e: Event) {
      states.TelErrorMessage = ''
      const target = e.target as HTMLInputElement
      const validatedValue = formatNumberInput(target.value, TELL_NUMBER_LENGTH)
      target.value = validatedValue
      emits('update:modelValue', validatedValue)
    },
    handleBlurTel(e: Event) {
      const target = e.target as HTMLInputElement
      if (!validateTel(target.value)) {
        states.TelErrorMessage = '電話番号は10桁または11桁で入力してください'
      }
      if (validateNotEntered(target.value)) {
        states.TelErrorMessage = '未入力です'
      }
    },
  }
}
