<script setup lang="ts">
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import TableRow from './Row/index.vue'
import TableHeaderRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import { TableProps, useTableStates } from '.'

interface IProps {
  details: TableProps['details']
  gasUnitPrice: TableProps['gasUnitPrice']
}
const props = defineProps<IProps>()
const states = useTableStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/sizes' as sizes;

.table {
  border-radius: 4px 4px 0 0;
  color: colors.$font-color-primary;
}
.header {
  font-weight: bold;
  font-size: sizes.$font-size-md;
  text-align: center;
  th {
    vertical-align: middle;
  }
}
.left {
  text-align: left;
  margin-left: space(2);
}
.gas-amount-header-first > th {
  border-left: 1px solid colors.$border-color-white;
  border-bottom: 1px solid colors.$border-color-white;
  font-size: sizes.$font-size-sm;
}
.gas-amount-header-second > th {
  border-left: 1px solid colors.$border-color-white;
  font-size: sizes.$font-size-sm;
}
</style>

<template>
  <Table :class="css.table">
    <template #head>
      <TableHeaderRow type="head" height="40px" :class="css.header">
        <th scope="col">No.</th>
        <th scope="col">
          <div :class="css.left">撮影日</div>
        </th>
        <th scope="col">
          <div :class="css.left">内容</div>
        </th>
        <th scope="col">
          <div :class="css.left">撮影費（税別）</div>
        </th>
        <TableHeaderRow
          type="head"
          height="20px"
          :class="css['gas-amount-header-first']"
        >
          <th :colspan="3" scope="colgroup">出張手当（ガソリン代）</th>
        </TableHeaderRow>
        <TableHeaderRow
          type="head"
          height="20px"
          :class="css['gas-amount-header-second']"
        >
          <th scope="col" width="96px">距離</th>
          <th scope="col" width="64px">単価</th>
          <th scope="col" width="96px">金額</th>
        </TableHeaderRow>
      </TableHeaderRow>
    </template>
    <TableRow
      v-for="item in states.details"
      :key="item.rowNo"
      :row-no="item.rowNo"
      :date="item.date"
      :content="item.content"
      :amount="item.amount"
      :mileage="item.mileage"
      :gas-unit-price="item.gasUnitPrice"
    />
  </Table>
</template>
