<script setup lang="ts">
import { defineProps } from 'vue'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import Notification from './Notification/index.vue'
import NotificationList from './NotificationList/index.vue'
import Event from './Event/index.vue'
import RegistrationRequest from '@/vueComponents/photographer/organisms/pageContent/TopPageContent/RegistrationRequest/index.vue'
import Calendar from '@/vueComponents/photographer/organisms/pageContent/TopPageContent/Calendar/index.vue'
import { TopPageContentProps, useTopPageContentStatus } from '.'
import { currentScheduleMonth } from '@/utils/functions/createSchedulePrams'

interface IProps {
  limitMatterRequests: TopPageContentProps['limitMatterRequests']
  noticeMatterRequests: TopPageContentProps['noticeMatterRequests']
  events: TopPageContentProps['events']
  invoiceRequests: TopPageContentProps['invoiceRequests']
  isScheduleRegistered: TopPageContentProps['isScheduleRegistered']
  todaysDate: TopPageContentProps['todaysDate']
  displayYearMonth: TopPageContentProps['displayYearMonth']
  schedules: TopPageContentProps['schedules']
  isLoading: TopPageContentProps['isLoading']
  invoiceGenerationFlg: TopPageContentProps['invoiceGenerationFlg']
  clickPrev: TopPageContentProps['clickPrev']
  clickNext: TopPageContentProps['clickNext']
}

const props = defineProps<IProps>()
const states = useTopPageContentStatus(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;

.top-content {
  @include space-mixins.vertical-margin(10);
}
.notification {
  @include space-mixins.vertical-margin(6);
}
.registration-request {
  margin-top: space(6);
}
.wrap-list-calendar {
  display: flex;
  justify-content: space-between;
  margin: space(6) auto 0;
}

.lists {
  width: 832px;
}
.list {
  &:nth-child(n + 2) {
    @include space-mixins.vertical-margin(6);
  }
}
.calendar {
  margin-left: space(8);
}
</style>

<template>
  <div>
    <div :class="css['top-content']">
      <PageBreadcrumbs :breadcrumbs="states.breadcrumbs" />
      <Notification
        v-if="states.noticeMatterRequests.length > 0"
        :matter-requests="states.noticeMatterRequests"
        :class="css.notification"
      />
    </div>
    <div
      v-if="!props.isScheduleRegistered"
      :class="css['registration-request']"
    >
      <RegistrationRequest :todays-year-month="currentScheduleMonth()" />
    </div>
    <div :class="css['wrap-list-calendar']">
      <div :class="css.lists">
        <NotificationList
          :matter-requests="props.limitMatterRequests"
          :inspection-delivery-requests="
            states.inspectionDeliveryRequests.slice(0, 5)
          "
          :invoice-requests="props.invoiceRequests"
          :invoice-generation-flg="props.invoiceGenerationFlg"
          :class="css.list"
        />
        <Event :events="props.events.slice(0, 5)" :class="css.list" />
      </div>
      <div :class="css.calendar">
        <Calendar
          :todays-date="props.todaysDate"
          :display-year-month="props.displayYearMonth"
          :schedules="states.schedules"
          :is-loading="props.isLoading"
          :click-prev="props.clickPrev"
          :click-next="props.clickNext"
        />
      </div>
    </div>
  </div>
</template>
