<script setup lang="ts">
import { defineProps } from 'vue'
import { IProgressBarProps, useProgressBarStates } from '.'

interface IProps {
  progressRate: IProgressBarProps['progressRate']
}

const props = defineProps<IProps>()

const states = useProgressBarStates(props)
</script>

<style lang="scss" module="css" scoped>
.wrapper {
  width: 100%;
  height: 16px;
  margin: space(2) 0 0;
  border: 1px solid #6c6c6c;
  border-radius: 8px;
  background-color: #eeeeee;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.bar {
  float: none;
  height: 100%;
  transition: width 0.6s ease;
  box-shadow: none;
  background-color: #673a1b;

  @keyframes stripe {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 20px;
    }
  }
}

.in-progress {
  animation: stripe 1.5s infinite linear;
}
</style>

<template>
  <div :class="css.wrapper">
    <div
      :class="{ [css.bar]: true, [css['in-progress']]: states.progressing }"
      :style="states.style"
    />
  </div>
</template>
