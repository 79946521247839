<script setup lang="ts">
import NotificationsPageContent from '@/vueComponents/photographer/organisms/pageContent/NotificationsPageContent/index.vue'
import { useNotificationsActions, useNotificationsStates } from '.'

const states = useNotificationsStates()
useNotificationsActions(states)
</script>

<template>
  <NotificationsPageContent
    :matter-requests="states.matterRequests"
    :events="states.events"
    :invoice-requests="states.invoiceRequests"
    :invoice-generation-flg="states.invoiceGenerationFlg"
  />
</template>
