<script setup lang="ts">
import { defineEmits, defineProps, withDefaults } from 'vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import TextInput from '@/vueComponents/photographer/molecules/TextInput/index.vue'
import Modal from '@/vueComponents/photographer/molecules/Modal/index.vue'
import FolderNamingRules from '../FolderNamingRules/index.vue'
import {
  useFolderNameSettingModalStates,
  IFolderNameSettingModalProps,
  useFolderNameSettingModalActions,
  FolderNameSettingModalEmitProps,
} from '.'

interface IProps {
  shown: IFolderNameSettingModalProps['shown']
  type: IFolderNameSettingModalProps['type']
  folderName?: IFolderNameSettingModalProps['folderName']
  eventDate: IFolderNameSettingModalProps['eventDate']
  eventName: IFolderNameSettingModalProps['eventName']
  onSubmit: IFolderNameSettingModalProps['onSubmit']
}

interface IEmits {
  (
    e: 'update:shown',
    shown: FolderNameSettingModalEmitProps['update:shown']
  ): void
}

const props = withDefaults(defineProps<IProps>(), {
  folderName: '',
})

const emits = defineEmits<IEmits>()

const states = useFolderNameSettingModalStates(props, emits)

const actions = useFolderNameSettingModalActions(props, states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
@use '@/styles/mixins/space' as space-mixins;
.content {
  @include space-mixins.vertical-margin(8, 18);
  margin-left: space(8);
  margin-right: space(6);
  display: flex;
  align-items: flex-start;
  gap: space(2);
  .folder-icon {
    margin-top: 7px;
  }
  .prefix {
    margin-top: 8px;
    flex-basis: 207px;
  }
  .plus-icon {
    margin-top: 6px;
  }
  .input {
    width: 100%;
    flex-basis: 517px;
    align-items: flex-end;
  }
}
.help {
  width: fit-content;
  @include space-mixins.vertical-margin(2);
  @include position-mixins.flex-horizontal(space(1));
  float: right;
}
</style>

<template>
  <Modal
    :shown="props.shown"
    :submit-disabled="!states.folderNameMain"
    :on-submit="actions.submit"
    @update:shown="(shown) => emits('update:shown', shown)"
  >
    <template #header>{{ states.labels.TITLE }}</template>
    <div :class="css.content">
      <div :class="css['folder-icon']">
        <Icon type="folder-gray" width="19px" />
      </div>
      <div :class="css.prefix">
        <Typography element="p" :line-breakable="true">{{
          states.folderNamePrefix
        }}</Typography>
      </div>
      <div :class="css['plus-icon']"><Icon type="plus" width="18px" /></div>
      <div :class="css.input">
        <TextInput
          v-model:value="states.folderNameMain"
          name="event-folder-name"
          type="text"
          placeholder="フォルダ名を入力してください"
          :error-message="states.errorMessage"
        />
        <div :class="css.help">
          <Typography>フォルダ名の命名ルールについて</Typography>
          <FolderNamingRules />
        </div>
      </div>
    </div>
    <template #submitLabel>{{ states.labels.BUTTON }}</template>
    <template #cancelLabel>キャンセル</template>
  </Modal>
</template>
