<script setup lang="ts">
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import InvoiceDetailTable from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable/index.vue'
import Note from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/Note/index.vue'
import Message from '@/vueComponents/photographer/molecules/Message/index.vue'
import {
  InvoiceDetailEmitParams,
  InvoiceDetailProps,
  useInvoiceDetailActions,
  useInvoiceDetailStates,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail'
import { ref } from 'vue'

interface IProps {
  note: InvoiceDetailProps['note']
  details: InvoiceDetailProps['details']
  gasUnitPrice: InvoiceDetailProps['gasUnitPrice']
}
interface IEmits {
  (
    e: 'update:invoice-details',
    value: InvoiceDetailEmitParams['update:invoice-details']
  ): void
}

const invoiceDetailTableRef = ref()
const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useInvoiceDetailStates(props)
const actions = useInvoiceDetailActions(
  states,
  props,
  emits,
  invoiceDetailTableRef
)

defineExpose({
  validate: actions.validate,
})
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;

.container {
  background: colors.$background-color-content-primary;
  padding: space(8);
  padding-bottom: space(14);
}
.description {
  font-size: sizes.$font-size-md;
  line-height: 22px;
}
.upper-separator,
.description,
.invoice-detail-table,
.textarea {
  margin-top: space(6);
}
.error-message,
.bottom-separator,
.note {
  margin-top: space(8);
}
</style>

<template>
  <div :class="css.container">
    <Typography type="h2-simple">請求内訳</Typography>
    <div :class="css['upper-separator']">
      <Separator />
    </div>
    <p :class="css.description">
      請求内訳をご入力ください。小数点以下は四捨五入してください。<br />
      撮影費項目には、発注時の金額が反映されます。<br />
      追加・変更がある場合は下記フォームより編集してください。
    </p>
    <Message v-if="states.hasError" :class="css['error-message']"
      >項目にエラーがあります。修正してください。</Message
    >
    <InvoiceDetailTable
      ref="invoiceDetailTableRef"
      :details="props.details"
      :gas-unit-price="gasUnitPrice"
      :class="css['invoice-detail-table']"
      @update:details="actions.updateDetails"
    />
    <div :class="css['bottom-separator']">
      <Separator />
    </div>
    <Note v-model:value="states.note" :class="css.note" />
  </div>
</template>
