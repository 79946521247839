import { Computable } from '@/types/utils'
import { reactive } from 'vue'
import { AccountType } from '@/utils/constants/enums/photographer/account'
import { AccountPageTableProps } from '../AccountPageTable/index.vue'

export type BankAccountInfo = {
  bankCode: string
  bankName: string
  branchNumber: string
  branchName: string
  accountType: number
  accountNumber: string
  accountHolder: string
}

export type BankAccountInfoProps = {
  bankAccountInfo: BankAccountInfo
}

type BankAccountInfoStates = {
  bankAccountInfoList: AccountPageTableProps[]
}

const accountTypeMap = {
  0: AccountType.NOT_SET_ACCOUNT,
  1: AccountType.SAVING_ACCOUNT,
  2: AccountType.CHECKING_ACCOUNT,
}

const accountTypeText = (accountType: BankAccountInfo['accountType']) => {
  return accountTypeMap[accountType as keyof typeof accountTypeMap]
}

export const useBankAccountInfoStates = (props: BankAccountInfoProps) => {
  const states: BankAccountInfoStates = reactive<
    Computable<BankAccountInfoStates>
  >({
    bankAccountInfoList: [
      { title: '金融機関コード', value: props.bankAccountInfo.bankCode },
      { title: '銀行名', value: props.bankAccountInfo.bankName },
      { title: '支店番号', value: props.bankAccountInfo.branchNumber },
      { title: '支店名', value: props.bankAccountInfo.branchName },
      {
        title: '口座種類',
        value: accountTypeText(props.bankAccountInfo.accountType),
      },
      { title: '口座番号（7桁）', value: props.bankAccountInfo.accountNumber },
      {
        title: '口座名義（カタカナ）',
        value: props.bankAccountInfo.accountHolder,
      },
    ],
  })
  return states
}
