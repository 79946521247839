export const enum TransportationCode {
  /** 初期値 */
  INITIAL_VALUE = 0,
  /** 車 */
  CAR = 1,
  /** 公共交通機関 */
  PUBLIC_TRANSPORT = 2,
  /** 徒歩 */
  WALK = 3,
  /** その他 */
  OTHER = 99,
}
