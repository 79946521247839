import { Computable, Emits } from '@/types/utils'
import { computed, reactive } from 'vue'
import { TransportationCode } from '@/utils/constants/enums/photographer/transportation'
import { ReplyStatus } from '@/utils/constants/enums/photographer/dispatchOrder'

export type SelectTransportationProps = {
  transportation: TransportationCode
  otherTransportation: string
  replyStatus: number
  reachedCapacity: boolean
  canReply: boolean
}

type SelectTransportationStates = {
  transportation: TransportationCode
  otherTransportation: string
  disabledInput?: boolean
}

export type SelectTransportationEmitParams = {
  'update:transportation': SelectTransportationStates['transportation']
  'update:otherTransportation': SelectTransportationStates['otherTransportation']
}

export const useSelectTransportationStates = (
  props: SelectTransportationProps,
  emits: Emits<SelectTransportationEmitParams>
): SelectTransportationStates => {
  const states: SelectTransportationStates = reactive<
    Computable<SelectTransportationStates>
  >({
    transportation: computed({
      get: () => props.transportation,
      set: (transportation: SelectTransportationStates['transportation']) => {
        emits('update:transportation', transportation)
      },
    }),
    otherTransportation: computed({
      get: () => props.otherTransportation,
      set: (
        otherTransportation: SelectTransportationStates['otherTransportation']
      ) => {
        emits('update:otherTransportation', otherTransportation)
      },
    }),
    disabledInput: computed(() => {
      return (
        /** その他以外を選択時or回答済みの場合は操作不可 */
        states.transportation !== TransportationCode.OTHER ||
        props.replyStatus !== ReplyStatus.UNANSWERED
      )
    }),
  })
  return states
}

/** 交通手段グレーアウトの判定 */
const isDisabled = (
  props: SelectTransportationProps,
  value: TransportationCode
) => {
  let isDisable
  switch (true) {
    /** 引き受けると回答済のとき選択された交通手段以外はグレーアウト */
    case props.replyStatus === ReplyStatus.ACCEPTANCE &&
      props.transportation !== value:
      isDisable = true
      break
    /** 断るor未回答と定員or回答不可の場合は交通手段をグレーアウト */
    case (props.replyStatus === ReplyStatus.UNANSWERED ||
      props.replyStatus === ReplyStatus.REFUSE) &&
      (props.reachedCapacity || !props.canReply):
      isDisable = true
      break
    default:
      isDisable = false
  }
  return isDisable
}

export const useSelectTransportationActions = (
  props: SelectTransportationProps,
  states: SelectTransportationStates
) => {
  return {
    disabled(value: TransportationCode) {
      /** 断ると回答している場合は交通手段を未選択状態にする */
      if (props.replyStatus === ReplyStatus.REFUSE) {
        states.transportation = TransportationCode.INITIAL_VALUE
        states.otherTransportation = ''
      }
      return isDisabled(props, value)
    },
  }
}
