import { Emits } from '@/types/utils'

export type TabItemProps = {
  activeTabId: number
  tabId: number
  tabItem: string
  type: 'normal' | 'link'
  width?: string
  height?: string
  hasSeparator?: boolean
}

export type TabItemEmitParams = {
  'update:activeTabId': TabItemProps['tabId']
}

export const useTabItemActions = (
  props: TabItemProps,
  emits: Emits<TabItemEmitParams>
) => ({
  onClick() {
    emits('update:activeTabId', props.tabId)
  },
})
