import { reactive, computed, ref } from 'vue'
import { Computable, Emits } from '@/types/utils'
import {
  parseDateDayOfWeekToString,
  parseDateYearToHyphenString,
} from '@/utils/functions/parser'
import { ScheduleReplyStatus } from '@/utils/constants/enums/photographer/schedule'

export type ScheduleTableRowProps = {
  date: Date
  scheduleReplyStatus: ScheduleReplyStatus
  disabled?: boolean
}

export type ScheduleTableRowStates = {
  date: string
  scheduleReplyStatus: ScheduleReplyStatus
}

export type ScheduleTableRowParams = {
  'update:scheduleReplyObj': {
    date: string
    plan: ScheduleReplyStatus
  }
}

export const useScheduleTableRowStates = (
  props: ScheduleTableRowProps,
  emits: Emits<ScheduleTableRowParams>
) => {
  const scheduleReplyStatusRef = ref(props.scheduleReplyStatus)
  return reactive<Computable<ScheduleTableRowStates>>({
    date: computed(() => parseDateDayOfWeekToString(props.date)),
    scheduleReplyStatus: computed({
      get() {
        return scheduleReplyStatusRef.value
      },
      set(value) {
        emits('update:scheduleReplyObj', {
          date: parseDateYearToHyphenString(props.date),
          plan: value,
        })
        scheduleReplyStatusRef.value = value
      },
    }),
  })
}
