import { reactive } from 'vue'
import { TabItemProps } from '../TabItem'

export type TabProps = {
  tabItems: string[]
  tabItemType?: TabItemProps['type']
  tabItemMargin?: string
  hasSeparator?: boolean
}

type TabStates = {
  activeTabId: number
}

export const useTabStates = (): TabStates =>
  reactive<TabStates>({
    activeTabId: 1,
  })
