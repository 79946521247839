import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { addPublicBasePath } from '@/utils/functions/vite'
import imgAccepted from '@/media/img/proposalComplete/img_accepted.svg'
import { useRouter } from 'vue-router'

export type CompleteProps = {
  month: number
}

type CompleteStates = {
  imgPath: string
}

export const useCompleteStates = (): CompleteStates => {
  return {
    imgPath: addPublicBasePath(imgAccepted),
  }
}

type CompleteActions = {
  toInvoices: () => Promise<void>
}

export const useCompleteActions = (): CompleteActions => {
  const router = useRouter()
  return {
    toInvoices: async () => {
      await router.push({ name: photographerRoutes.NAME.INVOICES })
    },
  }
}
