import { BaseEntity } from '@/entities/BaseEntity'
import { FetchStatus } from '@/types/photographer/invoice'
import {
  InvoiceStore,
  invoiceStoreKey,
} from '@/vue/stores/photographer/InvoiceStore'
import { Account } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/Account'
import { InvoiceDetail } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable'
import { Expense } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable'

export class InvoiceEntity extends BaseEntity<InvoiceStore> {
  constructor() {
    super(invoiceStoreKey)
  }

  public get details(): InvoiceDetail[] {
    return this.store.details.value
  }

  public get note(): string {
    return this.store.note.value
  }

  public get gasUnitPrice(): number {
    return this.store.gasUnitPrice.value
  }

  public get expenses(): Expense[] {
    return this.store.expenses.value
  }

  public get account(): Account | null {
    return this.store.account.value
  }

  public get status(): FetchStatus | null {
    return this.store.status.value
  }

  public setDetails(invoice: {
    details: InvoiceDetail[]
    note: string
    gasUnitPrice: number
  }) {
    this.store.details.value = invoice.details
    this.store.note.value = invoice.note
    this.store.gasUnitPrice.value = invoice.gasUnitPrice
  }

  public setExpenses(expenses: Expense[]) {
    this.store.expenses.value = expenses
  }

  public setAccount(account: Account) {
    this.store.account.value = account
  }

  public setStatus(status: FetchStatus) {
    this.store.status.value = status
  }

  public setToDefault() {
    this.store.account.value = null
    this.store.status.value = null
    this.store.details.value.length = 0
    this.store.note.value = ''
    this.store.gasUnitPrice.value = 0
    this.store.expenses.value.length = 0
  }
}
