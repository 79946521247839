import { IEventInfo } from '@/types/photographer/event'
import { DISPATCH_TYPE } from '@/utils/constants/messages/photographer/event'
import { Computable, ValueOf } from '@/types/utils'
import { convertDispatchType } from '@/utils/functions/convertEvent'
import {
  parseDateTimeToString,
  parseDateToString,
  parseDateYearToString,
  parseZipCode,
} from '@/utils/functions/parser'
import { computed, reactive } from 'vue'

export interface IEventInfoProps {
  eventInfo: IEventInfo
}

type DispatchType = ValueOf<typeof DISPATCH_TYPE>

interface IEventInfoStates {
  eventDateStr: string
  eventStartTime: string
  eventEndTime: string
  eventTypeStr: DispatchType
  plannedStartDateStr: string
  deliveryDeadlineStr: string
  venueAddress: string
}

export const useEventInfoStates = (props: IEventInfoProps) =>
  reactive<Computable<IEventInfoStates>>({
    eventDateStr: computed(() =>
      parseDateYearToString(props.eventInfo.startDate)
    ),
    eventStartTime: computed(() =>
      parseDateTimeToString(props.eventInfo.startDate)
    ),
    eventEndTime: computed(() =>
      parseDateTimeToString(props.eventInfo.endDate)
    ),
    eventTypeStr: computed(() => convertDispatchType(props.eventInfo.type)),
    plannedStartDateStr: computed(() =>
      parseDateToString(props.eventInfo.typeNormalStartDate)
    ),
    deliveryDeadlineStr: computed(() =>
      parseDateYearToString(props.eventInfo.deliveryDeadline)
    ),
    venueAddress: computed(
      () =>
        `${parseZipCode(props.eventInfo.venueZipcode)} ${
          props.eventInfo.venueAddress
        }`
    ),
  })
