import { formatNumberInput } from '@/utils/functions/validation/validation'

const ZIP_CODE_LENGTH = 7

export interface ZipcodeInputProps {
  modelValue: string
  locationZipcodeErrorMessage: string
}

interface ZipcodeInputEmits {
  (e: 'update:modelValue', value: string): void
  (e: 'input:zipcode'): void
}

type ZipcodeActions = {
  handleInputZipcode(e: Event): void
}

export const useZipcodeInputActions = (
  emits: ZipcodeInputEmits
): ZipcodeActions => {
  return {
    handleInputZipcode(e: Event) {
      const target = e.target as HTMLInputElement
      const validatedValue = formatNumberInput(target.value, ZIP_CODE_LENGTH)
      target.value = validatedValue
      emits('update:modelValue', validatedValue)
      emits('input:zipcode')
    },
  }
}
