import { IEventInfo, IEventTitle } from '@/types/photographer/event'
import { Computable, Promisable } from '@/types/utils'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'
import { computed, reactive } from 'vue'
import { SendingBackMessageProps } from '../../section/SendingBackMessage'
import { IPhotosProps } from './PhotosList/Photos'
import { IPhotoUploaderProps } from './Upload/PhotoUploader'
import labelSlowMode from '@/media/img/slowMode/label_slow-mode.png'
import { addPublicBasePath } from '@/utils/functions/vite'

export interface IFolderPageContentProps {
  onUploadFile: IPhotoUploaderProps['onUploadFile']
  onUpdateUploadingStatus: IPhotoUploaderProps['onUpdateUploadingStatus']
  onUpdateFailedPhotosStatus: IPhotoUploaderProps['onUpdateFailedPhotosStatus']
  photos: IPhotosProps['photos']
  failedPhotos: IPhotosProps['failedPhotos']
  thumbnailUrlGetter: IPhotosProps['thumbnailUrlGetter']
  onDeletePhoto: (photoIds: number[]) => Promisable<void>
  folderInspectionMessage: SendingBackMessageProps['sendingBackDirectories'][number]
  eventInspectionMessage: SendingBackMessageProps['inspectMemo']
  eventId: string
  eventStatus: IEventInfo['status']
  eventDate: string
  eventName: IEventTitle['eventName']
  slowModeFlg: boolean
}

interface IFolderPageContentStates {
  checkedPhotoIds: Set<number>
  selectedAll: boolean
  photoNum: number
  deliveredFlg: boolean
  labelSlowMode: string
}

export const useFolderPageContentStates = (props: IFolderPageContentProps) => {
  const states: IFolderPageContentStates = reactive<
    Computable<IFolderPageContentStates>
  >({
    checkedPhotoIds: new Set(),
    selectedAll: computed({
      get() {
        if (!states.photoNum) return false
        return states.photoNum === states.checkedPhotoIds.size
      },
      set(value) {
        const checked: Set<number> = new Set()
        if (value) {
          const photoIds = [
            ...props.photos.keys(),
            ...(props.failedPhotos?.keys() ?? []),
          ]
          for (const photoId of photoIds) {
            checked.add(photoId)
          }
        }
        states.checkedPhotoIds = checked
      },
    }),
    photoNum: computed(
      () => props.photos.size + (props.failedPhotos?.size ?? 0)
    ),
    deliveredFlg: computed(
      () => props.eventStatus === EventStatusCode.DELIVERED
    ),
    labelSlowMode: addPublicBasePath(labelSlowMode),
  })
  return states
}

export const useFolderPageContentActions = (
  props: IFolderPageContentProps,
  states: IFolderPageContentStates
) => {
  const onDeletePhoto = async (): Promise<void> => {
    const checkedPhotoIds = Array.from(states.checkedPhotoIds.values())
    states.checkedPhotoIds = new Set()
    await props.onDeletePhoto(checkedPhotoIds)
  }
  return { onDeletePhoto }
}
