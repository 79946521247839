<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import {
  IUploadFailedDialogProps,
  UploadFailedDialogEmitParams,
  useUploadFailedDialogActions,
  useUploadFailedDialogStates,
} from '.'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'

interface IProps {
  uploadStatus: IUploadFailedDialogProps['uploadStatus']
  slowModeFlg: IUploadFailedDialogProps['slowModeFlg']
}

type Emits = {
  (e: 'update:shown', shown: UploadFailedDialogEmitParams['update:shown']): void
}

const emits = defineEmits<Emits>()
const props = defineProps<IProps>()
const states = useUploadFailedDialogStates(props)
const actions = useUploadFailedDialogActions(emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
.text {
  @include space-mixins.vertical-margin(4, 8);
  padding-left: space(2);
}
</style>

<template>
  <div>
    <Typography type="h3" :line-breakable="true">{{ states.title }}</Typography>
    <Typography v-if="states.networkErrorFlg" :class="css.text"
      >通信状況をご確認の上、時間をおいて再度アップロードしてください。<br />
      <template v-if="!props.slowModeFlg">
        接続速度が遅い場合は<Link
          type="body-read-normal"
          :location="states.slowModePageLocation"
          @click="actions.close"
          >こちらの「低速モード画面」</Link
        >からお試しください。
      </template>
    </Typography>
    <Typography v-else :class="css.text"
      >アップロード画像の仕様をご確認の上、再度お試しください。</Typography
    >
  </div>
</template>
