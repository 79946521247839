<script setup lang="ts">
import { defineProps } from 'vue'
import Select from '@/vueComponents/photographer/atoms/Select/index.vue'
import { PrefectureInputProps, usePrefectureInputActions } from '.'

interface IProps {
  modelValue: PrefectureInputProps['modelValue']
  prefecturesOptions: PrefectureInputProps['prefecturesOptions']
  locationPrefectureErrorMessage: PrefectureInputProps['locationPrefectureErrorMessage']
}

interface IEmits {
  (e: 'update:modelValue', value: number): void
  (e: 'input:prefecture'): void
}

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const actions = usePrefectureInputActions(emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as space-positions;
@use '@/styles/mixins/font' as font-mixins;

.title {
  @include font-mixins.font-weight-bold();
}
.table-cell div,
.col {
  @include space-positions.flex-horizontal(space(2));
}
.table-cell {
  padding: space(3) space(2);
  width: 256px;
}
.input-error {
  margin-top: space(1);
}
</style>

<template>
  <Select
    :value="props.modelValue"
    name="都道府県"
    width="144px"
    :options="props.prefecturesOptions"
    :disabled="false"
    :has-error="Boolean(props.locationPrefectureErrorMessage)"
    @update:value="actions.handleInputPrefecture"
  />
</template>
