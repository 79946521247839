import { BaseEntity } from '@/entities/BaseEntity'
import { IPhoto } from '@/types/photographer/photo'
import { Unref } from '@/types/utils'
import { PhotoUploadStatus } from '@/utils/constants/enums/photographer/photo'
import { photographerConstants } from '@/utils/constants/photographerConstants'
import {
  FolderStore,
  folderStoreKey,
} from '@/vue/stores/photographer/FolderStore'
import { UploadedPhoto } from '@/vue/stores/photographer/FolderStore/UploadedPhoto'

const { FILE_UPLOAD } = photographerConstants

export class FolderEntity extends BaseEntity<FolderStore> {
  constructor() {
    super(folderStoreKey)
  }

  public get failedPhotos(): Unref<FolderStore['failedPhotos']> {
    return this.store.failedPhotos.value
  }

  public get uploadedPhotos(): Unref<FolderStore['uploadedPhotos']> {
    return this.store.uploadedPhotos.value
  }

  private get uploadedCount(): number {
    return this.store.uploadedPhotos.value.size
  }

  public get failedCount(): number {
    return this.store.failedPhotos.value.size
  }

  public get allUploadedPhotos(): [number, UploadedPhoto][] {
    return [...this.uploadedPhotos, ...this.failedPhotos]
  }

  /**
   * アップロード上限枚数超過チェック
   * @param uploadingCount アップロードしようとしている写真枚数
   * @returns true:上限枚数超過 false:上限枚数未満
   */
  public isUploadingCountExceeded(uploadingCount: number): boolean {
    return uploadingCount + this.uploadedCount > FILE_UPLOAD.MAX_COUNT
  }

  /** アップロード済写真セットをクリア */
  public initUploadedPhotos(): void {
    this.uploadedPhotos.clear()
    this.failedPhotos.clear()
  }

  public setUploadedPhotos(photos: IPhoto[]) {
    photos.forEach((photo) => this.distributeUploadedPhoto(photo))
  }

  public deleteUploadedPhotos(photoIds: number[]) {
    photoIds.forEach((photoId) => {
      this.uploadedPhotos.delete(photoId)
      this.failedPhotos.delete(photoId)
    })
  }

  /**
   * アップロード完了写真をアップロード済または失敗として分配
   * @param photo 写真情報
   */
  public distributeUploadedPhoto(photo: IPhoto): void {
    const uploadedPhoto = UploadedPhoto.createByPhotoData(photo)
    if (photo.status !== PhotoUploadStatus.COMPLETE) {
      this.failedPhotos.set(photo.photoId, uploadedPhoto)
      return
    }
    this.uploadedPhotos.set(photo.photoId, uploadedPhoto)
  }

  /**
   * 失敗写真のUploadedPhoto更新
   * @param photoId 写真ID
   * @param uploadedPhoto 写真情報
   */
  public updateUploadedPhoto(
    photoId: number,
    uploadedPhoto: UploadedPhoto
  ): void {
    this.failedPhotos.set(photoId, uploadedPhoto)
  }
}
