import {
  IPostInvoicesRequestDetail,
  IPostInvoicesRequestExpense,
} from '@/domains/api/invoice/InvoiceApi'
import { InvoiceDetail } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable'
import { Expense } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable'

export const convertEntityDetailsToPayload = (
  details: InvoiceDetail[]
): IPostInvoicesRequestDetail[] => {
  return details.map((detail) => {
    return {
      dispatch_cameraman_id: detail.dispatchCameramanId,
      id: detail.id,
      date: detail.date,
      content: detail.content,
      amount: detail.amount ?? 0,
      mileage: detail.mileage,
    }
  })
}

export const convertEntityExpensesToPayload = (
  expenses: Expense[]
): IPostInvoicesRequestExpense[] => {
  return expenses.map((expense) => {
    return {
      id: expense.id,
      date: expense.date,
      amount: expense.amount ?? 0,
      content: expense.content,
      file_name: expense.fileName,
      original_file_name: expense.originalFileName,
    }
  })
}
