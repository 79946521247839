import { IDispatchOrderDetail } from '@/types/photographer/dispatchOrder'
import { DISPATCH_TYPE } from '@/utils/constants/messages/photographer/event'
import { Computable, ValueOf } from '@/types/utils'
import { computed, reactive } from 'vue'
import {
  parseDateTimeToString,
  parseDateToString,
  parseDateYearWithDayOfWeekToString,
  parseZipCode,
  parseAmount,
} from '@/utils/functions/parser'
import { convertDispatchType } from '@/utils/functions/convertEvent'

export type ProposalDetailProps = {
  dispatchOrderDetail: IDispatchOrderDetail
}

type DispatchType = ValueOf<typeof DISPATCH_TYPE>

type ProposalDetailStates = {
  eventDateStr: string
  eventStartTime: string
  eventEndTime: string
  eventTypeStr: DispatchType
  typeNormalStartDate: string
  meetingPointAddress: string
  venueAddress: string
  consultationAmount: string
}

export const useProposalDetailStates = (props: ProposalDetailProps) =>
  reactive<Computable<ProposalDetailStates>>({
    eventDateStr: computed(() =>
      parseDateYearWithDayOfWeekToString(props.dispatchOrderDetail.startDate)
    ),
    eventStartTime: computed(() =>
      parseDateTimeToString(props.dispatchOrderDetail.startDate)
    ),
    eventEndTime: computed(() =>
      parseDateTimeToString(props.dispatchOrderDetail.endDate)
    ),
    eventTypeStr: computed(() =>
      convertDispatchType(props.dispatchOrderDetail.type)
    ),
    typeNormalStartDate: computed(() =>
      parseDateToString(props.dispatchOrderDetail.typeNormalStartDate)
    ),
    meetingPointAddress: computed(
      () =>
        `${parseZipCode(props.dispatchOrderDetail.meetingPointZipcode)} ${
          props.dispatchOrderDetail.meetingPointAddress
        }`
    ),
    venueAddress: computed(
      () =>
        `${parseZipCode(props.dispatchOrderDetail.venueZipcode)} ${
          props.dispatchOrderDetail.venueAddress
        }`
    ),

    consultationAmount: computed(() =>
      parseAmount(props.dispatchOrderDetail.consultationAmount)
    ),
  })
