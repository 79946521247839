import { TabProps } from '@/vueComponents/photographer/molecules/Tab'
import { computed, reactive } from 'vue'
import { InspectionDeliveryRequestTableProps } from '../../../section/InspectionDeliveryRequestTable'
import { FiscalYearTabProps } from '../FiscalYearTab'
import { IInvoiceRequest } from '@/types/photographer/invoice'
import { Computable } from '@/types/utils'
import { handleTabItems } from '@/utils/functions/notification'

export type NotificationsTabProps = {
  matterRequestsByYear: FiscalYearTabProps['matterRequestsByYear']
  inspectionDeliveryRequests: InspectionDeliveryRequestTableProps
  invoiceRequests: IInvoiceRequest[]
  invoiceGenerationFlg: boolean
}

type NotificationsTabStates = {
  tabItems: TabProps['tabItems']
}

export const useNotificationsTabStates = (props: NotificationsTabProps) =>
  reactive<Computable<NotificationsTabStates>>({
    tabItems: computed(() => handleTabItems(props.invoiceGenerationFlg)),
  })
