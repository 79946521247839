<script setup lang="ts">
import { defineProps } from 'vue'
import { HeadProps, useHeadStates } from '.'
interface IProps {
  account: HeadProps['account']
  totalAmount: HeadProps['totalAmount']
}
const props = defineProps<IProps>()
const states = useHeadStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/sizes' as sizes;
@use '@/styles/mixins/font' as font-mixins;
@use '@/styles/mixins/space' as space-mixins;
.container {
  color: colors.$font-color-primary;
}
.title {
  font-size: 48px;
  @include font-mixins.font-weight-bold();
  @include space-mixins.vertical-margin(4, 10);
}
.head {
  display: flex;
  justify-content: space-between;
}
.head-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 632px;
}
.total-amount {
  display: flex;
  justify-content: space-between;
  height: 56px;
  border-bottom: 4px solid colors.$border-color-gray;
  line-height: 30px;
}
.total-amount-title {
  @include font-mixins.font-weight-bold();
  font-size: 32px;
}
.total-amount-amount {
  @include font-mixins.font-weight-bold();
  font-size: 48px;
}
.total-amount-yen {
  font-size: 32px;
  margin-left: space(2);
}
.to {
  font-size: 26px;
  line-height: 34px;
}
.account {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: space(4);
  width: 271px;
}
.account-title {
  @include font-mixins.font-weight-bold();
  font-size: sizes.$font-size-lg;
  line-height: 24px;
  margin-bottom: space(2);
}
.account-content {
  font-size: sizes.$font-size-sm;
}
</style>

<template>
  <div :class="css.container">
    <div :class="css.title">請求書</div>
    <div :class="css.head">
      <div :class="css['head-left']">
        <div>
          <div :class="css.to">
            株式会社うるる<br />
            えんフォト事業部&emsp;御中
          </div>
        </div>
        <div :class="css['total-amount']">
          <div :class="css['total-amount-title']">請求総額：</div>
          <div>
            <span :class="css['total-amount-amount']">{{
              states.displayTotalAmount
            }}</span>
            <span :class="css['total-amount-yen']">円</span>
          </div>
        </div>
      </div>
      <div :class="css.account">
        <div>
          <div :class="css['account-title']">請求者情報</div>
          <div :class="css['account-content']">
            <div>{{ props.account.companyName }}</div>
            <div>{{ props.account.name }}</div>
            <div>{{ states.displayZipcode }}</div>
            <div>{{ states.displayAddress }}</div>
            <div>{{ props.account.invoiceNumber }}</div>
          </div>
        </div>
        <div>
          <div :class="css['account-title']">振込先情報</div>
          <div :class="css['account-content']">
            <div>
              {{ props.account.bankName }}&emsp;{{ props.account.branchName }}
            </div>
            <div>
              {{ states.displayAccountType }}&emsp;{{
                props.account.accountNumber
              }}
            </div>
            <div>{{ props.account.accountHolder }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
