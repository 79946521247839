<script setup lang="ts">
import { defineProps } from 'vue'
import { IAccountPageContentProps, useAccountPageContentActions } from '.'
import AccountInfo from '@/vueComponents/photographer/organisms/pageContent/AccountPageContent/AccountInfo/index.vue'
import BankAccountInfo from '@/vueComponents/photographer/organisms/pageContent/AccountPageContent/BankAccountInfo/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import AccountPageSection from '@/vueComponents/photographer/organisms/pageContent/AccountPageContent/AccountPageSection/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'

interface IProps {
  account: IAccountPageContentProps['account']
  company: IAccountPageContentProps['company']
  bankAccountInfo: IAccountPageContentProps['bankAccountInfo']
  breadcrumbs: IAccountPageContentProps['breadcrumbs']
  invoiceGenerationFlg: IAccountPageContentProps['invoiceGenerationFlg']
}

const props = defineProps<IProps>()
const actions = useAccountPageContentActions()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixin;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/colors' as colors;

.header {
  margin-top: space(10);
}
.bread-crumbs,
.account-content-container,
.account-content-info {
  margin-top: space(6);
}
.account-content-container {
  padding: space(8);
}
</style>

<template>
  <div>
    <div :class="css.header">
      <Typography type="h1">アカウント</Typography>
      <PageBreadcrumbs
        :class="css['bread-crumbs']"
        :breadcrumbs="props.breadcrumbs"
      />
    </div>
    <ContentBox :class="css['account-content-container']">
      <AccountPageSection>
        <template #title>登録情報</template>
        <template #editLink>
          <Link
            type="body-read-normal"
            display="block"
            @click="actions.toAccountEdit"
            >編集</Link
          >
        </template>
        <template #content>
          <AccountInfo
            :company="props.company"
            :account="props.account"
            :class="css['account-content-info']"
          />
        </template>
      </AccountPageSection>
      <AccountPageSection v-if="props.invoiceGenerationFlg">
        <template #title>振込先</template>
        <template #editLink>
          <Link
            type="body-read-normal"
            display="block"
            @click="actions.toAccountEditBank"
            >編集</Link
          >
        </template>
        <template #content>
          <BankAccountInfo
            :bank-account-info="props.bankAccountInfo"
            :class="css['account-content-info']"
          />
        </template>
      </AccountPageSection>
    </ContentBox>
  </div>
</template>
