<script setup lang="ts">
import { isThisMonth } from 'date-fns'
import { defineProps } from 'vue'
import {
  ScheduleRegistrationProps,
  useScheduleRegistrationStates,
  useScheduleRegistrationActions,
} from '.'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import ScheduleTable from '@/vueComponents/photographer/organisms/pageContent/ScheduleContent/ScheduleTable/index.vue'
import ScheduleSaveModal from '@/vueComponents/photographer/organisms/pageContent/ScheduleContent/ScheduleSaveModal/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'

interface IProps {
  scheduleMonth: ScheduleRegistrationProps['scheduleMonth']
  scheduleReplyData: ScheduleRegistrationProps['scheduleReplyData']
  onSave: ScheduleRegistrationProps['onSave']
}

const props = defineProps<IProps>()
const states = useScheduleRegistrationStates(props)
const actions = useScheduleRegistrationActions(states, props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;
.content {
  padding: space(8);
}
.title {
  display: flex;
  justify-content: space-between;
}
.link {
  display: flex;
  gap: 45px;
}
.separator {
  @include space-mixins.vertical-margin(6);
}
.text {
  @include space-mixins.vertical-margin(6);
  margin-left: space(4);
}
.table {
  margin: 0 auto;
  width: fit-content;
  @include space-mixins.vertical-margin(6, 8);
}
.btn {
  margin: space(8) auto space(4);
}
.to-top {
  width: 288px;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>

<template>
  <ContentBox :class="css.content">
    <div :class="css.title">
      <Typography type="h2-simple" :class="css.title">
        {{ states.title }}
      </Typography>
      <div :class="css.link">
        <Link
          :type="
            isThisMonth(props.scheduleMonth)
              ? 'body-read-disable'
              : 'body-read-normal'
          "
          @click="actions.toSchedule(true)"
          >前月
        </Link>
        <Link :type="'body-read-normal'" @click="actions.toSchedule(false)">
          翌月
        </Link>
      </div>
    </div>
    <Separator :class="css.separator" />
    <Typography :class="css.text">
      空いている日程を登録し、保存してください。案件を紹介させていただきます。
    </Typography>
    <ScheduleTable
      :class="css.table"
      :schedule-reply-data="props.scheduleReplyData"
      @update:schedule-reply-prams="
        (value) => (states.scheduleReplyPrams = value)
      "
    />
    <Separator />
    <Button
      :class="css.btn"
      color="primary"
      width="288px"
      :on-click="actions.onSave"
      >保存する</Button
    >
    <Typography :class="css['to-top']" type="read-large" @click="actions.toTop"
      >ホームへ</Typography
    >
  </ContentBox>
  <ScheduleSaveModal
    v-model:shown="states.isModalOpened"
    :is-saved="states.isSaved"
    :to-page="states.toPage"
  />
</template>
