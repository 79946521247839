import { computed, reactive } from 'vue'
import { Computable } from '@/types/utils'
import { RouteLocationRaw, useRouter, LocationQueryValue } from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { toBoolean } from '@/utils/functions/parser'

export type ProposalCompletePageContentProps = {
  isAccepted: LocationQueryValue | LocationQueryValue[]
}

type ProposalCompletePageContentStates = {
  isAccepted: boolean
}

export const useProposalCompletePageContentStates = (
  props: ProposalCompletePageContentProps
) => {
  return reactive<Computable<ProposalCompletePageContentStates>>({
    isAccepted: computed(() => toBoolean(String(props.isAccepted))),
  })
}

export const useProposalCompletePageContentActions = () => {
  const router = useRouter()

  const topPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.TOP,
  }

  return {
    async toTop(): Promise<void> {
      await router.push(topPageLocation)
    },
  }
}
