<script setup lang="ts">
import { defineProps } from 'vue'
import EventInfo from './Event/EventInfo/index.vue'
import SendingBackMessage from '@/vueComponents/photographer/organisms/section/SendingBackMessage/index.vue'
import EventPageFolder from './Folder/index.vue'
import { IEventPageContentProps, useEventPageContentStates } from '.'
import EventStatusLabel from '../../label/EventStatusLabel/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import { parseJaDateToString } from '@/utils/functions/parser'

interface IProps {
  dispatchId: IEventPageContentProps['dispatchId']
  eventName: IEventPageContentProps['eventName']
  sellerName: IEventPageContentProps['sellerName']
  eventInfo: IEventPageContentProps['eventInfo']
  directories: IEventPageContentProps['directories']
  onCreateFolder: IEventPageContentProps['onCreateFolder']
  onDeleteFolder: IEventPageContentProps['onDeleteFolder']
  onUpdateFolderName: IEventPageContentProps['onUpdateFolderName']
  onDelivery: IEventPageContentProps['onDelivery']
}

const props = defineProps<IProps>()
const states = useEventPageContentStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as space-positions;
@use '@/styles/mixins/space' as space-mixins;
.container {
  @include space-mixins.vertical-margin(10, 30);
  @include space-positions.flex-vertical(space(6));
  align-items: stretch;
}
.headings {
  @include space-positions.flex-vertical(space(2));
  align-items: flex-start;
}
</style>

<template>
  <div :class="css.container">
    <div :class="css.headings">
      <div>
        <EventStatusLabel :status="props.eventInfo.status" />
      </div>
      <div>
        <Typography type="h1">{{ props.sellerName }}</Typography>
      </div>
      <div>
        <Typography type="h2-headline">{{ props.eventName }}</Typography>
      </div>
    </div>
    <PageBreadcrumbs :breadcrumbs="states.breadcrumbs" />
    <EventInfo :event-info="props.eventInfo" />
    <SendingBackMessage
      v-if="states.sendingBackFlag"
      :inspect-memo="eventInfo.inspectMemo"
      :sending-back-directories="states.sendingBackDirectories"
      :event-date="parseJaDateToString(props.eventInfo.startDate)"
      :event-name="props.eventName"
    />
    <EventPageFolder
      :seller-name="props.sellerName"
      :event-name="props.eventName"
      :event-info="props.eventInfo"
      :directories="props.directories"
      :dispatch-id="props.dispatchId ?? 0"
      :on-create-folder="props.onCreateFolder"
      :on-update-folder-name="props.onUpdateFolderName"
      :on-deliver="props.onDelivery"
      :on-delete-folder="props.onDeleteFolder"
    />
  </div>
</template>
