import { IEvent } from '@/types/photographer/event'
import { Computable } from '@/types/utils'
import { convertDispatchType } from '@/utils/functions/convertEvent'
import {
  parseDateTimeToString,
  parseDateToString,
  parseDateYearToString,
} from '@/utils/functions/parser'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { computed, reactive } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

export interface IEventTableRowProps {
  event: IEvent
}

interface IEventTableRowStates {
  eventDateStr: string
  eventStartTime: string
  eventEndTime: string
  eventTypeStr: string
  plannedStartDateStr: string
  deliveryDeadlineStr: string
}

export const useEventTableRowStates = ({ event }: IEventTableRowProps) =>
  reactive<Computable<IEventTableRowStates>>({
    eventDateStr: computed(() => parseDateYearToString(event.startDate)),
    eventStartTime: computed(() => parseDateTimeToString(event.startDate)),
    eventEndTime: computed(() => parseDateTimeToString(event.endDate)),
    eventTypeStr: computed(() => convertDispatchType(event.type)),
    plannedStartDateStr: computed(() =>
      parseDateToString(event.typeNormalStartDate)
    ),
    deliveryDeadlineStr: computed(() =>
      parseDateYearToString(event.deliveryDeadline)
    ),
  })

export const useEventTableRowActions = ({ event }: IEventTableRowProps) => {
  const router = useRouter()

  const eventPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.EVENT,
    params: { eventId: event.dispatchId },
  }

  return {
    async selectEvent(): Promise<void> {
      await router.push(eventPageLocation)
    },
  }
}
