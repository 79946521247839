<script setup lang="ts">
import { defineProps } from 'vue'
import {
  useAccountInfoEditPageContentStates,
  useAccountInfoEditPageContentActions,
  AccountInfoEditPageContentEmitParams,
  AccountInfoEditPageContentProps,
} from '.'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import AccountInfoForm from '@/vueComponents/photographer/organisms/pageContent/AccountInfoEditPageContent/AccountInfoForm/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'

interface IProps {
  accountInfo: AccountInfoEditPageContentProps['accountInfo']
  breadcrumbs: AccountInfoEditPageContentProps['breadcrumbs']
}

interface IEmits {
  (
    e: 'save:account-info',
    {
      account,
      company,
    }: AccountInfoEditPageContentEmitParams['save:account-info']
  ): void
}

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const states = useAccountInfoEditPageContentStates(props)
const actions = useAccountInfoEditPageContentActions(states, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixin;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/colors' as colors;

.header {
  margin-top: space(10);
}
.bread-crumbs,
.account-content-container,
.separator,
.account-info-form {
  margin-top: space(6);
}
.account-content-container {
  padding: space(8);
}
.save-button-wrapper {
  margin-top: space(12);
  @include position-mixin.flex-vertical();
}
.separator {
  margin-bottom: space(6);
}
</style>

<template>
  <div>
    <div :class="css.header">
      <Typography type="h1">アカウント</Typography>
      <PageBreadcrumbs
        :class="css['bread-crumbs']"
        :breadcrumbs="props.breadcrumbs"
      />
    </div>
    <ContentBox :class="css['account-content-container']">
      <Typography type="h2-simple">登録情報</Typography>
      <Separator :class="css.separator" />
      <AccountInfoForm
        :account="states.accountInfoData.account"
        :company="states.accountInfoData.company"
        :validation-flg="states.validationFlg"
        @update:account-info="actions.onUpdateAccountInfo"
        @validation:off="actions.onValidationOff"
      />
      <div :class="css['save-button-wrapper']">
        <Button
          type="large"
          color="primary"
          :disabled="states.hasErrorAccountInfoForm"
          width="266px"
          :on-click="actions.onClickSaveButton"
          >保存する</Button
        >
      </div>
    </ContentBox>
  </div>
</template>
