import { Computable, Emits } from '@/types/utils'
import { UploadingModalStatus } from '@/utils/constants/enums/photographer/folder'
import { photographerConstants } from '@/utils/constants/photographerConstants'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { computed, reactive } from 'vue'
import { RouteLocationRaw } from 'vue-router'

const { FILE_UPLOAD } = photographerConstants

export interface IUploadFailedDialogProps {
  uploadStatus: UploadingModalStatus
  slowModeFlg: boolean
}

interface IUploadFailedDialogStates {
  title: string
  networkErrorFlg: boolean
  slowModePageLocation: RouteLocationRaw
}

export type UploadFailedDialogEmitParams = {
  'update:shown': boolean
}

export const useUploadFailedDialogStates = (props: IUploadFailedDialogProps) =>
  reactive<Computable<IUploadFailedDialogStates>>({
    title: computed(() => {
      switch (props.uploadStatus) {
        case UploadingModalStatus.FILE_COUNT_EXCEEDED:
          return `フォルダの上限枚数${FILE_UPLOAD.MAX_COUNT}枚を超えています。`
        case UploadingModalStatus.ILLEGAL:
          return 'アップロードできない画像が含まれています。'
        case UploadingModalStatus.NETWORK_ERROR:
          return '通信エラーが発生しました'
        default:
          return ''
      }
    }),
    networkErrorFlg: computed(
      () => props.uploadStatus === UploadingModalStatus.NETWORK_ERROR
    ),
    slowModePageLocation: {
      name: photographerRoutes.NAME.FOLDER,
      query: { slow: 'true' },
    },
  })

export const useUploadFailedDialogActions = (
  emits: Emits<UploadFailedDialogEmitParams>
) => {
  return {
    close() {
      emits('update:shown', false)
    },
  }
}
