import { MESSAGES } from '@/utils/constants/messages'
import { computed, reactive } from 'vue'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { Computable } from '@/types/utils'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { NotificationsTabProps } from './NotificationsTab'
import { IEvent } from '@/types/photographer/event'
import { IDispatchOrder } from '@/types/photographer/dispatchOrder'
import { FiscalYearTabProps } from './FiscalYearTab'
import { MatterRequestTableProps } from '../../section/MatterRequestTable'
import { photographerConstants } from '@/utils/constants/photographerConstants'
import { createInspectionDeliveryRequests } from '@/utils/functions/createInspectionDeliveryRequests'
import { IInvoiceRequest } from '@/types/photographer/invoice'

const { DISPLAYED_YEARS } = photographerConstants.NOTIFICATIONS

export type NotificationsPageContentProps = {
  matterRequests: IDispatchOrder[]
  events: IEvent[]
  invoiceRequests: IInvoiceRequest[]
  invoiceGenerationFlg: boolean
}

export type NotificationsPageContentStates = {
  breadcrumbs: IBreadcrumb[]
  inspectionDeliveryRequests: NotificationsTabProps['inspectionDeliveryRequests']
  matterRequestsByYear: FiscalYearTabProps['matterRequestsByYear']
}

type FilterMatterRequestsPayload = {
  matterRequests: IDispatchOrder[]
  year: number
  lastYear: number
}

type MatterRequestsPayload = {
  max: number
  min: number
  matterRequests: IDispatchOrder[]
  matterRequestsByYear: FiscalYearTabProps['matterRequestsByYear']
}

/**
 * 年別案件依頼情報のフィルタリング(6年以上データがある場合)
 * @param payload ペイロードデータ
 * @returns 年度別案件依頼情報
 */
const filterMatterRequests6YearsAndOver = (
  payload: FilterMatterRequestsPayload
) => {
  const { matterRequests, year, lastYear } = payload
  return matterRequests.filter(({ mailSendAt }) => {
    return lastYear === year
      ? mailSendAt.getFullYear() <= year
      : mailSendAt.getFullYear() === year
  })
}

/**
 * 年別案件依頼情報の生成(6年以上データがある場合)
 * @param payload ペイロードデータ
 */
const processingMatterRequests6YearsAndOver = (
  payload: MatterRequestsPayload
) => {
  const { max, matterRequests, matterRequestsByYear } = payload
  const lastYear = max - DISPLAYED_YEARS
  for (let y = max; y >= lastYear; y--) {
    const filterPayload: FilterMatterRequestsPayload = {
      matterRequests,
      year: y,
      lastYear,
    }
    const matterRequestByYear: MatterRequestTableProps['matterRequests'] =
      filterMatterRequests6YearsAndOver(filterPayload)
    matterRequestsByYear.push({
      year: lastYear === y ? `${y}年以前` : `${y}年`,
      matterRequest: matterRequestByYear,
    })
  }
}

/**
 * 年別案件依頼情報の生成(5年以下のデータがある場合)
 * @param payload ペイロードデータ
 */
const processingMatterRequests5YearsOrLess = (
  payload: MatterRequestsPayload
) => {
  const { max, min, matterRequests, matterRequestsByYear } = payload
  for (let y = max; y >= min; y--) {
    const matterRequestByYear: MatterRequestTableProps['matterRequests'] =
      matterRequests.filter(({ mailSendAt }) => mailSendAt.getFullYear() === y)
    matterRequestsByYear.push({
      year: `${y}年`,
      matterRequest: matterRequestByYear,
    })
  }
}

/**
 * 最新・最古のメール送信日時取得
 * @param matterRequests 案件依頼情報
 * @returns メール送信日時（max・min）
 */
const getMaxAndMinVal = (matterRequests: IDispatchOrder[]) => {
  const max = matterRequests
    .reduce((prev, current) => {
      return prev.mailSendAt > current.mailSendAt ? prev : current
    })
    .mailSendAt.getFullYear()
  const min = matterRequests
    .reduce((prev, current) => {
      return prev.mailSendAt < current.mailSendAt ? prev : current
    })
    .mailSendAt.getFullYear()
  return {
    max,
    min,
  }
}

/**
 * 年別案件依頼情報の生成
 * @param matterRequests 案件依頼情報
 * @returns 年度別案件依頼情報
 */
const createMatterRequestsByYear = (matterRequests: IDispatchOrder[]) => {
  const matterRequestsByYear: FiscalYearTabProps['matterRequestsByYear'] = []
  if (!matterRequests[0]) return matterRequestsByYear
  const max = getMaxAndMinVal(matterRequests).max
  const min = getMaxAndMinVal(matterRequests).min
  const diffYear = max - min
  const payload: MatterRequestsPayload = {
    max,
    min,
    matterRequests,
    matterRequestsByYear,
  }
  if (diffYear >= DISPLAYED_YEARS) {
    processingMatterRequests6YearsAndOver(payload)
  } else {
    processingMatterRequests5YearsOrLess(payload)
  }
  return matterRequestsByYear
}

export const useNotificationsPageContentStates = (
  props: NotificationsPageContentProps
) =>
  reactive<Computable<NotificationsPageContentStates>>({
    breadcrumbs: computed(() => [
      {
        label: MESSAGES.PHOTOGRAPHER.PAGE_TITLE.TOP,
        location: { name: photographerRoutes.NAME.TOP },
      },
      {
        label: MESSAGES.PHOTOGRAPHER.PAGE_TITLE.NOTIFICATIONS,
      },
    ]),
    inspectionDeliveryRequests: computed(() =>
      createInspectionDeliveryRequests(props.events)
    ),
    matterRequestsByYear: computed(() =>
      createMatterRequestsByYear(props.matterRequests)
    ),
  })
