import { reactive, watch } from 'vue'
import { Emits } from '@/types/utils'
import { IScheduleReplyData } from '@/types/photographer/schedules'
import { ScheduleReplyStatus } from '@/utils/constants/enums/photographer/schedule'
import { ScheduleTableRowParams } from '../ScheduleTableRow'
import { ScheduleReplyPrams } from '../ScheduleRegistration'

export type ScheduleTableProps = {
  scheduleReplyData: IScheduleReplyData[]
}

export type ScheduleTableStates = {
  scheduleReplyMap: Map<string, ScheduleReplyStatus>
}

export type ScheduleTableParams = {
  'update:scheduleReplyPrams': ScheduleReplyPrams[]
}

export const useScheduleTableStates = () =>
  reactive<ScheduleTableStates>({
    scheduleReplyMap: new Map(),
  })

export const useScheduleTableActions = (
  states: ScheduleTableStates,
  emits: Emits<ScheduleTableParams>,
  props: ScheduleTableProps
) => {
  watch(
    () => props.scheduleReplyData,
    () => (states.scheduleReplyMap = new Map())
  )
  const createScheduleReplyPrams = (
    obj: ScheduleTableRowParams['update:scheduleReplyObj']
  ): void => {
    states.scheduleReplyMap.set(obj.date, obj.plan)
    const scheduleReplyPrams = Array.from(
      states.scheduleReplyMap.entries()
    ).map(([k, v]) => ({
      date: k,
      plan: v,
    }))
    emits('update:scheduleReplyPrams', scheduleReplyPrams)
  }
  return { createScheduleReplyPrams }
}
