import { RouteLocationRaw, useRouter } from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { IDispatchOrder } from '@/types/photographer/dispatchOrder'

export type MatterRequestTableRowProps = {
  matterRequest: IDispatchOrder
  hasButton?: boolean
  isBold?: boolean
}

export const useMatterRequestTableRowActions = (
  props: MatterRequestTableRowProps
) => {
  const router = useRouter()

  const proposalPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.PROPOSAL,
    params: { proposalId: props.matterRequest.dispatchOrderId },
  }

  return {
    async toDetailPage(): Promise<void> {
      await router.push(proposalPageLocation)
    },
  }
}
