<script setup lang="ts">
import { defineProps, ref } from 'vue'
import { IThumbnailProps, useThumbnailActions, useThumbnailStates } from '.'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import VueElementLoading from 'vue-element-loading'

interface IProps {
  url?: IThumbnailProps['url']
  errorMsg?: IThumbnailProps['errorMsg']
  lazyLoadUrlGetter?: IThumbnailProps['lazyLoadUrlGetter']
}

const props = defineProps<IProps>()

const states = useThumbnailStates(props)

const observerTarget = ref<Element>()

useThumbnailActions({ states, props, observerTarget })
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/mixins/position' as position-mixin;
.wrapper {
  position: relative;
  width: 136px;
  height: 184px;
  border-radius: 5px;
  background: colors.$background-color-content-primary;
  border: 2px solid colors.$border-color-thumbnil;
  &:before {
    content: '';
    display: block;
    padding-top: 70%;
  }
  &-error {
    border: 2px solid colors.$border-color-thumbnil-error;
    background: v-bind('states.errorBgImg');
    background-size: 35% auto;
  }
}
.thumbnail {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.error {
  @include position-mixin.flex-center();
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  letter-spacing: -0.1px;
  white-space: pre-line;
}
</style>

<template>
  <div
    ref="observerTarget"
    :class="{
      [css['wrapper']]: true,
      [css['wrapper-error']]: props.errorMsg,
    }"
  >
    <div v-if="props.errorMsg" :class="css.error">
      <Typography type="read-small-attention" text-align="center">
        {{ props.errorMsg }}
      </Typography>
    </div>
    <div v-else-if="!states.thumbnailUrl" :class="css.thumbnail">
      <VueElementLoading
        :style="{ zIndex: 0 }"
        :active="!states.thumbnailUrl"
        :is-full-screen="false"
        spinner="spinner"
        size="37"
        duration="0.7"
        color="#6DB043"
      />
    </div>
    <div
      v-else
      :class="css.thumbnail"
      :style="{
        background: `url(${states.thumbnailUrl}) no-repeat center / contain`,
      }"
    />
  </div>
</template>
