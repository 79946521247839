<script setup lang="ts">
import { defineProps, defineEmits, withDefaults } from 'vue'

type SelectPropsOptions = {
  value: string | number
  label: string
  disabled?: boolean
}

interface IProps {
  name: string
  options: SelectPropsOptions[] | null
  defaultLabel?: string
  defaultLabelValue?: number
  value: string | number
  disabled: boolean
  hasError: boolean
  width?: string
}

interface IInputEmits {
  (e: 'update:value', value: IProps['value']): void
}

const props = withDefaults(defineProps<IProps>(), {
  name: '',
  options: null,
  defaultLabel: '',
  defaultLabelValue: 0,
  value: '',
  width: '100%',
  disabled: false,
  hasError: false,
})

const emit = defineEmits<IInputEmits>()

const select = (event: Event) => {
  emit('update:value', (event.target as HTMLSelectElement).value)
}
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/mixins/input' as input;
.select {
  @extend %input;
}

.error {
  background: colors.$background-color-danger;
  border: 1px solid colors.$border-color-attention;
  &:focus {
    border-color: colors.$border-color-attention;
  }
}
</style>

<template>
  <select
    :class="[css.select, props.hasError ? css.error : '']"
    :name="props.name"
    :value="props.value"
    :style="{ width: props.width }"
    :disabled="props.disabled"
    @change="select"
  >
    <option v-if="props.defaultLabel" :value="props.defaultLabelValue">
      {{ props.defaultLabel }}
    </option>
    <option
      v-for="item in props.options"
      :key="item.value"
      :value="item.value"
      :selected="item.value === props.value"
      :disabled="item.disabled"
    >
      {{ item.label }}
    </option>
  </select>
</template>
