import {
  IDispatchOrderMessage,
  IDispatchOrderDetail,
  IDispatchOrderTransportation,
  IDispatchOrderMemo,
  IDispatchOrderStatus,
  IDispatchOrderDetailBase,
  IDispatchOrderId,
} from '@/types/photographer/dispatchOrder'
import { SelfEntity } from '@/entities/SelfEntity'
import { reactive, computed, watch } from 'vue'
import { Computable } from '@/types/utils'
import { DispatchOrderApi } from '@/domains/api/dispatch_order/DispatchOrderApi'
import { createOnAsync, onCreate } from '@/utils/functions/lifecycle'
import { Router, useRoute, useRouter } from 'vue-router'
import {
  parseExtendedStringToString,
  parseNumber,
} from '@/utils/functions/parser'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { ReplyStatus } from '@/utils/constants/enums/photographer/dispatchOrder'
import { CreatingEntity } from '@/entities/global/CreatingEntity'

export type ProposalStates = {
  userName: string
  dispatchOrderId: IDispatchOrderId['dispatchOrderId']
  dispatchOrderMessage: IDispatchOrderMessage | null
  dispatchOrderDetail: IDispatchOrderDetail | null
  dispatchOrderMemo: IDispatchOrderMemo['memo']
  dispatchOrderTransportation: IDispatchOrderTransportation | null
  dispatchOrderStatus: IDispatchOrderStatus | null
  dispatchOrderDetailBase: IDispatchOrderDetailBase | null
}

type ProposalPayload = {
  states: ProposalStates
  dispatchOrderApi: DispatchOrderApi
  onAsync: (action: () => unknown) => Promise<unknown>
}

export const useProposalStates = () => {
  const selfEntity = new SelfEntity()
  const states: ProposalStates = reactive<Computable<ProposalStates>>({
    userName: computed(() => selfEntity.name),
    dispatchOrderId: computed(
      () => states.dispatchOrderDetailBase?.dispatchOrderId ?? 0
    ),
    dispatchOrderMessage: computed(() =>
      createDispatchOrderMessage(states.dispatchOrderDetailBase)
    ),
    dispatchOrderDetail: computed(() =>
      createDispatchOrderDetail(states.dispatchOrderDetailBase)
    ),
    dispatchOrderMemo: computed(
      () => states.dispatchOrderDetailBase?.memo ?? ''
    ),
    dispatchOrderTransportation: computed(() =>
      createDispatchOrderTransportation(states.dispatchOrderDetailBase)
    ),
    dispatchOrderStatus: computed(() =>
      createDispatchOrderStatus(states.dispatchOrderDetailBase)
    ),
    dispatchOrderDetailBase: null,
  })
  return states
}

/**
 * タイトル〜冒頭メッセージ情報の生成
 * @param dispatchOrderDetailBase タイトル〜冒頭メッセージ情報の全データ
 * @returns タイトル〜冒頭メッセージ情報
 */
const createDispatchOrderMessage = (
  dispatchOrderDetailBase: ProposalStates['dispatchOrderDetailBase']
) => {
  if (!dispatchOrderDetailBase) return null
  return {
    mailSendByName: dispatchOrderDetailBase.mailSendByName,
    startDate: dispatchOrderDetailBase.startDate,
    beforeDispatchOrderId: dispatchOrderDetailBase.beforeDispatchOrderId,
    currentDispatchOrderId: dispatchOrderDetailBase.currentDispatchOrderId,
    replyDeadlineCount: dispatchOrderDetailBase.replyDeadlineCount,
  }
}

/**
 * 案件詳細情報の生成
 * @param dispatchOrderDetailBase 案件詳細情報の全データ
 * @returns 案件詳細情報
 */
const createDispatchOrderDetail = (
  dispatchOrderDetailBase: ProposalStates['dispatchOrderDetailBase']
) => {
  if (!dispatchOrderDetailBase) return null
  return {
    eventName: dispatchOrderDetailBase.eventName,
    startDate: dispatchOrderDetailBase.startDate,
    endDate: dispatchOrderDetailBase.endDate,
    type: dispatchOrderDetailBase.type,
    typeNormalStartDate: dispatchOrderDetailBase.typeNormalStartDate,
    venueName: dispatchOrderDetailBase.venueName,
    venueZipcode: dispatchOrderDetailBase.venueZipcode,
    venueAddress: dispatchOrderDetailBase.venueAddress,
    meetingPointName: dispatchOrderDetailBase.meetingPointName,
    meetingPointTime: dispatchOrderDetailBase.meetingPointTime,
    meetingPointZipcode: dispatchOrderDetailBase.meetingPointZipcode,
    meetingPointAddress: dispatchOrderDetailBase.meetingPointAddress,
    consultationAmount: dispatchOrderDetailBase.consultationAmount,
  }
}

/**
 * 交通手段情報の生成
 * @param dispatchOrderDetailBase 交通手段情報の全データ
 * @returns 交通手段情報
 */
const createDispatchOrderTransportation = (
  dispatchOrderDetailBase: ProposalStates['dispatchOrderDetailBase']
) => {
  if (!dispatchOrderDetailBase) return null
  return {
    transportation: dispatchOrderDetailBase.transportation,
    otherTransportation: dispatchOrderDetailBase.otherTransportation,
  }
}

/**
 * 回答関連の状態の生成
 * @param dispatchOrderDetailBase 回答関連の状態の全データ
 * @returns 回答関連の状態
 */
const createDispatchOrderStatus = (
  dispatchOrderDetailBase: ProposalStates['dispatchOrderDetailBase']
) => {
  if (!dispatchOrderDetailBase) return null
  return {
    replyStatus: dispatchOrderDetailBase.replyStatus,
    reachedCapacity: dispatchOrderDetailBase.reachedCapacity,
    canReply: dispatchOrderDetailBase.canReply,
  }
}

/**
 * 回答ボタン押下時処理
 * @param dispatchOrderApi
 * @param router
 */
export const createReplyer =
  (dispatchOrderApi: DispatchOrderApi, router: Router) =>
  async (
    dispatchOrderId: number,
    params: {
      replyStatus: number
      transportation: number
      otherTransportation: string
    }
  ) => {
    await dispatchOrderApi.postDispatchOrder(dispatchOrderId, params)
    const accept = () => {
      return params.replyStatus === ReplyStatus.ACCEPTANCE ? 'true' : 'false'
    }
    await router.push({
      name: photographerRoutes.NAME.PROPOSAL_COMPLETE,
      query: { accept: accept() },
    })
  }

/**
 * DispatchOrderDetailBaseの更新
 * @param nextProposalId 遷移先のProposalId
 * @param prevProposalId 遷移前のProposalId
 * @param payload ペイロード
 */
export const updateDispatchOrderDetailBase = async (
  nextProposalId: string | string[],
  prevProposalId: string | string[],
  payload: ProposalPayload
) => {
  if (!nextProposalId || nextProposalId === prevProposalId) return
  const { states, dispatchOrderApi, onAsync } = payload
  const proposalId = parseNumber(parseExtendedStringToString(nextProposalId))
  await onAsync(async () => {
    states.dispatchOrderDetailBase =
      await dispatchOrderApi.fetchDispatchOrderDetail(proposalId)
  })
}

export const useProposalActions = (states: ProposalStates) => {
  const router = useRouter()
  const route = useRoute()
  const proposalId = parseNumber(
    parseExtendedStringToString(route.params.proposalId)
  )
  const dispatchOrderApi = new DispatchOrderApi()
  const onAsync = createOnAsync(new CreatingEntity())
  const payload = {
    states,
    dispatchOrderApi,
    onAsync,
  }
  watch(
    () => route.params.proposalId,
    (next, prev) => updateDispatchOrderDetailBase(next, prev, payload)
  )
  onCreate(async () => {
    states.dispatchOrderDetailBase =
      await dispatchOrderApi.fetchDispatchOrderDetail(proposalId)
  })

  return {
    onReply: createReplyer(dispatchOrderApi, router),
  }
}
