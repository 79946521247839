<script setup lang="ts">
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'
import EventStatusLabel from '@/vueComponents/photographer/organisms/label/EventStatusLabel/index.vue'
import FolderPageContent from '@/vueComponents/photographer/organisms/pageContent/FolderPageContent/index.vue'
import { useFolderActions, useFolderStates } from '.'

const states = useFolderStates()

const actions = useFolderActions(states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.title {
  @include space-mixins.vertical-margin(10);
  display: grid;
  gap: 8px;
}
.breadcrumbs {
  @include space-mixins.vertical-margin(6, 6);
}
</style>

<template>
  <div :class="css.title">
    <EventStatusLabel :status="states.eventStatus" />
    <Typography type="h1">
      {{ states.sellerName }}
    </Typography>
    <Typography type="h2-headline">
      {{ states.eventName }}
    </Typography>
  </div>
  <PageBreadcrumbs
    :class="css.breadcrumbs"
    :breadcrumbs="states.breadcrumbs"
  ></PageBreadcrumbs>
  <FolderPageContent
    :on-upload-file="actions.onUploadFile"
    :on-update-uploading-status="actions.onUpdateUploadingStatus"
    :on-update-failed-photos-status="actions.onUpdateFailedPhotosStatus"
    :on-delete-photo="actions.onDeletePhoto"
    :photos="states.photos"
    :thumbnail-url-getter="actions.thumbnailUrlGetter"
    :failed-photos="states.failedPhotos"
    :folder-inspection-message="states.folderInspectionMessage"
    :event-inspection-message="states.eventInspectionMessage"
    :event-id="states.eventId"
    :event-status="states.eventStatus"
    :event-name="states.eventName"
    :event-date="states.eventDate"
    :slow-mode-flg="!!states.slowModeFlg"
  />
</template>
