import { Computable, Emits } from '@/types/utils'
import { computed, reactive } from 'vue'

export interface IModalProps {
  shown: boolean
}

export type ModalEmitParams = {
  'update:shown': IModalProps['shown']
  'to-home': void
  'continue-edit': void
}

type IModalStates = {
  shown: boolean
}

export const useModalStates = (
  props: IModalProps,
  emits: Emits<ModalEmitParams>
): IModalStates => {
  return reactive<Computable<IModalStates>>({
    shown: computed({
      get() {
        return props.shown
      },
      set(value) {
        emits('update:shown', value)
      },
    }),
  })
}

type ModalActions = {
  toHome: () => Promise<void>
  continueEdit: () => Promise<void>
}

export const useModalActions = (
  emits: Emits<ModalEmitParams>
): ModalActions => {
  return {
    async toHome() {
      await emits('update:shown', false)
      await emits('to-home')
    },
    async continueEdit() {
      await emits('update:shown', false)
      await emits('continue-edit')
    },
  }
}
