<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Modal from '@/vueComponents/photographer/molecules/Modal/index.vue'
import DeliveryCompletedDialog from './DeliveryCompletedDialog/index.vue'
import DeliverySettingDialog from './DeliverySettingDialog/index.vue'
import {
  IDeliveryModalProps,
  DeliveryModalEmitParams,
  useDeliveryModalActions,
  useDeliveryModalStates,
} from '.'

interface IProps {
  shown: IDeliveryModalProps['shown']
  sellerName: IDeliveryModalProps['sellerName']
  eventName: IDeliveryModalProps['eventName']
  onSubmit: IDeliveryModalProps['onSubmit']
}

interface IEmits {
  (e: 'update:shown', shown: DeliveryModalEmitParams['update:shown']): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = useDeliveryModalStates(props, emits)

const actions = useDeliveryModalActions(states, props)
</script>

<template>
  <Modal
    v-model:shown="states.shown"
    :on-submit="!states.isDelivered ? actions.submit : actions.linkToEvents"
    :on-before-cancel="states.isDelivered ? actions.reload : undefined"
    :on-close="states.isDelivered ? actions.reload : undefined"
    :closeable="true"
    :submit-disabled="!states.value.match(/\S/g)"
    :enter-disabled="true"
    :btn-color="!states.isDelivered ? undefined : 'secondary'"
  >
    <template #header>{{ states.title }}</template>
    <template v-if="!states.isDelivered">
      <DeliverySettingDialog
        v-model:value="states.value"
        :seller-name="props.sellerName"
        :event-name="props.eventName"
      />
    </template>
    <template v-else>
      <DeliveryCompletedDialog />
    </template>
    <template #submitLabel>{{ states.submitLabel }}</template>
    <template #cancelLabel>{{ states.cancelLabel }}</template>
  </Modal>
</template>
