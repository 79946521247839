<script setup lang="ts">
import { defineProps, defineEmits, withDefaults } from 'vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import FolderTableNameCell from '../FolderTableNameCell/index.vue'
import {
  FolderTableRowProps,
  FolderTableRowEmitParams,
  useFolderTableRowActions,
  useFolderTableRowStates,
} from '.'

interface IProps {
  disabled?: FolderTableRowProps['disabled']
  checked: FolderTableRowProps['checked']
  eventDate: FolderTableRowProps['eventDate']
  eventName: FolderTableRowProps['eventName']
  directory: FolderTableRowProps['directory']
  dispatchId: FolderTableRowProps['dispatchId']
  eventStatus: FolderTableRowProps['eventStatus']
  onUpdateName: FolderTableRowProps['onUpdateName']
}

const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
})

const emits = defineEmits<{
  (e: 'update:checked', value: FolderTableRowEmitParams['update:checked']): void
}>()

const states = useFolderTableRowStates(props)

const actions = useFolderTableRowActions(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as position-mixins;
.label {
  display: flex;
  cursor: pointer;
}
.cell {
  @include position-mixins.flex-center;
  height: 100%;
}
.date,
.count {
  width: 112px;
  &-zero {
    margin-right: 3px;
  }
}
.button {
  width: 120px;
  > div {
    width: 104px;
  }
}
</style>

<template>
  <TableRow :type="states.rowType">
    <FolderTableNameCell
      :disabled="props.disabled"
      :checked="props.checked"
      :event-date="props.eventDate"
      :event-name="props.eventName"
      :folder-name="props.directory.name"
      :on-update-name="props.onUpdateName"
      :event-status="props.eventStatus"
      @update:checked="actions.emitCheckedFolderObj"
    />
    <TableCell :class="css.date">
      <div :class="css.cell">
        <Typography :type="states.textType">{{
          states.updatedAtStr
        }}</Typography>
      </div>
    </TableCell>
    <TableCell :class="css.count">
      <div :class="css.cell">
        <Typography :type="states.textType"
          ><Typography
            :class="{ [css['count-zero']]: states.photosCount === 0 }"
            :type="states.photosCountTextType"
            element="span"
            >{{ states.photosCount }}</Typography
          >枚</Typography
        >
      </div>
    </TableCell>
    <TableCell :class="css.button" :separatorable="false">
      <div :class="css.cell">
        <Button
          type="medium"
          color="secondary"
          :disabled="props.disabled"
          @click="actions.selectFolder"
          >{{ states.buttonLabel }}</Button
        >
      </div>
    </TableCell>
  </TableRow>
</template>
