<script setup lang="ts">
import { defineEmits } from 'vue'
import { DropzoneEmitParams, useDropzoneActions } from '.'

interface IEmits {
  (e: 'drop', files: DropzoneEmitParams['drop']): void
}

const emits = defineEmits<IEmits>()

const actions = useDropzoneActions(emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
.container {
  background-color: colors.$background-color-dropzone;
  border: dashed 2px colors.$border-color-dropzone;
  border-radius: 5px;
}
</style>

<template>
  <div
    :class="css.container"
    @drop="actions.dropFiles"
    @dragenter.prevent
    @dragover.prevent
  >
    <slot />
  </div>
</template>
