import { Computable } from '@/types/utils'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'
import { computed, reactive } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { photographerRoutes } from '@/vue/routers/photographer/routes'

export type InspectionDeliveryRequestTableRowProps = {
  type: EventStatusCode.BEFORE_DELIVERY | EventStatusCode.SENDING_BACK
  sellerName: string
  eventName: string
  eventId: number
}

type InspectionDeliveryRequestTableRowStates = {
  message: string
}

export const useInspectionDeliveryRequestTableRowStates = (
  props: InspectionDeliveryRequestTableRowProps
) => {
  const isBeforeDelivery = computed(
    () => props.type === EventStatusCode.BEFORE_DELIVERY
  )

  return reactive<Computable<InspectionDeliveryRequestTableRowStates>>({
    message: computed(() =>
      isBeforeDelivery.value
        ? `「${props.sellerName} ${props.eventName}」が納品前です。写真をアップロードし、検品依頼をお願いします。`
        : `「${props.sellerName} ${props.eventName}」に差し戻しがあります。修正内容をご確認の上、再度検品依頼をお願いします。`
    ),
  })
}

export const useInspectionDeliveryRequestTableRowActions = (
  props: InspectionDeliveryRequestTableRowProps
) => {
  const router = useRouter()

  const eventPageLocation: RouteLocationRaw = {
    name: photographerRoutes.NAME.EVENT,
    params: { eventId: props.eventId },
  }

  return {
    async toDetailPage(): Promise<void> {
      await router.push(eventPageLocation)
    },
  }
}
