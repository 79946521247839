<script setup lang="ts">
import { useAccountActions, useAccountStates } from '.'
import AccountPageContent from '@/vueComponents/photographer/organisms/pageContent/AccountPageContent/index.vue'

const states = useAccountStates()
useAccountActions(states)
</script>

<template>
  <AccountPageContent
    v-if="states.account && states.company && states.bankAccountInfo"
    :company="states.company"
    :account="states.account"
    :bank-account-info="states.bankAccountInfo"
    :breadcrumbs="states.breadcrumbs"
    :invoice-generation-flg="states.invoiceGenerationFlg"
  />
</template>
