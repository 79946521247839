import {
  validateNotEntered,
  formatNumberInput,
  validateZipcode,
} from '@/utils/functions/validation/validation'
import { reactive } from 'vue'

const ZIP_CODE_LENGTH = 7

export interface ZipcodeInputProps {
  modelValue: string
}

export interface ZipcodeInputStates {
  ZipcodeErrorMessage: string
}

interface ZipcodeInputEmits {
  (e: 'update:modelValue', value: string): void
}

export const useZipcodeInputStates = () => {
  return reactive<ZipcodeInputStates>({
    ZipcodeErrorMessage: '',
  })
}

type ZipcodeActions = {
  handleInputZipcode(e: Event): void
  handleBlurZipcode(e: Event): void
}

export const useZipcodeInputActions = (
  states: ZipcodeInputStates,
  emits: ZipcodeInputEmits
): ZipcodeActions => {
  return {
    handleInputZipcode(e: Event) {
      states.ZipcodeErrorMessage = ''
      const target = e.target as HTMLInputElement
      const validatedValue = formatNumberInput(target.value, ZIP_CODE_LENGTH)
      target.value = validatedValue
      emits('update:modelValue', validatedValue)
    },
    handleBlurZipcode(e: Event) {
      const target = e.target as HTMLInputElement
      if (!validateZipcode(target.value)) {
        states.ZipcodeErrorMessage = '郵便番号は7桁で入力してください'
      }
      if (validateNotEntered(target.value)) {
        states.ZipcodeErrorMessage = '未入力です'
      }
    },
  }
}
