<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Modal from '@/vueComponents/photographer/molecules/Modal/index.vue'
import {
  IModalProps,
  ModalEmitParams,
  useInvoiceSendConfirmModalStates,
  useInvoiceSendConfirmModalActions,
} from '.'

interface IProps {
  shown: IModalProps['shown']
}

interface IEmits {
  (e: 'update:shown', shown: ModalEmitParams['update:shown']): void
  (e: 'submit', shown: ModalEmitParams['submit']): void
  (e: 'close', shown: ModalEmitParams['close']): void
}

const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useInvoiceSendConfirmModalStates(props, emits)
const actions = useInvoiceSendConfirmModalActions(emits)
</script>

<style lang="scss" module="css" scoped>
.body {
  padding: 32px 23px 88px;
}
</style>

<template>
  <Modal
    v-model:shown="states.shown"
    :on-submit="actions.submit"
    :on-close="actions.close"
  >
    <template #header>請求書を送信しますか？</template>
    <div :class="css.body">
      この送信はキャンセルできません。<br />
      本当に送信してよろしいですか？
    </div>
    <template #submitLabel>送信する</template>
    <template #cancelLabel>キャンセル</template>
  </Modal>
</template>
