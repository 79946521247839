<script setup lang="ts">
import { defineProps } from 'vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import { InvoiceStatus } from '@/utils/constants/enums/photographer/invoice'
import {
  MaterialPreparationEmitsParams,
  MaterialPreparationProps,
  useMaterialPreparationActions,
  useMaterialPreparationStates,
} from '.'

interface IProps {
  invoiceStatusInfo: MaterialPreparationProps['invoiceStatusInfo']
}

interface IEmits {
  (
    e: 'invoice-create:transition',
    value: MaterialPreparationEmitsParams['invoice-create:transition']
  ): void
}

const props = defineProps<IProps>()
const states = useMaterialPreparationStates(props)
const emits = defineEmits<IEmits>()
const actions = useMaterialPreparationActions(emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/mixins/space' as space-mixins;
.container {
  padding: space(8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subtitle {
  margin-top: space(6);
}
.sub-container {
  display: flex;
  gap: space(6);
  margin-top: space(8);
}
.sub-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 320px;
  width: 336px;
  border-radius: 8px;
  background-color: colors.$background-color-subinfo;
}
.content-title {
  line-height: 24px;
}
.content-sub-title {
  margin-top: space(4);
  text-align: center;
  line-height: 22px;
}
.button {
  width: 314px;
  @include space-mixins.vertical-margin(8, 6);
}
.receipt {
  height: 112px;
  @include space-mixins.vertical-margin(8, 6);
}
.purchase-order {
  height: 105px;
  @include space-mixins.vertical-margin(8, 7.5);
}
.memo {
  height: 112px;
  @include space-mixins.vertical-margin(8, 6);
}
.photographer-link {
  margin-top: space(4);
}
.invoice-link {
  margin-top: space(4);
}
.icon {
  margin-left: space(1);
}
</style>

<template>
  <ContentBox :class="css.container">
    <Typography type="material-preparation-title">
      {{ props.invoiceStatusInfo.month }}月分請求書の作成
    </Typography>
    <Typography type="material-preparation-subtitle" :class="css.subtitle">
      入力のための資料をお手元にご準備し、作成にお進みください。
    </Typography>
    <Link
      href="https://en-photo-ph.studio.site/payment/invoice"
      :target-blank="true"
      :class="css['photographer-link']"
      >撮影マニュアルサイト
      <Icon type="external-link-alt" :class="css.icon" />
    </Link>
    <div :class="css['sub-container']">
      <div :class="css['sub-content']">
        <img :src="states.receipt" :class="css.receipt" alt="領収書の画像" />
        <Typography type="count-normal" :class="css['content-title']">
          領収書の画像
        </Typography>
        <Typography :class="css['content-sub-title']" text-align="center">
          文字の認識できる程度のカラー画像にして<br />
          jpg、pdfにして用意しておきましょう
        </Typography>
        <div :class="css['invoice-link']">
          <Link
            href="https://www.nta.go.jp/law/joho-zeikaishaku/sonota/jirei/tokusetsu/index.htm"
            :target-blank="true"
            >国税庁の電子帳簿等保存制度特設サイト
            <Icon type="external-link-alt" :class="css.icon" />
          </Link>
        </div>
      </div>
      <div :class="css['sub-content']">
        <img
          :src="states.purchaseOrder"
          :class="css['purchase-order']"
          alt="発注書の画像"
        />
        <Typography type="count-normal" :class="css['content-title']">
          発注書
        </Typography>
        <Typography :class="css['content-sub-title']" text-align="center">
          計上漏れや項目漏れを防ぐために<br />
          すぐに確認できる状態に しておきましょう
        </Typography>
      </div>
      <div :class="css['sub-content']">
        <img :src="states.memo" :class="css.memo" alt="メモの画像" />
        <Typography type="count-normal" :class="css['content-title']">
          メモや資料など
        </Typography>
        <Typography :class="css['content-sub-title']" text-align="center">
          明細書に関するメモや、<br />
          その他入力に必要な資料があれば<br />
          手元にまとめておきましょう
        </Typography>
      </div>
    </div>
    <div :class="css.button">
      <Button
        :disabled="
          props.invoiceStatusInfo.status === InvoiceStatus.CANNOT_CREATE
        "
        @click="actions.onClickButton"
        >{{ states.buttonMessage }}</Button
      >
    </div>
  </ContentBox>
</template>
