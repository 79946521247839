<script setup lang="ts">
import EventsPageContent from '@/vueComponents/photographer/organisms/pageContent/EventsPageContent/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import { MESSAGES } from '@/utils/constants/messages'
import { useEventsActions, useEventsStates } from '.'
import PageBreadcrumbs from '@/vueComponents/photographer/molecules/PageBreadcrumbs/index.vue'

const states = useEventsStates()
useEventsActions()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.h1 {
  @include space-mixins.vertical-margin(11);
}
.bread-crumbs {
  @include space-mixins.vertical-margin(7, 6);
}
</style>

<template>
  <Typography :class="css.h1" type="h1">{{
    MESSAGES.PHOTOGRAPHER.PAGE_TITLE.EVENTS
  }}</Typography>
  <PageBreadcrumbs
    :class="css['bread-crumbs']"
    :breadcrumbs="states.breadcrumbs"
  />
  <EventsPageContent :events="states.events" />
</template>
