type FilesFilter = Parameters<Array<File>['filter']>[0]

/**
 * ファイル名初出判定
 * @param file 判定対象ファイル
 * @param i 判定対象ファイルのインデックス
 * @param files ファイルリスト
 * @returns true:ファイル名初出(重複なし) false:ファイル名既出(重複あり)
 */
const isFileNameFirstAppeared: FilesFilter = (file, i, files) => {
  if (i === 0) return true
  const firstIndex = files.findIndex(
    ({ name }) => name.toLowerCase() === file.name.toLowerCase()
  )
  return i === firstIndex
}

/**
 * ファイル名の一意化
 * @param files アップロード中ファイルリスト
 * @returns ファイル名の重複を除いたファイルリスト
 */
export const uniformizeFileNames = (files: File[]) =>
  files.filter(isFileNameFirstAppeared)
