import { BaseApi } from '../BaseApi'

const PATH = {
  POST_PASSWORD_EMAIL: '/photographer/api/v1.0/password/email',
  POST_PASSWORD_RESET: '/photographer/api/v1.0/password/reset',
  POST_PASSWORD_UPDATE: '/photographer/api/v1.0/password/update',
} as const

export interface IPostPasswordEmailRequest {
  login_id: string
}

export interface IPostPasswordResetRequest {
  password: string
  password_confirmation: string
  token: string
  login_id: string
}

export interface IPostPasswordUpdateRequest {
  password: string
  password_confirmation: string
}

export class ResetPasswordApi extends BaseApi {
  /**
   * パスワード再設定画面のURLをメールにて送信。
   * @param login_id ログインID
   */
  public async postPasswordEmail(
    login_id: IPostPasswordEmailRequest['login_id']
  ): Promise<void> {
    await this.post<IPostPasswordEmailRequest>(PATH.POST_PASSWORD_EMAIL, {
      login_id,
    })
  }

  /**
   * パスワードリセット
   * @param password パスワード
   * @param password_confirmation パスワード確認
   * @param token トークン
   * @param login_id ログインID
   */
  public async postPasswordReset(
    password: IPostPasswordResetRequest['password'],
    password_confirmation: IPostPasswordResetRequest['password_confirmation'],
    token: IPostPasswordResetRequest['token'],
    login_id: IPostPasswordResetRequest['login_id']
  ): Promise<void> {
    await this.post<IPostPasswordResetRequest>(PATH.POST_PASSWORD_RESET, {
      password,
      password_confirmation,
      token,
      login_id,
    })
  }

  /**
   * ログイン後パスワードリセット
   * @param password パスワード
   * @param password_confirmation パスワード確認
   */
  public async postPasswordUpdate(
    password: IPostPasswordUpdateRequest['password'],
    password_confirmation: IPostPasswordUpdateRequest['password_confirmation']
  ): Promise<void> {
    await this.post<IPostPasswordUpdateRequest>(PATH.POST_PASSWORD_UPDATE, {
      password,
      password_confirmation,
    })
  }
}
