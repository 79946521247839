<script setup lang="ts">
import { defineProps } from 'vue'
import { ProposalMessageProps, useProposalMessageStates } from '.'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'

interface IProps {
  userName: ProposalMessageProps['userName']
  dispatchOrderMessage: ProposalMessageProps['dispatchOrderMessage']
  dispatchOrderCanReply: ProposalMessageProps['dispatchOrderCanReply']
}

const props = defineProps<IProps>()
const states = useProposalMessageStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.content {
  display: flex;
  margin: space(6) space(4);
  &-message {
    width: 100%;
  }
}
.user-name {
  margin-bottom: space(6);
  position: relative;
}
.title {
  display: flex;
  align-items: baseline;
}
.deadline {
  margin-left: space(3);
}
.order-link {
  position: absolute;
  right: 0;
  top: 0;
}
</style>

<template>
  <Typography type="h2" :class="css.title">
    {{ states.title }}
    <Typography
      v-if="
        props.dispatchOrderCanReply &&
        props.dispatchOrderMessage.replyDeadlineCount >= 0
      "
      :class="css.deadline"
      type="read-normal-attention"
      >【回答期限：あと{{
        props.dispatchOrderMessage.replyDeadlineCount
      }}日】</Typography
    >
  </Typography>
  <div :class="css.content">
    <div :class="css['content-message']">
      <Typography type="read-large" :class="css['user-name']">
        {{ props.userName }} さま
        <div :class="css['order-link']">
          <Link
            v-if="props.dispatchOrderMessage.currentDispatchOrderId"
            :location="states.currentDispatchOrderLocation"
          >
            最新の依頼内容を表示する
          </Link>
          <Link
            v-else-if="props.dispatchOrderMessage.beforeDispatchOrderId"
            :location="states.beforeDispatchOrderLocation"
          >
            前回の依頼内容を表示する
          </Link>
        </div>
      </Typography>
      <Typography>
        <p>いつもお世話になっております。</p>
        <p>
          えんフォト事務局の{{
            props.dispatchOrderMessage.mailSendByName
          }}です。
        </p>
        <br />
        <p>表題の件についてご相談させていただきたく、ご連絡いたしました。</p>
        <p>ご対応が可能か下記、ご確認くださいませ。</p>
      </Typography>
    </div>
  </div>
</template>
