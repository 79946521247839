import {
  IEventDetail,
  IEventInfo,
  IEventTitle,
} from '@/types/photographer/event'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { computed, reactive } from 'vue'
import { useRouter, useRoute, Router } from 'vue-router'
import { CreatingEntity } from '@/entities/global/CreatingEntity'
import { EventsApi } from '@/domains/api/event/EventsApi'
import {
  DeliveryApi,
  IPostDeliveryRequest,
} from '@/domains/api/delivery/DeliveryApi'
import { Computable } from '@/types/utils'
import { createOnAsync, onCreate } from '@/utils/functions/lifecycle'
import { EventEntity } from '@/entities/photographer/EventEntity'
import {
  parseExtendedStringToString,
  parseNumber,
} from '@/utils/functions/parser'
import {
  DirectoryApi,
  IPostDirectoryRequest,
  IPutDirectoryRequest,
} from '@/domains/api/directory/DirectoryApi'
import { IDirectoryInfo } from '@/types/photographer/directory'
import { IEventPageContentProps } from '../../organisms/pageContent/EventPageContent'
import { obtainEntityErrorMessage } from '@/utils/functions/errorHandler'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'
import { EventsEntity } from '@/entities/photographer/EventsEntity'

export interface IEventStates {
  dispatchId: IEventDetail['dispatchId']
  eventTitle: IEventTitle
  eventInfo?: IEventInfo
  directories: IDirectoryInfo[]
}

export const useEventStates = () => {
  const eventEntity = new EventEntity()
  return reactive<Computable<IEventStates>>({
    dispatchId: computed(() => eventEntity.dispatchId),
    eventTitle: computed(() => eventEntity.eventTitle),
    eventInfo: computed(() => eventEntity.eventInfo),
    directories: [],
  })
}

type EventActions = {
  createFolder: IEventPageContentProps['onCreateFolder']
  onDelivery: IEventPageContentProps['onDelivery']
  onDeleteFolder: IEventPageContentProps['onDeleteFolder']
  updateFolderName: IEventPageContentProps['onUpdateFolderName']
}

type EventActionPayload = {
  eventId: number
  states: IEventStates
  router: Router
  creatingEntity: CreatingEntity
  eventEntity: EventEntity
  deliveryApi: DeliveryApi
  directoryApi: DirectoryApi
  eventsApi: EventsApi
  eventsEntity: EventsEntity
}

/**
 * 初期表示時処理の生成
 * @param payload ペイロードデータ
 * @returns 初期表示時処理
 */
const createInitializer = (payload: EventActionPayload) => async () => {
  const { eventId, states, eventEntity, directoryApi, eventsApi } = payload
  eventEntity.setEvent(await eventsApi.fetchEvent(eventId))
  if (
    states.eventInfo?.status === EventStatusCode.BEFORE_ANSWER ||
    states.eventInfo?.status === EventStatusCode.CANCELLED
  )
    return
  states.directories = await directoryApi.fetchDirectories(eventId)
}

/**
 * フォルダ作成処理の生成
 * @param payload ペイロードデータ
 * @returns フォルダ作成処理
 */
const createFolderCreator =
  (payload: EventActionPayload): EventActions['createFolder'] =>
  async (directoryName) => {
    const { eventId, router, creatingEntity, directoryApi } = payload
    const creatingKey = Symbol('createFolder')
    creatingEntity.addCreateSymbol(creatingKey)
    try {
      const folderId = await directoryApi.postDirectory(eventId, directoryName)
      await router.push({
        name: photographerRoutes.NAME.FOLDER,
        params: { eventId, folderId },
      })
      return undefined
    } catch (e) {
      return obtainEntityErrorMessage<IPostDirectoryRequest>(e, 'name')[0]
    } finally {
      creatingEntity.deleteCreateSymbol(creatingKey)
    }
  }

/**
 * 納品処理の生成
 * @param payload ペイロードデータ
 * @returns 納品処理
 */
const createDeliverer =
  (payload: EventActionPayload): EventActions['onDelivery'] =>
  async (deliveryMemo) => {
    const { eventId, creatingEntity, deliveryApi, eventsApi, eventsEntity } =
      payload
    const deliveryKey = Symbol('delivery')
    creatingEntity.addCreateSymbol(deliveryKey)
    try {
      await deliveryApi.postDirectory(eventId, deliveryMemo)
      eventsEntity.events = await eventsApi.fetchEvents()
      return undefined
    } catch (e) {
      return obtainEntityErrorMessage<IPostDeliveryRequest>(
        e,
        'delivery_memo'
      )[0]
    } finally {
      creatingEntity.deleteCreateSymbol(deliveryKey)
    }
  }

/**
 * フォルダ削除処理の生成
 * @param payload ペイロードデータ
 * @returns フォルダ削除処理
 */
const createFolderDeleter =
  (payload: EventActionPayload): EventActions['onDeleteFolder'] =>
  async (directoryIds) => {
    const { eventId, states, creatingEntity, directoryApi } = payload
    await createOnAsync(
      creatingEntity,
      'deleteFolder'
    )(async () => {
      await Promise.all(
        directoryIds.map((id) => directoryApi.deleteDirectory(id))
      )
      states.directories = await directoryApi.fetchDirectories(eventId)
    })
  }

/**
 * フォルダ名更新処理の生成
 * @param payload ペイロードデータ
 * @returns フォルダ名更新処理
 */
const createFolderNameUpdator =
  (payload: EventActionPayload): EventActions['updateFolderName'] =>
  async (directoryId: number, directoryName: string) => {
    const { eventId, states, creatingEntity, directoryApi } = payload
    return await createOnAsync(
      creatingEntity,
      'updateFolderName'
    )(async () => {
      try {
        await directoryApi.putDirectory(directoryId, directoryName)
        states.directories = await directoryApi.fetchDirectories(eventId)
        return undefined
      } catch (e) {
        return obtainEntityErrorMessage<IPutDirectoryRequest>(e, 'name')[0]
      }
    })
  }

export const useEventActions = (states: IEventStates): EventActions => {
  const route = useRoute()
  const eventId = parseNumber(parseExtendedStringToString(route.params.eventId))
  const directoryApi = new DirectoryApi()

  const payload: EventActionPayload = {
    eventId,
    states,
    router: useRouter(),
    creatingEntity: new CreatingEntity(),
    eventEntity: new EventEntity(),
    eventsApi: new EventsApi(),
    directoryApi,
    deliveryApi: new DeliveryApi(),
    eventsEntity: new EventsEntity(),
  }

  onCreate(createInitializer(payload))

  return {
    createFolder: createFolderCreator(payload),
    onDelivery: createDeliverer(payload),
    onDeleteFolder: createFolderDeleter(payload),
    updateFolderName: createFolderNameUpdator(payload),
  }
}
