import { IEventInfo } from '@/types/photographer/event'
import { Computable, Emits, Unref } from '@/types/utils'
import { FolderStore } from '@/vue/stores/photographer/FolderStore'
import { UploadedPhoto } from '@/vue/stores/photographer/FolderStore/UploadedPhoto'
import { computed, reactive } from 'vue'
import { PhotoInfoEmitParams } from '../PhotoInfo'

export interface IPhotosProps {
  photos: Unref<FolderStore['uploadedPhotos']>
  failedPhotos?: Unref<FolderStore['failedPhotos']>
  checkedIdSet: Set<number>
  eventStatus: IEventInfo['status']
  thumbnailUrlGetter(photo: UploadedPhoto): Promise<string>
}

export type PhotosEmitParams = {
  'update:checkedIdSet': IPhotosProps['checkedIdSet']
}

interface IPhotosStates {
  checkedIdSet: Set<number>
  sortedPhotos: Unref<FolderStore['uploadedPhotos']>
}

const sortByPhotographed = (
  [, aVal]: [number, UploadedPhoto],
  [, bVal]: [number, UploadedPhoto]
) => {
  if (aVal.photographed && bVal.photographed) {
    return aVal.photographed > bVal.photographed ? 1 : -1
  }
  return aVal.photographed ? -1 : 1
}

export const usePhotosStates = (props: IPhotosProps) =>
  reactive<Computable<IPhotosStates>>({
    checkedIdSet: computed(() => props.checkedIdSet),
    sortedPhotos: computed(
      () =>
        new Map(
          [...props.photos.entries()]
            .sort(([aKey], [bKey]) => aKey - bKey)
            .sort(sortByPhotographed)
        )
    ),
  })

export const usePhotosActions = (
  states: IPhotosStates,
  emits: Emits<PhotosEmitParams>
) => {
  const checkedPhoto = (obj: PhotoInfoEmitParams['update:checked']): void => {
    const id = parseInt(Object.keys(obj)[0], 10)
    const checked = Object.values(obj)[0]
    checked
      ? states.checkedIdSet.add(id)
      : states.checkedIdSet.forEach((item) => {
          if (item !== id) return
          states.checkedIdSet.delete(item)
        })
    emits('update:checkedIdSet', states.checkedIdSet)
  }

  return {
    checkedPhoto,
  }
}
