import { InvoiceEntity } from '@/entities/photographer/InvoiceEntity'
import { onCreate } from '@/utils/functions/lifecycle'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { useRouter } from 'vue-router'

export type CompleteStates = {
  month: number
}

export const useCompleteStates = (): CompleteStates => {
  return {
    month: 0,
  }
}

export const useCompleteActions = (states: CompleteStates) => {
  onCreate(async () => {
    const invoiceEntity = new InvoiceEntity()
    const month = invoiceEntity.status?.month
    if (month) {
      states.month = month
    } else {
      const router = useRouter()
      await router.push({ name: photographerRoutes.NAME.INVOICES })
    }
  })
}
