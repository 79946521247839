<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue'
import { ITextareaProps } from '.'

interface IProps {
  name?: ITextareaProps['name']
  value?: ITextareaProps['value']
  placeholder?: ITextareaProps['placeholder']
  disabled?: ITextareaProps['disabled']
  maxlength?: ITextareaProps['maxlength']
}

interface ITextareaEmits {
  (e: 'update:value', value: IProps['value']): void
}

const props = defineProps<IProps>()

const textarea = ref<HTMLInputElement>()

const emits = defineEmits<ITextareaEmits>()

const handleTextarea = () => {
  emits('update:value', textarea.value?.value)
}
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.textarea {
  display: block;
  width: 100%;
  background-image: none;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  height: auto;
  min-height: 100px;
  box-sizing: border-box;
  padding: space(2);
}
</style>

<template>
  <textarea
    ref="textarea"
    :class="css.textarea"
    :name="props.name"
    :value="props.value"
    :placeholder="props.placeholder"
    :maxlength="props.maxlength"
    :disabled="props.disabled"
    @input.self="handleTextarea"
  ></textarea>
</template>
