<script setup lang="ts">
import { useInvoicesActions, useInvoicesStates } from '.'
import InvoicePageContent from '@/vueComponents/photographer/organisms/pageContent/InvoicesPageContent/index.vue'
const states = useInvoicesStates()
const actions = useInvoicesActions(states)
</script>

<template>
  <InvoicePageContent
    :breadcrumbs="states.breadcrumbs"
    :invoice-status-info="states.invoiceStatusInfo"
    :table-list="states.tableList"
    @download:pdf="actions.handleDownloadFile"
    @invoice:create:transition="actions.toInvoiceCreate"
  />
</template>
