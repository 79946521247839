import { Computable, Emits } from '@/types/utils'
import { assertString } from '@/utils/functions/typeCheck'
import { computed, reactive } from 'vue'
import { ITextareaProps } from '@/vueComponents/photographer/atoms/Textarea'

export interface ITextareaInputProps {
  name?: string
  value?: string
  placeholder?: string
  description?: Array<string>
  disabled?: boolean
  maxlength?: number
  label?: string
  errorMessage?: string
  isMust?: boolean
}

export type TextareaInputEmitParams = {
  'update:value': ITextareaInputProps['value']
}

interface ITextareaInputStates {
  value: ITextareaProps['value']
}

export const useTextareaInputStates = (
  props: ITextareaInputProps,
  emits: Emits<TextareaInputEmitParams>
) =>
  reactive<Computable<ITextareaInputStates>>({
    value: computed({
      get() {
        return props.value
      },
      set(value) {
        assertString(value)
        emits('update:value', value)
      },
    }),
  })
