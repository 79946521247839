import { parseExtendedStringToString } from '@/utils/functions/parser'
import { PasswordUpdateContentProps } from '@/vueComponents/photographer/organisms/pageContent/PasswordUpdateContent'
import { useRoute } from 'vue-router'
import {
  IPostPasswordResetRequest,
  ResetPasswordApi,
} from '@/domains/api/account/ResetPasswordApi'
import { ResetTokenExpiredError } from '@/utils/modules/exceptions/ResetTokenExpiredError'
import { isUnprocessableEntityError } from '@/utils/modules/exceptions/UnprocessableEntityError'
import { validateLoginId } from '@/utils/functions/validation'
import { ValidationCode } from '@/utils/constants/enums/validation'
import { NotFoundError } from '@/utils/modules/exceptions/NotFoundError'
import { onBeforeMount } from 'vue'
import { obtainEntityErrorMessage } from '@/utils/functions/errorHandler'

type PasswordResetActions = {
  resetPassword: PasswordUpdateContentProps['onClickButton']
}

type PasswordResetPayload = {
  token: string
  loginId: string
  resetPasswordApi: ResetPasswordApi
}

/**
 * URIからトークンとログインIDを取得
 * @returns トークンとログインID
 */
const obtainTokenAndloginId = (): { token: string; loginId: string } => {
  const route = useRoute()
  const token = parseExtendedStringToString(route.params.token)
  const loginId = parseExtendedStringToString(route.query.login_id)
  return { token, loginId }
}

/**
 * URLパラメータの判定
 * @param payload ペイロードデータ
 * @throws {NotFoundError} パラメータ不正
 */
const checkUrlParams = ({ token, loginId }: PasswordResetPayload): void => {
  const hasTokenError = !token
  const hasloginIdError = validateLoginId(loginId) !== ValidationCode.SUCCESS
  if (hasTokenError || hasloginIdError) throw new NotFoundError()
}

/**
 * パスワード再設定時エラー処理
 * @param e エラー
 * @returns エラーメッセージ配列
 * @throws {ResetTokenExpiredError} ログイン情報の誤り(トークン不正)
 * @throws {unknown} 予期せぬエラー
 */
const handlePasswordResetError = (
  e: unknown
): ReturnType<PasswordResetActions['resetPassword']> => {
  if (
    isUnprocessableEntityError<IPostPasswordResetRequest>(e) &&
    (e.errors?.login_id || e.errors?.token)
  ) {
    throw new ResetTokenExpiredError()
  }
  return obtainEntityErrorMessage<IPostPasswordResetRequest>(
    e,
    'password',
    'password_confirmation'
  )
}

/**
 * パスワード再設定処理の生成
 * @param payload ペイロードデータ
 * @returns パスワード再設定処理
 */
const createPasswordResetter =
  (payload: PasswordResetPayload): PasswordResetActions['resetPassword'] =>
  async (password) => {
    const { token, loginId, resetPasswordApi } = payload
    return await resetPasswordApi
      .postPasswordReset(password, password, token, loginId)
      .catch(handlePasswordResetError)
  }

export const usePasswordResetActions = (): PasswordResetActions => {
  const payload: PasswordResetPayload = {
    resetPasswordApi: new ResetPasswordApi(),
    ...obtainTokenAndloginId(),
  }

  onBeforeMount(() => checkUrlParams(payload))

  return { resetPassword: createPasswordResetter(payload) }
}
