import { InjectionKey, ref } from 'vue'
import { BaseStore } from '../..'
import { InvoiceDetail } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTable'
import { Expense } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable'
import { Account } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/Account'
import { FetchStatus } from '@/types/photographer/invoice'
import { InvoiceStatus } from '@/utils/constants/enums/photographer/invoice'

export const defaultStore = {
  details: [],
  note: '',
  gasUnitPrice: 0,
  expenses: [],
  account: null,
  status: null,
}

export const defaultStatus: FetchStatus = {
  month: 1,
  status: InvoiceStatus.CANNOT_CREATE,
  revisionMessage: '',
}

export class InvoiceStore extends BaseStore {
  details = ref<InvoiceDetail[]>([])
  note = ref('')
  gasUnitPrice = ref(0)
  expenses = ref<Expense[]>([])
  account = ref<Account | null>(null)
  status = ref<FetchStatus | null>(null)
}

export const invoiceStoreKey: InjectionKey<InvoiceStore> =
  Symbol('invoiceStoreKey')
