import { Computable } from '@/types/utils'
import { reactive } from 'vue'
import { IFolderNameSettingModalProps } from '../FolderNameSettingModal'

export interface IFolderNameChangeLinkProps {
  disabled?: boolean
  eventDate: Date
  eventName: string
  folderName: string
  onSubmit: IFolderNameSettingModalProps['onSubmit']
}

interface IFolderNameChangeLinkStates {
  isModalOpened: boolean
}

export const useFolderNameChangeLinkStates = () =>
  reactive<Computable<IFolderNameChangeLinkStates>>({
    isModalOpened: false,
  })

export const useFolderNameChangeLinkActions = (
  states: IFolderNameChangeLinkStates
) => {
  return {
    openModal(): void {
      states.isModalOpened = true
    },
  }
}
