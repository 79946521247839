<script setup lang="ts">
import { defineProps } from 'vue'
import Input from '@/vueComponents/photographer/atoms/Input/index.vue'
import { AddressInputProps, useAddressInputActions } from '.'

interface IProps {
  modelValue: AddressInputProps['modelValue']
  locationAddressErrorMessage: AddressInputProps['locationAddressErrorMessage']
}

interface IEmits {
  (e: 'update:modelValue', value: string): void
  (e: 'input:address'): void
}

const emits = defineEmits<IEmits>()
const props = defineProps<IProps>()
const actions = useAddressInputActions(emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/position' as space-positions;
@use '@/styles/mixins/font' as font-mixins;

.title {
  @include font-mixins.font-weight-bold();
}
.table-cell div,
.col {
  @include space-positions.flex-horizontal(space(2));
}
.table-cell {
  padding: space(3) space(2);
  width: 256px;
}
.input-error {
  margin-top: space(1);
}
</style>

<template>
  <Input
    :value="props.modelValue"
    type="text"
    name="Address"
    placeholder="中央区晴海３丁目１２-１ KDX晴海ビル９Ｆ"
    maxlength="250"
    width="408px"
    :has-error="Boolean(props.locationAddressErrorMessage)"
    @input="actions.handleInputAddress"
  ></Input>
</template>
