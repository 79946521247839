import { reactive } from 'vue'
import fileFormat from '@/media/img/uploadRules/img_file_format.png'
import folderCapacity from '@/media/img/uploadRules/img_folder_capacity.png'
import imageCapacity from '@/media/img/uploadRules/img_image_capacity.png'
import { addPublicBasePath } from '@/utils/functions/vite'

type UploadRulesStates = {
  fileFormat: string
  folderCapacity: string
  imageCapacity: string
}

export const useUploadRulesStates = () => {
  return reactive<UploadRulesStates>({
    fileFormat: addPublicBasePath(fileFormat),
    folderCapacity: addPublicBasePath(folderCapacity),
    imageCapacity: addPublicBasePath(imageCapacity),
  })
}
