import { Ref, nextTick } from 'vue'
import { parseZipCode } from '../parser'
import { InvoiceDetailInput } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceDetail/InvoiceDetailTableRow'
import { ExpenseTableRowProps } from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTableRow'
import {
  CONTENT_MAX_LENGTH,
  MAX_AMOUNT,
  MAX_MILEAGE,
  MIN_AMOUNT,
  MIN_MILEAGE,
  ORIGINAL_FILE_NAME_MAX_LENGTH,
} from '@/utils/constants/invoice'

export const defaultAccount = {
  companyName: '',
  name: '',
  zipcode: '',
  prefectureId: 0,
  address: '',
  invoiceNumber: '',
  bankName: '',
  branchName: '',
  accountType: 0,
  accountNumber: '',
  accountHolder: '',
}

export const handleDeleteRow = <T extends { rowNo: number }>(
  list: T[],
  rowNo: T['rowNo']
): T[] => {
  return list.filter((item: T) => item.rowNo !== rowNo)
}

export const isOverFlow = async (
  fileDivElement: Ref<HTMLDivElement | undefined>
) => {
  await nextTick()
  const offsetWidth = fileDivElement.value?.offsetWidth
  const scrollWidth = fileDivElement.value?.scrollWidth
  return offsetWidth && scrollWidth ? offsetWidth < scrollWidth : false
}

export const displayZipcode = (zipcode: string) => {
  if (!zipcode) return ''
  return `${parseZipCode(Number(zipcode))}`
}

/**
 * 全角数字を半角に変換するし、数字以外を削除する
 * @param fieldValue
 * @returns string
 */
const formatNumberInput = (fieldValue: string) => {
  const normalizedValue = fieldValue.replace(/[０-９]/g, (char) => {
    return String.fromCharCode(char.charCodeAt(0) - 0xfee0)
  })
  const onlyNumbers = normalizedValue.replace(/[^\d]/g, '')
  return onlyNumbers
}

export const handleInput = (e: Event) => {
  const target = e.target as HTMLInputElement
  const validatedNumber = formatNumberInput(target.value)
  target.value = validatedNumber
  return validatedNumber
}

export const downloadFile = (obj: object, fileName: string) => {
  const objUrl = URL.createObjectURL(obj as Blob)
  const a = document.createElement('a')
  a.download = fileName
  a.href = objUrl
  a.click()
  URL.revokeObjectURL(objUrl)
}

const openCreatedLink = (url: string) => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.click()
}

export const openLocalFile = (obj: object) => {
  const objUrl = URL.createObjectURL(obj as Blob)
  openCreatedLink(objUrl)
  // FirefoxでのみrevokeObjectURLの実行を遅らせる必要があるため
  setTimeout(() => {
    URL.revokeObjectURL(objUrl)
  }, 100)
}

export const openS3File = async (
  fetchFileUrl?: () => Promise<string>
): Promise<void> => {
  if (!fetchFileUrl) return
  const url = await fetchFileUrl()
  if (!url) return
  openCreatedLink(url)
}

// 請求内訳入力と諸経費入力の共通で使用されているプロパティ（date, content, amount）が空かどうかを判定する
export const isInvoiceCommonInputEmpty = <
  T extends InvoiceDetailInput | ExpenseTableRowProps['expenseInput']
>(
  input: T
): boolean => {
  return input.date === '' && input.content === '' && input.amount === ''
}

export const isDateInputInvalid = (date: string): boolean => {
  return date === ''
}

export const isContentInputInvalid = (content: string): boolean => {
  if (content === '') {
    return true
  }
  return content.length > CONTENT_MAX_LENGTH
}

export const isAmountInputInvalid = (amount: string): boolean => {
  if (amount === '') {
    return true
  }
  const numberAmount = Number(amount)
  if (isNaN(numberAmount)) {
    return true
  }
  return numberAmount < MIN_AMOUNT || numberAmount > MAX_AMOUNT
}

export const isMileageInputInvalid = (mileage: string): boolean => {
  if (mileage === '') {
    return false
  }
  const numberMileage = Number(mileage)
  if (isNaN(numberMileage)) {
    return true
  }
  return numberMileage < MIN_MILEAGE || numberMileage > MAX_MILEAGE
}

export const isOriginalFileNameInputInvalid = (
  originalFileName: string
): boolean => {
  if (originalFileName === '') {
    return false
  }
  return originalFileName.length > ORIGINAL_FILE_NAME_MAX_LENGTH
}
