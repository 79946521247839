export enum InvoiceStatus {
  /** 作成できない */
  CANNOT_CREATE,
  /** 作成可能 */
  CREATEABLE,
  /** 作業中(一時保存) */
  IN_PROGRESS,
  /** 修正中(差し戻しが入った際の修正) */
  MODIFYING,
}
