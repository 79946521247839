import {
  IPhoto,
  IPhotoUploadData,
  IPhotoUploadingStatus,
  IThumbnailData,
} from '@/types/photographer/photo'
import { PhotoUploadStatus } from '@/utils/constants/enums/photographer/photo'
import { BaseApi } from '../BaseApi'

const PATH = {
  FETCH_PHOTOS: '/photographer/api/v1.0/directories/:directory_id/photos',
  PHOTOS: '/photographer/api/v1.0/photos',
  DISPATCHES: '/photographer/api/v1.0/dispatches',
  PHOTO_STATUS: '/photographer/api/v1.0/photos/:photo_id/status',
  PHOTOS_STATUS: '/photographer/api/v1.0/photos/statuses?photo_ids=:photoIds',
  PHOTOS_DELETE: '/photographer/api/v1.0/photos/bulk_delete',
} as const

interface IFetchPhotosRequest {
  directory_id: number
}

interface IFetchPhotosResponse {
  photo_id: number
  photographed: string
  img_name: string
  org_img_name: string
  status: PhotoUploadStatus
  height: number
  width: number
}

interface IPostPhotoRequest {
  directory_id: number
  org_img_name: string
}

interface IPostPhotoResponse {
  photo_id: number
  presigned_url: string
  img_name: string
}

interface IFetchPhotoStatusResponse {
  id: number
  org_file_name: string
  photographed: string
  status: PhotoUploadStatus
  is_completed: boolean
  has_error: boolean
}

interface IDeletePhotoRequest {
  photo_ids: number[]
}

interface IFetchThumbnailUrlResponse {
  photo_id: number
  thumbnail_url: string
}

/** 写真API */
export class PhotoApi extends BaseApi {
  /**
   * 写真一覧取得
   * @param directory_id ディレクトリID
   * @returns directory_idと紐づく写真一覧
   */
  public async fetchPhotos(
    directory_id: IFetchPhotosRequest['directory_id']
  ): Promise<IPhoto[]> {
    const { data } = await this.get<IFetchPhotosResponse[]>(PATH.FETCH_PHOTOS, {
      directory_id,
    })
    return data.map((photo) => ({
      photoId: photo.photo_id,
      photographed: photo.photographed
        ? new Date(photo.photographed)
        : undefined,
      imgName: photo.img_name,
      orgImgName: photo.org_img_name,
      status: photo.status,
      height: photo.height,
      width: photo.width,
    }))
  }

  /**
   * アップロード用署名付きURL取得
   * @param directory_id ディレクトリID
   * @param org_img_name 画像名
   * @returns アップロード用データ
   */
  public async postPhoto(
    directory_id: IPostPhotoRequest['directory_id'],
    org_img_name: IPostPhotoRequest['org_img_name']
  ): Promise<IPhotoUploadData> {
    const { data } = await this.post<IPostPhotoRequest, IPostPhotoResponse>(
      PATH.PHOTOS,
      {
        directory_id,
        org_img_name,
      }
    )
    return {
      photoId: data.photo_id,
      presignedUrl: data.presigned_url,
      imgName: data.img_name,
    }
  }

  /**
   * 写真のアップロードステータスを取得
   * @param photo_id 写真ID
   * @returns 写真アップロード情報
   * @deprecated ステータスを一括取得しているため現在未使用
   */
  public async fetchPhotoStatus(
    photo_id: number
  ): Promise<IPhotoUploadingStatus> {
    const { data } = await this.get<IFetchPhotoStatusResponse>(
      PATH.PHOTO_STATUS,
      { photo_id }
    )
    return {
      id: data.id,
      orgFileName: data.org_file_name,
      status: data.status,
      photographed: data.photographed ? new Date(data.photographed) : undefined,
      isCompleted: data.is_completed,
      hasError: data.has_error,
    }
  }

  /**
   * 写真のアップロードステータスを一括取得
   * @param photo_ids 写真ID
   * @returns 写真アップロード情報
   */
  public async fetchPhotosStatus(
    photo_ids: number[]
  ): Promise<IPhotoUploadingStatus[]> {
    const photoIds = photo_ids.toString()
    const { data } = await this.get<IFetchPhotoStatusResponse[]>(
      PATH.PHOTOS_STATUS,
      { photoIds }
    )
    return data.map((photo) => ({
      id: photo.id,
      orgFileName: photo.org_file_name,
      status: photo.status,
      photographed: photo.photographed
        ? new Date(photo.photographed)
        : undefined,
      isCompleted: photo.is_completed,
      hasError: photo.has_error,
    }))
  }

  /**
   * 写真削除
   * @param photo_ids 写真ID
   */
  public async deletePhoto(
    photo_ids: IDeletePhotoRequest['photo_ids']
  ): Promise<void> {
    await this.delete<IDeletePhotoRequest>(PATH.PHOTOS_DELETE, {
      photo_ids,
    })
  }

  /**
   * サムネイル画像取得
   * @param params イベントID、フォルダID、ファイル名
   * @returns サムネイル画像データ
   */
  public async fetchThumbnailUrl(params: {
    /** イベントID */
    dispatchId: number
    /** フォルダID */
    directoryId: number
    /** ファイル名 */
    imgName: string
  }): Promise<IThumbnailData> {
    const { data } = await this.get<IFetchThumbnailUrlResponse>(
      `${PATH.DISPATCHES}/${params.dispatchId}/directories/${params.directoryId}/${params.imgName}`
    )
    return {
      photoId: data.photo_id,
      thumbnailUrl: data.thumbnail_url,
    }
  }
}
