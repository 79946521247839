<script setup lang="ts">
import { defineProps } from 'vue'
import Tab from '@/vueComponents/photographer/molecules/Tab/index.vue'
import FiscalYearTab from '@/vueComponents/photographer/organisms/pageContent/NotificationsPageContent/FiscalYearTab/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import EmptyMassage from '@/vueComponents/photographer/organisms/section/EmptyMassage/index.vue'
import InspectionDeliveryRequestTable from '@/vueComponents/photographer/organisms/section/InspectionDeliveryRequestTable/index.vue'
import InvoiceRequestTable from '@/vueComponents/photographer/organisms/section/InvoiceRequestTable/index.vue'
import { NotificationsTabProps, useNotificationsTabStates } from '.'

interface IProps {
  matterRequestsByYear: NotificationsTabProps['matterRequestsByYear']
  inspectionDeliveryRequests: NotificationsTabProps['inspectionDeliveryRequests']
  invoiceRequests: NotificationsTabProps['invoiceRequests']
  invoiceGenerationFlg: NotificationsTabProps['invoiceGenerationFlg']
}
const props = defineProps<IProps>()
const states = useNotificationsTabStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.container {
  padding: space(8);
  min-height: 280px;
}
.text {
  padding: space(32.5) space(0);
}
</style>

<template>
  <Tab :tab-items="states.tabItems" tab-item-margin="32px">
    <template #1>
      <FiscalYearTab :matter-requests-by-year="props.matterRequestsByYear"
    /></template>
    <template #2>
      <ContentBox :class="css.container">
        <EmptyMassage
          v-if="!props.inspectionDeliveryRequests[0]"
          height="280px"
        />
        <InspectionDeliveryRequestTable
          v-else
          :inspection-delivery-requests="props.inspectionDeliveryRequests"
        />
      </ContentBox>
    </template>
    <template #3>
      <ContentBox :class="css.container">
        <EmptyMassage v-if="!props.invoiceRequests[0]" height="280px" />
        <InvoiceRequestTable
          v-else
          :invoice-requests="props.invoiceRequests"
        ></InvoiceRequestTable>
      </ContentBox>
    </template>
  </Tab>
</template>
