import { INotifyDeparture } from '@/types/photographer/notifyDeparture'
import { BaseApi } from '../BaseApi'

const PATH = {
  NOTIFY_DEPARTURE: '/photographer/api/v1.0/notify-departure/:token',
} as const

interface IFetchNotifyDepartureRequest {
  token: string
}

export interface IFetchNotifyDepartureResponse {
  expiry_status: number
  is_notified: boolean
}

interface IPostNotifyDepartureRequest {
  token: string
}

/** 出発報告API */
export class NotifyDepartureApi extends BaseApi {
  /**
   * 出発報告状況取得
   * @param token
   * @returns 出発報告状況
   */
  public async fetchNotifyDeparture(
    token: IFetchNotifyDepartureRequest['token']
  ): Promise<INotifyDeparture> {
    const { data } = await this.get<IFetchNotifyDepartureResponse>(
      PATH.NOTIFY_DEPARTURE,
      { token }
    )
    return {
      expiryStatus: data.expiry_status,
      isNotified: data.is_notified,
    }
  }

  /**
   * 出発報告
   * @param token
   */
  public async postNotifyDeparture(
    token: IPostNotifyDepartureRequest['token']
  ): Promise<void> {
    await this.post<IPostNotifyDepartureRequest>(PATH.NOTIFY_DEPARTURE, {
      token,
    })
  }
}
