<script setup lang="ts">
import { defineProps } from 'vue'
import Label from '@/vueComponents/photographer/atoms/Label/index.vue'
import { IEventStatusLabelProps, useEventStatusLabelStates } from '.'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'

interface IProps {
  status: IEventStatusLabelProps['status']
}

const props = defineProps<IProps>()

const states = useEventStatusLabelStates(props)
</script>

<template>
  <Label :color="states.color">
    <Typography type="read-tiny-white" text-align="center">
      {{ states.text }}</Typography
    >
  </Label>
</template>
