<script setup lang="ts">
import { defineProps } from 'vue'
import AccountPageTable from '@/vueComponents/photographer/organisms/pageContent/AccountPageContent/AccountPageTable/index.vue'
import { useBankAccountInfoStates, BankAccountInfoProps } from '.'

interface IProps {
  bankAccountInfo: BankAccountInfoProps['bankAccountInfo']
}

const props = defineProps<IProps>()

const states = useBankAccountInfoStates(props)
</script>

<template>
  <AccountPageTable :group="states.bankAccountInfoList" />
</template>
