/**
 * スケジュール登録回答ステータス
 */
export const enum ScheduleReplyStatus {
  /** 未選択 */
  UNSELECTED = 0,
  /** OK */
  OK = 1,
  /** NG */
  NG = 2,
}
