<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import RadioWithLabel from '@/vueComponents/photographer/molecules/RadioWithLabel/index.vue'
import MustLabel from '@/vueComponents/photographer/atoms/MustLabel/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import {
  SelectTransportationProps,
  useSelectTransportationStates,
  SelectTransportationEmitParams,
  useSelectTransportationActions,
} from '.'
import { TRANSPORTATION } from '@/utils/constants/messages/photographer/transportation'
import { TransportationCode } from '@/utils/constants/enums/photographer/transportation'
interface IProps {
  transportation: SelectTransportationProps['transportation']
  otherTransportation: SelectTransportationProps['otherTransportation']
  replyStatus: SelectTransportationProps['replyStatus']
  reachedCapacity: SelectTransportationProps['reachedCapacity']
  canReply: SelectTransportationProps['canReply']
}
interface IEmits {
  (
    e: 'update:transportation',
    value: SelectTransportationEmitParams['update:transportation']
  ): void
  (
    e: 'update:otherTransportation',
    value: SelectTransportationEmitParams['update:otherTransportation']
  ): void
}

const props = defineProps<IProps>()
const emits = defineEmits<IEmits>()
const states = useSelectTransportationStates(props, emits)
const actions = useSelectTransportationActions(props, states)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/functions/space' as *;
.content {
  &-wrap {
    margin: space(6);
  }
  &-main {
    @include space-mixins.vertical-margin(6, 6);
  }
}
.message {
  &-instruct {
    margin-bottom: space(4);
  }
}
.transportation {
  padding: space(4);
  &-radiobutton {
    display: flex;
  }
  &-radiobutton div:last-child {
    margin-right: space(8);
  }
}
.select {
  &-heading {
    display: flex;
    align-items: center;
    margin-bottom: space(2);
    &-label {
      padding-right: space(1.5);
    }
  }
}
</style>

<template>
  <div :class="css['content-wrap']">
    <Typography type="h3">お引き受けいただける方へ</Typography>
    <div :class="css['content-main']">
      <Typography :class="css['message-instruct']"
        >撮影場所の詳細を確認していただき交通手段を選択してください。</Typography
      >
      <ContentBox type="subinfo" :class="css.transportation">
        <div :class="css['select-heading']">
          <Typography
            type="read-large"
            element="span"
            :class="css['select-heading-label']"
            >交通手段</Typography
          >
          <MustLabel />
        </div>
        <div :class="css['transportation-radiobutton']">
          <RadioWithLabel
            v-model:selected-radio-value="states.transportation"
            v-model:radio-value="TransportationCode.CAR"
            name="transportation"
            :disabled="actions.disabled(TransportationCode.CAR)"
            >{{ TRANSPORTATION.CAR }}</RadioWithLabel
          >
          <RadioWithLabel
            v-model:selected-radio-value="states.transportation"
            v-model:radio-value="TransportationCode.PUBLIC_TRANSPORT"
            name="transportation"
            :disabled="actions.disabled(TransportationCode.PUBLIC_TRANSPORT)"
            >{{ TRANSPORTATION.PUBLIC_TRANSPORT }}</RadioWithLabel
          >
          <RadioWithLabel
            v-model:selected-radio-value="states.transportation"
            v-model:radio-value="TransportationCode.WALK"
            name="transportation"
            :disabled="actions.disabled(TransportationCode.WALK)"
            >{{ TRANSPORTATION.WALK }}</RadioWithLabel
          >
          <RadioWithLabel
            v-model:selected-radio-value="states.transportation"
            v-model:input-value="states.otherTransportation"
            v-model:radio-value="TransportationCode.OTHER"
            :has-input="true"
            :disabled-input="states.disabledInput"
            placeholder="例：タクシー、自転車など"
            input-width="320px"
            name="transportation"
            :disabled="actions.disabled(TransportationCode.OTHER)"
            >{{ TRANSPORTATION.OTHER }}</RadioWithLabel
          >
        </div>
      </ContentBox>
    </div>
    <div>
      <Typography type="read-small">
        ※撮影当日の交通手段変更は、場合によりお受け出来かねます。<br />
        ※その他不明点やご質問がありましたら<Link href="mailto:enphoto@uluru.jp"
          >事務局連絡先までお問い合わせ</Link
        >ください。<br />
      </Typography>
    </div>
  </div>
</template>
