<script setup lang="ts">
import { defineProps, withDefaults } from 'vue'
import MatterRequestTableCell from '@/vueComponents/photographer/organisms/section/MatterRequestTableCell/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import { MatterRequestTableRowProps, useMatterRequestTableRowActions } from '.'

interface IProps {
  matterRequest: MatterRequestTableRowProps['matterRequest']
  hasButton?: MatterRequestTableRowProps['hasButton']
  isBold?: MatterRequestTableRowProps['isBold']
}

const props = withDefaults(defineProps<IProps>(), {
  hasButton: true,
  isBold: false,
})

const actions = useMatterRequestTableRowActions(props)
</script>

<style lang="scss" module="css" scoped>
.pointer {
  cursor: pointer;
}
</style>

<template>
  <TableRow v-if="props.hasButton">
    <MatterRequestTableCell
      :matter-request="matterRequest"
      :has-button="props.hasButton"
      :is-bold="props.isBold"
    />
  </TableRow>
  <TableRow v-else :class="css.pointer" @click="actions.toDetailPage">
    <MatterRequestTableCell
      :matter-request="matterRequest"
      :has-button="props.hasButton"
      :is-bold="props.isBold"
    />
  </TableRow>
</template>
