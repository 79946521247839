<script setup lang="ts">
import { defineEmits, defineProps, withDefaults } from 'vue'
import { SpeechBubbleProps, useSpeechBubbleStates } from '.'

interface IProps {
  shown: SpeechBubbleProps['shown']
  top?: SpeechBubbleProps['top']
  position: SpeechBubbleProps['position']
}

interface IEmits {
  (e: 'update:shown', shown: boolean): void
}

const props = withDefaults(defineProps<IProps>(), {
  top: '0',
})
defineEmits<IEmits>()
const states = useSpeechBubbleStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/z-indexes' as z-indexes;
@use '@/styles/functions/space' as *;

$speech-bubble-border: 1px solid colors.$background-color-primary;

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: z-indexes.$speech-bubble;
}
.container {
  position: absolute;
  top: v-bind('props.top');
  left: 50%;
  transform: translate(v-bind('states.transform'), calc(-100% - 8px));
  padding: space(4);
  background-color: colors.$background-color-content-primary;
  border-radius: 8px;
  border: $speech-bubble-border;
  &::after {
    content: '';
    width: 16px;
    height: 16px;
    background-color: colors.$background-color-content-primary;
    border-left: $speech-bubble-border;
    border-bottom: $speech-bubble-border;
    position: absolute;
    transform: rotate(-45deg);
    top: calc(100% - 8px);
    left: calc(v-bind('states.position') - 8px);
  }
}
</style>

<style lang="scss" scoped>
.fade {
  &-enter-active {
    transition: all 0.2s ease-out;
  }

  &-leave-active {
    transition: all 0.2s;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>

<template>
  <transition name="fade">
    <div v-show="props.shown" :class="css.wrapper">
      <div :class="css.container">
        <slot />
      </div>
    </div>
  </transition>
</template>
