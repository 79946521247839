<script setup lang="ts">
import ProposalContent from '@/vueComponents/photographer/organisms/pageContent/ProposalContent/index.vue'
import { useProposalStates, useProposalActions } from '.'

const states = useProposalStates()
const actions = useProposalActions(states)
</script>

<template>
  <ProposalContent
    v-if="
      states.dispatchOrderMessage &&
      states.dispatchOrderDetail &&
      states.dispatchOrderTransportation &&
      states.dispatchOrderStatus
    "
    :user-name="states.userName"
    :dispatch-order-id="states.dispatchOrderId"
    :dispatch-order-message="states.dispatchOrderMessage"
    :dispatch-order-detail="states.dispatchOrderDetail"
    :dispatch-order-memo="states.dispatchOrderMemo"
    :dispatch-order-transportation="states.dispatchOrderTransportation"
    :dispatch-order-status="states.dispatchOrderStatus"
    :on-reply="actions.onReply"
  />
</template>
