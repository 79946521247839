import { RowProps } from './Row'

export type Expense = {
  date: string
  content: string
  originalFileName: string
  file: File | null
  fetchFileUrl?: () => Promise<string>
  amount: number
}

export type TableProps = {
  expenses: Expense[]
}

type TableStates = {
  expenses: RowProps[]
}

export const useTableStates = (props: TableProps): TableStates => {
  return {
    expenses: props.expenses.map((expense, index) => {
      return {
        rowNo: index + 1,
        date: expense.date,
        content: expense.content,
        originalFileName: expense.originalFileName,
        file: expense.file,
        fetchFileUrl: expense.fetchFileUrl,
        amount: expense.amount,
      }
    }),
  }
}
