import { DispatchOrderApi } from '@/domains/api/dispatch_order/DispatchOrderApi'
import { EventsApi } from '@/domains/api/event/EventsApi'
import { CreateRequestApi } from '@/domains/api/invoice/CreateRequestApi'
import { SelfEntity } from '@/entities/SelfEntity'
import { EventsEntity } from '@/entities/photographer/EventsEntity'
import { Computable } from '@/types/utils'
import { onCreate } from '@/utils/functions/lifecycle'
import { computed, reactive } from 'vue'
import { NotificationsPageContentProps } from '../../organisms/pageContent/NotificationsPageContent'

export type NotificationsStates = NotificationsPageContentProps

export const useNotificationsStates = () => {
  const eventsEntity = new EventsEntity()
  const selfEntity = new SelfEntity()
  return reactive<Computable<NotificationsStates>>({
    matterRequests: [],
    events: computed(() => eventsEntity.events),
    invoiceRequests: [],
    invoiceGenerationFlg: computed(() => selfEntity.invoiceGenerationFlg),
  })
}

export const useNotificationsActions = (states: NotificationsStates) => {
  const eventsEntity = new EventsEntity()
  const eventsApi = new EventsApi()
  const dispatchOrderApi = new DispatchOrderApi()
  const createRequestApi = new CreateRequestApi()

  onCreate(async () => {
    if (!eventsEntity.events[0]) {
      eventsEntity.events = await eventsApi.fetchEvents()
    }

    states.matterRequests = await dispatchOrderApi.fetchDispatchOrder()
    states.invoiceRequests = await createRequestApi.fetchInvoices()
  })
}
