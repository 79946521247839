<script setup lang="ts">
import { defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Link from '@/vueComponents/photographer/atoms/Link/index.vue'
import Separator from '@/vueComponents/photographer/atoms/Separator/index.vue'
import ContentBox from '@/vueComponents/photographer/atoms/ContentBox/index.vue'
import EventTable from '@/vueComponents/photographer/organisms//pageContent/TopPageContent/Event/EventTable/index.vue'
import { EventProps, useEventActions } from '.'

interface IProps {
  events: EventProps['events']
}

const props = defineProps<IProps>()
const actions = useEventActions()
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/functions/space' as *;
.container {
  padding: space(8) space(8);
}
.title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.separator {
  @include space-mixins.vertical-margin(6);
}
</style>

<template>
  <ContentBox>
    <div :class="css.container">
      <div :class="css.title">
        <Typography type="h2-simple">最新のイベント一覧</Typography>
        <Link display="block" @click="actions.toEvents">すべて見る</Link>
      </div>
      <div :class="css.separator"><Separator /></div>
      <EventTable :events="props.events" />
    </div>
  </ContentBox>
</template>
