import { ISelf } from '@/types/photographer/self'
import { setBugsnagUserData } from '@/utils/functions/bugsnag'
import { BaseApi } from '../BaseApi'

const PATH = {
  GET_SELF: '/photographer/api/v1.0/self',
} as const

interface IGetSelfResponse {
  name: string
  email: string
  initial_password_flg: boolean
  is_schedule_registered: boolean
  invoice_generation_flg: boolean
}

export class SelfApi extends BaseApi {
  public async fetchSelf(): Promise<ISelf> {
    const { data } = await this.get<IGetSelfResponse>(PATH.GET_SELF)
    setBugsnagUserData({ email: data.email, name: data.name })

    return {
      name: data.name,
      email: data.email,
      initialPasswordFlg: data.initial_password_flg,
      isScheduleRegistered: data.is_schedule_registered,
      invoiceGenerationFlg: data.invoice_generation_flg,
    }
  }
}
