<script setup lang="ts">
import { ref, defineProps, withDefaults } from 'vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import SpeechBubble from '@/vueComponents/photographer/molecules/SpeechBubble/index.vue'
import { HelpIconProps, useHelpIconActions, useHelpIconStates } from '.'

interface IProps {
  speechBubblePosition?: HelpIconProps['speechBubblePosition']
}

const props = withDefaults(defineProps<IProps>(), {
  speechBubblePosition: 50,
})

const states = useHelpIconStates()

const container = ref<Element>()

const actions = useHelpIconActions(states, container)
</script>

<style lang="scss" module="css" scoped>
.container {
  position: relative;
}
.icon {
  display: flex;
  align-items: center;
  width: fit-content;
}
.speech-bubble {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>

<template>
  <div ref="container" :class="css.container" @mouseover="actions.openHelp">
    <div :class="css.icon" @click.stop="actions.openHelp">
      <Icon type="question" is-block />
    </div>
    <SpeechBubble
      v-model:shown="states.isHelpShown"
      top="-8px"
      :position="props.speechBubblePosition"
      @mouseleave="actions.closeHelp"
    >
      <slot />
    </SpeechBubble>
  </div>
</template>
