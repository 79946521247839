import { IInvoiceRequest } from '@/types/photographer/invoice'
import { BaseApi } from '../BaseApi'

const PATH = {
  INVOICES: '/photographer/api/v1.0/invoices/create_requests',
} as const

interface IFetchInvoicesResponse {
  requested_date: string
  year: number
  month: number
  is_submitted_flg: boolean
  revision_request_flg: boolean
}

const formatRequestedDate = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export class CreateRequestApi extends BaseApi {
  /**
   * 請求書依頼一覧
   * @param limit 表示する件数
   * @returns 請求書依頼一覧
   */
  public async fetchInvoices(limit?: number): Promise<IInvoiceRequest[]> {
    const params = new URLSearchParams()
    if (limit) params.append('limit', limit.toString())
    const { data } = await this.get<IFetchInvoicesResponse[]>(
      `${PATH.INVOICES}?${params.toString()}`
    )
    return data.map((invoice: IFetchInvoicesResponse) => {
      const requestedDateDateType = new Date(invoice.requested_date)
      const formattedRequestedDate = formatRequestedDate(requestedDateDateType)
      return {
        requestedDate: formattedRequestedDate,
        year: invoice.year,
        month: invoice.month,
        isSubmittedFlg: invoice.is_submitted_flg,
        revisionRequestFlg: invoice.revision_request_flg,
      }
    })
  }
}
