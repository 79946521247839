<script setup lang="ts">
import InvoiceCompletePageContent from '@/vueComponents/photographer/organisms/pageContent/InvoiceCompletePageContent/index.vue'
import { useCompleteStates, useCompleteActions } from '.'

const states = useCompleteStates()
useCompleteActions(states)
</script>

<template>
  <InvoiceCompletePageContent :month="states.month" />
</template>
