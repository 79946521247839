import { useOutsideClickDetection } from '@/utils/functions/eventListener'
import { reactive, Ref } from 'vue'

export type HelpIconProps = {
  speechBubblePosition?: number
}

type HelpIconStates = {
  isHelpShown: boolean
}

export const useHelpIconStates = (): HelpIconStates =>
  reactive<HelpIconStates>({
    isHelpShown: false,
  })

type HelpIconActions = {
  openHelp: () => void
  closeHelp: () => void
}

export const useHelpIconActions = (
  states: HelpIconStates,
  container: Ref<Element | undefined>
): HelpIconActions => {
  const closeHelp: HelpIconActions['closeHelp'] = () => {
    states.isHelpShown = false
  }
  useOutsideClickDetection(closeHelp, container)

  return {
    openHelp() {
      states.isHelpShown = true
    },
    closeHelp,
  }
}
