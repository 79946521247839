<script setup lang="ts">
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import TableRow from './Row/index.vue'
import TableHeaderRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import { TableProps, useTableStates } from '.'

interface IProps {
  expenses: TableProps['expenses']
}
const props = defineProps<IProps>()
const states = useTableStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
@use '@/styles/mixins/space' as space-mixins;
@use '@/styles/sizes' as sizes;

.table {
  border-radius: 4px 4px 0 0;
  color: colors.$font-color-primary;
}
.header {
  font-weight: bold;
  font-size: sizes.$font-size-md;
  text-align: center;
  th {
    vertical-align: middle;
  }
}
.left {
  text-align: left;
  margin-left: space(2);
}
</style>

<template>
  <Table :class="css.table">
    <template #head>
      <TableHeaderRow type="head" height="40px" :class="css.header">
        <th scope="col">No.</th>
        <th scope="col">
          <div :class="css.left">日付</div>
        </th>
        <th scope="col">
          <div :class="css.left">諸経費内容</div>
        </th>
        <th scope="col">
          <div :class="css.left">領収書添付</div>
        </th>
        <th scope="col">適用税率</th>
        <th scope="col">
          <div :class="css.left">諸経費金額（税込）</div>
        </th>
      </TableHeaderRow>
    </template>
    <TableRow
      v-for="item in states.expenses"
      :key="item.rowNo"
      :row-no="item.rowNo"
      :date="item.date"
      :content="item.content"
      :original-file-name="item.originalFileName"
      :file="item.file"
      :fetch-file-url="item.fetchFileUrl"
      :amount="item.amount"
    />
  </Table>
</template>
