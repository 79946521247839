<script setup lang="ts">
import ResetMailFormContent from '@/vueComponents/photographer/organisms/pageContent/ResetMailFormContent/index.vue'
import BeforeLoginContentBox from '@/vueComponents/photographer/organisms/contentBox/BeforeLoginContentBox/index.vue'
import { useResetMailFormActions } from '.'

const actions = useResetMailFormActions()
</script>

<template>
  <div>
    <BeforeLoginContentBox title="パスワード設定">
      <ResetMailFormContent :on-click="actions.passwordLoginId" />
    </BeforeLoginContentBox>
  </div>
</template>
