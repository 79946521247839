<script setup lang="ts">
import TopPageContent from '@/vueComponents/photographer/organisms/pageContent/TopPageContent/index.vue'
import { useTopStates, useTopActions } from '.'

const states = useTopStates()
const actions = useTopActions(states)
</script>

<template>
  <TopPageContent
    :limit-matter-requests="states.limitMatterRequests"
    :notice-matter-requests="states.noticeMatterRequests"
    :events="states.events"
    :invoice-requests="states.invoiceRequests"
    :is-schedule-registered="states.isScheduleRegistered"
    :todays-date="states.todaysDate"
    :display-year-month="states.displayYearMonth"
    :schedules="states.schedules"
    :is-loading="states.isLoading"
    :invoice-generation-flg="states.invoiceGenerationFlg"
    :click-prev="actions.clickPrev"
    :click-next="actions.clickNext"
  ></TopPageContent>
</template>
