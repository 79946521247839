<script setup lang="ts">
import Table from '@/vueComponents/photographer/atoms/Table/index.vue'
import ExpenseTableRow from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTableRow/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import AddRowButton from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/AddRowButton/index.vue'
import {
  ExpenseTableEmitParams,
  ExpenseTableProps,
  useExpenseTableActions,
  useExpenseTableStates,
} from '@/vueComponents/photographer/organisms/pageContent/InvoiceCreatePageContent/InvoiceExpense/InvoiceExpenseTable'

interface IProps {
  expenses: ExpenseTableProps['expenses']
}
interface IEmits {
  (e: 'update:expenses', value: ExpenseTableEmitParams['update:expenses']): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = useExpenseTableStates()
const actions = useExpenseTableActions(props, states, emits)

defineExpose({
  validate: actions.validate,
})
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
@use '@/styles/colors' as colors;
th {
  vertical-align: middle;
}
.th-title-align-left {
  text-align: left;
  padding-left: space(2.5);
}
.footer {
  display: flex;
  justify-content: space-between;
  margin-top: space(6);
}
.amount-block {
  background: colors.$background-color-gray;
  padding: space(4);
  width: 289px;
  box-sizing: border-box;
  dl {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  dt {
    width: 60%;
  }
  dd {
    width: 40%;
    text-align: right;
  }
}
.total-amount {
  font-weight: bold;
}
</style>

<template>
  <Table>
    <template #head>
      <TableRow type="head" height="40px">
        <th scope="col">
          <Typography type="read-normal-bold" element="p">No.</Typography>
        </th>
        <th scope="col" :class="css['th-title-align-left']">
          <Typography type="read-normal-bold" element="p">日付</Typography>
        </th>
        <th scope="col" :class="css['th-title-align-left']">
          <Typography type="read-normal-bold" element="p"
            >諸経費内容</Typography
          >
        </th>
        <th scope="col" :class="css['th-title-align-left']">
          <Typography type="read-normal-bold" element="p"
            >領収書添付</Typography
          >
        </th>
        <th scope="col">
          <Typography type="read-normal-bold" element="p">適用税率</Typography>
        </th>
        <th scope="col" :class="css['th-title-align-left']">
          <Typography type="read-normal-bold" element="p"
            >諸経費金額（税込）</Typography
          >
        </th>
        <th scope="col">
          <Typography type="read-normal-bold" element="p">操作</Typography>
        </th>
      </TableRow>
    </template>
    <ExpenseTableRow
      v-for="item in states.expenses"
      :id="item.id"
      :key="item.rowNo"
      v-model:expense-input="item.expenseInput"
      :row-no="item.rowNo"
      :fetch-file-url="item.fetchFileUrl"
      :has-row-error="item.hasRowError"
      :has-file-error="item.hasFileError"
      :file-name="item.fileName"
      @delete-row="actions.deleteRow(item.rowNo)"
      @set-file="(params) => actions.setFile(params, item.rowNo)"
    />
  </Table>
  <div :class="css.footer">
    <AddRowButton @add-row="actions.addRow" />
    <div :class="css['amount-block']">
      <dl>
        <dt :class="css['total-amount']">税込総額</dt>
        <dd :class="css['total-amount']">
          {{ states.totalAmount.toLocaleString() }} 円
        </dd>
      </dl>
    </div>
  </div>
</template>
