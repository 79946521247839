<script setup lang="ts">
import PasswordUpdateContent from '@/vueComponents/photographer/organisms/pageContent/PasswordUpdateContent/index.vue'
import BeforeLoginContentBox from '@/vueComponents/photographer/organisms/contentBox/BeforeLoginContentBox/index.vue'
import { PHOTOGRAPHER } from '@/utils/constants/messages/photographer'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { usePasswordResetActions } from '.'

const actions = usePasswordResetActions()
</script>

<template>
  <BeforeLoginContentBox title="パスワード設定">
    <PasswordUpdateContent
      :message="PHOTOGRAPHER.MESSAGE.PASSWORD_RESETTING"
      button-label="再設定"
      :on-click-button="actions.resetPassword"
      :next-location="{ name: photographerRoutes.NAME.TOP }"
    />
  </BeforeLoginContentBox>
</template>
