<script setup lang="ts">
import { defineProps } from 'vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import TableCell from '@/vueComponents/photographer/atoms/TableCell/index.vue'
import TableRow from '@/vueComponents/photographer/atoms/TableRow/index.vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import EventStatusLabel from '@/vueComponents/photographer/organisms/label/EventStatusLabel/index.vue'
import { EventTypeCode } from '@/utils/constants/enums/photographer/event'
import {
  IEventTableRowProps,
  useEventTableRowStates,
  useEventTableRowActions,
} from '.'
import Button from '@/vueComponents/photographer/atoms/Button/index.vue'

interface IProps {
  event: IEventTableRowProps['event']
}

const props = defineProps<IProps>()

const states = useEventTableRowStates(props)

const actions = useEventTableRowActions(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/functions/space' as *;
.event-name {
  margin-left: 6px;
}
.time-icon {
  margin-right: space(1);
}
.event-status-label {
  text-align: center;
}
.label {
  margin-right: auto;
  margin-left: auto;
}
</style>

<template>
  <TableRow>
    <TableCell>
      <div :class="css['event-name']">
        <Typography>{{ props.event.sellerName }}</Typography>
        <Typography type="read-small">{{ props.event.eventName }}</Typography>
      </div>
    </TableCell>
    <TableCell>
      <Typography text-align="center">{{ states.eventDateStr }}</Typography>
      <Typography text-align="center" type="read-tiny"
        ><Icon :class="css['time-icon']" type="clock" />{{
          states.eventStartTime
        }}〜{{ states.eventEndTime }}</Typography
      >
    </TableCell>
    <TableCell>
      <Typography text-align="center">{{ states.eventTypeStr }}</Typography>
      <Typography
        v-if="props.event.type === EventTypeCode.POSTPONED"
        text-align="center"
        type="read-tiny"
        >通常{{ states.plannedStartDateStr }}</Typography
      >
    </TableCell>
    <TableCell>
      <Typography text-align="center">{{
        states.deliveryDeadlineStr
      }}</Typography>
    </TableCell>
    <TableCell :class="css['event-status-label']">
      <EventStatusLabel :class="css.label" :status="props.event.status" />
    </TableCell>
    <TableCell :separatorable="false">
      <Button type="medium" color="secondary" @click.once="actions.selectEvent"
        >詳細を見る</Button
      >
    </TableCell>
  </TableRow>
</template>
