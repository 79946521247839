<script setup lang="ts">
import { defineProps } from 'vue'
import { ProposalDetailProps, useProposalDetailStates } from '.'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import DataList from '@/vueComponents/photographer/atoms/DataList/index.vue'
import DataListItem from '@/vueComponents/photographer/atoms/DataListItem/index.vue'
import Icon from '@/vueComponents/photographer/atoms/Icon/index.vue'
import { EventTypeCode } from '@/utils/constants/enums/photographer/event'

interface IProps {
  dispatchOrderDetail: ProposalDetailProps['dispatchOrderDetail']
}

const props = defineProps<IProps>()
const states = useProposalDetailStates(props)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/colors' as colors;
@use '@/styles/functions/space' as *;
@use '@/styles/mixins/space' as space-mixins;

.content {
  margin: space(6) space(4);
}
.detail-head {
  margin-bottom: space(6);
}
.event-date {
  margin-right: space(4);
}
.event-type {
  margin-right: space(3.5);
}
.dd-flex {
  display: flex;
  align-items: center;
}
.list {
  @include space-mixins.vertical-padding(4);
}
.icon {
  width: 31px;
  text-align: center;
  padding-bottom: 3px;
  &-clock {
    padding-right: space(1);
  }
}
</style>

<template>
  <div :class="css.content">
    <Typography type="h3" :class="css['detail-head']">内容詳細</Typography>
    <DataList>
      <DataListItem>
        <span :class="css['icon']"><Icon type="camera" /></span
        ><Typography type="head">撮影日程</Typography>
        <template #desc>
          <div :class="css['list']">
            <div :class="css['dd-flex']">
              <Typography :class="css['event-date']">
                {{ states.eventDateStr }}
              </Typography>
              <Icon type="clock" :class="css['icon-clock']" /><Typography
                type="read-tiny"
              >
                {{ states.eventStartTime }}~{{ states.eventEndTime }}
              </Typography>
            </div>
            <div>
              <Typography type="read-small">
                ※撮影時間は、多少前後する可能性があります。
              </Typography>
            </div>
          </div>
        </template>
      </DataListItem>
      <DataListItem>
        <span :class="css['icon']"><Icon type="event-info-clock" /></span
        ><Typography type="head">集合時間</Typography>
        <template #desc>
          <Typography>{{
            props.dispatchOrderDetail.meetingPointTime
          }}</Typography>
        </template>
      </DataListItem>
      <DataListItem>
        <span :class="css['icon']"><Icon type="map-marker" /></span
        ><Typography type="head">集合場所</Typography>
        <template #desc>
          <div :class="css['list']">
            <Typography>{{
              props.dispatchOrderDetail.meetingPointName
            }}</Typography>
            <div>
              <Typography>{{ states.venueAddress }}</Typography>
            </div>
          </div>
        </template>
      </DataListItem>
      <DataListItem>
        <span :class="css['icon']"><Icon type="campground" /></span
        ><Typography type="head">撮影場所</Typography>
        <template #desc>
          <div :class="css['list']">
            <Typography>{{ props.dispatchOrderDetail.venueName }}</Typography>
            <Typography>{{ states.venueAddress }}</Typography>
          </div>
        </template>
      </DataListItem>
      <DataListItem>
        <span :class="css['icon']"><Icon type="calendar" /></span
        ><Typography type="head">案件種別</Typography>
        <template #desc>
          <Typography :class="css['event-type']">
            {{ states.eventTypeStr }}
          </Typography>
          <Typography
            v-if="props.dispatchOrderDetail.type === EventTypeCode.POSTPONED"
            type="read-tiny"
          >
            通常開催 {{ states.typeNormalStartDate }}
          </Typography>
        </template>
      </DataListItem>
      <DataListItem>
        <span :class="css['icon']"><Icon type="photo-video" /></span
        ><Typography type="head">撮影内容</Typography>
        <template #desc>
          <Typography>{{ props.dispatchOrderDetail.eventName }}</Typography>
        </template>
      </DataListItem>
      <DataListItem>
        <span :class="css['icon']"><Icon type="yen-sign" /></span
        ><Typography type="head">撮影料金</Typography>
        <template #desc>
          <div :class="css['list']">
            <Typography> {{ states.consultationAmount }}円</Typography>
            <Typography type="read-small">
              ※撮影料金は、繁忙期のご依頼、4時間を超える長時間のご依頼の場合など、別途事務局より価格調整のご連絡をさせていただく可能性がございます。<br />
              そのため上記価格は参考価格とご認識ください。
            </Typography>
          </div>
        </template>
      </DataListItem>
    </DataList>
  </div>
</template>
