import { validateNotEntered } from '@/utils/functions/validation/validation'
import { reactive } from 'vue'

export interface PrefectureInputProps {
  modelValue: number
  prefecturesOptions: {
    value: string | number
    label: string
    disabled?: boolean
  }[]
}

export interface PrefectureInputStates {
  PrefectureErrorMessage: string
}

interface PrefectureInputEmits {
  (e: 'update:modelValue', value: number): void
}

export const usePrefectureInputStates = () => {
  return reactive<PrefectureInputStates>({
    PrefectureErrorMessage: '',
  })
}

type PrefectureActions = {
  handleInputPrefecture(value: number): void
}

export const usePrefectureInputActions = (
  states: PrefectureInputStates,
  emits: PrefectureInputEmits
): PrefectureActions => {
  return {
    handleInputPrefecture(value: number) {
      states.PrefectureErrorMessage = ''
      emits('update:modelValue', Number(value))
      if (validateNotEntered(Number(value))) {
        states.PrefectureErrorMessage = '未入力です'
      }
    },
  }
}
