import { IEvent, IFilterEvents } from '@/types/photographer/event'
import { EventStatusCode } from '@/utils/constants/enums/photographer/event'
import { InspectionDeliveryRequestTableProps } from '@/vueComponents/photographer/organisms/section/InspectionDeliveryRequestTable'

/**
 * 検品納品依頼情報の生成
 * @param events イベント一覧
 * @returns 検品納品依頼情報
 */
export const createInspectionDeliveryRequests = (
  events: IEvent[]
): InspectionDeliveryRequestTableProps =>
  events
    .filter(
      (event: IEvent): event is IFilterEvents =>
        event.status === EventStatusCode.BEFORE_DELIVERY ||
        event.status === EventStatusCode.SENDING_BACK
    )
    .map((event) => ({
      sellerName: event.sellerName,
      eventName: event.eventName,
      type: event.status,
      eventId: event.dispatchId,
    }))
