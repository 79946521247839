import { Emits } from '@/types/utils'

export type DropzoneEmitParams = {
  drop: File[]
}

export const useDropzoneActions = (emits: Emits<DropzoneEmitParams>) => {
  const dropFiles = (event: DragEvent): void => {
    event.preventDefault()
    const { dataTransfer } = event
    if (!dataTransfer) return
    const { files } = dataTransfer
    if (!files) return
    emits('drop', Array.from(files))
  }

  return { dropFiles }
}
