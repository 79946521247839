import { DirectoryApi } from '@/domains/api/directory/DirectoryApi'
import { EventsApi } from '@/domains/api/event/EventsApi'
import { PhotoApi } from '@/domains/api/photo/PhotoApi'
import { IDirectoryDetail } from '@/types/photographer/directory'
import { InspectionStatusCode } from '@/utils/constants/enums/photographer/event'
import { FileUploadApi } from '@/domains/api/serverless/FileUploadApi'
import { EventEntity } from '@/entities/photographer/EventEntity'
import { onCreate } from '@/utils/functions/lifecycle'
import {
  parseExtendedStringToString,
  parseJaDateToString,
  parseNumber,
} from '@/utils/functions/parser'
import { photographerRoutes } from '@/vue/routers/photographer/routes'
import { IBreadcrumb } from '@/vueComponents/photographer/molecules/PageBreadcrumbs'
import { IFolderPageContentProps } from '@/vueComponents/photographer/organisms/pageContent/FolderPageContent'
import { computed, reactive, Ref, ref } from 'vue'
import { LocationQueryValue, Router, useRoute, useRouter } from 'vue-router'
import { CreatingEntity } from '@/entities/global/CreatingEntity'
import { FolderEntity } from '@/entities/photographer/FolderEntity'
import { IEventInfo, IEventTitle } from '@/types/photographer/event'
import {
  createInitializer,
  createFileUploader,
  createPhotoDeleter,
  createThumbnailUrlGetter,
  createUploadingStatusUpdater,
  createFailedPhotosStatusUpdater,
} from './folderAction'

export type FolderStates = {
  folderDetail: IDirectoryDetail
  photos: IFolderPageContentProps['photos']
  failedPhotos: IFolderPageContentProps['failedPhotos']
  breadcrumbs: IBreadcrumb[]
  folderInspectionMessage: IFolderPageContentProps['folderInspectionMessage']
  eventInspectionMessage: IFolderPageContentProps['eventInspectionMessage']
  eventId: string
  sellerName: IEventTitle['sellerName']
  eventName: IEventTitle['eventName']
  eventStatus: IEventInfo['status']
  eventDate: string
  slowModeFlg: LocationQueryValue | LocationQueryValue[]
}

export type FolderPayload = {
  states: FolderStates
  eventId: number
  directoryId: number
  router: Router
  creatingEntity: CreatingEntity
  eventEntity: EventEntity
  folderEntity: FolderEntity
  eventApi: EventsApi
  directoryApi: DirectoryApi
  photoApi: PhotoApi
  fileUploadApi: FileUploadApi
}

/**
 * パンくずリスト用Computedの生成
 * @param folderDetailRef フォルダ詳細情報（Ref）
 * @param eventId イベントID
 * @param eventEntity イベントエンティティ
 * @returns パンくずリスト用Computed
 */
const defineBreadcrumbs = (
  folderDetailRef: Ref<FolderStates['folderDetail']>,
  eventId: string,
  eventEntity: EventEntity,
  eventDate: FolderStates['eventDate']
) => {
  return computed<FolderStates['breadcrumbs']>(() => [
    {
      label: 'ホーム',
      location: { name: photographerRoutes.NAME.TOP },
    },
    {
      label: 'イベント一覧',
      location: { name: photographerRoutes.NAME.EVENTS },
    },
    {
      label: `${eventEntity.eventTitle.sellerName} ${eventEntity.eventTitle.eventName}`,
      location: { name: photographerRoutes.NAME.EVENT, params: { eventId } },
    },
    {
      label: `${eventDate}${'\u3000'}${eventEntity.eventTitle.eventName}_${
        folderDetailRef.value.name
      }`,
    },
  ])
}

export const useFolderStates = (): FolderStates => {
  const route = useRoute()
  const eventId = parseExtendedStringToString(route.params.eventId)
  const eventEntity = new EventEntity()
  const folderEntity = new FolderEntity()
  const eventDate = parseJaDateToString(
    eventEntity.eventInfo?.startDate ?? new Date()
  )

  const folderDetailRef = ref<FolderStates['folderDetail']>({
    id: 0,
    dispatchId: 0,
    name: '',
    inspectStatus: InspectionStatusCode.WAITING_INSPECTION,
    inspectMemo: '',
  })

  const states: FolderStates = reactive({
    folderDetail: folderDetailRef,
    photos: computed(() => folderEntity.uploadedPhotos),
    failedPhotos: computed(() => folderEntity.failedPhotos),
    breadcrumbs: defineBreadcrumbs(
      folderDetailRef,
      eventId,
      eventEntity,
      eventDate
    ),
    folderInspectionMessage: computed(() =>
      states.folderDetail.inspectStatus === InspectionStatusCode.SENDING_BACK
        ? {
            name: states.folderDetail.name,
            inspectMemo: states.folderDetail.inspectMemo,
          }
        : {
            name: '',
            inspectMemo: '',
          }
    ),
    eventInspectionMessage: computed(
      () => eventEntity.eventInfo?.inspectMemo ?? ''
    ),
    eventId,
    sellerName: computed(() => eventEntity.eventTitle.sellerName),
    eventName: computed(() => eventEntity.eventTitle.eventName),
    eventStatus: computed(() => eventEntity.eventInfo?.status ?? 0),
    eventDate,
    slowModeFlg: computed(() => route.query.slow),
  })
  return states
}

type FolderActions = {
  onUploadFile: IFolderPageContentProps['onUploadFile']
  onUpdateUploadingStatus: IFolderPageContentProps['onUpdateUploadingStatus']
  onUpdateFailedPhotosStatus: IFolderPageContentProps['onUpdateFailedPhotosStatus']
  onDeletePhoto: IFolderPageContentProps['onDeletePhoto']
  thumbnailUrlGetter: IFolderPageContentProps['thumbnailUrlGetter']
}

export const useFolderActions = (states: FolderStates): FolderActions => {
  const route = useRoute()
  const payload: FolderPayload = {
    states,
    eventId: parseNumber(parseExtendedStringToString(route.params.eventId)),
    directoryId: parseNumber(
      parseExtendedStringToString(route.params.folderId)
    ),
    router: useRouter(),
    creatingEntity: new CreatingEntity(),
    eventEntity: new EventEntity(),
    folderEntity: new FolderEntity(),
    eventApi: new EventsApi(),
    directoryApi: new DirectoryApi(),
    photoApi: new PhotoApi(),
    fileUploadApi: new FileUploadApi(),
  }
  onCreate(createInitializer(payload))
  return {
    onUploadFile: createFileUploader(payload),
    onUpdateUploadingStatus: createUploadingStatusUpdater(payload),
    onUpdateFailedPhotosStatus: createFailedPhotosStatusUpdater(payload),
    onDeletePhoto: createPhotoDeleter(payload),
    thumbnailUrlGetter: createThumbnailUrlGetter(payload),
  }
}
